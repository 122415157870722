/**
 * @description normalizes the value passed to it if the value is string 
 * it will trim and conform empty spaces to single space 
 * it will confirm dashes to a specific type of dash (-)
 * @param {*} value 
 * @returns not-null
 */
const normalizeValue = (value) => {
    if (value === null) {
        return undefined
    }
    if (typeof value === 'string') {
        return value.trim()
            .replace(/\s+/g, ' ')
            .replace(/\s*\/\s*/g, "/")
            .replace("–", "-")
            .replace("−", "-")
            .replace("\u2011", "-")
            .replace("\u2010", "-")

    } else {
        return value
    }
}

export { normalizeValue }