import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Icon, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react'

function AccountTypes() {

    return <>
        <Header as="h2" textAlign='center'>Account Types</Header>
        <Table celled structured>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell></TableHeaderCell>
                    <TableHeaderCell textAlign='center'>Base (Free)</TableHeaderCell>
                    <TableHeaderCell textAlign='center'>Silver</TableHeaderCell>
                    <TableHeaderCell textAlign='center'>Gold</TableHeaderCell>
                    <TableHeaderCell textAlign='center'>Platinum</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                <TableRow>
                    <TableCell>Income Statements (5Y)</TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                </TableRow>
                {/* <TableRow>
                    <TableCell>Balance Sheets (5Y)</TableCell>
                    <TableCell />
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Cash Flows (5Y)</TableCell>
                    <TableCell />
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                </TableRow> */}
                <TableRow>
                    <TableCell>KPIs (5Y)</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                </TableRow>
                {/* <TableRow>
                    <TableCell>See the notes associated with the financial tables.</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                </TableRow> */}
                <TableRow>
                    <TableCell>See the original reporting label of any number.</TableCell>
                    <TableCell />
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>See financial numbers that are not presented in the original financial statements but are calculated by 3D.</TableCell>
                    <TableCell />
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Report Formats</TableCell>
                    <TableCell textAlign='center'>
                        Standard
                    </TableCell>
                    <TableCell textAlign='center'>
                        Standard
                    </TableCell>
                    <TableCell textAlign='center'>
                        Standard, Condensed, Proforma
                    </TableCell>
                    <TableCell textAlign='center'>
                        Standard, Condensed, Proforma
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Download Reports, Presentations, and Financial Statements</TableCell>
                    <TableCell />
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Direct Links to the Investor Relations, Reports, Presentations, and other useful pages of the companies.</TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Request new companies to be added to the platform.</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Request new or existing companies to be entered in a specific way and visible only for you.</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Export Data to Excel</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell textAlign='center'>
                        <Icon color='green' name='checkmark' size='large' />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>

        <p>
            Plase <Link to="/contactUs">contact us</Link> if you want to upgrade your free account.
        </p>

    </>
}


export default AccountTypes


