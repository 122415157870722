import React, { useEffect, useState } from 'react';
import { Button, Loader, Modal, Table } from 'semantic-ui-react'
import { serverToLocalDateTime } from '../../../../../../utils/dateTimeUtils';
import SchemaMergeRepo from '../../../../../../utils/repository/schemaMergeRepo';


function MergeHistory({ schemaId }) {
    const [merges, setMerges] = useState(null);

    useEffect(() => {
        SchemaMergeRepo.findBySchemaId(schemaId, setMerges)
    }, [schemaId])

    return (merges === null) ? <Loader /> : <Table celled>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Merged By</Table.HeaderCell>
                <Table.HeaderCell>Approved By</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {merges.length === 0 ? "No Data" : merges.map(merge => {
                return <Table.Row key={merge.id}>
                    <Table.Cell>{merge.mergedBy.fullName}</Table.Cell>
                    <Table.Cell>{merge.approvedBy.map(u => u.fullName).join(", ")}</Table.Cell>
                    <Table.Cell>{merge.description}</Table.Cell>
                    <Table.Cell>{serverToLocalDateTime(merge.mergedOn)}</Table.Cell>
                </Table.Row>
            })}
        </Table.Body>
    </Table>

}

function MergeHistoryModal({ schemaId, onCancel }) {

    return <Modal open={true} >
        <Modal.Header>Merge History</Modal.Header>
        <Modal.Content>
            <MergeHistory schemaId={schemaId} />
        </Modal.Content>
        <Modal.Actions>
            <Button size='mini' onClick={onCancel}>
                Close
            </Button>
        </Modal.Actions>
    </Modal>

}

export default MergeHistoryModal
