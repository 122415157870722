class DescriptorType {
    static NUMBER = "number"
    static ANY = "any"
    static PERCENT = "percent"
    static HEADLINE = "headline"

    /**
     * @returns not-null list of all descripto types 
     */
    static getAllTypes() {
        return [DescriptorType.NUMBER, DescriptorType.ANY, DescriptorType.PERCENT, DescriptorType.HEADLINE]
    }

    /**
     * @param {{}} descriptor Required 
     * @returns True if the descriptor type is 'headline' otherwice false
     */
    static isHeadline(descriptor) {
        return descriptor.type === DescriptorType.HEADLINE
    }

    /**
     * @param {{}} descriptor Required 
     * @returns True if the descriptor type is 'number' otherwice false
     */
    static isNumber(descriptor) {
        return descriptor.type === DescriptorType.NUMBER
    }

    /**
     * @param {{}} descriptor Required 
     * @returns True if the descriptor type is 'any' otherwice false
     */
    static isAny(descriptor) {
        return descriptor.type === DescriptorType.ANY
    }

    /**
     * @param {{}} descriptor Required 
     * @returns True if the descriptor type is 'percent' otherwice false
     */
    static isPercent(descriptor) {
        return descriptor.type === DescriptorType.PERCENT
    }
}

export default DescriptorType