import React from 'react';

type GrowthPercentageProps = {
    children: React.ReactNode;
    className: string;
};

const GrowthPercentage = ({ children, className }: GrowthPercentageProps) => {
    return <span className={className}>{children}</span>;
};

export default GrowthPercentage;
