class RouteC {

    //+ID
    static COMPANY_PUBLIC = "/companies/"

    static CREATE_COMPANY_INTERNAL = "/admin/companies/new"
    //+ID
    static EDIT_COMPANY_CONFIG_INTERNAL = "/admin/companies/"

}

export default RouteC