
import TableUpdatesRepo from "../utils/repository/tableUpdatesRepo"
import UserRepo from "../utils/repository/userRepo"
import { ROLES } from "../constnats/user"


function usersToMap(users) {
    const userIdToUser = {}
    for (const user of users) {
        userIdToUser[user.id] = user
    }

    return userIdToUser
}

function getUpdatesWithOwners(updates, usersMap) {
    updates = JSON.parse(JSON.stringify(updates))
    for (const update of updates) {
        update.owner = usersMap[update.ownerId]

        for (const review of update.reviews) {
            review.owner = usersMap[review.ownerId]
        }
    }
    return updates
}

function getUpdates(searchSpec) {
    return new Promise((resolve, reject) => {
        TableUpdatesRepo.find(searchSpec, resolve)
    })
}


function getUsers() {
    return new Promise((resolve, reject) => {
        UserRepo.list({ userRoles: [ROLES.EMPLOYEE], includeDisabled: true }, users => {
            resolve(usersToMap(users))
        })
    })
}

class TableUpdates {

    static find(searchSpec, includeOwners = true) {
        const updatesRes = getUpdates(searchSpec)

        if (includeOwners) {
            const usersRes = getUsers()

            return Promise.all([updatesRes, usersRes])
                .then(([updates, usersMap]) =>
                    getUpdatesWithOwners(updates, usersMap)
                )
        } else {
            return updatesRes
        }
    }
}

export default TableUpdates