import { useState } from "react"
import { Dropdown } from "semantic-ui-react"

class PasteModeModel {
    static EU_MODE = "EU"
    static US_MODE = "US"

    static _mode = PasteModeModel.US_MODE

    /**
     * @param {string} mode Required
     */
    static set(mode) {
        PasteModeModel._mode = mode
    }

    /**
     * @returns {string} not-null
     */
    static get() {
        return PasteModeModel._mode
    }
}

function PasteMode() {
    let [mode, setMode] = useState(PasteModeModel.get());

    return <div className='pasteOps'><span>Paste Number Mode: </span>
        <Dropdown pointing
            onChange={(a, b) => {
                PasteModeModel.set(b.value)
                setMode(b.value)
            }}
            value={mode}
            options={[
                { key: 'comma', text: 'US', value: PasteModeModel.US_MODE },
                { key: 'dot', text: 'DE', value: PasteModeModel.EU_MODE }
            ]} /></div>
}
export { PasteModeModel, PasteMode }