import { toast } from 'react-toastify';
import JsEventLogger from '../service/jsEventsLogger'

function Msg({ msg }) {
    return <span>{msg}</span>;
}

class Alert {
    static activeErrorAlerts = new Set()

    static error(msg, stacktrace = "N/A", log = true) {
        if (log === true) {
            JsEventLogger.log(msg, stacktrace, "Alerts")
        }

        if (msg && msg.includes("userId")) {
            return
        }
        if (!Alert.activeErrorAlerts.has(msg)) {
            Alert.activeErrorAlerts.add(msg)

            toast.error(<Msg msg={msg} />, {
                position: "top-center",
                autoClose: 30000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: ({ msg }) => Alert.activeErrorAlerts.delete(msg)
            });
        }
    }

    static warn(msg) {
        toast.warning(msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    static success(msg) {
        toast.success(msg, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}

export default Alert