import React, { useContext, useEffect, useMemo } from 'react';
import { SchemasContext } from '../../contexts/schemaContext';
import { BranchTypes } from '../../constnats/schemaConstants';
import { useState } from 'react';
import TableUpdatesRepo from '../../utils/repository/tableUpdatesRepo';
import { UpdatePhase } from '../financialTables/configuration/constants';
import { ReviewTypeContext } from '../../contexts/reviewTypeContext';

const ReviewTypeContextProvider = ({ schema, children }) => {
    const schemaContext = useContext(SchemasContext);
    const srcSchema = useMemo(() => {
        return schemaContext.find((s) => s.name === schema.name && s.branchType === BranchTypes.MAIN);
    }, [schema, schemaContext]);

    const [review, setReview] = useState();

    useEffect(() => {
        if (schema.branchType !== BranchTypes.REVIEW) {
            return;
        }
        if (!srcSchema) {
            return;
        }
        TableUpdatesRepo.find({ srcSchema: srcSchema.id }, (updates) => {
            const reviews = [];
            updates
                .filter((x) => x.phase !== UpdatePhase.APPLIED)
                .forEach((u) => {
                    reviews.push(...u.reviews);
                });

            const targetReview = reviews.find((r) => r.schemaId == schema.id);
            setReview(targetReview);
        });
    }, [srcSchema]);
    if (!review) {
        return <ReviewTypeContext.Provider value={{ reviewBranchType: null }}>{children}</ReviewTypeContext.Provider>;
    }

    return (
        <ReviewTypeContext.Provider value={{ reviewBranchType: review.type }}>
            <div style={{ padding: 15, textAlign: 'center', fontWeight: 'bold' }}>This Review is for {review.type}</div>
            {children}
        </ReviewTypeContext.Provider>
    );
};

export default ReviewTypeContextProvider;
