import ReportUtils from "../../../utils/reportUtils";
import { MetadataField, ReportField, ReportFillingType, SrcFieldValue } from "../../../constnats/reportConstants";
import { ConfigProps } from "./constants";
import Alert from "../../../utils/alert";
import Assert from "../../../utils/asserts";
import CloneUtils from "../../../utils/cloneUtils";
import Utils from "../../../utils/utils";
import ObjCheck from "../../../utils/objCheck";
import { DescriptorProps } from "../../../constnats/descriptor";
import DescriptorType from "../../../utils/descriptor/descriptorType";
import { CURRENT_TO_PAST_PERIODS_MAP, PERIODS_MAP } from "../../../constnats/periods";

/**
 * Map that holds functions used to get the past versions of reports
 * Used for the growth calculation
 */
const pastVersionsGettersMap = {
    /**
     * @description Accepts the report for which the past year version is needed
     * and returns the past year version of that report
     * Example:
     * if A report with a period of Q2 and year of 2018 is passed
     * an object containing Q2 as a period and 2017 year will be returned along side
     * a property called propToSave which specifies the name of the property in the 
     * metadata, which will hold the value from the growth calculation
     * @param {Object} report Required
     * @returns {{period:string,year:number,propToSave:string}} not null
     */
    PAST_YEAR: (report) => ({
        period: report[ReportField.PERIOD],
        year: report[ReportField.YEAR] - 1,
        propToSave: MetadataField.YEARLY_GROWTH_PERCENT
    }),
    /**
     * @description Accepts the report for which the past period version is needed
     * and returns the past period version of that report or undefined 
     * if the report version does not have a corresponding past period version
     * Examples:
     * If a report that has a period of Q2 and year of 2018 is passed
     * it will return an object containing a period of Q1 and a year of 2018 along side
     * a property called propToSave which specifies the name of the property in the 
     * metadata, which will hold the value from the growth calculation
     * 
     * If a report with a period of Q1 and year 2018 is passed
     * it will return an object containing a period of Q4 and a year of 2017 along side
     * a property called propToSave which specifies the name of the property in the 
     * metadata, which will hold the value from the growth calculation 
     * 
     * If the report version does not have a corresponding past period version
     * from the {@link CURRENT_TO_PAST_PERIODS_MAP} it will return 
     * undefined
     * Example:
     * If a report has a period of FY, undefined will be returned, because there is 
     * no corresponding past period for FY
     * @param {Object} report Required
     * @returns {{period:string,year:number,propToSave:string} | undefined}
     */
    PAST_PERIOD: (report) => {
        const reportPeriod = report[ReportField.PERIOD]
        const previousReportPeriod = CURRENT_TO_PAST_PERIODS_MAP[reportPeriod];
        if (!previousReportPeriod) {
            return
        }
        let year = report[ReportField.YEAR];
        if (reportPeriod === PERIODS_MAP.Q1 || reportPeriod === PERIODS_MAP.H1) {
            year = year - 1;
        }
        return { period: previousReportPeriod, year, propToSave: MetadataField.PERIOD_GROWTH_PERCENT }
    }
}
/**
 * @description Accepts a report and gets every corresponding past version by 
 * executing the functions specified in {@link pastVersionsGettersMap}
 * After getting the results from the functions it will filter out any falsy values
 * which might have been returned
 * Function that may return undefined is PAST_PERIOD
 * @param {Object} report Required
 * @returns {Array.<{period:string,year:number,propToSave:string}>} not-null
 */
const getReportCorrespondingPastVersions = (report) => {
    return Object.values(pastVersionsGettersMap)
        .map(versionGetter => versionGetter(report))
        .filter(x => x);

}

/**
 * Checkers for redundancy of original report versions.
 * 
 * Every checker accepts a report to be checked and a map of all existing
 * reports that are going to be rendered. If the report is redundant the
 * checks return true, otherwise false.
 */
const ORIGINAL_V_REDUNDANCY_CHECKS = {
    [ConfigProps.CONDITIONAL_ORIGINAL_DISABLED]: () => false,
    [ConfigProps.HIDE_ORIGINAL_IF_SAME_AS_LATEST]:
        (originalReport, reportsBag) => {
            const latest = reportsBag.getLatestVersionOf(originalReport)
            return latest && latest.originalToLatestDiff !== true
        },
    [ConfigProps.HIDE_ORIGINAL_IF_LATEST_PRESENTED]:
        (originalReport, reportsBag) => reportsBag.getLatestVersionOf(originalReport) !== undefined,
    [ConfigProps.CONDITIONAL_ORIGINAL_BASED_ON_FUZZY_DIFF]:
        (originalReport, reportsBag) => {
            const latest = reportsBag.getLatestVersionOf(originalReport)
            return latest !== undefined && latest.originalToLatestFuzzyDiff !== true
        }
}

class ReportsBag {

    /**
     * @param {[]} reports Reports to create a bag for. Required
     */
    constructor(reports) {
        this.reportsMap = this._mapReports(reports)
    }

    /**
     * @param {Object} report 
     *
     * @returns true if the report exists in the bag, otherwise false
     */
    contains(report) {
        return this.reportsMap[this._getKey(report)] !== undefined
    }

    /**
     * @description Retrieves report version based on report and overrideSpec
     * @param {Object} report Required
     * @param {Object} overrideSpec Required
     * @param {number} [overrideSpec.year] Optional
     * @param {string} [overrideSpec.period] Optional
     * @param {boolean} [overrideSpec.calced] Optional
     * @returns not-null
     */
    getCorrespondingPastVersion(report, { year, period, calced }) {
        return this.reportsMap[this._getKey(report, { year, period, calced })]
    }

    /**
     * @param {Object} report Required
     * @returns calced report version or undefined if ont found
     */
    getCalcedVersionOf(report) {
        return this.reportsMap[this._getKey(report, { calced: true })]
    }

    /**
     * @param {Object} report Required
     * @returns non calced report version or undefined if ont found
     */
    getNonCalcedVersionOf(report) {
        return this.reportsMap[this._getKey(report, { calced: false })]
    }

    /**
     * @param {Object} report Required
     * @returns original report version or undefined if ont found
     */
    getOriginalVersionOf(report) {
        return this.reportsMap[this._getKey(report, { version: ReportFillingType.ORIGINAL_FILLING })]
    }

    /**
     * @param {Object} report Required
     * @returns latest report version or undefined if ont found
     */
    getLatestVersionOf(report) {
        return this.reportsMap[this._getKey(report, { version: ReportFillingType.LATEST_FILLING })]
    }

    _mapReports(reports) {
        const reportsMap = {};
        for (const report of reports) {
            const key = this._getKey(report)
            const existingReport = reportsMap[key]
            if (existingReport) {
                const existingReportSrcIsUserInfo = existingReport[ReportField.SRC] === SrcFieldValue.USER_INFO
                Assert.trueVal(report[ReportField.SRC] === SrcFieldValue.USER_INFO
                    || existingReportSrcIsUserInfo, `applyUtils> _mapReports report src is equal to User Info or existing report src is equal to user info. report src${report[ReportField.SRC]}, existing report src: ${existingReport[ReportField.SRC]}`)

                /**
                 * Non USER_INFO has priority over USER_INFO reports.
                 */
                if (existingReportSrcIsUserInfo) {
                    reportsMap[key] = report
                }

            } else {
                reportsMap[key] = report
            }

        }

        return reportsMap
    }

    _getKey(r, { version, calced, year, period } = {}) {
        calced = calced === undefined ? ReportUtils.isCalced(r) : calced
        version = version === undefined ? r[ReportField.TYPE] : version
        year = year || r[ReportField.YEAR]
        period = period || r[ReportField.PERIOD]

        return year + period + r[ReportField.REPORT_TYPE] + version + calced
    }
}


/**
 * @param {[]} reports Reports to fill in missing values based on
 * calced reports. Required
 * @param {ReportsBag} reportsBag Reports bag where to search for calced
 * reports. Required
 * @param {[]} dynamicFlatDescriptors Required
 * 
 * NOTE: The function mutates the report 
 */
function fillInMissingValues(reports, reportsBag, dynamicFlatDescriptors) {
    const nonCalculatedReports = reports.filter(r => !ReportUtils.isCalced(r))
    for (const report of nonCalculatedReports) {
        const calcedReport = reportsBag.getCalcedVersionOf(report)
        if (calcedReport !== undefined) {
            for (const flatDescriptor of dynamicFlatDescriptors) {
                ReportUtils.fillInMissingValues(report, calcedReport, flatDescriptor)
            }
        }
    }
}

/**
 * @param {[]} reports Reports to fill in diffs for. Required
 * @param {ReportsBag} reportsBag Reports bag where to search for diffs. Required
 * @param {[]} dynamicFlatDescriptors Required
 * @param {{}} advancedTableConfig Required
 * 
 * NOTE: The function mutates the reports. 
 */
function fillInDiffs(reports, reportsBag, dynamicFlatDescriptors, advancedTableConfig) {
    const diffThreshold = Number(advancedTableConfig[ConfigProps.DIFF_DETECTION_THRESHOLD])
    const diffIgnoreZeroAndDash = advancedTableConfig[ConfigProps.DIFF_DETECTION_IGNORE_ZERO_DASH]


    /**
     * Fill in diffs in non calced reports based on calced reports
     */
    const nonCalculatedReports = reports.filter(r => !ReportUtils.isCalced(r))
    for (const report of nonCalculatedReports) {
        const calcedReport = reportsBag.getCalcedVersionOf(report)
        if (calcedReport !== undefined) {
            ReportUtils.markDiffs(calcedReport, report, dynamicFlatDescriptors,
                diffThreshold, diffIgnoreZeroAndDash)
        }
    }


    /**
     * Fill in diffs in latest reports based on original reports.
     * 
     * Known Issue: The diffs form the latest reports override the diffs from the calculated reports.
     */
    const latestReports = reports.filter(r => r[ReportField.TYPE] === ReportFillingType.LATEST_FILLING)
    for (const latestReport of latestReports) {
        const originalReport = reportsBag.getOriginalVersionOf(latestReport)
        if (originalReport !== undefined) {
            ReportUtils.markDiffs(originalReport, latestReport,
                dynamicFlatDescriptors, diffThreshold, diffIgnoreZeroAndDash)

            /**
             * The fuzzy diff is used for two purposes. The first purpose is to
             * warn the users for data inconsistency using the red icons. 
             * The second is to filter the original reports based on their
             * differences with the latest. This is why we need to know if there
             * is a difference specifically between the latest and the original reports.
             */
            latestReport.originalToLatestDiff = latestReport.hasDiff
            latestReport.originalToLatestFuzzyDiff = latestReport.hasFuzzyDiff
        }
    }
}

/**
 * Filters all reports that represent a subset of other reports.
 * NOTE: Subset of persisted reports can occur because of a P2P calculation of
 * non cumulative data. 
 *
 * @param {[]} reports Reports to be filtered
 * @returns not-null array of filtered reports
 */
function filterSubsetReports(reports) {
    const reportsBag = new ReportsBag(reports)

    /**
     * NOTE: There is a bug here. If the system has more than one subset reports
     * and the src report is not present than all subset reports are going to be presented.
     */
    return reports
        .filter(r => r.subsetOfReport === undefined || !reportsBag.contains(r.subsetOfReport))
}

/**
 * @description Fills in the growth for the report for the specified descriptor field
 * @param {Object} descriptor Required
 * @param {Object} report Required
 * @param {Object} pastVersion Required
 * @param {Object} version Required
 * @param {string} version.propToSave Required
 */
function fillInGrowth(descriptor, report, pastVersion, version) {
    if (!DescriptorType.isNumber(descriptor) && !DescriptorType.isPercent(descriptor)) {
        return
    }
    const descriptorId = descriptor[DescriptorProps.ID]

    let [currentValue] = ReportUtils.getDynamicFieldValue(report, descriptorId)
    let [pastValue] = ReportUtils.getDynamicFieldValue(pastVersion, descriptorId)

    if (ObjCheck.isNullUndefinedEmptyOrDash(currentValue) || ObjCheck.isNullUndefinedEmptyOrDash(pastValue)) {
        return;
    }

    if (DescriptorType.isPercent(descriptor)) {
        currentValue = currentValue.replace("%", "")
        pastValue = pastValue.replace("%", "")
    }

    let differencePercent
    if (Utils.isNumber(pastValue) && Utils.isNumber(currentValue)) {
        pastValue = Utils.toNumber(pastValue)
        /**
         * here we check if the past value is a 0. If it is, we do not proceed 
         * with the calculation since we cannot divide by 0
         */
        if (!pastValue) {
            return;
        }
        currentValue = Utils.toNumber(currentValue)
    
        differencePercent = ((currentValue - pastValue) /
            Math.abs(pastValue) * 100).toFixed(1)
    }

    if (!Utils.isNumber(Number(differencePercent))) {
        Assert.fail(`[fillInGrowth] differencePercent is not a number, inputs: currentValue: ${currentValue}, pastValue:${pastValue}, result: ${differencePercent}`);
        differencePercent = "ERROR"
    }
    
    const currentFieldMetaData = ReportUtils.getFieldMetadata(report, descriptorId)
    //TMP Fix: Check if this is the right approach or we need to set default metadata
    if (currentFieldMetaData) {
        currentFieldMetaData[version.propToSave] = differencePercent
    }

}

/**
 * @description Gets the past versions of the reports (the past versions information of the report are specified in the 
 * {@link getReportCorrespondingPastVersions}) and fills in the reports with the growth they have had in comparison
 * to the past versions
 * NOTE: the function compares reports with non calculated reports first, if such reports are not present, the function
 * compares the reports with calculated reports
 * @param {[Object]} reports Required Reports to go through and add in the yearly growth
 * @param {ReportsBag} reportsBag Required Map containing the corresponding reports version for past years
 * @param {[]} dynamicFlatDescriptors Required
 */
const fillInReportsGrowth = (reports, reportsBag, dynamicFlatDescriptors) => {
    reports.forEach(report => {
        getReportCorrespondingPastVersions(report).forEach(version => {
            let pastVersion = reportsBag.getCorrespondingPastVersion(report, { period: version.period, year: version.year, calced: false })
            // if there is not a non calculated past version get the calculated
            if (!pastVersion) {
                pastVersion = reportsBag.getCorrespondingPastVersion(report, { period: version.period, year: version.year, calced: true })
            }
            if (!pastVersion) {
                return
            }
            dynamicFlatDescriptors.forEach(descriptor => {
                fillInGrowth(descriptor, report, pastVersion, version)
            })
        })
    })
}

/**
 * TODO Rename to apply table/reports settings
 *
 * @param {[]} reports Required 
 * @param {{}} filterCfg Required
 * @param {{}} advancedTableConfig Required 
 * @param {{}} dynamicFlatDescriptors Required
 * @returns not-null array of reports
 */
function applyFilterCfg(reports, filterCfg, advancedTableConfig,
    dynamicFlatDescriptors) {

    reports = CloneUtils.deepClone(reports)

    const reportsBag = new ReportsBag(reports)

    const originalReportRedundancyCheck = ORIGINAL_V_REDUNDANCY_CHECKS[filterCfg[ConfigProps.ORIGINAL_VERSION_CONDITIONAL_FILTER]]
    Assert.notNullOrUndefined(originalReportRedundancyCheck, "originalReportRedundancyCheck")

    // Show/Hide calculated reports
    const showCalculatedStatements = advancedTableConfig[ConfigProps.SHOW_CALCULATED_STATEMENTS] === true
    const { from, to } = filterCfg[ConfigProps.YEARS_FILTER]

    reports = reports
        // Periods filtering [Q1, Q2, H1,...]
        .filter(r => filterCfg[ConfigProps.PERIODS_FILTER].includes(r[ReportField.PERIOD]))
        // Filtering by report version [Latest, Original]
        .filter(r => filterCfg[ConfigProps.FILLING_TYPE_FILTER].includes(r[ReportField.TYPE]))
        // Filter by report format [Proforma, Standard, Condensed]
        .filter(r => filterCfg[ConfigProps.REPORT_TYPES_FILTER].includes(r[ReportField.REPORT_TYPE]))
        /**
         * Here we subtract 1 (year) from the from value
         * This is needed so that later when we are filling in the growth we have 
         * The previous year to compare them to.
         * Example: 
         * Display only the reports for 2020. Here we are getting the 2020 reports
         * and thе 2019 reports in order to get the growth for 2020.
         * Later on the 2019 reports will be filtered out.
         */
        .filter(r => r[ReportField.YEAR] >= from - 1 && r[ReportField.YEAR] <= to)
        // Filtering calculated reports
        .filter(r => !ReportUtils.isCalced(r) || showCalculatedStatements)
        // Filters the calculated reports that have alternatives as persisted reports.
        .filter(r => !ReportUtils.isCalced(r) || !reportsBag.getNonCalcedVersionOf(r))

    /**
     * Fills in the missing values of the non calculated reports that can be found
     * in the calculated reports.
     */
    fillInMissingValues(reports, reportsBag, dynamicFlatDescriptors)

    /**
     * The diff fill in must be executed before the redundancy filtering
     * because most of the reports needed for it will be filtered in it.
     */
    fillInDiffs(reports, reportsBag, dynamicFlatDescriptors, advancedTableConfig)

    /**
     * Adds in the yearly growth percentage for reports which have a corresponding past report version
     * for the specified reports
     * Warning: This should be executed after {@link fillInDiffs} because it depends on the diffs
     */
    fillInReportsGrowth(reports, reportsBag, dynamicFlatDescriptors)

    /**
     * Filters the original reports based in "Conditionally Hide Original Versions" settings. 
     * It must be executed after the diffs configuration because it depends on the diffs. 
     */
    reports = reports
        .filter(r => r[ReportField.YEAR] >= from)
        .filter(r => r[ReportField.TYPE] === ReportFillingType.ORIGINAL_FILLING ?
            !originalReportRedundancyCheck(r, reportsBag) : true)

    return filterSubsetReports(reports)
}

export { applyFilterCfg }