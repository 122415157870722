import { addMonths, subMonths } from "date-fns";
import { useCallback, useState } from "react"
/**
 * 
 * @param {Object} initialValue
 * @param {Date} initialValue.from
 * @param {Date} initialValue.to 
 */
const useDateRange = (initialValue) => {
    const [from, setFrom] = useState(initialValue.from);
    const [to, setTo] = useState(initialValue.to);

    const handleMoveToPrevMonth = useCallback(() => {
        setFrom((prevValue) => subMonths(prevValue, 1))
        setTo((prevValue) => subMonths(prevValue, 1))
    }, [])

    const handleMoveToNextMonth = useCallback(() => {
        setFrom(prevValue => addMonths(prevValue, 1))
        setTo(prevValue => addMonths(prevValue, 1))
    }, [])

    /**
     * 
     * @param {Date} value 
     */
    const handleFromChange = useCallback((value) => {
        setFrom(value)
    }, [])

    /**
     * 
     * @param {Date} value 
     */
    const handleToChange = useCallback((value) => {
        setTo(value)
    }, [])

    return { from, to, handleMoveToPrevMonth, handleMoveToNextMonth, handleFromChange, handleToChange }
}

export default useDateRange
