const settingsKeyPefix = "userSettings"

class UserSettingsRepo {

    /**
     * 
     * @param {int|string} userId Required 
     * @returns not-null Promise
     */
    static get(userId) {
        return Promise.resolve(JSON.parse(localStorage.getItem(settingsKeyPefix + userId)) || {})
    }

    /**
     * 
     * @param {int|string} userId 
     * @param {{}} settings 
     * @returns not-null Promise 
     */
    static save(userId, settings) {

        localStorage.setItem(settingsKeyPefix + userId, JSON.stringify(settings))
        return Promise.resolve()
    }

}

export default UserSettingsRepo