import HttpReq from "../../../service/httpReq"

class ReviewViewService {
    static prefix = 'api/mgmt/ui/review'

    static getReviewViewData(reviewId, dataConsumer, errorConsumer) {
        HttpReq.get(ReviewViewService.prefix + "/" + reviewId, dataConsumer, errorConsumer)
    }
}

export default ReviewViewService