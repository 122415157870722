import { useState } from "react"
import { Button, Form, Modal } from "semantic-ui-react"
import Loader from "../../../../../components/loader"
import ResourceRepo from "../../../../../utils/repository/resourceRepo"
import { getExtension, removeFileExtension, ResourceProps } from "../../../../../utils/reportResourceUtil"
import Alert from "../../../../../utils/alert"
import { ResourceConfigTable, ResourceConfigRow } from './resourceConfigV2';
import { ResourceCategory } from "../../../../../constnats/resourceConstants"
import { ReportType } from "../../../../../constnats/reportConstants"
import Utils from "../../../../../utils/utils"
import ObjCheck from "../../../../../utils/objCheck"

const DOC_TYPE_MAP = {
    re: ResourceCategory.REPORT,
    p: ResourceCategory.PRESENTATION,
    o: ResourceCategory.OUTLOOK,
    ro: ResourceCategory.REPORT_OUTLOOK,
    po: ResourceCategory.PRESENTATION_OUTLOOK,
    pro: ResourceCategory.PRESS_RELEASE_OUTLOOK,
    rs: ResourceCategory.REPORT_SUPLEMENT,
    pr: ResourceCategory.PRESS_RELEASE,
    is: ResourceCategory.INCOME_STATEMENT,
    bs: ResourceCategory.BALANCE_SHEET,
    cf: ResourceCategory.CASH_FLOW,
    kpi: ResourceCategory.KPI,
    byprod: ResourceCategory.SEG_BY_PROD,
    byreg: ResourceCategory.SEG_BY_REG
}

const REPORT_TYPE_MAP = {
    s: ReportType.STANDARD,
    c: ReportType.CONDENSED,
    p: ReportType.PROFORMA
}


function toFormData(selectedFiles, companyId, year, quoter) {
    const formData = new FormData();

    for (const file of selectedFiles) {
        formData.append("files", file.file);
    }

    const spec = {}

    spec.companyId = companyId
    spec.year = year
    spec.quoter = quoter

    spec.resourcesCfg = {}
    selectedFiles.forEach(f => spec.resourcesCfg[f.file.name] =
    {
        systemFileName: ResourceProps.serialize(f.resourceProps),
        originalOnly: f.originalOnly
    })

    const blob = new Blob([JSON.stringify(spec)], {
        type: 'application/json'
    });
    formData.append("spec", blob)


    return formData
}


function upload(files, companyId, year, quoter) {
    return new Promise((resolve, reject) => {
        const formData = toFormData(files, companyId, year, quoter)
        if (formData !== null) {
            ResourceRepo.saveReportResources(formData, resolve, err => {
                reject()
                Alert.error("Upload failed", "N/A", false)
                console.error("Upload failed:" + err)
            })
        }
    })
}

function UploadFormV2Modal({ companyId, onClose }) {
    let [selectedFiles, setSelectedFiles] = useState({})
    let [uploading, setUploading] = useState(false)
    let [uploadFiles, setUploadFiles] = useState([])

    const onUploadClick = async () => {

        for (const file of selectedFiles) {
            if (!ResourceProps.valid(file.resourceProps)
                || ObjCheck.isNullUndefinedOrEmpty(file.period)
                || ObjCheck.isNullUndefinedOrEmpty(file.year)) {
                Alert.warn("The Financial Document Type, Report Type, Year and Period must be configured for all files.")
                return null
            }
        }

        const uploadGroups = toUploadGroupos(selectedFiles)
        setUploading(true)
        const _uploadFiles = []
        const promises = []
        for (const files of Object.values(uploadGroups)) {
            promises.push(upload(files, companyId, files[0].year, files[0].period).then(() => {
                _uploadFiles.push(...files.map(f => f.file.name))
                setUploadFiles(_uploadFiles.slice())
            }))
        }

        Promise.all(promises).then(() => {
            setUploading(false)
            setUploadFiles([])
            onSelectedFilesChange([])
            onClose(true)
            Alert.success("The files were uploaded successfully")
        });
    }

    const onSelectedFilesChange = files => {
        const selectedFiles = []

        for (const file of files) {
            const args = removeFileExtension(file.name).split(/\s+/)
            const docType = DOC_TYPE_MAP[args[0]?.toLowerCase()] || ""
            let year = args[1]
            if (year === undefined) {
                year = ""
            } else {
                year = year.length == 2 ? "20" + year : year
                if (!Utils.isNumber(year) || year.length != 4) {
                    year = ""
                }
            }

            let period = args[2] || ""

            if (!Utils.isNumber(period) || period?.length != 1) {
                period = ""
            }

            const reportType = REPORT_TYPE_MAP[args[3]?.toLowerCase()] || ""
            let suffix = ""

            const argsLength = args.length
            if (argsLength > 3) {
                for (let i = 4; i < argsLength; i++) {
                    suffix += args[i] + " ";
                }

                suffix.trim().replace(/[^A-Za-z0-9\s()]/g, "")
            }

            selectedFiles.push({
                resourceProps:
                    new ResourceProps(docType, reportType, false, suffix,
                        getExtension(file.name)),
                file, year, period, 
            })
        }

        setSelectedFiles(selectedFiles)
    }

    return <Modal size='fullscreen' open={true}>
        <Modal.Header>Upload Resources</Modal.Header>
        <Modal.Content>
            <Form>
                {uploading ? <UploadedingView uploadFiles={uploadFiles} /> :
                    selectedFiles.length > 0 ?
                        <ResourceConfigTable rows=
                            {selectedFiles.map((f, i) => <ResourceConfigRow
                                key={i}
                                originalFileName={f.file.name}
                                year={f.year}
                                onYearChange={newYear => {
                                    const selectedFilesCopy = selectedFiles.slice(0)
                                    selectedFilesCopy[i].year = newYear
                                    setSelectedFiles(selectedFilesCopy)
                                }}
                                period={f.period}
                                onPeriodChange={newPeriod => {
                                    const selectedFilesCopy = selectedFiles.slice(0)
                                    selectedFilesCopy[i].period = newPeriod
                                    setSelectedFiles(selectedFilesCopy)
                                }}

                                resourceProps={f.resourceProps}
                                onResourcePropsChange={newProps => {
                                    const selectedFilesCopy = selectedFiles.slice(0)
                                    selectedFilesCopy[i].resourceProps = newProps
                                    setSelectedFiles(selectedFilesCopy)
                                }}
                                originalOnly={selectedFiles[i].originalOnly === true}
                                onOriginalOnlyChange={newVal => {
                                    const selectedFilesCopy = selectedFiles.slice(0)
                                    selectedFilesCopy[i].originalOnly = newVal
                                    setSelectedFiles(selectedFilesCopy)
                                }} />)}
                        />
                        : <><Form.Input
                            fluid label='Select files for upload'
                            multiple type="file"
                            onChange={(e) => onSelectedFilesChange(e.target.files)}
                            accept="application/pdf,image/png,.docx,.pptx,.xlsx" />
                            <b>File Name Format:</b>{'{re|p|o|ro|po|pro|rs|pr|is|bs|cf|kpi|byprod|byreg} {last two digits of the year} {1|2|3|4} {s|c|p} {suffix}'}
                        </>
                }
            </Form>
        </Modal.Content>
        {!uploading && <Modal.Actions>
            {selectedFiles.length > 0 &&
                <Button primary size='mini' onClick={onUploadClick}>
                    Upload
                </Button>}
            <Button size='mini' onClick={onClose}>
                Close
            </Button>
        </Modal.Actions>}
    </Modal>
}

function toUploadGroupos(selectedFiles) {
    const uploadGroups = {}

    for (const file of selectedFiles) {
        const grouoId = file.year + "_" + file.period
        if (uploadGroups[grouoId] === undefined) {
            uploadGroups[grouoId] = []
        }

        uploadGroups[grouoId].push(file)
    }
    return uploadGroups
}

function UploadedingView({ uploadFiles }) {
    return <><Loader msg="Uploading" />
        Uploaded Files:
        <ul>
            {uploadFiles.map((f, i) => <li key={i}>{f}</li>)}
        </ul>
    </>
}

export { UploadFormV2Modal, toFormData } 