import React from 'react';
import { Button, Modal, Form, Dropdown } from 'semantic-ui-react'

class MoveToModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            targetDescriptorId: this.props.targetDescriptors[0].id
        }
    }

    getTargetsDropdown() {
        const ops = this.props.targetDescriptors.map((d, idx) => {
            return {
                key: idx,
                text: d.label,
                value: d.id
            }
        })

        return <Form.Field>
            <Dropdown
                onChange={(a, b) =>
                    this.setState({ targetDescriptorId: b.value })}
                value={this.state.targetDescriptorId}
                placeholder='Target'
                options={ops} fluid selection search />
        </Form.Field>
    }

    render() {
        return (<>
            <Modal
                size="mini"
                open={true}>
                <Modal.Header>Field Relocation</Modal.Header>
                <Modal.Content>
                    Move <b>{this.props.field.label}</b> to:
                    <Form>
                        {this.getTargetsDropdown()}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button size='mini' positive
                        onClick={() => this.props.onApply(this.state.targetDescriptorId)}>
                        Apply
                    </Button>
                    <Button size='mini' negative onClick={() => this.props.onCancel()}>
                        Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        </>)
    }
}

export default MoveToModal