import React from 'react';
import './tableActionButton.styles.css';

type TableActionButtonProps = React.ComponentPropsWithoutRef<'button'>;

const TableActionButton = ({ children, className, ...rest }: TableActionButtonProps) => {
    return (
        <button className={`tableActionButton ${className ? className : ''}`} {...rest}>
            {children}
        </button>
    );
};

export default TableActionButton;
