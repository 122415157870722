import React from 'react';
import { Button } from 'semantic-ui-react';
import DatePicker from '../form/datePicker';
import './rangeDatePicker.styles.css';

const RangeDatePicker = ({
    from,
    to,
    handleFromChange,
    handleToChange,
    handleMoveToPrevMonth,
    handleMoveToNextMonth,
}) => {
    return (
        <>
            <div className="range-date-picker__actions-container">
                <Button size="mini" onClick={handleMoveToPrevMonth}>
                    Prev Month
                </Button>
                <Button size="mini" onClick={handleMoveToNextMonth}>
                    Nex Month
                </Button>
            </div>
            <div>
                <DatePicker label="From:" date={from} onChange={handleFromChange} />
                <DatePicker label="To:" date={to} onChange={handleToChange} />
            </div>
        </>
    );
};

export default RangeDatePicker;
