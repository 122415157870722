class CompanyC {

    static LINK_PROPS_MAP = {
        investorRelationsLink: "Investor Relations", reportsLink: "Reports",
        reportsHYLink: "Reports Half-Year", reportsQLink: "Reports Quarterly",
        presentationsLink: "Presentations",
        pressReleasesLink: "Press Releases / News",
        financialCalendarLink: "Financial Calendar"
    }
}

export default CompanyC