import React from 'react';
import { Tab, Header, Loader, Card } from 'semantic-ui-react'
import { useParams } from "react-router-dom";
import CompanyRepo from '../../utils/repository/companyRepo';
import { AuthContext, CompanyAuthContext } from '../../App';
import SchemaUtil from '../../utils/schemaUtil';
import PaneNameFactory from '../../utils/tabPaneNameFactory';
import ReportsTable from './components/fiancialTable/tableWrapper';
import { ExternalResourcesCard } from '../../components/company/externalLinkResources';
import { Description } from '../../components/company/description';
import { Legend } from './components/legend';
import { ROLES } from '../../constnats/user';
import { SchemaNames } from '../../constnats/schemaConstants';
import AuthService from '../../service/auth';
import { NOT_ENOUGHT_PRIVILEGES_FOR_CLIENT } from '../../messages';
import Utils from '../../utils/descriptor/descriptorUtils';

const anonnymousClientsAllowed = process.env.REACT_APP_ANONYMOUS_CLIENTS_ALLOWED === "true"

function getPaneCfg(company, schema, hasReadRighs) {
    return {
        menuItem: PaneNameFactory.forSchema(schema),
        render: () => <Tab.Pane>
            {hasReadRighs ? <ReportsTable key={schema.id} schema={schema}
                companyId={company.id} currency={company.currency} /> :
                <Header as="h2" textAlign='center'>
                    {NOT_ENOUGHT_PRIVILEGES_FOR_CLIENT}
                </Header>}
        </Tab.Pane>
    }
}
class CompanyPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            company: null,
        }
    }

    componentDidMount() {
        CompanyRepo.get(this.props.companyId,
            company => {
                Utils.addFullCategoriesToSchemas(company.schemas, () => this.setState({ company }))
            })
    }

    getCompanyView() {
        const company = this.state.company
        const panes = company.schemas
            .sort(SchemaUtil.comapreSchemas).map(schema => {
                const name = schema.name
                let hasReadRighs = true
                if (name.includes(SchemaNames.KPIs) &&
                    !AuthService.hasAnyGlobalRole([ROLES.READ_KPIS, ROLES.EMPLOYEE]) && !anonnymousClientsAllowed) {
                    hasReadRighs = false
                } else if (name.includes(SchemaNames.BS) &&
                    !AuthService.hasAnyGlobalRole([ROLES.READ_BS, ROLES.EMPLOYEE]) && !anonnymousClientsAllowed) {
                    hasReadRighs = false
                } else if (name.includes(SchemaNames.CF) &&
                    !AuthService.hasAnyGlobalRole([ROLES.READ_CF, ROLES.EMPLOYEE]) && !anonnymousClientsAllowed) {
                    hasReadRighs = false
                } else if ((name.includes(SchemaNames.SEG_BY_PROD)
                    || name.includes(SchemaNames.SEG_BY_REG))
                    && !AuthService.hasAnyGlobalRole([ROLES.READ_SEGMENTS, ROLES.EMPLOYEE]) && !anonnymousClientsAllowed) {
                    hasReadRighs = false
                }

                return getPaneCfg(company, schema, hasReadRighs)
            })

        return <div className='reportTabsContainer client'>
            <Header as="h2" textAlign='center'>{company.name}</Header>
            <Tab panes={panes} />
            <Card.Group className="companyCardGroup">
                <Description description={company.description} />
                <ExternalResourcesCard company={company} />
                <Legend />
            </Card.Group>
        </div>
    }

    render() {
        return this.state.company === null ? <Loader /> : this.getCompanyView()
    }
}

CompanyPage.contextType = CompanyAuthContext;

export default function Company() {
    let params = useParams();
    const companyId = params.companyID
    const authContext = Object.assign({}, React.useContext(AuthContext))
    authContext.entityId = companyId

    //key={companyId} is for forced recreation
    return <CompanyAuthContext.Provider value={authContext}>
        <CompanyPage key={companyId} companyId={companyId} />
    </CompanyAuthContext.Provider>
}