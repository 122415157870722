const VALID = "VALID"
const INVALID = "INVALID"
const WARN = "WARN"


const SUPPORTED_YEARS = []

const currentYear = new Date().getFullYear()
for (let year = 2017; year <= currentYear + 1; year++) {
    SUPPORTED_YEARS.push(String(year))
}

class Scale {
    static ACTUAL = 1
}


export { VALID, INVALID, WARN, SUPPORTED_YEARS, Scale }
