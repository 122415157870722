import React from 'react'
import { Dropdown } from 'semantic-ui-react'

class SchemaOps extends React.Component {

    convertOp(op) {
        return {
            key: op.name,
            size: "mini",
            icon: op.icon,
            text: op.name,
            value: op.name,
            onClick: op.onClick
        }
    }

    render() {
        return (
            this.props.options.length > 0 && <Dropdown
                className='icon schemaOps'
                size="mini"
                icon={"ellipsis vertical"}
                options={this.props.options.map(op => this.convertOp(op))}
                trigger={<></>}
                direction={"left"}
            />
        )
    }
}

class Option {
    /**
     * 
     * @param {string} name 
     * @param {string} icon 
     * @param {func} onClick 
     */
    constructor(name, icon, onClick) {
        this.name = name
        this.icon = icon
        this.onClick = onClick
    }
}

export { SchemaOps, Option }