import HttpReq from "../../service/httpReq"

const loggingEnabled = process.env.REACT_APP_SERVER_LOGGING_ENABLED === "true"
class EventRepo {

    static mngmtPrefix = 'api/mgmt/js-events'
    static clientPrefix = 'api/js-events'

    /**
     * @param {function} reportsConsumer Required
     * @param {function} errorConsumer Optional
     */
    static list(reportsConsumer, errorConsumer) {
        HttpReq.get(EventRepo.mngmtPrefix, reportsConsumer, errorConsumer)
    }

    /**
     * @param {Object} createSpec Required
     * @param {function} onSuccess Required
     * @param {function} errorConsumer Required
     */
    static create(createSpec, onSuccess, errorConsumer) {
        if (loggingEnabled) {
            HttpReq.post(EventRepo.clientPrefix, createSpec, onSuccess, errorConsumer)
        } else {
            onSuccess()
        }
    }

    /**
     * @param {function} onSuccess Required
     * @param {function} errorConsumer Optional
     */
    static deleteAll(onSuccess, errorConsumer) {
        HttpReq.delete(EventRepo.mngmtPrefix, onSuccess, errorConsumer)
    }

}

export default EventRepo