import Alert from "../../../../../../../../utils/alert";
import Utils from '../../../../../../../../utils/descriptor/descriptorUtils';
import cloneDeep from "lodash/cloneDeep";

class DescriptorOps {
    static CONTACT_ADMIN_MSG = " Please do not save the data and contact the administrator."

    /**
     * @param {[]} descriptors As persisted in the database. Required
     * @param {func} onChange Function to be executed every time when the descriptors change. Required 
     */
    constructor(descriptors, onChange) {
        this.descriptors = this._deepClone(descriptors)
        this.onChange = () => onChange(this._deepClone(this.descriptors))
    }

    /**
     * @param {{}} descriptor The representation of a desctiptor. Required
     */
    update(descriptor) {
        this._exec(descriptor.id, (idx, collection) => collection[idx] = descriptor)
        this.onChange()
    }

    /**
     * @param {string} id Id of the descriptor that needs to be removed. Required 
     */
    remove(id) {
        this._exec(id, (idx, collection) => collection.splice(idx, 1))
        this.onChange()
    }

    /**
     * @param {string} targetParentId Target parent descriptor id or ROOT. Required 
     * @param {int} targetPosiotion Target position in the containing collection. Required
     * @param {{}} newDescriptor Descriptor to be added Required 
     */
    add(targetParentId, targetPosiotion, newDescriptor) {
        let collection
        if (targetParentId === "ROOT") {
            collection = this.descriptors
        } else {
            collection = this._findById(targetParentId).subFields
        }

        if (targetPosiotion <= collection.length + 1 && targetPosiotion >= 0) {
            collection.splice(targetPosiotion, 0, newDescriptor)
        } else {
            Alert.error("Failed to add a descriptor because of invalid position" + targetPosiotion + DescriptorOps.CONTACT_ADMIN_MSG)
            return
        }

        this.onChange()
    }

    /**
     * @param {string} id Id of the descriptor that needs to be moved. Required 
     */
    moveUp(id) {
        this._exec(id, (idx, collection) => {
            if (idx >= 1) {
                const oldIdxVal = collection[idx]
                collection[idx] = collection[idx - 1]
                collection[idx - 1] = oldIdxVal
            }
        })

        this.onChange()
    }

    /**
     * @param {string} id Id of the descriptor that needs to be moved. Required 
     */
    moveDown(id) {
        this._exec(id, (idx, collection) => {
            if (idx < collection.length - 1) {
                const oldIdxVal = collection[idx]
                collection[idx] = collection[idx + 1]
                collection[idx + 1] = oldIdxVal
            }
        })

        this.onChange()
    }

    /**
     * @param {string} descriptorId id Id of the descriptor that needs to be moved. Required 
     * @param {string} targetDescriptorId Target parent descriptor id or ROOT. Required  
     */
    moveTo(descriptorId, targetDescriptorId) {
        const srcDescriptor = this._findById(descriptorId)

        this._exec(descriptorId, (idxToRemove, collection) => {
            collection.splice(idxToRemove, 1)
        })

        try {
            if (targetDescriptorId === "ROOT") {
                this.descriptors.push(srcDescriptor)
            } else {
                this._findById(targetDescriptorId).subFields.push(srcDescriptor)
            }
        } catch (error) {
            this.descriptors.push(srcDescriptor)
            Alert.error("Failed to relocate a descriptor with ID" + targetDescriptorId + DescriptorOps.CONTACT_ADMIN_MSG)
        }


        this.onChange()
    }

    /**
     * @param {string} descriptorId id Id of the descriptor that needs to be relocated. Required 
     * @param {string} targetDescriptorId Target descriptor id to position after. Required  
     */
    moveBellow(descriptorId, targetDescriptorId) {
        const descriptor = this._findById(descriptorId)

        this._exec(descriptorId, (idxToRemove, collection) => {
            collection.splice(idxToRemove, 1)
        })

        this._exec(targetDescriptorId, (idxToAddAfter, collection) => {
            collection.splice(idxToAddAfter + 1, 0, descriptor)
        })

        this.onChange()
    }

    /**
     * @param {string} id Desctiptor id. Required
     */
    _findById(id) {
        const descriptor = Utils.findById(id, this.descriptors)

        if (descriptor === null) {
            Alert.error("Failed to find descriptor with ID:" + id + DescriptorOps.CONTACT_ADMIN_MSG)
            throw new Error("Illecal state. Cannot find a descriptor with ID:" + id)
        }

        return descriptor
    }

    /**
     * @param {string} id Descriptor id. Required
     */
    findContainingCollectionOfId(id) {
        const collection = Utils.findContainingCollectionOfId(id, this.descriptors)

        if (collection === null) {
            Alert.error("Failed to find descriptors collection of descriptor with ID:" + id + DescriptorOps.CONTACT_ADMIN_MSG)
            throw new Error("Illecal state. Cannot find a descriptor with ID:" + id)
        }

        return collection
    }

    /**
    * @param {string} id Id of the descriptor to provide targets for. Required
    */
    getTargetOptions(descriptorId) {
        const get = (descriptors) => {
            const targets = []
            for (const descriptor of descriptors) {
                if (descriptor.id !== descriptorId) {
                    if (!descriptor.subFields.some(d => d.id === descriptorId)) {
                        targets.push(descriptor)
                    }
                    targets.push(...get(descriptor.subFields))
                }
            }

            return targets
        }

        //TODO refactor
        const targets = get(this.descriptors)
        if (this.descriptors.findIndex(d => d.id === descriptorId) < 0) {
            targets.push({ id: "ROOT", label: "ROOT" })
        }

        return targets
    }

    /**
     * @param {string} id Descriptor id or ROOT. Required
     * @param {func} func Function that to pass the containing collection of the descriptor and its index in it. Required 
     */
    _exec(id, func) {
        const collection = id === "ROOT" ? this.descriptors : this.findContainingCollectionOfId(id)
        const idx = collection.findIndex(d => d.id === id)
        if (idx < 0) {
            Alert.error("Failed to find a descriptor with ID:" + id + " In containing collection " + collection + DescriptorOps.CONTACT_ADMIN_MSG)
            throw new Error("Illecal state. Failed to find a descriptor with ID: " + id + " In containing collection " + collection)
        }

        func(idx, collection)
    }

    /**
     * @param {[]} descriptors Required 
     * @returns not-null
     */
    _deepClone(descriptors) {
        return cloneDeep(descriptors)
    }
}

export default DescriptorOps