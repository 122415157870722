import HttpReq from "../../service/httpReq";
import { PathFactory } from "./utils";
import Cache from "./cache";


class CategoriesRepo {
    static cacheKeyCategory = 'categoryCache';
    static publicPath = "api/financial-categories";
    static mgmtPath = "api/mgmt/financial-categories";

    static getAll(categoryConsumer, errorConsumer) {
        Cache.get(this.cacheKeyCategory, func => {
            HttpReq.get(CategoriesRepo.publicPath, func, errorConsumer)
        }, categoryConsumer)
    }

    static getOne(id, companyConsumer, errorConsumer) {
        HttpReq.get(PathFactory.create([CategoriesRepo.mgmtPath, id]), companyConsumer, errorConsumer);
    }

    static save(saveCategorySpec, categoryConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.post(CategoriesRepo.mgmtPath, saveCategorySpec, categoryConsumer, errorConsumer)
    }

    static update(updateCategorySpec, categoryConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.put(CategoriesRepo.mgmtPath, updateCategorySpec, categoryConsumer, errorConsumer)
    }

    static delete(id, categoryConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.delete(PathFactory.create([CategoriesRepo.mgmtPath, id]), categoryConsumer, errorConsumer)
    }
}

export default CategoriesRepo;
