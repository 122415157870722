/**
 * @param {string|long} dateTime Required 
 * @returns not-null string
 */
function serverToLocalDateTime(dateTime) {
    return new Date(dateTime).toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour12: false,
        hour: "2-digit", minute: "2-digit"
    })
}


/**
 * @param {string|long} dateTime Required 
 * @returns not-null string
 */
function serverToLocalTime(dateTime) {
    return new Date(dateTime).toLocaleString("en-GB", {
        hour12: false,
        hour: "2-digit", minute: "2-digit"
    })
}

/**
 * @param {string|long} dateTime Required 
 * @returns not-null string
 */
function serverToLocalDate(dateTime) {
    return new Date(dateTime).toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
    })
}

/**
 * @param {string|long} dateTime 
 * @returns not-null string
 */
function formatDateTimeUTC(dateTime) {
    return new Date(dateTime).toLocaleString("en-GB", {
        timeZone: 'UTC',
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour12: false,
        hour: "2-digit", minute: "2-digit"
    })
}

/**
 * @param {*} utcMs number of milliseconds for this date since the epoch,
 *  which is defined as the midnight at the beginning of January 1, 1970, UTC.
 * @returns 
 */
function utcToSofia(utcMs) {
    return utcMs + Math.abs(new Date().getTimezoneOffset()) * 60 * 1000
}


export { serverToLocalDateTime, serverToLocalDate, serverToLocalTime, utcToSofia, formatDateTimeUTC }
