import { Header, Table } from "semantic-ui-react"
import CollectionUtils from "../../../utils/collections"
import AcrivityLogRepo from "../../../utils/repository/activityLogRepo"
import { useEffect, useMemo, useState } from "react"
import { serverToLocalDate, serverToLocalTime, utcToSofia } from "../../../utils/dateTimeUtils"
import Alert from "../../../utils/alert"
import Assert from "../../../utils/asserts"
import useDateRange from '../../../hooks/useDateRange'
import { subDays } from "date-fns"
import RangeDatePicker from "../../../components/rangeDatePicker"

function mapLogsToDate(logs) {
    const dateTologs = []

    for (const log of logs) {
        const start = serverToLocalDate(log.start);
        if (!dateTologs[start]) {
            dateTologs[start] = []
        }
        dateTologs[start].push(log)
    }

    return dateTologs
}

function ActivityLogs() {
    const [logs, setLogs] = useState([])

    const { from, to, handleFromChange, handleToChange, handleMoveToNextMonth, handleMoveToPrevMonth } = useDateRange({ from: subDays(new Date(), 7), to: new Date() })

    const fromTM = useMemo(() => new Date(from.getTime()).setUTCHours(0, 0, 0, 0), [from])
    const toTM = useMemo(() => new Date(to.getTime()).setUTCHours(23, 59, 0, 0), [to])


    if (((to - from) / (1000 * 3600 * 24)) > 31) {
        Alert.warn("The specified period cannot be higher than 31 days")
        handleFromChange(to);
    }

    useEffect(() => AcrivityLogRepo.list(
        { from: fromTM, to: toTM },
        logs => setLogs(logs.sort(function (a, b) {
            return new Date(b.start) - new Date(a.start);
        }))
    ), [fromTM, toTM])


    const dateTologs = mapLogsToDate(logs)
    const usernames = Array.from(new Set(logs.map(l => l.user.fullName))).sort()

    const rows = []
    const logTotalsMap = {}

    for (const username of usernames) {
        logTotalsMap[username] = {
            duration: 0,
            clicks: 0,
            keystrokes: 0,
            startToEnd: 0
        }
    }

    const mergedSessions = new Set()

    let totalClicks = 0
    let totalKeystrokes = 0
    let middleDuration = 0

    for (const [date, logs] of Object.entries(dateTologs)) {
        const usernameToLog = CollectionUtils.arrToMap(logs, l => l.user.fullName, (a, b) => {

            a.error = a.error || !(a.end < b.start || b.end < a.start)
            a.warn = a.error ? false : true

            let inactivityDurationMs

            if (a.end < b.start) {
                inactivityDurationMs = b.start - a.end
            } else if (b.end < a.start) {
                Assert.positiveNumber(a.start - b.end, "test")
                inactivityDurationMs = a.start - b.end
            }
            a.inactivityDurationMs = inactivityDurationMs

            a.duration += b.duration
            a.clicks += b.clicks
            a.keystrokes += b.keystrokes
            a.middleDuration += b.middleDuration
            a.longDuration += b.longDuration
            a.start = a.start > b.start ? b.start : a.start
            a.end = a.end > b.end ? a.end : b.end

            return a
        })

        const cells = [date]

        for (const username of usernames) {
            const log = usernameToLog[username]

            if (log) {
                cells.push(<LogTable {...log} />)
                const total = logTotalsMap[username]
                total.duration += (log.duration - (log.inactivityDuration === undefined ? 0 : log.inactivityDuration))
                middleDuration += (log.middleDuration - (log.inactivityDuration === undefined ? 0 : log.inactivityDuration))
                total.middleDuration += (log.middleDuration - (log.inactivityDuration === undefined ? 0 : log.inactivityDuration))
                total.longDuration += (log.longDuration - (log.inactivityDuration === undefined ? 0 : log.inactivityDuration))
                totalClicks += log.clicks
                total.clicks += log.clicks
                total.keystrokes += log.keystrokes
                totalKeystrokes += log.keystrokes
                total.startToEnd += log.end - log.start
            } else {
                cells.push(<EmptyLogTable />)
            }
        }

        rows.push(cells)
    }

    if (mergedSessions.size > 0) {
        Alert.warn("Merge of multiple user sessons for same date. Users:" + Array.from(mergedSessions))
    }

    const totals = ["TOTAL"]

    for (const username of usernames) {
        totals.push(<TotalTable {...logTotalsMap[username]} />)
    }

    rows.push(totals)

    return logs.length === 0 ? <>No Logs</> : <>
        <RangeDatePicker from={from} to={to}
            handleFromChange={handleFromChange} handleToChange={handleToChange}
            handleMoveToNextMonth={handleMoveToNextMonth} handleMoveToPrevMonth={handleMoveToPrevMonth} />
        <TotalStatsTable middleDuration={middleDuration} totalClicks={totalClicks} totalKeystrokes={totalKeystrokes} />
        <div className="activityLogsTable">

            <Table className="activityLogsTableZ" striped celled >
                <Table.Header>
                    <Table.Row >
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        {usernames.map((u, i) => <Table.HeaderCell key={i}> {u}</Table.HeaderCell>)}
                    </Table.Row>
                </Table.Header>
                <Table.Header className="subHeader">
                    <Table.Row >
                        <Table.HeaderCell></Table.HeaderCell>
                        {usernames.map(u =>
                            <Table.HeaderCell key={u}>
                                <Table fixed >
                                    <Table.Header>
                                        <Table.Row >
                                            <Table.HeaderCell>Duration 2M</Table.HeaderCell>
                                            <Table.HeaderCell>Duration 5M</Table.HeaderCell>
                                            <Table.HeaderCell>Duration 10M</Table.HeaderCell>
                                            <Table.HeaderCell>Clicks</Table.HeaderCell>
                                            <Table.HeaderCell>Keystrokes</Table.HeaderCell>
                                            <Table.HeaderCell>Start</Table.HeaderCell>
                                            <Table.HeaderCell>End</Table.HeaderCell>
                                            <Table.HeaderCell>End-Start</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                </Table>
                            </Table.HeaderCell>)}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((cells, i) =>
                        <Table.Row key={i} >
                            {cells.map((cellData, ci) => <Table.Cell key={ci}>
                                {cellData}
                            </Table.Cell>)}
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </div>
    </>
}

function msToHour(ms) {
    return secToHour(Math.abs(ms) / 1000)
}

function secToHour(sec) {
    return minToHour(Math.floor(sec / 60))
}

function minToHour(min) {
    let minutes = min % 60 + ""
    if (minutes.length == 1) {
        minutes = 0 + minutes
    }
    return (Math.floor(min / 60)) + "." + minutes
}

function LogTable({ duration, clicks, keystrokes, start, end, warn, error, inactivityDurationMs, middleDuration, longDuration }) {
    return <Table fixed>
        <Table.Body>
            <Table.Row warning={warn == true} error={error == true}>
                <Table.Cell className="redFont">
                    {minToHour(duration)}
                </Table.Cell>
                <Table.Cell>
                    {minToHour(middleDuration)}
                </Table.Cell>
                <Table.Cell >
                    {minToHour(longDuration)}
                </Table.Cell>
                <Table.Cell >
                    {clicks}
                </Table.Cell>
                <Table.Cell >
                    {keystrokes}
                </Table.Cell>
                <Table.Cell >
                    {serverToLocalTime(start)}
                </Table.Cell>
                <Table.Cell >
                    {serverToLocalTime(end)}
                </Table.Cell>
                <Table.Cell className="yellowFont">
                    {msToHour((end - start) - (inactivityDurationMs || 0))}
                </Table.Cell>
            </Table.Row>
        </Table.Body>
    </Table >
}

function EmptyLogTable() {
    return <Table fixed>
        <Table.Body>
            <Table.Row >
                <Table.Cell />
                <Table.Cell />
                <Table.Cell />
                <Table.Cell />
                <Table.Cell />
                <Table.Cell />
                <Table.Cell />
                <Table.Cell />
            </Table.Row>
        </Table.Body>
    </Table >
}

function TotalTable({ duration, clicks, keystrokes, startToEnd }) {
    return <Table striped className="revewRequiestTable">

        <Table.Body>
            <Table.Row >
                <Table.Cell className="redFont">
                    {minToHour(duration)}
                </Table.Cell>
                <Table.Cell />
                <Table.Cell />
                <Table.Cell >
                    {clicks}
                </Table.Cell>
                <Table.Cell >
                    {keystrokes}
                </Table.Cell>
                <Table.Cell />
                <Table.Cell />
                <Table.Cell>
                    {msToHour(startToEnd)}
                </Table.Cell>
            </Table.Row>
        </Table.Body>
    </Table >
}

function TotalStatsTable({ totalClicks, totalKeystrokes, middleDuration }) {
    return (
        <>
            <Header textAlign="center">Totals</Header>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            Clicks
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Keystrokes
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Middle Duration
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Keystrokes + Clicks
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            {totalClicks}
                        </Table.Cell>
                        <Table.Cell>
                            {totalKeystrokes}
                        </Table.Cell>
                        <Table.Cell>
                            {middleDuration}
                        </Table.Cell>
                        <Table.Cell>
                            {totalClicks + totalKeystrokes}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </>

    )
}

export { ActivityLogs }
