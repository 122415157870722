import ObjCheck from "./objCheck"
class Equals {

    /**
     * @param obj1 Optional 
     * @param obj2 Optional
     * @returns true of the objects are equal, false otherwice
     */
    static deepEquals(obj1, obj2) {
        if (obj1 === obj2 || JSON.stringify(obj1) === JSON.stringify(obj2)) {
            return true
        } else if (ObjCheck.isPrimitive(obj1) || ObjCheck.isNullOrUndefined(obj1) || ObjCheck.isNullOrUndefined(obj2)) {
            return false
        } else {
            if (!ObjCheck.isObjType(obj1) || !ObjCheck.isObjType(obj2)) {
                throw new Error("Usuported object comparison.")
            }
            const keys1 = Object.keys(obj1)
            const keys2 = Object.keys(obj2)
            if (keys1.length !== keys2.length || keys1.length === 0) {
                return false
            }

            for (const key1 of keys1) {
                if (ObjCheck.isObjType(obj1[key1])) {
                    return this.deepEquals(obj1[key1], obj2[key1])
                }

                if (obj1[key1] !== obj2[key1]) {
                    return false
                }
            }

            return true
        }
    }
}


export default Equals