import HttpReq from "../../service/httpReq"

const loggingEnabled = process.env.REACT_APP_SERVER_LOGGING_ENABLED === "true"

class AcrivityLogRepo {
    static prefix = 'api/mgmt/activityLogs'


    static save(activity, successConsumer, errorConsumer) {
        if (loggingEnabled) {
            HttpReq.post(AcrivityLogRepo.prefix, activity, successConsumer, errorConsumer)
        } else {
            successConsumer()
        }
    }

    static list(filterSpec, logsConsumer, errorConsumer) {
        HttpReq.get(AcrivityLogRepo.prefix + "?" + new URLSearchParams(filterSpec), logsConsumer, errorConsumer)
    }
}

export default AcrivityLogRepo