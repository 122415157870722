import { Card } from "semantic-ui-react"
import ObjCheck from "../../utils/objCheck"
import CompanyC from "../../constnats/companyConstants"

function ExternalResourcesCard({ company }) {
    const links = Object.keys(CompanyC.LINK_PROPS_MAP).filter(k => !ObjCheck.isNullUndefinedOrEmpty(company[k])).map(k =>
        <div key={k}><a target="_blank" rel="noreferrer" href={company[k]}>{CompanyC.LINK_PROPS_MAP[k]}</a></div>)

    return links.length > 0 &&
        <Card>
            <Card.Content>
                <Card.Header className="smallHardHeader">External Resources</Card.Header>
                <Card.Description>
                    {links}
                </Card.Description>
            </Card.Content>
        </Card>
}


export { ExternalResourcesCard }