import { Card } from 'semantic-ui-react';

type DescriptionProps = {
    description: string;
};

function Description({ description }: DescriptionProps) {
    return (
        <Card>
            <Card.Content>
                <Card.Header className="smallHardHeader">Description</Card.Header>
                <Card.Description>{description}</Card.Description>
            </Card.Content>
        </Card>
    );
}

export { Description };
