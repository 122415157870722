import { Header, Table } from "semantic-ui-react";
import CompanyRepo from "../../../../utils/repository/companyRepo";
import SchemaRepo from "../../../../utils/repository/schemaRepo";
import TableUpdatesRepo from "../../../../utils/repository/tableUpdatesRepo";
import { useEffect, useState } from "react";
import Loader from "../../../../components/loader";

function CompaniesStatus() {
    const [companyKPIs, setCompanyKPIs] = useState({ internalCount: 0, publicCount: 0 })

    useEffect(() => {
        CompanyRepo.kpis(setCompanyKPIs)
    }, [false]);

    return <>
        <Header as="h4">Companies</Header>
        <Table striped>
            <Table.Header>
                <Table.Row >
                    <Table.HeaderCell>Public</Table.HeaderCell>
                    <Table.HeaderCell>Internal</Table.HeaderCell>
                    <Table.HeaderCell>Total</Table.HeaderCell>
                    <Table.HeaderCell>Outdated</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row >
                    <Table.Cell>
                        {companyKPIs.publicCount}
                    </Table.Cell>
                    <Table.Cell>
                        {companyKPIs.internalCount}
                    </Table.Cell>
                    <Table.Cell>
                        {companyKPIs.publicCount + companyKPIs.internalCount}
                    </Table.Cell>
                    <Table.Cell>
                        {companyKPIs.outdatedCount}
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    </>
}

function SchemasStatus() {
    const [schemaKPIs, setSchemaKPIs] = useState(null)

    useEffect(() => {
        SchemaRepo.kpis(setSchemaKPIs)
    }, [false]);

    return schemaKPIs === null ? <Loader /> : <>
        <Header as="h4">Tables</Header>
        <Table striped>
            <Table.Header>
                <Table.Row >
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Public</Table.HeaderCell>
                    <Table.HeaderCell>Internal</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {Object.keys(schemaKPIs.count).sort((a, b) => {
                    const map = schemaKPIs.count
                    return (map[b].PUBLIC + map[b].INTERNAL) - (map[a].PUBLIC + map[a].INTERNAL)
                }).filter(n => (schemaKPIs.count[n].PUBLIC + schemaKPIs.count[n].INTERNAL) > 0)
                    .map((name, i) =>
                        <Table.Row key={i} >
                            <Table.Cell>
                                {name}
                            </Table.Cell>
                            <Table.Cell>
                                {schemaKPIs.count[name].PUBLIC}
                            </Table.Cell>
                            <Table.Cell>
                                {schemaKPIs.count[name].INTERNAL}
                            </Table.Cell>
                        </Table.Row>
                    )}
            </Table.Body>
        </Table>
    </>
}

function UpdatesStatus() {
    const [updateKPIs, setUpdateKPIs] = useState({})

    useEffect(() => {
        TableUpdatesRepo.kpis(setUpdateKPIs)
    }, [false]);

    const draftsKey = "DRAFT"
    const peerReviewsKey = "READY_FOR_PEER_REVIEW"
    const qaReviewsKey = "READY_FOR_QA_REVIEW"
    const countMap = {
        [draftsKey]: 0,
        [peerReviewsKey]: 0,
        [qaReviewsKey]: 0
    }

    if (updateKPIs.notAppliedKpis) {
        for (const record of updateKPIs.notAppliedKpis) {
            countMap[record.phase] += record.count
        }
    }

    return updateKPIs.notAppliedKpis === undefined ? "" : <>
        <Header as="h4" >Table Updates</Header>
        <Table striped>
            <Table.Header>
                <Table.Row >
                    <Table.HeaderCell>In Draft Phase</Table.HeaderCell>
                    <Table.HeaderCell>In Peer Review Phase</Table.HeaderCell>
                    <Table.HeaderCell>In QA Review Phase</Table.HeaderCell>
                    <Table.HeaderCell>Total</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        {countMap[draftsKey]}
                    </Table.Cell>
                    <Table.Cell>
                        {countMap[peerReviewsKey]}
                    </Table.Cell>
                    <Table.Cell>
                        {countMap[qaReviewsKey]}
                    </Table.Cell>
                    <Table.Cell>
                        {
                            countMap[draftsKey] +
                            countMap[peerReviewsKey] +
                            countMap[qaReviewsKey]
                        }
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    </>
}


export default function DatabaseStatus() {
    return <>
        <CompaniesStatus />
        <SchemasStatus />
        <UpdatesStatus />
    </>
}
