import HttpReq from "../../service/httpReq"

class SchemaMergeRepo {
    static prefix = 'api/mgmt/schemaMerge'

    static findBySchemaId(schemaId, resConsumer, errorConsumer) {
        HttpReq.get(SchemaMergeRepo.prefix + "/search?schemaId=" + schemaId, resConsumer, errorConsumer)
    }
}

export default SchemaMergeRepo