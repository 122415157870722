import { ReviewType, UpdatePurpose } from "../../../../components/financialTables/configuration/constants";
import Assert from "../../../../utils/asserts";

const UPDATE_PURPOSES_TO_IGNORE_MISTAKES_FOR = [UpdatePurpose.MISTAKES_DETECTION, UpdatePurpose.DEFERRED_REVIEWS_EXECUTION]

function accumulateQaEffectiveness(userToMistakesKPIs, mistakes, tableTypesToInclude) {
    const qaEffectiveness = mistakes.qaEffectiveness
        .filter(e => tableTypesToInclude.includes(e.tableType))
        .filter(e => userToMistakesKPIs[e.userId] !== undefined)

    for (const effectiveness of qaEffectiveness) {
        const userEffectiveness = userToMistakesKPIs[effectiveness.userId]
        userEffectiveness.detectedMistakesAsQA.mistakePoints += effectiveness.detectedMistakes
        userEffectiveness.detectedMistakesAsQA.cellsScope += effectiveness.cellsScope
    }
}

function accumulateUpdateMistakeKPIs(userToMistakesKPIs, mistakes, tableTypesToInclude) {
    const updates = mistakes.updates
        .filter(u => tableTypesToInclude.includes(u.tableType))
        .filter(u => u.cellsScope !== null)
        .filter(u => userToMistakesKPIs[u.userId] !== undefined)
        .filter(u => !UPDATE_PURPOSES_TO_IGNORE_MISTAKES_FOR.includes(u.purpose))

    for (const updateMistakes of updates) {
        const userMistakes = userToMistakesKPIs[updateMistakes.userId]
        userMistakes.updates.mistakePoints += (updateMistakes.mistakePointsFromPeer + updateMistakes.mistakePointsFromQa)
        userMistakes.updates.cellsScope += updateMistakes.cellsScope
    }
}


function accumulateReviewMistakeKPIs(userToMistakesKPIs, mistakes, tableTypesToInclude) {
    const reviews = mistakes.reviews
        .filter(r => tableTypesToInclude.includes(r.tableType))
        .filter(u => u.cellsScope !== null)
        .filter(u => u.mistakePoints !== null)
        .filter(u => userToMistakesKPIs[u.userId] !== undefined)

    for (const reviewMistakes of reviews) {
        const userMistakes = userToMistakesKPIs[reviewMistakes.userId]

        let reviewTypeKey
        if (reviewMistakes.type === ReviewType.FULL_CHECK) {
            reviewTypeKey = "fullReviews"
        } else if (reviewMistakes.type === ReviewType.CORE_DATA_CHECK && reviewMistakes.updatePurpose === UpdatePurpose.MISTAKES_DETECTION) {
            reviewTypeKey = "coreDataReviews"
        } else if (reviewMistakes.type === ReviewType.CORE_DATA_CHECK && reviewMistakes.updatePurpose === UpdatePurpose.REGULAR_UPDATE) {
            reviewTypeKey = "incrementalCoreDataReviews"
        } else {
            Assert.trueVal(reviewMistakes.type === ReviewType.VISUAL_CHECK, "qualityUtils.js accumulateReviewMistakeKPIs: type is not visual check")
        }

        if (reviewTypeKey) {
            userMistakes[reviewTypeKey].mistakePoints += reviewMistakes.mistakePoints
            userMistakes[reviewTypeKey].cellsScope += reviewMistakes.cellsScope

            if (reviewTypeKey === 'coreDataReviews' || reviewTypeKey === "incrementalCoreDataReviews") {
                userMistakes.totalCoreDataReviews.mistakePoints += reviewMistakes.mistakePoints
                userMistakes.totalCoreDataReviews.cellsScope += reviewMistakes.cellsScope
            }
        }
    }
}


function accumulateAverageMistakeKPIs(userToMistakesKPIs) {
    const total = {
        updates: { cellsScope: 0, mistakePoints: 0 },
        fullReviews: { cellsScope: 0, mistakePoints: 0 },
        coreDataReviews: { cellsScope: 0, mistakePoints: 0 },
        incrementalCoreDataReviews: { cellsScope: 0, mistakePoints: 0 },
        totalCoreDataReviews: { cellsScope: 0, mistakePoints: 0 },
        detectedMistakesAsQA: { mistakePoints: 0, cellsScope: 0 },
    }

    for (const kpis of Object.values(userToMistakesKPIs)) {
        total.updates.cellsScope += kpis.updates.cellsScope || 0
        total.updates.mistakePoints += kpis.updates.mistakePoints || 0
        total.fullReviews.cellsScope += kpis.fullReviews.cellsScope || 0
        total.fullReviews.mistakePoints += kpis.fullReviews.mistakePoints || 0
        total.coreDataReviews.cellsScope += kpis.coreDataReviews.cellsScope || 0
        total.coreDataReviews.mistakePoints += kpis.coreDataReviews.mistakePoints || 0
        total.incrementalCoreDataReviews.cellsScope += kpis.incrementalCoreDataReviews.cellsScope || 0
        total.incrementalCoreDataReviews.mistakePoints += kpis.incrementalCoreDataReviews.mistakePoints || 0
        total.totalCoreDataReviews.cellsScope += kpis.totalCoreDataReviews.cellsScope || 0
        total.totalCoreDataReviews.mistakePoints += kpis.totalCoreDataReviews.mistakePoints || 0
        total.detectedMistakesAsQA.cellsScope += kpis.detectedMistakesAsQA.cellsScope || 0
        total.detectedMistakesAsQA.mistakePoints += kpis.detectedMistakesAsQA.mistakePoints || 0
    }

    userToMistakesKPIs["===AVERAGE W==="] = total
}

function calcMistakeScore({ cellsScope, mistakePoints }) {
    return cellsScope === 0 ? undefined : Number((mistakePoints / cellsScope) * 100)
}


/**
 * @param {int[]} usersToInclude 
 * @param {*} performaceKpis 
 * @param {string[]} tableTypesToInclude 
 * @returns not-null
 */
function getRawUserGroupedMistakeKPIs(usersToInclude, performaceKpis, tableTypesToInclude) {
    const userToMistakesKPIs = {}

    for (const userId of usersToInclude) {
        userToMistakesKPIs[userId] = {
            updates: { mistakePoints: 0, cellsScope: 0 },
            fullReviews: { mistakePoints: 0, cellsScope: 0 },
            /** 
             * `coreDataReviews` is used to keep track of the `mistakePoints` and  `cellsScope` for
             * update with a purpose of `MISTAKES_DETECTION`
             */
            coreDataReviews: { mistakePoints: 0, cellsScope: 0 },
            /** 
             * `incrementalCoreDataReviews` is used to keep track of the `mistakePoints` and  `cellsScope` for
             * update with a purpose of `REGULAR_UPDATE`
             */
            incrementalCoreDataReviews: { mistakePoints: 0, cellsScope: 0 },
            /**
             * totalCoreDataReviews is used to keep track of the sum of the `mistakePoints` and `cellsScope` for
             * `coreDataReviews` and `incrementalCoreDataReviews`
             */
            totalCoreDataReviews: { mistakePoints: 0, cellsScope: 0 },
            /**
             * `detectedMistakesAsQA` is used to hold the mistakes that the user has detected 
             * when the user was doing a visual check as a QA for a given update 
             */
            detectedMistakesAsQA: { mistakePoints: 0, cellsScope: 0 },
        }
    }

    accumulateUpdateMistakeKPIs(userToMistakesKPIs, performaceKpis, tableTypesToInclude)
    accumulateReviewMistakeKPIs(userToMistakesKPIs, performaceKpis, tableTypesToInclude)
    accumulateQaEffectiveness(userToMistakesKPIs, performaceKpis, tableTypesToInclude)
    accumulateAverageMistakeKPIs(userToMistakesKPIs)

    return userToMistakesKPIs
}


/**
 * @param {{@see getRawUserGroupedMistakeKPIs}} userToMistakesKPIs  Required
 * @returns not-null
 */
function adjustedUserGroupedMistakeKPIs(userToMistakesKPIs) {
    return Object.keys(userToMistakesKPIs)
        .map(userId => {
            const quality = userToMistakesKPIs[userId]
            const updates = quality.updates
            const updatesScore = calcMistakeScore(updates)
            const fullReviews = quality.fullReviews
            const fullReviewsScore = calcMistakeScore(fullReviews)
            const coreDataReviews = quality.coreDataReviews
            const coreDataReviewsScore = calcMistakeScore(coreDataReviews)
            const incrementalCoreDataReviews = quality.incrementalCoreDataReviews
            const incrementalCoreDataReviewsScore = calcMistakeScore(incrementalCoreDataReviews)
            const totalCoreDataReviews = quality.totalCoreDataReviews
            const totalCoreDataReviewsScore = calcMistakeScore(totalCoreDataReviews)
            const detectedMistakesAsQA = quality.detectedMistakesAsQA
            const detectedMistakesAsQAScore = calcMistakeScore(detectedMistakesAsQA)

            const totalScope = updates.cellsScope
                + fullReviews.cellsScope

            const totalScore = calcMistakeScore(
                {
                    cellsScope: totalScope,
                    mistakePoints: updates.mistakePoints +
                        fullReviews.mistakePoints
                }
            )

            return {
                userId, updatesScore, updatesScope: updates.cellsScope,
                fullReviewsScore, fullReviewsScope: fullReviews.cellsScope,
                coreDataReviewsScore, coreDataReviewsScope: coreDataReviews.cellsScope,
                incrementalCoreDataReviewsScore, incrementalCoreDataReviewsScope: incrementalCoreDataReviews.cellsScope,
                totalCoreDataReviewsScore, totalCoreDataReviewsScope: totalCoreDataReviews.cellsScope,
                detectedMistakesAsQAScore, detectedMistakesAsQAScope: detectedMistakesAsQA.cellsScope,
                totalScore, totalScope
            }
        })
}


export { adjustedUserGroupedMistakeKPIs, getRawUserGroupedMistakeKPIs }