import React, { useEffect, useState } from 'react';
import { Header, Tab } from 'semantic-ui-react'
import Loader from '../../../components/loader';
import { UpdatesTableSummary } from '../../../components/admin/updates/updatesTab';
import Alert from '../../../utils/alert';
import DatePicker from '../../../components/form/datePicker';
import TableUpdates from '../../../service/tableUpdates';


function GlobalTableUpdates() {
    return <>
        <Header as="h2" textAlign='center'>Table Updates</Header>
        <Tab panes={
            [
                getPaneCfg("Drafts",
                    <UpdatesTable query={{ phases: ["DRAFT"] }} includeMistakes={false} />),
                getPaneCfg("Pending Peer Review",
                    <UpdatesTable query={{ phases: ["READY_FOR_PEER_REVIEW"] }} includeMistakes={false} />),
                getPaneCfg("Pending QA Review",
                    <UpdatesTable query={{ phases: ["READY_FOR_QA_REVIEW"] }} includeMistakes={false} />),
                getPaneCfg("Applied", <AppliedUpdatesTable />),
            ]} />
    </>
}

function getPaneCfg(name, content) {
    return {
        menuItem: name,
        render: () => <Tab.Pane>
            {content}
        </Tab.Pane>
    }
}


function AppliedUpdatesTable() {
    const [from, setFrom] = useState(() => new Date(new Date().setDate(new Date().getDate() - 7)))
    const [to, setTo] = useState(() => new Date())

    let fromTM = new Date(from.getTime()).setUTCHours(0, 0, 0, 0)
    const toTM = new Date(to.getTime()).setUTCHours(23, 59, 0, 0)

    if (((to - from) / (1000 * 3600 * 24)) > 31 * 2) {
        Alert.warn("The specified period cannot be higher than 62 days")
        fromTM = to
    }

    return <>
        <DatePicker
            label="From:" date={from}
            onChange={d => setFrom(d)} />
        <DatePicker
            label="To:" date={to}
            onChange={d => setTo(d)} />
        <UpdatesTable
            query={{ phases: ["APPLIED"], appliedAfter: fromTM, appliedBefore: toTM }}
            includeMistakes={true} />
    </>
}

function UpdatesTable({ query, includeMistakes }) {
    const [updates, setUpdates] = useState(null)
    const [loading, setLoading] = useState(true)


    function loadUpdates() {
        setLoading(true)
        TableUpdates.find(query).then(updates => {
            setUpdates(updates)
            setLoading(false)
        })
    }

    useEffect(() => {
        loadUpdates()
    }, [query])

    return loading ? <Loader /> : <UpdatesTableSummary {...{ updates, includeMistakes }}
        onUpdatesChange={loadUpdates} />
}

export default GlobalTableUpdates