import { Link } from "react-router-dom";
import CookieBanner from "./cookiesContest";

function Footer() {
    return (
        <footer>
            <Link to="/info/aboutUs">About Us</Link>
            <Link to="/contactUs">Contact Us</Link>
            {/* <Link to="/info/accountTypes">Account Types</Link> */}
            <Link to="/info/services">Services</Link>
            <Link to="/info/careers">Careers</Link>
            <Link to="/info/privacyPolicy">Privacy Policy</Link>
            <Link to="/info/termsAndConditions">Terms and Conditions</Link>
            <br />
            Copyright © 2022-2024 3D StockPicker. All Rights Reserved.
            <CookieBanner />
        </footer>
    )
}

export default Footer
