import { SchemaNames } from "./schemaConstants" 

const PERIODS = ["1", "2", "3", "4"]

class ResourceCategory {
    static REPORT = "Report"
    static PRESENTATION = "Presentation"
    static PRESS_RELEASE = "Press Release"
    static INCOME_STATEMENT = SchemaNames.IS
    static BALANCE_SHEET = SchemaNames.BS
    static KPI = "KPI"
    static CASH_FLOW = SchemaNames.CF
    static REPORT_SUPLEMENT = "Report Suplement"
    static SEG_BY_PROD = SchemaNames.SEG_BY_PROD
    static SEG_BY_REG = SchemaNames.SEG_BY_REG
    static OUTLOOK = "Outlook"
    static PRESENTATION_OUTLOOK = "Presentation Outlook"
    static REPORT_OUTLOOK = "Report Outlook"
    static PRESS_RELEASE_OUTLOOK = "Press Release Outlook"
}

const RESOURCE_CATEGORIES = [
    ResourceCategory.REPORT,
    ResourceCategory.PRESENTATION,
    ResourceCategory.PRESS_RELEASE,
    ResourceCategory.INCOME_STATEMENT,
    ResourceCategory.BALANCE_SHEET,
    ResourceCategory.KPI,
    ResourceCategory.CASH_FLOW,
    ResourceCategory.OUTLOOK,
    ResourceCategory.PRESENTATION_OUTLOOK,
    ResourceCategory.REPORT_OUTLOOK,
    ResourceCategory.PRESS_RELEASE_OUTLOOK,
    ResourceCategory.REPORT_SUPLEMENT,
    ResourceCategory.SEG_BY_PROD,
    ResourceCategory.SEG_BY_REG
]


export { PERIODS, RESOURCE_CATEGORIES, ResourceCategory }
