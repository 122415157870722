import { HALF_YEAR_OVER_HALF_YEAR_GROWTH_HEADING, QUARTER_OVER_QUARTER_GROWTH_HEADING } from "../constnats/growth"
import { PERIODS_MAP } from "../constnats/periods"

const QUARTER_OVER_QUARTER_PERIODS = [PERIODS_MAP.Q1, PERIODS_MAP.Q2, PERIODS_MAP.Q3, PERIODS_MAP.Q4]
const HALF_YEAR_OVER_HALF_YEAR_PERIODS = [PERIODS_MAP.H1, PERIODS_MAP.H2]
/**
 * @description Returns the heading for the growth percentages depending on the period
 * @param {string} period Required
 * @returns {string | undefined}
 */
export const getCorrespondingPeriodGrowthHeading = (period) => {
    if (QUARTER_OVER_QUARTER_PERIODS.includes(period)) {
        return QUARTER_OVER_QUARTER_GROWTH_HEADING
    }
    if (HALF_YEAR_OVER_HALF_YEAR_PERIODS.includes(period)) {
        return HALF_YEAR_OVER_HALF_YEAR_GROWTH_HEADING
    }
    return

}
