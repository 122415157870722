import { Header, Tab } from "semantic-ui-react"
import DatabaseStatus from "./components/databaseStatus";
import TaskManagement from "./components/tasksManagement";
import QualityMetrix from "./components/quality";
import PerformanceManagement from "./components/performance";
import ProductivityMetrix from "./components/productivity";
import ReportsFocusedProductivityMetrix from "./components/reportsFocusedProductivity";

function GloalKPIs() {
    return <>
        <Header as="h2" textAlign='center'>KPIs</Header>
        <Tab panes={
            [
                getPaneCfg("Database Status", <DatabaseStatus />),
                getPaneCfg("Tasks Management", <TaskManagement />),
                getPaneCfg("Performance (DEPRECATED)", <PerformanceManagement />),
                getPaneCfg("Quality", <QualityMetrix />),
                getPaneCfg("Productivity", <ProductivityMetrix />)
            ]} />
    </>
}


function getPaneCfg(name, content) {
    return {
        menuItem: name,
        render: () => <Tab.Pane>
            {content}
        </Tab.Pane>
    }
}

export default GloalKPIs