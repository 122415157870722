import React from 'react';
import { Button, Form, Dropdown, Header, Divider } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { CalculationExpUtil } from '../../../../../../../utils/descriptor/calculationExp';
import Utils from '../../../../../../../utils/descriptor/descriptorUtils';
import DescriptorType from '../../../../../../../utils/descriptor/descriptorType';

const MATH_SIGN_OPTIONS = [
    { key: '+', text: 'Sum', value: '+' },
    { key: '-', text: 'Substract', value: '-' },
    { key: '/', text: 'Divide', value: '/' },
    { key: '*', text: 'Multiply', value: '*' }
]

function MathSignSelect({ sign, onSignChange, error, disabled }) {

    return (
        <Form.Select
            {...{ error, disabled }}
            label='Operation'
            options={MATH_SIGN_OPTIONS}
            value={sign}
            onChange={(a, b) => onSignChange(b.value)} />
    )
}

function FieldsGroup({ allowedFieldsOps, currentFieldsIds, onGroupChange, error, disabled }) {

    const allowedFieldIds = allowedFieldsOps.map(o => o.value)
    const invalidIds = currentFieldsIds.filter(i => !allowedFieldIds.includes(i))
    const fieldOpsToPresent = allowedFieldsOps.slice()

    for (const invalidIdx of invalidIds) {
        fieldOpsToPresent.push(
            {
                "key": invalidIdx,
                "text": "(INVALID)" + invalidIdx,
                "value": invalidIdx
            }
        )
    }

    return (
        <Form.Field>
            <Header as="h6">Sum Group</Header>
            <Dropdown
                {...{ error, disabled }}
                onChange={(a, b) => onGroupChange(b.value)}
                value={currentFieldsIds}
                placeholder='Sum Group'
                options={fieldOpsToPresent} fluid multiple selection search />
        </Form.Field>
    )
}

class CalcField extends React.Component {

    constructor(props) {
        super(props);
        this.state = { calculationArr: CalculationExpUtil.strToFragments(this.props.calculation) }
    }

    removeGroup(calculationArr) {
        const copy = calculationArr.slice();
        copy.splice(copy.length - 2, 1);
        this.onCalcArrCahnge(copy)
    }

    addGroup(calculationArr) {
        const copy = calculationArr.slice();
        copy.push("+")
        copy.push([])
        this.onCalcArrCahnge(copy)
    }

    onFragmentChange(fragIdx, newFrag, calculationArr) {
        const copy = calculationArr.slice();
        copy[fragIdx] = newFrag
        this.onCalcArrCahnge(copy)
    }

    onCalcArrCahnge(newCalcArr) {
        this.setState({ calculationArr: newCalcArr })
        this.props.onChange(
            CalculationExpUtil.fragmentsToStr(CalculationExpUtil.filterEmptyFragments(newCalcArr))
        )
    }

    getField(fragment, allowedFieldsOps, key, error, disabled, onFragmentChange) {
        return Array.isArray(fragment) ?
            <FieldsGroup
                {...{ error, key, disabled, allowedFieldsOps }}
                currentFieldsIds={fragment}
                onGroupChange={onFragmentChange} /> 
            :
            <MathSignSelect
                {...{ error, key, disabled }}        
                sign={fragment}
                onSignChange={onFragmentChange} />
    }

    getAllowedCalcFieldOps() {
        const desctrptors = this.props.flatDescriptors.filter(d => DescriptorType.isNumber(d))
        const dependancyIds = Utils.dependancyList(this.props.forFieldId, desctrptors)

        return desctrptors
            .filter(d => !dependancyIds.has(d.id))
            .map(f => {
                return { key: f.id, text: f.label, value: f.id }
            })
    }

    render() {
        const calcArr = this.state.calculationArr.length === 0 ? [[]] : this.state.calculationArr
        const allowedFiledOps = this.getAllowedCalcFieldOps()
        const disabled = this.props.disabled

        return <>
            <Divider />
            <Header as="h5">{this.props.header}</Header>

            {calcArr.map((frag, idx) => this.getField(frag, allowedFiledOps, idx,
                this.props.error, disabled,
                newFrag => this.onFragmentChange(idx, newFrag, calcArr)
            ))}

            <Button {...{ disabled }}
                size='mini'
                onClick={() => this.addGroup(calcArr)}>
                Add Group
            </Button>

            {calcArr.length > 1 &&
                <Button negative {...{ disabled }} size='mini' onClick={() => this.removeGroup(calcArr)}>Remove Group</Button>}
        </>
    }
}

CalcField.propTypes = {
    /**
     * not-null field calculation
     */
    calculation: PropTypes.string.isRequired,
    /**
     * All schema descriptors in flat format.
     */
    flatDescriptors: PropTypes.array.isRequired,
    /**
     * Id of the field for which the calculation is created.
     */
    forFieldId: PropTypes.string.isRequired,
    /**
     * Func to be called with the new value of the calculation if changed.
     */
    onChange: PropTypes.func.isRequired
}

export { CalcField }