import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Segment, Header, Dropdown, FormField } from 'semantic-ui-react'
import { CalcField } from "./calculation"
import PropTypes from 'prop-types'
import { DataCalcType, DescriptorCategoryProps } from '../../../../../../../models/descriptorModel';
import ScaleUtil from '../../../../../../../utils/scaleUtil';
import KeyValue from '../../../../../../../structures/keyValePair';
import { SelectField } from '../../../../../../../components/form/fields';
import CategoriesRepo from '../../../../../../../utils/repository/categoriesRepo';
import CloneUtils from '../../../../../../../utils/cloneUtils'
import { CssProps } from '../../../../../../../constnats/css'
import { DescriptorColors, NO_OP_CALC } from '../../../../../../../constnats/schemaConstants';
import { CheckboxFieldSpec, createForm, CustomFieldSpec, FormSection, SelectFiedSpec, TextFiedSpec } from '../../../../../../../utils/htmlFieldsFactory';
import Alert from '../../../../../../../utils/alert';
import { DescriptorProps } from '../../../../../../../constnats/descriptor';
import DescriptorType from '../../../../../../../utils/descriptor/descriptorType';

const SCALE_MAP = ScaleUtil.scalePairs.filter(p => p.key !== 0)

const IMPORTANCE_MAP = [
    KeyValue.createSame(0), KeyValue.createSame(5), KeyValue.createSame(10)
]

const DESCRIPTOR_TYPES_MAP = DescriptorType.getAllTypes()
    .map(t => new KeyValue(t, t.charAt(0).toUpperCase() + t.slice(1)))

const DATA_CALC_TYPES_MAP = DataCalcType.getAllTypes().map(KeyValue.createSame)

const CATEGORY_SIGNS_MAP = [
    new KeyValue(false, 'Plus'), new KeyValue(true, 'Minus')
]

function numFieldsDisabled({ descriptor }) {
    return !DescriptorType.isNumber(descriptor)
}

function primaryCalcDisabled({ descriptor }) {
    return !DescriptorType.isNumber(descriptor) && !DescriptorType.isPercent(descriptor)
}

function validatePrimaryCalc({ descriptor }) {
    return !primaryCalcDisabled({ descriptor }) || descriptor[DescriptorProps.CALCULATION] === NO_OP_CALC
}

function secondaryCalcDisabled({ descriptor }) {
    return primaryCalcDisabled({ descriptor }) || descriptor[DescriptorProps.CALCULATION] === NO_OP_CALC
}

function validateSecondaryCalc({ descriptor }) {
    return !secondaryCalcDisabled({ descriptor }) || descriptor[DescriptorProps.SECONDARY_CALCULATION] === NO_OP_CALC
}

function validateDataCalcType({ descriptor }) {
    const calcType = descriptor[DescriptorProps.DATA_CALC_TYPE]
    const hasVerticalCalc = descriptor[DescriptorProps.CALCULATION] !== NO_OP_CALC
    let valid = true
    if (DescriptorType.isHeadline(descriptor)) {
        valid = calcType === DataCalcType.NA
    } else if (DescriptorType.isPercent(descriptor)) {
        valid = [DataCalcType.NA, DataCalcType.NON_CUMULATIVE].includes(calcType)
            || (hasVerticalCalc && calcType === DataCalcType.VERTICAL)
    } else if (DescriptorType.isNumber(descriptor)) {
        valid = [DataCalcType.NA, DataCalcType.NON_CUMULATIVE, DataCalcType.CUMULATIVE].includes(calcType)
            || (hasVerticalCalc && calcType === DataCalcType.VERTICAL)
    } else if (DescriptorType.isAny(descriptor)) {
        valid = [DataCalcType.NA, DataCalcType.NON_CUMULATIVE].includes(calcType)
    } else {
        valid = false
    }

    return valid
}

function validateCategory({ descriptor }) {
    return !DescriptorType.isHeadline(descriptor) || descriptor[DescriptorProps.CATEGORY] === null
}

function validateIScale({ descriptor }) {
    return DescriptorType.isNumber(descriptor) || descriptor[DescriptorProps.INPUT_SCALE] === 1
}

function validateVScale({ descriptor }) {
    return DescriptorType.isNumber(descriptor) || descriptor[DescriptorProps.VIEW_SCALE] === 1
}

function validationScalable({ descriptor }) {
    return DescriptorType.isNumber(descriptor) || descriptor[DescriptorProps.SCALABLE] === false
}

function getCalcFieldCfgSpec(prop, label, checkDisabled, validation) {
    return new CustomFieldSpec(prop, {
        dynamicPropsProvider: context => {
            return {
                flatDescriptors: context.flatDescriptors,
                forFieldId: context.descriptor.id
            }
        },
        checkDisabled,
        valueProp: "calculation",
        validate: validation,
        customStaticProps: {
            header: label
        }
    }, CalcField)
}

const FORM_SPEC = {
    fields: [
        new TextFiedSpec(DescriptorProps.LABEL),
        new TextFiedSpec(DescriptorProps.STANDARDIZED_LABEL),
        new SelectFiedSpec(DescriptorProps.TYPE, {}, DESCRIPTOR_TYPES_MAP),
        new SelectFiedSpec(DescriptorProps.IMPORTANCE, {}, IMPORTANCE_MAP),
        new CustomFieldSpec(DescriptorProps.CATEGORY,
            {
                valueProp: "category",
                checkDisabled: ({ descriptor }) => DescriptorType.isHeadline(descriptor),
                dynamicPropsProvider: (context) => ({
                    [DescriptorProps.CATEGORY_CONFIGURED]: context.descriptor[DescriptorProps.CATEGORY_CONFIGURED],
                    additionalPropChange: (data) => context.baseHandleFieldChange(DescriptorProps.CATEGORY_CONFIGURED, data)
                }),
                validate: validateCategory
            }, CategoriesSectionField),
        new SelectFiedSpec(DescriptorProps.DATA_CALC_TYPE, {
            label: "Calculation Method for Calculated Reports",
            checkDisabled: ({ descriptor }) => DescriptorType.isHeadline(descriptor),
            validate: validateDataCalcType
        }, DATA_CALC_TYPES_MAP),
        new CheckboxFieldSpec(DescriptorProps.NOTE),
        new FormSection("Scale", [
            new SelectFiedSpec(DescriptorProps.INPUT_SCALE,
                {
                    checkDisabled: numFieldsDisabled,
                    validate: validateIScale,
                    customStaticProps: { number: true }
                }, SCALE_MAP),
            new SelectFiedSpec(DescriptorProps.VIEW_SCALE,
                {
                    checkDisabled: numFieldsDisabled,
                    validate: validateVScale,
                    customStaticProps: { number: true }
                }, SCALE_MAP),
            new CheckboxFieldSpec(DescriptorProps.SCALABLE,
                {
                    checkDisabled: numFieldsDisabled,
                    validate: validationScalable
                }),
        ]),
        new SelectFiedSpec(DescriptorProps.STYLE, {
            label: "Background Color",
            onChangeAdapter: newVal => newVal === "inherit" ? {} : { [CssProps.BACKGROUND_COLOR_PROP]: newVal },
            valueAdapter: val => val[CssProps.BACKGROUND_COLOR_PROP]
        }, DescriptorColors.ALL_AS_KV),
        new FormSection("Vertical Calculations", [
            getCalcFieldCfgSpec(DescriptorProps.CALCULATION,
                "Primary Calculation", primaryCalcDisabled, validatePrimaryCalc),
            getCalcFieldCfgSpec(DescriptorProps.SECONDARY_CALCULATION,
                "Secondary Calculation", secondaryCalcDisabled, validateSecondaryCalc)
        ])
    ]
}

function FieldModal({ descriptor, onSave, flatDescriptors, open, onCancel }) {

    const [descriptorInternal, setDescriptor] = useState(CloneUtils.deepClone(descriptor))

    function handleFieldChange(fileldName, value) {
        setDescriptor((prev) => {
            const copy = CloneUtils.deepClone(prev)
            copy[fileldName] = value
            return copy
        })
    }

    const formContext = { descriptor: descriptorInternal, flatDescriptors, baseHandleFieldChange: handleFieldChange }

    const { elements, validate } = createForm(FORM_SPEC, descriptorInternal,
        handleFieldChange, formContext)

    function save() {
        if (validate(formContext)) {
            onSave(descriptorInternal)
        } else {
            Alert.warn("The form contains invalid data!")
        }
    }

    return <>
        <Modal open={open}>
            <Modal.Content>
                <Form className="descriptorForm">
                    {elements}
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button size='mini' positive onClick={save}>
                    Apply Changes
                </Button>
                <Button size='mini' negative onClick={onCancel}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    </>
}


FieldModal.propTypes = {

    /**
     * All schema descriptors in flat format.
     */
    flatDescriptors: PropTypes.array.isRequired,

    /**
     * Func to be called if an edit cancelation is requested.
     */
    onCancel: PropTypes.func.isRequired,

    /**
     * Func to be called if the edited data needs to be saved.
     */
    onSave: PropTypes.func.isRequired,

    /**
     * Specified if the modal is open
     */
    open: PropTypes.bool.isRequired,

    /**
     * Descriptor of the field that needs to be edited. 
     */
    descriptor: PropTypes.object.isRequired
}

function getCategoryInternal(category, categoryConfigured) {
    let categoryInternal = null
    if (category === null) {
        if (categoryConfigured) {
            categoryInternal = { [DescriptorCategoryProps.CATEGORY_ID]: 0, [DescriptorCategoryProps.INVERSESIGN]: false }
        } else {
            categoryInternal = { [DescriptorCategoryProps.CATEGORY_ID]: -1, [DescriptorCategoryProps.INVERSESIGN]: false }
        }
    } else {
        categoryInternal = category
    }

    return categoryInternal
}

function CategoriesSectionField({ category, onChange, error, disabled, additionalPropChange, categoryConfigured }) {
    const [categories, setCategories] = useState([])
    const categoryInternal = getCategoryInternal(category, categoryConfigured)
    const categoryId = categoryInternal[DescriptorCategoryProps.CATEGORY_ID]

    useEffect(() => CategoriesRepo.getAll(setCategories), [])
    const options = [{ key: -1, value: -1, text: "Not Configured" }, { key: 0, value: 0, text: "No Category", }, ...categories.map(x => ({
        key: x.id, value: x.id, text: x.name
    }))]

    function propChangeHandler(categoryField) {
        return newVal => {
            const category = CloneUtils.deepClone(categoryInternal)
            category[categoryField] = newVal

            const categoryId = category[DescriptorCategoryProps.CATEGORY_ID]
            if (categoryId > 0) {
                Object.assign(category, categories.find(c => categoryId == c.id))
                onChange(category)
            } else {
                onChange(null)
            }

            if (categoryId >= 0) {
                additionalPropChange(true)
            } else {
                additionalPropChange(false)
            }
        }
    }



    return (
        <Segment>
            <Header as="h5" textAlign='center'>Category</Header>
            <FormField>
                <label>Category Name</label>
                <Dropdown
                    error={error}
                    disabled={disabled}
                    value={categoryId}
                    options={options}
                    fluid
                    search
                    selection
                    onChange={(__, data) => propChangeHandler(DescriptorCategoryProps.CATEGORY_ID)(data.value)}

                />
            </FormField>

            {categoryInternal[DescriptorCategoryProps.CATEGORY_ID] > 0 && (
                <SelectField
                    {...{ error, disabled }}
                    value={categoryInternal[DescriptorCategoryProps.INVERSESIGN]}
                    valuesMap={CATEGORY_SIGNS_MAP}
                    label="Category Relation"
                    onChange={propChangeHandler(DescriptorCategoryProps.INVERSESIGN)}
                />)}
        </Segment>
    )
}

export default FieldModal