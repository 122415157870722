class ObjCheck {

    static isPrimitive(obj) {
        return obj !== Object(obj)
    }

    static isNullOrUndefined(obj) {
        return obj === null || obj === undefined
    }

    static isNullUndefinedOrEmpty(obj) {
        return ObjCheck.isNullOrUndefined(obj) || ObjCheck.isEmptyString(obj)
    }

    static isNullUndefinedEmptyOrDash(obj) {
        return ObjCheck.isNullUndefinedOrEmpty(obj) || obj === "-"
    }

    static isEmptyString(obj) {
        return typeof obj === 'string' ? obj.trim() === "" : false
    }

    static isObjType(obj) {
        return obj != null && typeof obj === 'object'
    }

    static objHasKeys(obj) {
        return Object.keys(obj).length > 0
    }

}

export default ObjCheck