import { createElement } from "react";
import { CheckboxField, SelectField, TextareaField, TextField } from "../components/form/fields";
import { Header, Segment } from "semantic-ui-react";

class FiedSpec {

    /**
     * @param {string} prop The property that is represented by the field.
     * Required 
     * @param {Object} optCfg Configuration for the field. Optional
     * @param {string} optCfg.label The lable of the field. If not specified 
     * the prop is used for a label. Optional
     * @param {function} optCfg.checkDisabled A function that accepts 
     * context and returns true if the field is disabled, otherwise false.
     * If not presented the field is always enabled. Optional
     * @param {function} optCfg.dynamicPropsProvider A function that accepts 
     * context and props that need to be added to the field. Optional
     * @param {Object} optCfg.customStaticProps A key value map that contains
     * custom properties that must be added to the field.
     * @param {string} optCfg.valueProp The name of the prop that accepts the
     * value of the field. 'value' is used if not specied. Optional
     * @param {function} optCfg.valueAdapter Optional
     * @param {function} optCfg.onChangeAdapter Optional
     * @param {function} optCfg.validate A function that accepts 
     * context and returns true if the field is valid, otherwise false. Optional
     */
    constructor(prop, optCfg = {}) {
        this.prop = prop
        this.optCfg = optCfg

        const label = optCfg.label
        this.label = label === undefined ? propToLabel(prop) : label
        this.checkDisabled = optCfg.checkDisabled || (() => false)
        this.dynamicPropsProvider = optCfg.dynamicPropsProvider || (() => { })
        this.valueAdapter = optCfg.valueAdapter || (v => v)
        this.onChangeAdapter = optCfg.onChangeAdapter || (v => v)
        this.valueProp = optCfg.valueProp || "value"
        this.customStaticProps = optCfg.customStaticProps || {}
        this.validate = optCfg.validate || (v => true)
    }
}


class TextFiedSpec extends FiedSpec {

    /**
     * See {@link FiedSpec}
     */
    constructor(prop, optCfg) {
        super(prop, optCfg)
        this.type = TextField
    }
}

class TextareaFiedSpec extends FiedSpec {

    /**
     * See {@link FiedSpec}
     */
    constructor(prop, optCfg) {
        super(prop, optCfg)
        this.type = TextareaField
    }
}

class CheckboxFieldSpec extends FiedSpec {

    /**
     * See {@link FiedSpec}
     */
    constructor(prop, optCfg) {
        super(prop, optCfg)
        this.type = CheckboxField
    }
}

class SelectFiedSpec extends FiedSpec {

    /**
     * See {@link FiedSpec}
     * 
     * @param {KeyValue[]} options Required
     */
    constructor(prop, optCfg, options) {
        super(prop, optCfg)
        this.type = SelectField
        this.customStaticProps = { ...this.customStaticProps, valuesMap: options } 
    }
}

class CustomFieldSpec extends FiedSpec {

    /**
     * See {@link FiedSpec}
     * 
     * @param {?} type Type of a react component that represents the field.
     * Required
     */
    constructor(prop, optCfg, type) {
        super(prop, optCfg)
        this.type = type
    }
}

class FormSection {

    /**
     * @param {string} name Name of the section. Required 
     * @param {FiedSpec[]} fields Fields that are inside the section. Required
     */
    constructor(name, fields) {
        this.name = name
        this.fields = fields
    }
}


/**
 * @param {FiedSpec} spec 
 * @param {any} value The value of the field. Optional
 * @param {function} onChange Handler of the field value change. Required
 * @param {any} context Context of the field. Optional
 *
 * @returns not-null
 */
function createField(spec, value, onChange, context) {
    const onChangeInternal = newVal => onChange(spec.onChangeAdapter(newVal))
    const dinamycProps = spec.dynamicPropsProvider(context)
    const adaptedValue = spec.valueAdapter(value)
    const valid = spec.validate(context, adaptedValue)
    const disabled = valid ? spec.checkDisabled(context) : false

    return createElement(spec.type,
        {
            key: spec.prop,
            label: spec.label,
            [spec.valueProp]: adaptedValue,
            onChange: onChangeInternal,
            error: !valid,
            disabled,
            ...dinamycProps,
            ...spec.customStaticProps
        })
}


/**
 * @param {Object} formSpec Required
 * @param {FiedSpec|FormSection[]} formSpec.fields Required
 * @param {Object} formData An object that represents that data that is managed
 * by the form. Required
 * @param {function} onFieldChange Handler for field value changes.
 * It accepts the field property and the new value. Required
 * @param {any} context Context of the form. Optional
 * 
 * @returns not-null
 */
function createForm(formSpec, formData, onFieldChange, context) {

    const validate = context => !fieldValidators(formSpec.fields)
        .some(validator => !validator(context))
    const elements = []

    const form = { elements, validate }

    for (const spec of formSpec.fields) {
        if (spec instanceof FormSection) {
            elements.push(
                <Segment key={spec.name}>
                    <Header as="h5" textAlign='center'>{spec.name}</Header>
                    {spec.fields.map(s => createField(s, formData[s.prop],
                        newVal => onFieldChange(s.prop, newVal), context))}
                </Segment>
            )

        } else {
            elements.push(createField(spec, formData[spec.prop],
                newVal => onFieldChange(spec.prop, newVal), context))
        }
    }

    return form
}

/**
 * 
 * @param {FiedSpec|FormSection[]} formFieldsSpec Required
 * @returns not-null collection of validator functions for all fields of the spec
 */
function fieldValidators(formFieldsSpec) {
    const validators = []
    for (const spec of formFieldsSpec) {
        if (spec instanceof FormSection) {
            validators.push(...fieldValidators(spec.fields))
        } else {
            validators.push(spec.validate)
        }
    }

    return validators
}

/**
 * @param {string} prop Required 
 * @returns not-null
 */
function propToLabel(prop) {
    return prop.charAt(0).toUpperCase() + prop.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2')
}

export {
    createField, createForm, TextFiedSpec, SelectFiedSpec, CustomFieldSpec,
    CheckboxFieldSpec, TextareaFiedSpec, FormSection
}