import { DescriptorProps } from "../../constnats/descriptor";
import DescriptorType from "../descriptor/descriptorType";
import CloneUtils from "../cloneUtils";

/**
 * @description Goes over the children of the descriptor and checks to see
 * if the descriptor has a categorized child..
 * @param {Object} descriptor Required
 * @returns {boolean} 
 */
const hasCategorizedChildren = (descriptor) => {
    const queue = [...descriptor[DescriptorProps.SUBFIELDS]];

    while (queue.length) {
        const childDescriptor = queue.shift();
        if (childDescriptor[DescriptorProps.CATEGORY]) {
            return true;
        }

        queue.push(...childDescriptor[DescriptorProps.SUBFIELDS]);
    }

    return false;
}

/**
 * 
 * @param {Object} mergeCandidate Required Descriptor that will potentially be merged with the merge target  
 * @param {string[]} mergedDescriptors Required List of descriptors ids that have already been merged
 * @param {Object} mergeTarget Required Descriptor that merges with merge candidates(sibling descriptors 
 * with same category) 
 * @returns {boolean}
 */
const getCanBeMerged = (mergeCandidate, mergedDescriptors, mergeTarget) => {
    let result = true
    if (!mergeCandidate[DescriptorProps.CATEGORY]) {
        result = false
    } else if (mergeCandidate[DescriptorProps.CATEGORY].categoryId !== mergeTarget[DescriptorProps.CATEGORY].categoryId) {
        result = false
    } else if (!DescriptorType.isNumber(mergeCandidate)) {
        result = false
    } else if (mergeCandidate[DescriptorProps.ID] === mergeTarget[DescriptorProps.ID]) {
        result = false
    } else if (mergedDescriptors.includes(mergeCandidate[DescriptorProps.ID])) {
        result = false
    } else if (hasCategorizedChildren(mergeCandidate)) {
        result = false
    }
    return result
}

/**
 * @description Generates categorized descriptors bases on the categories to which the regular descriptors are associated
 * to. Every categorized descriptor has an additional property (categoryRelationSigns):
 * categoryRelationSigns which is an object containing the id of the descriptor as a key
 * and a value which is a boolean specifying the sign of the mathematical operation when merging 
 * false represents +(plus) and true represents -(minus)
 * NOTE: All non dynamic descriptors are automatically categorized
 * @param {Object[]} descriptors Required
 * @param {Object | null} [parentDescriptor] Optional 
 * @returns {Object[]} not null
 */

const getCategorizedDescriptors = (descriptors, parentDescriptor = null) => {
    const categorizedDescriptors = []
    const visitedDescriptors = []
    for (const descriptor of descriptors) {
        let parent = parentDescriptor
        const currentDescriptorId = descriptor[DescriptorProps.ID];
        const hasChildrenWithCategory = hasCategorizedChildren(descriptor);
        if (descriptor[DescriptorProps.CATEGORY] && !visitedDescriptors.includes(currentDescriptorId)) {
            const categoryRelationSigns = { [currentDescriptorId]: descriptor[DescriptorProps.CATEGORY].inverseSign };
            /**
             * Check if descriptor is eligible for merge with siblings.
             */
            if (!hasChildrenWithCategory && DescriptorType.isNumber(descriptor)) {
                descriptors.filter(siblingDescriptor =>
                    getCanBeMerged(siblingDescriptor, visitedDescriptors, descriptor)).forEach(d => {
                    categoryRelationSigns[d[DescriptorProps.ID]] = d[DescriptorProps.CATEGORY].inverseSign;
                });
            }

            const key = `TMP:${Object.keys(categoryRelationSigns).join('')}`
            const descriptorCopy = CloneUtils.deepClone(descriptor);
            const categorizedDescriptor = { ...descriptorCopy, id: key, [DescriptorProps.SUBFIELDS]: [], categoryRelationSigns }

            if (parent) {
                parent[DescriptorProps.SUBFIELDS].push(categorizedDescriptor)
            } else {
                categorizedDescriptors.push(categorizedDescriptor)
            }
            parent = categorizedDescriptor
            visitedDescriptors.push(...Object.keys(categoryRelationSigns))
        } else if (!descriptor[DescriptorProps.DYNAMIC]) {
            categorizedDescriptors.push(CloneUtils.deepClone(descriptor));
        }

        if (hasChildrenWithCategory) {
            categorizedDescriptors.push(...getCategorizedDescriptors(descriptor[DescriptorProps.SUBFIELDS], parent))
        }
    }

    return categorizedDescriptors
}

export { getCategorizedDescriptors, hasCategorizedChildren };

