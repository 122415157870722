import { Table } from "semantic-ui-react"
import TableUpdatesRepo from "../../../../utils/repository/tableUpdatesRepo";
import { useEffect, useState } from "react";
import Loader from "../../../../components/loader";
import UserRepo from "../../../../utils/repository/userRepo";
import CollectionUtils from "../../../../utils/collections";
import { ROLES } from "../../../../constnats/user";
import Alert from "../../../../utils/alert";
import AuthService from "../../../../service/auth";
import DatePicker from "../../../../components/form/datePicker";

function PerformanceManagement() {
    const [usersMap, setUsersMap] = useState(null)
    const [from, setFrom] = useState(() => new Date(new Date().setDate(new Date().getDate() - 7)))
    const [to, setTo] = useState(() => new Date())

    const fromTM = new Date(from.getTime()).setUTCHours(0, 0, 0, 0)
    const toTM = new Date(to.getTime()).setUTCHours(23, 59, 0, 0)

    useEffect(() => {
        UserRepo.list({ userRoles: [ROLES.EMPLOYEE], includeDisabled: false },
            users => setUsersMap(CollectionUtils.arrToMap(users, u => u.id)))
    }, [false]);

    return usersMap === null ? <Loader /> : <>
        <DatePicker
            label="From:" date={new Date(from)}
            onChange={d => setFrom(d)} />
        <DatePicker className="smallField"
            label="To:" date={new Date(to)}
            onChange={d => setTo(d)} />
        <UpdatesPerformance {...{ usersMap, from: fromTM, to: toTM }} />
    </>
}


const excludedUserIds = [28, 11, 463514, 799842, 809507]

function UpdatesPerformance({ usersMap, from, to }) {
    const [updateKPIs, setUpdateKPIs] = useState(undefined)
    const [reviewKPIs, setReviewKPIs] = useState(undefined)

    const minTasks = ((to - from) / (1000 * 60 * 60 * 24)) * 1.5

    useEffect(() => {
        TableUpdatesRepo.performanceKpis({ from, to }, setUpdateKPIs)
        TableUpdatesRepo.reviewPerformanceKpis({ from, to }, setReviewKPIs)
    }, [from, to]);

    const userToTasks = {}
    for (const userId of Object.keys(usersMap)) {
        userToTasks[userId] = {
            completed: { updates: {}, reviews: {} },
            progressed: { updates: 0, reviews: {} }
        }
    }

    accumulateUpdateTasks(userToTasks, updateKPIs)
    accumulateReviewTasks(userToTasks, reviewKPIs)

    return <>
        <Table striped selectable celled>
            <Table.Header>
                <Table.Row >
                    <Table.HeaderCell rowSpan='3'>User</Table.HeaderCell>
                    <Table.HeaderCell colSpan='3' >Updates</Table.HeaderCell>
                    <Table.HeaderCell colSpan='4' rowSpan='2'>Reviews</Table.HeaderCell>
                    <Table.HeaderCell rowSpan='3'>Total Tasks exc. Visual Checks</Table.HeaderCell>
                    <Table.HeaderCell rowSpan='3'>Total Tasks</Table.HeaderCell>
                </Table.Row>
                <Table.Row >
                    <Table.HeaderCell rowSpan='2'>Regular</Table.HeaderCell>
                    <Table.HeaderCell rowSpan='2'>Mistakes Detection</Table.HeaderCell>
                    <Table.HeaderCell rowSpan='2'>Total Updates</Table.HeaderCell>
                </Table.Row>
                <Table.Row >
                    {/* <Table.HeaderCell>Initial</Table.HeaderCell> */}
                    {/* <Table.HeaderCell>AVG time</Table.HeaderCell> */}
                    {/* <Table.HeaderCell>Incremental</Table.HeaderCell> */}
                    {/* <Table.HeaderCell>AVG time</Table.HeaderCell> */}
                    {/* <Table.HeaderCell>Incremental</Table.HeaderCell> */}
                    {/* <Table.HeaderCell>AVG time</Table.HeaderCell> */}
                    <Table.HeaderCell>FULL_CHECKs</Table.HeaderCell>
                    {/* <Table.HeaderCell>AVG Time</Table.HeaderCell> */}
                    <Table.HeaderCell>VISUAL_CHECKs</Table.HeaderCell>
                    {/* <Table.HeaderCell>AVG Time</Table.HeaderCell> */}
                    <Table.HeaderCell>CORE_DATA_CHECKs</Table.HeaderCell>
                    {/* <Table.HeaderCell>AVG Time</Table.HeaderCell> */}
                    <Table.HeaderCell>Total Reviews</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {Object.keys(userToTasks)
                    .filter(userId => AuthService.hasGlobalRole(ROLES.ADMIN) ? !excludedUserIds.includes(Number(userId)) : userId === AuthService.getUserId())
                    .map(userId => toKPIsRowData(userToTasks[userId], userId))
                    .sort((a, b) => b.totalTasks - a.totalTasks)
                    .map(({ regularUpdates, mistakesDetection, initialRegularCount,
                        incremenrtalRegularCount, mistakesDetectionCount,
                        totalUpdates, fullChecks, fullChecksCount,
                        visualChecks, visualChecksCount, coreChecks, coreChecksCount,
                        totalReviews, totalTasks, userId, progressedUserUpdates,
                        progressedFullChecksCount, progressedVisualChecksCount,
                        progressedCoreChecksCount, totalProgressedReviews }) =>
                        <Table.Row key={userId}>
                            <Table.Cell>
                                {usersMap[userId]?.fullName}
                            </Table.Cell>
                            {/* //Updates */}
                            {/* <Table.Cell >
                                {initialRegularCount}
                            </Table.Cell> */}
                            {/* <Table.Cell>
                                {formatSeconds(regularUpdates?.initial?.avgHoursTillCurrentState)}
                            </Table.Cell> */}
                            <Table.Cell >
                                {incremenrtalRegularCount + initialRegularCount}
                            </Table.Cell>
                            {/* <Table.Cell>
                                {formatSeconds(regularUpdates?.incremental?.avgHoursTillCurrentState)}
                            </Table.Cell> */}
                            <Table.Cell >
                                {mistakesDetectionCount}
                            </Table.Cell>
                            {/* <Table.Cell>
                                {formatSeconds(mistakesDetection?.incremental?.avgHoursTillCurrentState)}
                            </Table.Cell> */}
                            <Table.Cell negative={totalUpdates < minTasks / 2}>
                                <b>{totalUpdates}</b> ({progressedUserUpdates})
                            </Table.Cell>
                            {/* //Reviews */}
                            <Table.Cell >
                                <b>{fullChecksCount}</b> ({progressedFullChecksCount})
                            </Table.Cell>
                            {/* <Table.Cell >
                                {formatSeconds(fullChecks?.avgHoursTillCurrentState)}
                            </Table.Cell> */}
                            <Table.Cell>
                                <b>{visualChecksCount}</b> ({progressedVisualChecksCount})
                            </Table.Cell>
                            {/* <Table.Cell >
                                {formatSeconds(visualChecks?.avgHoursTillCurrentState)}
                            </Table.Cell> */}
                            <Table.Cell >
                                <b>{coreChecksCount}</b> ({progressedCoreChecksCount})
                            </Table.Cell>
                            {/* <Table.Cell >
                                {formatSeconds(coreChecks?.avgHoursTillCurrentState)}
                            </Table.Cell> */}
                            <Table.Cell negative={totalReviews < minTasks / 2}>
                                <b>{totalReviews}</b> ({totalProgressedReviews})
                            </Table.Cell>
                            <Table.Cell negative={(totalTasks - visualChecksCount) < minTasks}>
                                {totalTasks - visualChecksCount}
                            </Table.Cell>
                            <Table.Cell negative={totalTasks < minTasks}>
                                {totalTasks}
                            </Table.Cell>
                        </Table.Row>
                    )}
            </Table.Body>
        </Table>
        <br />
        NOTES:
        <ul>
            <li>The data in brackets in column <b>Total Updates</b> represents the number of Updates that have been sent for PEER Review but are still not APPLIED.</li>
            <li>The data in brackets in all <b>Reviews</b> related columns represents the number of Reviews that have been APPROVED but are still not ARCHIVED.</li>
            <li>The <b>AVG time</b> all <b>Updates</b> related columns represents the time form the Update creation till the moment when it is APPLIED.</li>
            <li>The <b>AVG time</b> all <b>Reviews</b> related columns represents the time form the Review creation till the moment when it is APPROVED for last time.</li>
            <li>The <b>Total Task</b> column does not include data that is preented in brackets.</li>
            <li>The table rows are ordered by <b>Total Task</b>.</li>
            <li>The data in brakets and the <b>AVG time</b> columns may be inaccurate for reports that include periods before 12/12/23.</li>
            <li>The distribution of Incremental and Initial updates will be inaccurate for all periods that include application of updates that were created before 01/12/23.</li>
        </ul>
    </>
}




function toKPIsRowData(userTasks, userId) {
    //Updates
    const userUpdates = userTasks.completed.updates
    const regularUpdates = userUpdates['REGULAR_UPDATE']
    const mistakesDetection = userUpdates['MISTAKES_DETECTION']
    const initialRegularCount = regularUpdates?.initial?.count || 0
    const incremenrtalRegularCount = regularUpdates?.incremental?.count || 0
    const mistakesDetectionCount = mistakesDetection?.incremental?.count || 0
    if (mistakesDetection?.initial) {
        Alert.warn("Unexpected data: Initial updates for mistakes detection")
    }
    const totalUpdates = initialRegularCount + incremenrtalRegularCount + mistakesDetectionCount
    const progressedUserUpdates = userTasks.progressed.updates
    //Reviews 
    const userReviews = userTasks.completed.reviews
    const fullChecks = userReviews["FULL_CHECK"]
    const fullChecksCount = fullChecks?.count || 0
    const visualChecks = userReviews["VISUAL_CHECK"]
    const visualChecksCount = visualChecks?.count || 0
    const coreChecks = userReviews["CORE_DATA_CHECK"]
    const coreChecksCount = coreChecks?.count || 0
    //Progressed
    const progressedUserReviews = userTasks.progressed.reviews
    const progressedFullChecksCount = progressedUserReviews["FULL_CHECK"]?.count || 0
    const progressedVisualChecksCount = progressedUserReviews["VISUAL_CHECK"]?.count || 0
    const progressedCoreChecksCount = progressedUserReviews["CORE_DATA_CHECK"]?.count || 0


    const totalReviews = fullChecksCount + visualChecksCount + coreChecksCount
    const totalProgressedReviews = progressedFullChecksCount + progressedVisualChecksCount + progressedCoreChecksCount
    const totalTasks = totalReviews + totalUpdates

    return {
        regularUpdates, mistakesDetection, initialRegularCount,
        incremenrtalRegularCount, mistakesDetectionCount,
        totalUpdates, fullChecks, fullChecksCount,
        visualChecks, visualChecksCount,
        coreChecks, coreChecksCount, totalReviews, userId, totalTasks,
        progressedUserUpdates,
        progressedFullChecksCount,
        progressedVisualChecksCount,
        progressedCoreChecksCount,
        totalProgressedReviews
    }
}

function accumulateUpdateTasks(accumulator, updateKPIs) {
    if (updateKPIs) {
        for (const record of updateKPIs.applied) {
            if (accumulator[record.userId] === undefined) {
                continue
            }
            const currentKnowedge = accumulator[record.userId].completed.updates
            const purpose = record.purpose
            const updateType = record.initial === true ? "initial" : "incremental"

            if (currentKnowedge[purpose] === undefined) {
                currentKnowedge[purpose] = {}
            }

            if (currentKnowedge[purpose][updateType] !== undefined) {
                Alert.warn("Unexpected data: Duplication of updates data for " + updateType)
            }

            currentKnowedge[purpose][updateType] = {
                count: record.count,
                avgHoursTillCurrentState: record.avgHoursTillCurrentState
            }
            accumulator[record.userId].completed.updates = currentKnowedge
        }

        for (const record of updateKPIs.sentForReview) {
            if (accumulator[record.userId] === undefined) {
                continue
            }
            accumulator[record.userId].progressed.updates = record.count
        }
    }
}

function accumulateReviewTasks(accumulator, reviewKPIs) {
    if (reviewKPIs) {
        for (const record of reviewKPIs.archived) {
            if (accumulator[record.userId] === undefined) {
                continue
            }
            const currntKnowedge = accumulator[record.userId].completed.reviews
            if (currntKnowedge[record.type] !== undefined) {
                Alert.warn("Unexpected data: Duplication of review data for " + record.type)
            }

            currntKnowedge[record.type] = { count: record.count, avgHoursTillCurrentState: record.avgHoursTillCurrentState }
            accumulator[record.userId].completed.reviews = currntKnowedge
        }

        for (const record of reviewKPIs.approved) {
            if (accumulator[record.userId] === undefined) {
                continue
            }
            const currntKnowedge = accumulator[record.userId].progressed.reviews
            if (currntKnowedge[record.type] !== undefined) {
                Alert.warn("Unexpected data: Duplication of review data (p) for " + record.type)
            }
            currntKnowedge[record.type] = { count: record.count, avgHoursTillCurrentState: record.avgHoursTillCurrentState }
            accumulator[record.userId].progressed.reviews = currntKnowedge
        }
    }
}



function formatSeconds(seconds) {
    if (seconds === undefined) {
        return ""
    } else {
        const minutes = seconds / 60
        const hours = minutes / 60
        return Math.floor(hours / 24) + "D " + Math.floor(hours % 60) + "H " + Math.floor(minutes % 60) + "M"
    }
}

export default PerformanceManagement