import { Header, Message, Table } from "semantic-ui-react"

function ShemaDiff(props) {
    const schemaDiffs = props.schemaDiffs

    return <>
        <Header as="h5">Model Differences</Header>
        {schemaDiffs.length === 0 ? <Message success attached='bottom'>
            No differences found. </Message> :
            <Table celled selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>No</Table.HeaderCell>
                        <Table.HeaderCell>Difference</Table.HeaderCell>
                        <Table.HeaderCell>EDIT branch</Table.HeaderCell>
                        <Table.HeaderCell>REVIEW branch</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {schemaDiffs.map((diff, idx) => {
                        return <Table.Row key={idx} error={diff.type === "ERROR"} warning={diff.type === "WARNING"}>
                            <Table.Cell>{idx}</Table.Cell>
                            <Table.Cell>{diff.summary}</Table.Cell>
                            <Table.Cell><b>{diff.edit}</b></Table.Cell>
                            <Table.Cell><b>{diff.review}</b></Table.Cell>
                        </Table.Row>
                    })}
                </Table.Body>
            </Table>}
    </>
}

export default ShemaDiff