import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Label, Table } from 'semantic-ui-react';
import { serverToLocalDate } from '../../../utils/dateTimeUtils';
import UserRepo from '../../../utils/repository/userRepo';
import { ROLES } from '../../../constnats/user';

const currentDate = new Date()

function CompaniesTable({ companies, companyUrlPrefix }) {

    const [userIdToName, setUserIdToName] = useState({})

    function getIndexTableCell(company, idx){
        const props = {size:'mini'}

        if(company.suspended) {
            props.color = 'blue'  
        }else if(company.visibility !== "PUBLIC"){
            props.color = 'yellow'          
        }

    return <Label {...props}>{idx + 1}</Label>
    }

    useEffect(() => {
        UserRepo.list({ userRoles: [ROLES.EMPLOYEE], includeDisabled: true }, users => {
            const userIdToName = {}
            for (const user of users) {
                userIdToName[user.id] = user
            }
            setUserIdToName(userIdToName)
        })
    }, [])

    return companies.length === 0 ? <>No companies found</> :
        <Table striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell rowSpan={2}>No</Table.HeaderCell>
                    <Table.HeaderCell rowSpan={2}>Name</Table.HeaderCell>
                    <Table.HeaderCell rowSpan={2}>Owner</Table.HeaderCell>
                    <Table.HeaderCell rowSpan={2}>Contributors</Table.HeaderCell>
                    <Table.HeaderCell rowSpan={2}>ISIN</Table.HeaderCell>
                    <Table.HeaderCell colSpan={3}>Next Reporting</Table.HeaderCell>

                    {/* <Table.HeaderCell rowSpan={2}>Visibility</Table.HeaderCell> */}
                </Table.Row>
                <Table.Row>

                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Period</Table.HeaderCell>
                    <Table.HeaderCell>Confirmed On</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {companies.map((c, idx) =>
                    <Table.Row key={c.id}>
                        <Table.Cell>
                               {getIndexTableCell(c, idx)}
                        </Table.Cell>
                        <Table.Cell>
                            <Link to={companyUrlPrefix + c.id}>
                                {c.name}
                            </Link>
                        </Table.Cell>
                        <Table.Cell>
                            <User id={c.ownerId} userIdToUser={userIdToName} />
                        </Table.Cell>
                        <Table.Cell>
                            {c.contributors
                                .filter(id => id !== c.ownerId)
                                .map(id => <div key={id}>
                                    <User id={id} userIdToUser={userIdToName} />
                                </div>)}
                        </Table.Cell>
                        <Table.Cell>
                            {c.isin}
                        </Table.Cell>
                        <Table.Cell>
                            {c.nextReportingDate && (currentDate > new Date(c.nextReportingDate) ? (<Label color="red"
                                size="mini">{serverToLocalDate(c.nextReportingDate)}</Label>) : serverToLocalDate(c.nextReportingDate))}
                        </Table.Cell>
                        <Table.Cell>
                            {c.nextReportingPeriod}
                        </Table.Cell>
                        <Table.Cell>
                            {c.nextReportingDateConfirmedOn && serverToLocalDate(c.nextReportingDateConfirmedOn)}
                        </Table.Cell>

                        {/* <Table.Cell>
                            {c.visibility}
                        </Table.Cell> */}
                    </Table.Row>
                )}
            </Table.Body>
        </Table >
}

function User({ id, userIdToUser }) {
    const user = userIdToUser[id]

    const fullName = user?.fullName
    return user?.disabled ?
        <Label color="red" size="mini">
            {fullName}
        </Label>
        : fullName || ""
}
export default CompaniesTable