import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'semantic-ui-react';

type LoginPromptProps = {
    onClose: () => void;
};

function LoginPrompt({ onClose }: LoginPromptProps) {
    const navigate = useNavigate();

    const hangleLoginRequest = () => {
        navigate('/user/login');
        onClose();
    };

    return (
        <Modal open={true} size="small">
            <Modal.Header>Hello and Welcome!</Modal.Header>
            <Modal.Content>
                <p>
                    Unlock the full potential of our services by logging in. As a registered user, you can manage your
                    own reports, save your settings, and enjoy a personalized experience designed specifically for you.
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button size="mini" primary onClick={hangleLoginRequest}>
                    Log In
                </Button>
                <Button size="mini" onClick={onClose}>
                    Continue without Log In
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default LoginPrompt;
