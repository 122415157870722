import React, { useState } from 'react';
import { Button, Modal, Form, Dropdown } from 'semantic-ui-react'
import Alert from '../../../../../../../utils/alert';


function TargetsDropdown({ targetDescriptorId, avalableDescriptors, onChange }) {
    const ops = avalableDescriptors.map((d, idx) => {
        return {
            key: idx,
            text: d.label,
            value: d.id
        }
    })

    return <Form.Field>
        <Dropdown
            onChange={(a, b) => onChange(b.value)}
            value={targetDescriptorId}
            placeholder='Target'
            options={ops} fluid selection search />
    </Form.Field>
}

function MoveBellowModal({ descriptor, avalableDescriptors, onApply, onCancel }) {
    const [targetDescriptorId, setTargetDescriptorId] = useState(null)

    return <Modal
        size="mini"
        open={true}>
        <Modal.Header>Field Relocation</Modal.Header>
        <Modal.Content>
            Move <b>{descriptor.label}</b> bellow:
            <Form>
                <TargetsDropdown targetDescriptorId={targetDescriptorId}
                    avalableDescriptors={avalableDescriptors}
                    onChange={setTargetDescriptorId} />
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button size='mini' positive
                onClick={() => {
                    if (targetDescriptorId !== null) {
                        onApply(targetDescriptorId)
                    } else {
                        Alert.warn("The targer is not selected!")
                    }
                }}>
                Apply
            </Button>
            <Button size='mini' negative onClick={onCancel}>
                Cancel
            </Button>
        </Modal.Actions>
    </Modal>
}

export default MoveBellowModal