import React, { useState } from 'react';
import { Chart } from "react-google-charts";
import Assert from '../../../../utils/asserts';
import { ReportField } from '../../../../constnats/reportConstants';
import { Dropdown } from 'semantic-ui-react';
import KeyValue from '../../../../structures/keyValePair';
import Alert from '../../../../utils/alert';
import ReportUtils from '../../../../utils/reportUtils';


function dataToNumber(data) {
    if (typeof data === "string") {
        data = Number(data.replace(",", ".").replace("%", ""))
    } else {
        Assert.typeNumber(data, "dataToNumber")
    }

    return data
}

function reportsMatcher(srcReport, exact) {
    const srcPeriod = srcReport[ReportField.PERIOD]
    if (!exact && srcPeriod.startsWith("Q")) {
        return r => r[ReportField.PERIOD].startsWith("Q")
    } else {
        return r => r[ReportField.PERIOD] === srcPeriod
    }
}

function getDataForSequentialChart(reportsForChart, fieldName, fieldId) {
    const chartData = [
        ["Year", fieldName, { type: 'string', role: 'annotation' }]
    ]


    let preVal = dataToNumber(ReportUtils.getFieldValueOrP2PCalc(reportsForChart[0], fieldId))
    for (const reportForChart of reportsForChart) {
        const value = dataToNumber(ReportUtils.getFieldValueOrP2PCalc(reportForChart, fieldId))


        let percentChange = "-"
        try {
            if (value === 0 && preVal === 0) {
                percentChange = "0%"
            } else {
                percentChange = (((value - preVal) / Math.abs(preVal)) * 100).toFixed(2) + "%"
            }

        } catch (error) {
            console.error(error)
        }

        preVal = value

        chartData.push([(reportForChart[ReportField.YEAR] + " ").slice(2) + reportForChart[ReportField.PERIOD], value, percentChange])
    }

    return chartData
}

/**
 * 
 * @param {Object} reportsForChart Required
 * @param {string} fieldId Required
 * @param {string} fieldName Required 
 * @returns 
 */
function getDataForParalelChart(reportsForChart, fieldId, fieldName) {

    const colsConfig = ["Year"]
    const chartData = [colsConfig]

    const allPeriods = []
    const reportsMap = {}
    for (const report of reportsForChart) {
        const year = report[ReportField.YEAR]
        if (reportsMap[year] === undefined) {
            reportsMap[year] = []
        }

        const period = report[ReportField.PERIOD]
        if (reportsMap[year][period] === undefined) {
            reportsMap[year][period] = []
            if (!allPeriods.includes(period)) {
                allPeriods.push(period)
            }
        }

        reportsMap[year][period].push(report)
    }

    colsConfig.push(...allPeriods.map(p => `${p} ${fieldName}`))

    const years = Object.keys(reportsMap)

    let moreThanOnePeriods = false
    for (const year of years) {
        const row = [String(year)]
        for (const period of allPeriods) {
            const periodReports = reportsMap[year][period]
            if (periodReports === undefined) {
                row.push(undefined)
            } else {
                row.push(dataToNumber(periodReports[0][ReportField.DYNAMIC_FIELDS][fieldId]))
                if (periodReports.length > 1) {
                    moreThanOnePeriods = true
                }

            }

        }
        chartData.push(row)
    }

    if (moreThanOnePeriods) {
        Alert.warn("The chart may be inaccurate because your table contains more than one report for same year and period. Plase adjust your filters")
    }

    return chartData
}

class ViewType {
    static SEQUENTIAL = "Include all Qs in Sequence"
    static PARALEL = "Include  all Qs in Parallel"
    static EXACT_PERIOD = "{} Only"
}

function CellChart({ srcReport, fieldId, fieldName, reports }) {
    const [viewType, setViewType] = useState(ViewType.EXACT_PERIOD)

    const reportsFilter = reportsMatcher(srcReport, viewType === ViewType.EXACT_PERIOD)
    const reportsForChart = reports
        .filter(r => reportsFilter(r)
            && ReportUtils.fieldHasValueOrP2PCalc(r, fieldId))

    if (reportsForChart.length === 0) {
        return "No data found"
    }

    let chartData
    if (viewType !== ViewType.PARALEL) {
        chartData = getDataForSequentialChart(reportsForChart, fieldName, fieldId)
    } else {
        chartData = getDataForParalelChart(reportsForChart, fieldId, fieldName)
    }

    const srcPeriod = srcReport[ReportField.PERIOD]
    return <div>
        {srcPeriod.startsWith("Q") && <Dropdown onChange={(c, v) =>
            setViewType(v.value)}
            value={viewType}
            options={arrToDropdownOps([
                KeyValue.createSame(ViewType.SEQUENTIAL),
                KeyValue.createSame(ViewType.PARALEL),
                new KeyValue(ViewType.EXACT_PERIOD,
                    ViewType.EXACT_PERIOD.replace("{}", srcPeriod))
            ])} />}

        <Chart
            chartType="LineChart"
            width="600px"
            height="280px"
            data={chartData}
            options={{
                legend: {
                    position: "bottom",
                    textStyle: {
                        fontSize: 12,
                        maxLines: 3,
                    }
                },
                fontSize: 11,
                chartArea: {
                    left: 50,
                    right: 10,
                    top: 10,
                    bottom: 30
                },
                vAxis: {
                    format: 'short'
                }
            }}

        />
    </div>
}

function arrToDropdownOps(arr) {
    return arr.map(c => {
        return { key: c.key, text: c.value, value: c.key }
    })
}

export default CellChart