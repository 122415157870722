import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

const COOKIES_CONTEST_KEY = "COOKIES_CONTEST_KEY"

const CookieBanner = () => {
    const [cookiesAllowed, setCookiesAllowed] = useState(undefined);

    useEffect(() => {
        setTimeout(() => {
            setCookiesAllowed(localStorage.getItem(COOKIES_CONTEST_KEY))
        }, 2000)
    }, [])


    const setCookiesAllowedProxy = val => {
        localStorage.setItem(COOKIES_CONTEST_KEY, val)
        setCookiesAllowed(val)
    }

    const handleCookieConsent = () => {
        setCookiesAllowedProxy(true)
    }

    const handleOptOut = () => {
        setCookiesAllowedProxy(false)
    }

    return cookiesAllowed === null && (
        <div className='cookiesConsentWrapper'>
            <p>
                We use cookies to enhance your browsing experience and to improve our tool. By clicking "I Accept,"
                you agree to the use of cookies on our site. For more information or to opt-out, please review our
                <Link to="/info/privacyPolicy"> Privacy Policy</Link>. Thanks for visiting!
                <br />
                <Button size='mini' onClick={handleCookieConsent}>I Accept</Button>
                <Button size='mini' color='grey' inverted onClick={handleOptOut}> Opt-Out</Button>
            </p>
        </div>
    )
}

export default CookieBanner;
