import React, { useState } from 'react';
import { Button, Divider, Form, FormField, Header, Input, TextArea } from 'semantic-ui-react'
import Alert from '../../utils/alert';
import MailService from '../../service/mails'

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;

const descriptionValidation = val => (val.length > 10) ? false : { content: "It must be longer than 10 symbols." }
const nameValidation = val => (val.length < 50 && val.length > 3) ? false : { content: "It must be shorter than 50 symbols and longer than 3 symbols." }
const clientEamilValidation = val => (val.match(emailRegex) && val.length < 51 && val.length > 3) ? false : { content: "The email is invalid." }

function getFiledChangeHandler(newValHandler, validator) {

    return changeEvent => {
        const newVal = changeEvent.target.value

        newValHandler({ value: newVal, error: validator(newVal) })
    }
}

function ContactUs() {

    const [description, setDescription] = useState({ value: "", error: false })
    const [clientName, setClientName] = useState({ value: "", error: false })
    const [clientEamil, setClientEamil] = useState({ value: "", error: false })
    const [validationEnabled, setValidationEnabled] = useState(false)
    const [requestSubmitted, setRquestSubmitted] = useState(false)

    function onSubmit() {
        setValidationEnabled(true)

        if (description.error === false && clientEamil.error === false && clientName.error === false) {
            MailService.sendUserRequest({ description: description.value, clientEmail: clientEamil.value, clientName: clientName.value },
                () => setRquestSubmitted(true),
                () => Alert.error("We are sorry but we was not able to process your request. Please try again or send us a direct email."))
        }
    }

    return requestSubmitted ?
        <>
            <Header as="h4" textAlign='center'>Your request was submitted successfully. We will contact you soon.</Header>
        </> :
        <>
            <Header as="h2" textAlign='center'>Contact us</Header>
            <Form>
                <FormField
                    control={Input}
                    label='Your name'
                    placeholder='Name'
                    onChange={getFiledChangeHandler(setClientName, nameValidation)}
                    value={clientName.value}
                    error={validationEnabled ? clientName.error : false}
                />
                <FormField
                    control={Input}
                    label='Your email'
                    placeholder='Email'
                    onChange={getFiledChangeHandler(setClientEamil, clientEamilValidation)}
                    value={clientEamil.value}
                    error={validationEnabled ? clientEamil.error : false}
                />
                <FormField
                    control={TextArea}
                    label='Describe we can help you'
                    placeholder='Description'
                    onChange={getFiledChangeHandler(setDescription, descriptionValidation)}
                    value={description.value}
                    error={validationEnabled ? description.error : false} />

                <Button size='mini' onClick={onSubmit}> Submit</Button >
            </Form>
            <Divider horizontal>Or</Divider>
            <span className='fontSize14'>Contact us directly at: <b>support@3d-stockpicker.com</b></span>
        </>
}


export default ContactUs


