import { ReportField, ReportFillingType } from '../../../../../../../constnats/reportConstants';

class CopyTarget {
    constructor(period, type) {
        this.period = period
        this.type = type
    }
}

const COPY_TARGETS_MAP = {
    ["Q1 " + ReportFillingType.ORIGINAL_FILLING]: [
        new CopyTarget("Q1", ReportFillingType.LATEST_FILLING)
    ],
    ["Q2 " + ReportFillingType.ORIGINAL_FILLING]: [
        new CopyTarget("Q2", ReportFillingType.LATEST_FILLING),
        new CopyTarget("H1", ReportFillingType.LATEST_FILLING),
        new CopyTarget("H1", ReportFillingType.ORIGINAL_FILLING)
    ],
    ["H1 " + ReportFillingType.ORIGINAL_FILLING]: [
        new CopyTarget("Q2", ReportFillingType.LATEST_FILLING),
        new CopyTarget("H1", ReportFillingType.LATEST_FILLING),
        new CopyTarget("Q2", ReportFillingType.ORIGINAL_FILLING)
    ],
    ["Q3 " + ReportFillingType.ORIGINAL_FILLING]: [
        new CopyTarget("Q3", ReportFillingType.LATEST_FILLING),
        new CopyTarget("9M", ReportFillingType.LATEST_FILLING),
        new CopyTarget("9M", ReportFillingType.ORIGINAL_FILLING)
    ],
    ["9M " + ReportFillingType.ORIGINAL_FILLING]: [
        new CopyTarget("Q3", ReportFillingType.LATEST_FILLING),
        new CopyTarget("9M", ReportFillingType.LATEST_FILLING),
        new CopyTarget("Q3", ReportFillingType.ORIGINAL_FILLING)
    ],
    ["Q4 " + ReportFillingType.ORIGINAL_FILLING]: [
        new CopyTarget("Q4", ReportFillingType.LATEST_FILLING),
        new CopyTarget("H2", ReportFillingType.LATEST_FILLING),
        new CopyTarget("FY", ReportFillingType.LATEST_FILLING),
        new CopyTarget("H2", ReportFillingType.ORIGINAL_FILLING),
        new CopyTarget("FY", ReportFillingType.ORIGINAL_FILLING)
    ],
    ["H2 " + ReportFillingType.ORIGINAL_FILLING]: [
        new CopyTarget("Q4", ReportFillingType.LATEST_FILLING),
        new CopyTarget("H2", ReportFillingType.LATEST_FILLING),
        new CopyTarget("FY", ReportFillingType.LATEST_FILLING),
        new CopyTarget("Q4", ReportFillingType.ORIGINAL_FILLING),
        new CopyTarget("FY", ReportFillingType.ORIGINAL_FILLING)
    ],
    ["FY " + ReportFillingType.ORIGINAL_FILLING]: [
        new CopyTarget("Q4", ReportFillingType.LATEST_FILLING),
        new CopyTarget("H2", ReportFillingType.LATEST_FILLING),
        new CopyTarget("FY", ReportFillingType.LATEST_FILLING),
        new CopyTarget("Q4", ReportFillingType.ORIGINAL_FILLING),
        new CopyTarget("H2", ReportFillingType.ORIGINAL_FILLING)
    ]

}

/**
 * 
 * @param {{}} srcReport Report in raw fromat. Required
 * @param {[]} allPersistedReports Reports in raw format, non empty
 * @returns {[]} non-null  
 */
function getAvalableTargetReports(srcReport, allPersistedReports) {
    const srcYear = srcReport[ReportField.YEAR]
    const srcPeriod = srcReport[ReportField.PERIOD]
    const srcType = srcReport[ReportField.TYPE]
    const reportType = srcReport[ReportField.REPORT_TYPE]

    const cts = COPY_TARGETS_MAP[srcPeriod + " " + srcType] || []

    for (const ct of cts) {
        if (ct.type === ReportFillingType.ORIGINAL_FILLING) {
            ct.year = srcYear
        } else {
            ct.year = srcYear - 1
        }
    }

    return allPersistedReports.filter(r => cts.find(ct =>
        r[ReportField.YEAR] == ct.year && r[ReportField.PERIOD] === ct.period
        && r[ReportField.TYPE] === ct.type && r[ReportField.REPORT_TYPE] === reportType
    ))
}



export { getAvalableTargetReports }