class PathFactory {

    static create(pathElements, urlParams) {
        let path = pathElements.map(e => String(e).replace(/\/$/, '')).join("/")
        if (urlParams) {
            path = path + "?" + urlParams
        }
        return path
    }
}

export { PathFactory }