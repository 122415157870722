import React from 'react';
import { Header } from 'semantic-ui-react'


function Careers() {


    return <>
        <Header as="h2" textAlign='center'>Careers</Header>
        <div>
            <Header as="h4" textAlign='center'>Join Our Team as a Financial Data Analyst: Unlock Your Career Potential</Header>
            Embark on a rewarding career journey with us as we seek talented individuals to join our team as Financial Data Analysts.
            At the forefront of innovation and excellence, we offer a unique opportunity for dedicated professionals to contribute their expertise to a dynamic and collaborative work environment.


            <Header as="h5">Why Choose Us:</Header>
            <ul>
                <li> <b>Innovative Work Culture:</b>Be part of a team that thrives on innovation, where your analytical skills will be harnessed to tackle complex financial data challenges.</li>
                <li> <b>Professional Growth:</b>Our commitment to your professional development ensures that you not only meet but exceed your career aspirations. Benefit from continuous learning and skill enhancement programs.</li>
                <li> <b>Collaborative Environment:</b> Experience a collaborative and inclusive work environment where your insights and contributions are valued. We believe in fostering a culture of teamwork that fuels success.</li>
                <li> <b>Impactful Contributions:</b>Your role as a Financial Data Analyst with us extends beyond numbers; it is an opportunity to make impactful contributions to our organization and the financial industry as a whole.
                </li>
            </ul>
            <p>
                Ready to take the next step in your career journey? For more information and to explore exciting opportunities, please follow the link below.
            </p>
            <a target="_blank" rel="noreferrer" href={"https://www.jobs.bg/company/312874"}>Apply Now</a>
        </div>
    </>
}


export default Careers


