import HttpReq from "../../service/httpReq"

class RolesRepo {
    static prefix = 'api/mgmt/role'

    static list(rolesConsumer, errorConsumer) {
        HttpReq.get(RolesRepo.prefix, rolesConsumer, errorConsumer)
    }
}

export default RolesRepo