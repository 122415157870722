import Alert from "../utils/alert"
import AuthService from "./auth"
import ObjCheck from "../utils/objCheck"
import { NO_INTERNET_CONNECTION } from "../messages"

class HttpReq {
    static hostName = process.env.REACT_APP_BACKEND_HOST

    static FAILURE_MSG = "Faled to execute remote operation"
    static FAILURE_MSG_PLUS_STATUS = HttpReq.FAILURE_MSG + " Status Code:"
    static FAILURE_MSG_EXPIRED_SESSION = "Your session has expired."

    static get(path, successHandler, errorHandler) {
        HttpReq._exec(HttpReq.hostName + path, {
            method: 'GET',
        }, successHandler, errorHandler)
    }

    static post(path, body, successHandler, errorHandler) {
        HttpReq._exec(HttpReq.hostName + path, {
            method: 'POST',
            body: body instanceof FormData ? body : JSON.stringify(body),
        }, successHandler, errorHandler)

    }

    static put(path, body, successHandler, errorHandler) {
        HttpReq._exec(HttpReq.hostName + path, {
            method: 'PUT',
            body: JSON.stringify(body)
        }, successHandler, errorHandler)

    }

    static delete(path, successHandler, errorHandler) {
        HttpReq._exec(HttpReq.hostName + path, {
            method: 'DELETE'
        }, successHandler, errorHandler)
    }

    static _exec(host, request, successHandler, errorHandler) {
        const defaultHeaders = HttpReq._defaultHeaders(request.body);
        if (request.headers === undefined) {
            request.headers = defaultHeaders
        } else {
            Object.assign(request.headers, defaultHeaders)
        }
        fetch(host, request).then(res => {
            if (res.ok) {
                return res.json();
            } else if (res.status === 422) {
                //Relocate: This must not be hardcoded here
                window.location = "/entityNotFound"
                return new Promise((resolve, reject) =>
                    reject(new RejectModel("Entity not found", res)))
            } else {
                return new Promise((resolve, reject) => {
                    try {
                        if (res.status === 401) {
                            AuthService.refreshAuthToken(() => {
                                this._exec(host, request, s => resolve(s), e => reject(e))
                            }, () => {
                                AuthService.logout()
                                reject(new RejectModel(HttpReq.FAILURE_MSG_EXPIRED_SESSION, res, true))
                            })
                        } else {
                            res.json().then(res => {
                                if (res.message !== undefined) {
                                    reject(new RejectModel(res.message, res, true));
                                } else {
                                    reject(new RejectModel(HttpReq.FAILURE_MSG_PLUS_STATUS +
                                        res.status + " Details: " + JSON.stringify(res), res));
                                }
                            }, e => {
                                reject(new RejectModel(HttpReq.FAILURE_MSG_PLUS_STATUS +
                                    res.status, res))
                            })
                        }
                    } catch (error) {
                        reject(new RejectModel(HttpReq.FAILURE_MSG_PLUS_STATUS +
                            res.status, res))
                    }
                })
            }
        }).then(
            successHandler,
            error => {
                if (ObjCheck.isNullOrUndefined(errorHandler)) {
                    if (error instanceof RejectModel) {
                        error = error.getUserError()
                    }
                    const normalizedMsg = HttpReq._normalizeError(error)

                    let msg
                    if (typeof normalizedMsg === 'string') {
                        msg = normalizedMsg
                    } else if (typeof normalizedMsg?.msg === 'string') {
                        msg = normalizedMsg.msg
                    } else {
                        msg = JSON.stringify(normalizedMsg)
                    }

                    Alert.error(msg, "Host:" + host + ", Details:" + safeErrorStringify(error))
                } else {
                    let errorRes
                    if (error instanceof RejectModel) {
                        errorRes = error
                    } else {
                        errorRes = HttpReq._normalizeError(error)
                    }
                    errorHandler(errorRes)
                }
            }
        ).catch(error => {
            error = HttpReq._normalizeError(error)
            if (ObjCheck.isNullOrUndefined(errorHandler)) {
                Alert.error(HttpReq.FAILURE_MSG + " Details:" + JSON.stringify(error))
            } else {
                errorHandler(error)
            }
        })
    }

    static _normalizeError(error) {
        if (ObjCheck.isNullOrUndefined(error)) {
            error = { message: "Unknown Error" }
        }

        if (!ObjCheck.isNullOrUndefined(error.message)) {
            let msg
            if (error.message === "Failed to fetch") {
                msg = NO_INTERNET_CONNECTION
            } else {
                msg = error.message
            }
            error.msg = msg
        }

        return error
    }

    static _authHeader() {
        const token = AuthService.getAuthToken()
        if (ObjCheck.isNullOrUndefined(token)) {
            return {}
        } else {
            return { Authorization: 'Bearer ' + token }
        }
    }

    static _defaultHeaders(forBody) {
        const headers = HttpReq._authHeader()
        if (!(forBody instanceof FormData)) {
            Object.assign(headers, {
                'Content-Type': 'application/json',
            })
        }

        return headers
    }

}

function safeErrorStringify(error) {
    let details
    try {
        details = JSON.stringify(error)
    } catch (error) {
        details = "Failed to process details."
    }

    return details
}

class RejectModel {
    constructor(msg, res, directMessage) {
        this.msg = msg
        this.json = { statusCode: res.status }
        if (directMessage === true) {
            this.directMessage = true
        }
    }

    getUserError() {
        return this.directMessage === true ? this.msg : this
    }
}

export default HttpReq