import React, { useEffect, useState } from 'react';
import { Header, Tab } from 'semantic-ui-react'
import Loader from '../../components/loader';
import AuthService from '../../service/auth';
import { ROLES } from '../../constnats/user';
import { AuthContext } from '../../App';
import RouteC from '../../constnats/routeConstants';
import CompaniesTable from '../../components/admin/company/companiesTable';
import { UpdatesTableSummary } from '../../components/admin/updates/updatesTab';
import CompanyRepo from '../../utils/repository/companyRepo';
import { SelectField } from '../../components/form/fields';
import UserRepo from '../../utils/repository/userRepo';
import KeyValue from '../../structures/keyValePair';
import CollectionUtils from '../../utils/collections';
import TableUpdates from '../../service/tableUpdates';

function useEmployeesKV(includeDisabled = false) {

    const [users, setUsers] = useState([])

    useEffect(() => {
        UserRepo.list({ userRoles: [ROLES.EMPLOYEE], includeDisabled },
            users => setUsers(CollectionUtils.sortArrByString(users, u => u.fullName)
                .map(t => new KeyValue(t.id, t.fullName))))
    }, [includeDisabled])

    return users
}

function EmployeeHome() {
    const [userId, setUserId] = useState(AuthService.getUserId())
    const users = useEmployeesKV()

    const panes = []

    panes.push(getPaneCfg("My Updates",
        <UpdatesTable query={
            {
                notPhase: "APPLIED",
                owner: userId
            }}
            filter={userUpdatesFilter}
        />))
    panes.push(getPaneCfg("Updates For Peer Review",
        <UpdatesTable query={
            {
                phases: ["READY_FOR_PEER_REVIEW", "READY_FOR_QA_REVIEW"],
                assignee: userId
            }}
            filter={pendingPeerFilter(userId)}
        />))

    if (AuthService.hasAnyGlobalRole([ROLES.QA])) {
        panes.push(getPaneCfg("Updates For QA Review",
            AuthService.getUserId() === userId ? <UpdatesTable
                query={{ phases: ["READY_FOR_QA_REVIEW"] }}
                filter={pendingQAFilter(userId)}
            /> : "Not supported"))
    }
    panes.push(getPaneCfg("My Companies",
        <Companies owner={userId} />))

    panes.push(getPaneCfg("Contributor to Companies",
        <Companies contributor={userId} />))

    return (
        <>
            <SelectField
                className="smallField"
                label="View As: "
                value={userId}
                onChange={setUserId}
                valuesMap={users} number={true}
            />
            <br />
            <Tab panes={panes} />
        </>
    )
}

class Home extends React.Component {

    componentWillUnmount() {
        Cache.clean()
    }

    render() {
        return <div>
            {AuthService.hasRole(this.context, ROLES.EMPLOYEE) && <EmployeeHome />}
        </div>
    }
}

function getPaneCfg(name, content) {
    return {
        menuItem: name,
        render: () => <Tab.Pane>
            {content}
        </Tab.Pane>
    }
}

function pendingQAFilter(userId) {
    return u => {
        const qaReviews = u.reviews.filter(r => r.type === "VISUAL_CHECK")
        const nonQaReviews = u.reviews.filter(r => r.type !== "VISUAL_CHECK")
        return nonQaReviews.every(r =>
            (r.status === "APPROVED" && r.ownerId !== userId)) &&
            (
                (qaReviews.length === 0) ||
                qaReviews.find(r =>
                    (r.status === "PENDING_REVISION" ||
                        r.status === "IN_PROGRESS")
                    && r.ownerId === userId)
            )
    }
}


function pendingPeerFilter(userId) {
    return u => {
        const peerReviews = u.reviews.filter(r => r.type !== "VISUAL_CHECK")
        return !peerReviews.find(r =>
            (r.status === "APPROVED" || r.status === "REJECTED") &&
            r.ownerId === userId)
    }
}

function userUpdatesFilter() {
    return u => {
        const reviews = u.reviews
        return reviews.length === 0 ||
            reviews.find(r => r.status !== "APPROVED") ||
            reviews.every(r => r.status === "APPROVED")
    }
}

function UpdatesTable({ query, filter }) {
    const [updates, setUpdates] = useState(null)
    const [loading, setLoading] = useState(true)
    const [v, setV] = useState(1)

    let visibleUpdates = updates
    if (updates) {
        visibleUpdates = updates.filter(filter)
    }

    useEffect(() => {
        setLoading(true)
        Cache.get(JSON.stringify(query), () =>
            TableUpdates.find(query)
        ).then(updates => {
            setUpdates(updates)
            setLoading(false)

        })
    }, [query, v])

    return loading ? <Loader /> : <UpdatesTableSummary
        onUpdatesChange={() => {
            Cache.remove(JSON.stringify(query))
            setV(v + 1)
        }}
        updates={visibleUpdates}
        includeMistakes={false} />
}

function Companies({ owner, contributor }) {
    const [companies, setCompanies] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        const params = {}
        if (owner) {
            params.owner = owner

        }
        if (contributor) {
            params.contributor = contributor
        }
        new Promise(resolve => {
            CompanyRepo.listInternal(params, resolve)
        }).then(companies => {
            setCompanies(companies)
            setLoading(false)
        })
    }, [owner, contributor])

    return loading ? <Loader /> : <>
        <Header as="h5" textAlign='center' className="subPageHeader">PUBLIC</Header>
        <CompaniesTable companies={companies.filter(c => c.visibility === "PUBLIC")}
            companyUrlPrefix={RouteC.EDIT_COMPANY_CONFIG_INTERNAL} />
        <Header as="h5" textAlign='center' className="subPageHeader">INTERNAL</Header>
        <CompaniesTable companies={companies.filter(c => c.visibility === "INTERNAL")}
            companyUrlPrefix={RouteC.EDIT_COMPANY_CONFIG_INTERNAL} />
    </>
}


class Cache {

    static _cacheMap = {};
    static set(key, val) {
        Cache._cacheMap[key] = val
    }

    static get(key, valuePovider) {
        let res = Cache._cacheMap[key]
        if (res === undefined) {
            res = valuePovider()
            Cache.set(key, res)
        }
        return res
    }

    static remove(key) {
        Cache._cacheMap[key] = undefined
    }

    static clean() {
        Cache._cacheMap = {}
    }
}

Home.contextType = AuthContext;

export default Home
