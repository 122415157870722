
const NOT_ENOUGHT_PRIVILEGES_FOR_CLIENT = "You need to upgrade your account to view this data."
const NO_INTERNET_CONNECTION = "No internet connection!"
const CONFIRM_DELETE_CATEGORY = "Are you sure that you want to delete this Category?"
const SITE_NOT_CONVENIENT_ON_MOBILE_DEVICES = "Our website is not optimized for mobile use. For the best browsing experience, we recommend using a desktop computer."

export {
    NOT_ENOUGHT_PRIVILEGES_FOR_CLIENT, NO_INTERNET_CONNECTION,
    CONFIRM_DELETE_CATEGORY, SITE_NOT_CONVENIENT_ON_MOBILE_DEVICES
}
