import AuthService from "../service/auth"

class PaneNameFactory {

    /**
     * 
     * @param {object} schema As persisted. Required
     * @returns {string} not-null
     */
    static forSchema(schema) {
        let paneName
        if (schema.branchType === "MAIN") {
            paneName = schema.name
        } else {
            const owner = schema.owner
            const suffix = AuthService.getUserId() === owner.id ? "" : ("-" + owner.fullName)
            paneName = (schema.name + "-" + schema.branchType + suffix)
        }

        return paneName
    }
}

export default PaneNameFactory