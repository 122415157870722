import React from 'react';
import { Dimmer, Loader as L, Segment, Container } from 'semantic-ui-react';

type LoaderProps = {
    msg?: string;
};

function Loader({ msg }: LoaderProps) {
    return (
        <Container>
            <Segment className="loaderContainer">
                <Dimmer active inverted>
                    <L size="large">{msg || 'Loading'}</L>
                </Dimmer>
            </Segment>
        </Container>
    );
}

export default Loader;
