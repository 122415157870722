import HttpReq from "../../service/httpReq"
import Cache from "./cache"

function cacheKey(schemaId) {
    return "ManagedReportsRepo" + schemaId
}

//TODO Rename ManagedReportsRepo
class ReportsRepo {
    static publicPath = 'api/managed-reports'
    static mgmtPath = 'api/mgmt/managed-reports'

    static listBySchema(schemaId, reportConsumer, errorConsumer) {
        Cache.get(cacheKey(schemaId), func => {
            HttpReq.get(ReportsRepo.publicPath + "?schemaId=" + schemaId, func, errorConsumer)
        }, reportConsumer)
    }

    static save(createReportSpec, reportConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.post(ReportsRepo.mgmtPath, createReportSpec, reportConsumer, errorConsumer)
    }

    static update(updateReportSpec, reportConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.put(ReportsRepo.mgmtPath, updateReportSpec, reportConsumer, errorConsumer)
    }

    static delete(id, resConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.delete(ReportsRepo.mgmtPath + "/" + id, resConsumer, errorConsumer)
    }
}

export default ReportsRepo