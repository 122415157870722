import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox, Header, Segment, Table } from 'semantic-ui-react'
import Loader from '../../../components/loader';
import UserRepo from '../../../utils/repository/userRepo';
import { booleanToLablel } from "../../../utils/labelsConverter"
import { serverToLocalDate } from '../../../utils/dateTimeUtils';
import { ROLES } from '../../../constnats/user';
import KeyValue from '../../../structures/keyValePair';
import { SelectField } from '../../../components/form/fields';

function Users() {
    const [users, setUsers] = useState(null)
    const [includeDisabled, setIncludeDisabled] = useState(false)
    const [userRole, setUserRole] = useState(ROLES.CLIENT)
    const [sortDateProp, setSortDateProp] = useState("createdOn")


    useEffect(() => {
        const spec = { includeDisabled }
        if (userRole !== "") {
            spec.userRoles = [userRole]
        }
        UserRepo.list(spec, setUsers)
    }, [includeDisabled, userRole])

    return users === null ? <Loader /> : <>
        <Header as="h2" textAlign='center'>Users</Header>
        <Checkbox className='checkbox' checked={includeDisabled}
            label="Include Disabled"
            onChange={(evt, data) => setIncludeDisabled(data.checked)} />
        <SelectField label="User Type:"
            valuesMap={[
                new KeyValue(ROLES.CLIENT, "Client"),
                new KeyValue(ROLES.EMPLOYEE, "Employee"),
                new KeyValue("", "ALL")
            ]}
            value={userRole}
            onChange={key => setUserRole(key)} />
        <SelectField label="Sort By: "
            valuesMap={[
                new KeyValue("createdOn", "Created On"),
                new KeyValue("lastLogin", "Last Login")
            ]}
            value={sortDateProp}
            onChange={key => setSortDateProp(key)} />
        <UsersTable users={users.sort((a, b) =>
            new Date(b[sortDateProp]) - new Date(a[sortDateProp])
        )} />
        <Segment>
            <Header as="h4" textAlign='center'>Activated newsletter Subscribers</Header>
            {users.filter(u => u.newsletterSubscription && u.activated && !u.disabled)
                .map(u => u.email).join("; ") || "No Subscribers"}
        </Segment>
    </>
}

function UsersTable({ users }) {
    return <Table striped>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>No</Table.HeaderCell>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Full Name</Table.HeaderCell>
                <Table.HeaderCell>Username</Table.HeaderCell>
                <Table.HeaderCell>Registration Date</Table.HeaderCell>
                <Table.HeaderCell>Last Login</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Newsletter Subscription</Table.HeaderCell>
                <Table.HeaderCell>Activated</Table.HeaderCell>
                <Table.HeaderCell>Disabled</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {users.map((u, i) =>
                <Table.Row key={u.id}>
                    <Table.Cell>
                        {i + 1}
                    </Table.Cell>
                    <Table.Cell>
                        {u.id}
                    </Table.Cell>
                    <Table.Cell>
                        <Link to={"/admin/users/" + u.id}>
                            {u.fullName}
                        </Link>
                    </Table.Cell>
                    <Table.Cell>
                        {u.username}
                    </Table.Cell>
                    <Table.Cell>
                        {serverToLocalDate(u.createdOn)}
                    </Table.Cell>
                    <Table.Cell>
                        {serverToLocalDate(u.lastLogin)}
                    </Table.Cell>
                    <Table.Cell>
                        {u.email}
                    </Table.Cell>
                    <Table.Cell>
                        {booleanToLablel(u.newsletterSubscription)}
                    </Table.Cell>
                    <Table.Cell>
                        {booleanToLablel(u.activated)}
                    </Table.Cell>
                    <Table.Cell>
                        {booleanToLablel(u.disabled, true)}
                    </Table.Cell>
                </Table.Row>
            )}
        </Table.Body>
    </Table >
}

export default Users


