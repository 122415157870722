import { Label } from "semantic-ui-react"

/**
 * 
 * @param {string} status Required
 * @returns not-null
 */
function reportStatusToLablel(status) {
    let color
    if (status === "REJECTED" || status === "APPROVAL_AUTO_REJECTED") {
        color = "red"
    } else if (status === "APPROVED") {
        color = "green"
    } else if (status === "PENDING_REVISION") {
        color = "yellow"
    } else if (status === "IN_PROGRESS") {
        color = "blue"
    }

    return <Label color={color}
        size="mini">{status}</Label>
}

/** 
 * @param {boolean} boolValue Required
 * @param {boolean} inverse Optional
 * @returns not-null
 */
function booleanToLablel(boolValue, inverse = false) {
    let color
    let target = inverse === true ? false : true
    if (boolValue === target) {
        color = "green"
    } else {
        color = "red"
    }

    return <Label color={color}
        size="mini">{String(boolValue) + ""}</Label>
}

export { reportStatusToLablel, booleanToLablel }
