class AuthMonitor {
    static _changeListeners = []
    static _issueListeners = []

    /**
     * @param {func} listener
     * Function to be executed on auth data change event
     */
    static registerAuthChangeListener(listener) {
        AuthMonitor._changeListeners.push(listener)
    }

    /**
     * @param {func} listener
     * Function to be executed on auth issue event
     */
    static registerIssueListener(listener) {
        AuthMonitor._issueListeners.push(listener)
    }

    static onChange() {
        AuthMonitor._changeListeners.forEach(l => l())
    }

    static onIssue(issue) {
        AuthMonitor._issueListeners.forEach(l => l(issue))
    }
}

export default AuthMonitor