import { Grid, Header, Label, Segment } from "semantic-ui-react"
import { ConfigProps, KeyValueOptions } from './constants'
import ReactSlider from 'react-slider'
import { MultiselectField, CheckboxBtn, SelectFieldWrapper, SelectField } from './fields'
import { SUPPORTED_YEARS } from "../../../constnats/generalConstants"

const currentYear = new Date().getFullYear()

function FilterAndSortCfg({ config, onChange, granularPeriodsFiltering }) {

    const onPropChange = (prop, val) => {
        onChange(Object.assign({}, config, { [prop]: val }))
    }

    return <Segment className="tableConfigSegment baseSettings" size="mini">


        <div className="flex-container">
            <div className="flex-container--item min-w-100" style={{ flexGrow: "1.5" }}>
                <YearsFilter config={config[ConfigProps.YEARS_FILTER]}
                    onChange={val => onPropChange(ConfigProps.YEARS_FILTER, val)} />
            </div>
           
            <div className="flex-container--item" style={{ flexGrow: granularPeriodsFiltering ? "2.9" : "1.9" }}>
                <PeriodFilter config={config[ConfigProps.PERIODS_FILTER]}
                    onChange={val => onPropChange(ConfigProps.PERIODS_FILTER, val)}
                    {...{ granularPeriodsFiltering }} />
            </div>

            <div className="flex-container--item" style={{ flexGrow: "1.9" }}>
                <ReportTypeFilter config={config[ConfigProps.REPORT_TYPES_FILTER]}
                    onChange={val => onPropChange(ConfigProps.REPORT_TYPES_FILTER, val)} />
            </div>

            <div className="flex-container--item">
                <FillingTypeFilter config={config[ConfigProps.FILLING_TYPE_FILTER]}
                    onChange={val => onPropChange(ConfigProps.FILLING_TYPE_FILTER, val)} />
            </div>

            <div className="flex-container--item" style={{ flexGrow: "1.3" }}>
                <ConditionalFilteringFilter config={config}
                    onChange={(p, val) => {
                        onPropChange(ConfigProps.ORIGINAL_VERSION_CONDITIONAL_FILTER, val)
                    }} />
            </div>

            <div className="flex-container--item">
                <Scale config={config} onPropChange={(p, val) => {
                    onPropChange(ConfigProps.PREFFERED_SCALE, val)
                }} />
            </div>

            <div className="flex-container--item">
                <Order config={config} onPropChange={(p, val) => {
                    onPropChange(ConfigProps.REPORTS_ORDER, val)
                }} />
            </div>

            <div className="flex-container--item">
                <Growth config={config} onPropChange={(p, val) => {
                    onPropChange(p, val)
                }} />
            </div>
        </div>

    </Segment>
}

function Growth({ config, onPropChange }) {
    const checkbox = (prop, label) => <CheckboxBtn label={label}
        checked={config[prop]}
        onChange={data => onPropChange(prop, data)} />
    return (
        <div className="configModule">
            <ModuleHeader text='Growth' />
            {checkbox(ConfigProps.SHOW_YEARLY_GROWTH_PERCENTAGES, 'YoY')}
            {checkbox(ConfigProps.SHOW_PERIOD_GROWTH_PERCENTAGES, 'PoP')}
        </div>
    )
}

function Scale({ config, onPropChange }) {
    return (
        <div className="configModule">
            <ModuleHeader text="Scale" />
            <SelectFieldWrapper
                prop={ConfigProps.PREFFERED_SCALE}
                config={config} opsKV={KeyValueOptions.SCALE_OPS}
                onChange={onPropChange}
            />
        </div>
    )
}
function Order({ config, onPropChange }) {
    return (
        <div className="configModule">
            <ModuleHeader text="Order" />
            <SelectField
                val={config[ConfigProps.REPORTS_ORDER]}
                opsKV={KeyValueOptions.SORTING}
                onChange={val => onPropChange(ConfigProps.REPORTS_ORDER, val)} />
        </div>
    )
}


function PeriodFilter({ config, onChange, granularPeriodsFiltering }) {
    return <div className="configModule">
        <ModuleHeader text="Periods" />
        {/* <Form.Group> */}
        <MultiselectField selected={config}
            optionsKV={granularPeriodsFiltering ? KeyValueOptions.GRANULAR_PERIODS_FILTER_OPS : KeyValueOptions.PERIODS_FILTER_OPS}
            onChange={x => onChange(x)} />
        {/* </Form.Group> */}
    </div>
}


function ReportTypeFilter({ config, onChange }) {
    return <div className="configModule">
        <ModuleHeader text="Formats" />
        {/* <Form.Group> */}
        <MultiselectField selected={config}
            optionsKV={KeyValueOptions.REPORT_TYPE_FILTER}
            onChange={onChange} />
        {/* </Form.Group> */}
    </div>
}


function FillingTypeFilter({ config, onChange }) {
    return <div className="configModule">
        <ModuleHeader text="Versions" />
        <MultiselectField selected={config}
            optionsKV={KeyValueOptions.FILLING_TYPE_FILTER}
            onChange={onChange} />
    </div>
}

function ConditionalFilteringFilter({ config, onChange }) {
    return <div className="configModule">
        <ModuleHeader text="Hide Original Version If" />
        <SelectFieldWrapper label="" config={config}
            prop={ConfigProps.ORIGINAL_VERSION_CONDITIONAL_FILTER}
            opsKV={KeyValueOptions.ORIGINAL_VERSION_CONDITIONAL_FILTER}
            onChange={onChange} />
    </div>
}

function YearsFilter({ config, onChange }) {
    return <div className="configModule">
        <ModuleHeader text="Years" />
        <ReactSlider
            className="horizontal-slider"
            thumbClassName="slider-thumb"
            trackClassName="slider-track"
            defaultValue={[Number(SUPPORTED_YEARS[0]), currentYear + 1]}
            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
            pearling
            value={[config.from, config.to]}
            minDistance={0}
            min={Number(SUPPORTED_YEARS[0])}
            max={currentYear + 1}
            onAfterChange={s => onChange({ from: s[0], to: s[1] })}
        />
    </div>
}

function ModuleHeader({ text }) {
    return <Header as="h5" textAlign='center'>{text}</Header>
}

export default FilterAndSortCfg