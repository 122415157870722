import Alert from "./alert"
import KeyValue from "../structures/keyValePair"

class ScaleUtil {

    static _scalesMap = {
        0: "Default",
        1: "Actual",
        1000: "K",
        1000000: "M",
        1000000000: "B"
    }

    static scalePairs = Object.keys(ScaleUtil._scalesMap)
        .map(k => new KeyValue(Number(k), ScaleUtil._scalesMap[k]))

    /**
     * Converts a scale number to its text representation. e.g. 1000000 => Mio  
     *
     * @param {Number} num Required 
     * @param {boolean} shortForm Optional
     * @returns not-null
     * @throws Error if the num paraeter is not converatble. 
     */
    static numToTextRepresentation(num, shortForm = false) {
        const text = ScaleUtil._scalesMap[num]
        if (text !== undefined) {
            if (shortForm === true) {
                return text[0].toLowerCase()
            } else {
                return text
            }
        } else {
            Alert.error("Failed to execute numToTextRepresentation num:" + num)
            throw new Error("Failed to execute numToTextRepresentation num:" + num)
        }
    }
}

export default ScaleUtil