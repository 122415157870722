import { Button, Modal, Table } from 'semantic-ui-react';


function ReportChangeConfirmationModalModal({ changes, onConfirm, onCancel }) {

    return (
        <Modal size='large' open={true}>
            <Modal.Header>Do you want to save the following conflicting changes?</Modal.Header>
            <Modal.Content className="overflowYScroll">
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Label</Table.HeaderCell>
                            <Table.HeaderCell>Old Value</Table.HeaderCell>
                            <Table.HeaderCell>New Value</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {changes.map((change, key) => <Row {...{ change, key }} />)}
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button size='mini' primary onClick={onConfirm}>
                    Yes
                </Button>
                <Button size='mini' onClick={onCancel}>
                    No
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

function Row({ change }) {
    return (
        <Table.Row >
            <Table.Cell>{change.label}</Table.Cell>
            <Table.Cell>{change.old}</Table.Cell>
            <Table.Cell>{change.new}</Table.Cell>
        </Table.Row>
    )
}

export { ReportChangeConfirmationModalModal } 