import KeyValue from "../structures/keyValePair"

class SchemaField {

    static DEF_DATA_CALC_TYPE = "defaultDataCalcType"
    static VISIBILITY = "visibility"
    static DEF_SCALE = "defaultScale"
    static DESCRIPTORS = "descriptors"
}

class SchemaNames {
    static IS = "Income Statement"
    static CF = "Cash Flow"
    static BS = "Balance Sheet"
    static KPIs = "KPIs"
    static SEG_BY_PROD = "Segments by Product"
    static SEG_BY_REG = "Segments by Region"

    static ALL_OPT = [SchemaNames.KPIs, SchemaNames.SEG_BY_PROD, SchemaNames.SEG_BY_REG]
}

const NO_OP_CALC = "NO_OP" 

class DescriptorColors {

    static ALL_AS_KV = [
        new KeyValue('inherit', "Default"),
        new KeyValue('#ebebeb', "Grey"),
        new KeyValue('#fafa4b', "Yellow"),
        new KeyValue('#6fe36f', "Green"),
        new KeyValue('#8fceff', "Blue")
    ]

}

class BranchTypes {
    static MAIN = "MAIN"
    static EDIT = "EDIT"
    static REVIEW = "REVIEW"
}

export { SchemaField, SchemaNames, DescriptorColors, NO_OP_CALC, BranchTypes }