import { ReportType, ReportFillingType, ReportField, SrcFieldValue } from '../../../../../constnats/reportConstants';
import { Button, Modal, Dropdown, Checkbox, Form, Table, Header } from 'semantic-ui-react';
import ReportsRepo from '../../../../../utils/repository/repoertsRepo';
import ResourceRepo from '../../../../../utils/repository/resourceRepo';
import { useState, useEffect } from 'react';
import Loader from '../../../../../components/loader';
import { SUPPORTED_YEARS } from '../../../../../constnats/generalConstants';
import ReportUtils from '../../../../../utils/reportUtils';


function resourcesToMap(resources) {
    const map = {}
    for (const resource of resources) {
        const props = resource.props
        const year = props.year
        const quoter = props.quoter

        if (map[year] === undefined) {
            map[year] = new Set()
        }

        map[year].add(quoter)
    }

    return map
}

function prepareReports(schemaId, fromYear, toYear, resources, latestFillings,
    reportType, reportSrc, quartersMap, existingReports) {
    const reportsForCreation = []
    const rersourcesMap = resourcesToMap(resources)
    const fillingTypes = [ReportFillingType.ORIGINAL_FILLING]

    if (latestFillings) {
        fillingTypes.push(ReportFillingType.LATEST_FILLING)
    }

    for (const year of Object.keys(rersourcesMap)) {
        if (year < fromYear || year > toYear) {
            continue
        }
        for (const quarter of rersourcesMap[year]) {
            const periods = quartersMap[quarter]
            for (const period of periods) {

                for (const fillingType of fillingTypes) {
                    const yearToCreateReportFor =
                        fillingType === ReportFillingType.LATEST_FILLING ?
                            year - 1 : year

                    if (!existingReports.find(r =>
                        r[ReportField.YEAR] == yearToCreateReportFor &&
                        r[ReportField.PERIOD] === period &&
                        r[ReportField.REPORT_TYPE] === reportType &&
                        r[ReportField.TYPE] === fillingType
                    )) {
                        reportsForCreation.push(
                            Object.assign(ReportUtils.createEmptyReport(null),
                                {
                                    [ReportField.YEAR]: yearToCreateReportFor,
                                    [ReportField.PERIOD]: period,
                                    [ReportField.TYPE]: fillingType,
                                    [ReportField.REPORT_TYPE]: reportType,
                                    [ReportField.SRC]: reportSrc,
                                    schemaId
                                }
                            )
                        )
                    }
                }
            }
        }
    }

    return reportsForCreation
}

function saveReports(reports, onSaved) {
    function saveReport(reports, idx) {
        if (reports[idx] !== undefined) {
            ReportsRepo.save(reports[idx], res => saveReport(reports, ++idx))
        } else {
            onSaved()
        }
    }

    saveReport(reports, 0)
}


function arrToDropdownOps(arr) {
    return arr.map(c => {
        return { key: c, text: c, value: c }
    })
}

const QUATER_TO_PERIODS_MAP_ALL = {
    1: ["Q1"],
    2: ["Q2", "H1"],
    3: ["Q3", "9M"],
    4: ["Q4", "H2", "FY"]
}

const QUATER_TO_PERIODS_MAP_DEFAULT = {
    1: ["Q1"],
    2: ["Q2", "H1"],
    3: ["Q3", "9M"],
    4: ["FY"]
}

const YEARS = SUPPORTED_YEARS

function SyncWithResurcesModal({ companyId, schemaId, closeModal, onCreate }) {
    const [quartersMap, setQuartersMap] = useState(Object.assign({}, QUATER_TO_PERIODS_MAP_DEFAULT))
    const [reportType, setReportType] = useState(ReportType.STANDARD)
    const [reportSrc, setReportSrc] = useState(SrcFieldValue.REPORT)
    const [latestFillings, setLatestFillings] = useState(true)
    const [fromYear, setFromYear] = useState(YEARS[0])
    const [toYear, setToYear] = useState(YEARS[YEARS.length - 1])
    const [existingReports, setExistingReports] = useState(null)
    const [resources, setResources] = useState(null)
    const [uploading, setUploading] = useState(false)

    const loading = existingReports === null || resources === null || uploading

    useEffect(() => ReportsRepo.listBySchema(schemaId, reports =>

        ResourceRepo.listCompanyResources(companyId, resources => {
            setExistingReports(reports)
            setResources(resources)
        })
    ), [companyId, schemaId])


    const updateQuartersMap = (k, v) => {
        setQuartersMap(Object.assign({}, quartersMap, { [k]: v }))
    }

    const reportsToBeCreated = loading ? [] : prepareReports(schemaId, fromYear,
        toYear, resources, latestFillings, reportType, reportSrc, quartersMap,
        existingReports)

    return loading ? <Loader /> :
        <Modal size='large' open={true}>
            <Modal.Header>Create Reports Based On Existing Resources.</Modal.Header>
            <Modal.Content >
                <Header as="h5">Configuration</Header>
                <Form>
                    <Form.Field>
                        <label>From Year</label>
                        <Dropdown onChange={(c, v) =>
                            setFromYear(v.value)}
                            value={fromYear}
                            placeholder='From Year'
                            options={arrToDropdownOps(YEARS)}
                            fluid selection />
                    </Form.Field>
                    <Form.Field>
                        <label>To Year</label>
                        <Dropdown onChange={(c, v) =>
                            setToYear(v.value)}
                            value={toYear}
                            placeholder='To Year'
                            options={arrToDropdownOps(YEARS)}
                            fluid selection />
                    </Form.Field>
                    <PeriodDropdown
                        onChange={v => updateQuartersMap(1, v)}
                        value={quartersMap[1]}
                        period={1} />
                    <PeriodDropdown
                        onChange={v => updateQuartersMap(2, v)}
                        value={quartersMap[2]}
                        period={2} />
                    <PeriodDropdown
                        onChange={v => updateQuartersMap(3, v)}
                        value={quartersMap[3]}
                        period={3} />
                    <PeriodDropdown
                        onChange={v => updateQuartersMap(4, v)}
                        value={quartersMap[4]}
                        period={4} />
                    <Form.Field>
                        <label>Report Type</label>
                        <Dropdown onChange={(c, v) =>
                            setReportType(v.value)}
                            value={reportType}
                            placeholder='Report Type'
                            options={arrToDropdownOps(ReportType.ALL)}
                            fluid selection search />
                    </Form.Field>
                    <Form.Field>
                        <label>Source</label>
                        <Dropdown onChange={(c, v) =>
                            setReportSrc(v.value)}
                            value={reportSrc}
                            placeholder='Source'
                            options={arrToDropdownOps(SrcFieldValue.ALL_PERSISTED)}
                            fluid selection search />
                    </Form.Field>
                    <Checkbox checked={latestFillings} label="Create Latest Fillings"
                        onChange={(evt, data) => setLatestFillings(data.checked)} />
                </Form>

                <Preview {...{ reportsToBeCreated }} />
            </Modal.Content>
            <Modal.Actions>
                <Button size='mini' primary onClick={() => {
                    setUploading(true)
                    saveReports(reportsToBeCreated, () => {
                        setUploading(false)
                        onCreate()
                    })
                }}>
                    Create
                </Button>
                <Button size='mini' onClick={closeModal}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal >
}


function PeriodDropdown({ value, onChange, period }) {

    const label = 'Periods For ' + period

    return <Form.Field>
        <label>{label}</label>
        <Dropdown
            onChange={(a, b) => onChange(b.value)}
            value={value}
            placeholder={label}
            options={QUATER_TO_PERIODS_MAP_ALL[period + ""].map(c => {
                return { key: c, text: c, value: c }
            })}
            fluid selection search multiple />
    </Form.Field>
}


function Preview({ reportsToBeCreated }) {
    return reportsToBeCreated.length > 0 &&
        <>
            <Header as="h5">The following {reportsToBeCreated.length} reports are going to be created.</Header>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Year</Table.HeaderCell>
                        <Table.HeaderCell>Period</Table.HeaderCell>
                        <Table.HeaderCell>Filling Type</Table.HeaderCell>
                        <Table.HeaderCell>Report Type</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {reportsToBeCreated.map((r, idx) => {
                        return <Table.Row key={idx}>
                            <Table.Cell>{r.year}</Table.Cell>
                            <Table.Cell>{r.period}</Table.Cell>
                            <Table.Cell>{r.type}</Table.Cell>
                            <Table.Cell>{r.reportType}</Table.Cell>
                        </Table.Row>
                    })}
                </Table.Body>
            </Table>
        </>
}

export default SyncWithResurcesModal