import AcrivityLogRepo from "../utils/repository/activityLogRepo";
import AuthService from "./auth";
import Assert from "../utils/asserts";
import { utcToSofia } from '../utils/dateTimeUtils';
import JsEventLogger from './jsEventsLogger';

const LS_ACTIVITY_KEY = "userActivityData"
const LS_SAVED_ACTIVITY_KEY = "savedUserActivityData"


const getEmptyActivity = (userId, timeNow) => {
    return {
        userId, clicks: 0, keystrokes: 0,
        duration: 0, middleDuration: 0, longDuration: 0,
        start: timeNow, end: timeNow,
        localSessionStart: timeNow
    }
}

function toMinutes(ms) {
    return Math.round((ms / 1000) / 60);
}

setInterval(() => {

    if (AuthService.isAuthenticated()) {
        const activityStr = localStorage.getItem(LS_ACTIVITY_KEY)

        if (activityStr) {
            const savedActivityStr = localStorage.getItem(LS_SAVED_ACTIVITY_KEY)
            if (activityStr !== savedActivityStr) {
                Assert.notNullOrUndefined(activityStr, "Invalid activity")
                ActivityLogger.save(JSON.parse(activityStr))
                localStorage.setItem(LS_SAVED_ACTIVITY_KEY, activityStr)
            }
        }
    }

}, 1000 * 60 * 4)

class ActivityLogger {

    static _enabled = false

    static save(activity, msg = "") {
        activity.duration = toMinutes(activity.duration)
        activity.middleDuration = toMinutes(activity.middleDuration)
        activity.longDuration = toMinutes(activity.longDuration)

        let activityStr
        try {
            activityStr = activity ? JSON.stringify(activity) : "no data"
        } catch (e) {
            activityStr = "FAILED TO PARSE"
        }

        AcrivityLogRepo.save(activity, () => {
        }, (e) => {
            const lastSavedActivity = JSON.parse(localStorage.getItem(LS_SAVED_ACTIVITY_KEY))
            const mDiff = activity.middleDuration - toMinutes(lastSavedActivity === null ? 0 : lastSavedActivity.middleDuration)
            if (mDiff > 5 || mDiff < 0) {
                JsEventLogger.log("FAILED TO SAVE ACTIVITY " + msg +
                    ", M DIFF:" + mDiff, activityStr, "activity logger")
            }
        })
    }
    static enable() {
        if (ActivityLogger._enabled === false) {
            AcrivityLogRepo._enabled = true
        } else {
            return
        }

        document.addEventListener("DOMContentLoaded", function () {
            let events = ["mouseup", "keydown", "scroll"];

            events.forEach(function (e) {
                document.addEventListener(e, function () {
                    const timeNow = Date.now();
                    const userId = AuthService.getUserId()
                    if (userId === null) {
                        return
                    }

                    let activity = JSON.parse(localStorage.getItem(LS_ACTIVITY_KEY)) ||
                        getEmptyActivity(userId, timeNow)

                    if (userId !== activity.userId) {
                        Assert.fail("Multiple employees use same machine. Current:" + userId + " Old:" + activity.userId)
                        ActivityLogger.save(activity)
                        activity = getEmptyActivity(userId, timeNow)
                    }

                    if (new Date(utcToSofia(activity.start)).getUTCDate() !== new Date(utcToSofia(timeNow)).getUTCDate()) {
                        ActivityLogger.save(activity, "ROTATE LOGS")
                        activity = getEmptyActivity(userId, timeNow)
                    }

                    activity.middleDuration = activity.middleDuration === undefined ? 0 : activity.middleDuration
                    activity.longDuration = activity.longDuration === undefined ? 0 : activity.longDuration

                    const msFromLastActivity = timeNow - activity.localSessionStart
                    Assert.positiveNonNegative(msFromLastActivity, "Negative duration:" + JSON.stringify(localStorage.getItem(LS_ACTIVITY_KEY)))

                    if (activity.end > timeNow - 120000) {
                        activity.duration += msFromLastActivity
                    } else {
                        activity.duration += 30000
                    }

                    if (activity.end > timeNow - 300000) {
                        activity.middleDuration += msFromLastActivity
                    } else {
                        activity.middleDuration += 30000
                    }

                    if (activity.end > timeNow - 600000) {
                        activity.longDuration += msFromLastActivity
                    } else {
                        activity.longDuration += 30000
                    }

                    activity.localSessionStart = timeNow
                    activity.end = timeNow
                    if (e === "mouseup") {
                        activity.clicks++
                    } else if (e === "keydown") {
                        activity.keystrokes++
                    }

                    localStorage.setItem(LS_ACTIVITY_KEY, JSON.stringify(activity))
                })
            })
        })
    }
}


export default ActivityLogger