import React from 'react';
import { Form, Button } from 'semantic-ui-react'
import AuthService from '../../../service/auth';
import UserRepo from '../../../utils/repository/userRepo';
import FiledsValidator from '../../../utils/validation/fieldsValidator';
import Alert from '../../../utils/alert';

class PasswordChangeForm extends React.Component {
    constructor(props) {
        super(props);

        this.filedsValidator = new FiledsValidator(["oldPassword", "newPassword", "passwordConfirmation"], {
            "passwordConfirmation": {
                validate: val => val === this.state.fieldsData.newPassword,
                msg: "The new password confirmation does not match"
            }
        })
        const userId = AuthService.getUserId()
        this.state = {
            fieldsData: {
                oldPassword: "",
                newPassword: "",
                passwordConfirmation: "",
                userId: userId,
            },
            propsValidation: this.filedsValidator.getValidErrorProps(),
        }
    }

    onPropChange(newVal, prop) {
        const fieldsDataCopy = Object.assign({}, this.state.fieldsData)
        fieldsDataCopy[prop] = newVal
        this.setState({ fieldsData: fieldsDataCopy })
        this.setState({ propsValidation: this.filedsValidator.dicardErrorIfExists(prop) })
    }

    getField(forProp, label, type = "text") {
        return <Form.Field>
            <Form.Input
                input={type}
                label={label}
                placeholder={label}
                onChange={v => this.onPropChange(v.target.value, forProp)}
                value={this.state.fieldsData[forProp]}
                error={this.state.propsValidation[this.filedsValidator.getErrorPropName(forProp)]} />
        </Form.Field>
    }

    changePass() {
        if (this.validate()) {
            UserRepo.changePass(this.state.fieldsData, res => {
                Alert.success("Your password was successfully updated")
            })
        }
    }

    validate() {
        const validationRes = this.filedsValidator.validate(this.state.fieldsData)
        this.setState({ propsValidation: validationRes.newErrorProps })
        return validationRes.valid
    }

    render() {
        return (
            <Form>
                {this.getField("oldPassword", "Current Password", "password")}
                {this.getField("newPassword", "New Password", "password")}
                {this.getField("passwordConfirmation", "Confirm New Password", "password")}

                <Button size='mini' onClick={() => this.changePass()} primary>Change Password</Button>
            </Form>
        );
    }
}


export default PasswordChangeForm


