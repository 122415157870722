import React from 'react';
import { Form, Button, Dropdown, Segment, Container } from 'semantic-ui-react'
import Alert from '../../../../utils/alert';
import FiledsValidator from '../../../../utils/validation/fieldsValidator';
import UserRepo from '../../../../utils/repository/userRepo';
import CompanyRepo from '../../../../utils/repository/companyRepo';
import Loader from '../../../../components/loader';
import { ROLES } from '../../../../constnats/user';

class Contributors extends React.Component {
    constructor(props) {
        super(props);

        this.filedsValidator = new FiledsValidator(["selectedUserIds"])
        this.state = {
            roleIds: this.props.roleIds,
            users: null,
            userIds: [],
            selectedUserIds: null,
            propsValidation: this.filedsValidator.getValidErrorProps()
        }
    }

    componentDidMount() {
        UserRepo.list({ userRoles: [ROLES.EMPLOYEE], includeDisabled: false },
            users => {
                CompanyRepo.getContributors(this.props.companyId, contributors => {
                    for (const contributor of contributors) {
                        if (!users.find(u => u.id === contributor.id)) {
                            contributor.fullName = contributor.fullName + " (DELETED)"
                            users.push(contributor)
                        }
                    }

                    this.setState({ selectedUserIds: contributors.map(u => u.id) })
                })

                this.setState({ users })
            })
    }

    save() {
        if (this.validate()) {
            CompanyRepo.updateContributors({
                companyId: this.props.companyId,
                userIds: this.state.selectedUserIds
            }, res => {
                Alert.success("Saved")
                this.props.setIsDirty(false)
            })
        }
    }

    validate() {
        const validationRes = this.filedsValidator.validate(this.state)
        this.setState({ propsValidation: validationRes.newErrorProps })
        return validationRes.valid
    }

    onPropChange(prop, newVal) {
        this.props.setIsDirty(true)
        this.setState({
            [prop]: newVal,
            propsValidation: this.filedsValidator.dicardErrorIfExists(prop, this.state.propsValidation)
        })
    }

    getContributorsField() {
        const roleOptions = this.state.users.map(r => {
            return {
                key: r.id,
                text: r.fullName,
                value: r.id
            }
        })
        const propName = "selectedUserIds"

        return <Form.Field>
            <label>Contributors</label>
            <Dropdown
                disabled={!this.props.editMode}
                onChange={(a, b) => this.onPropChange(propName, b.value)}
                value={this.state.selectedUserIds}
                placeholder='Contributors'
                options={roleOptions} fluid multiple selection search
                error={this.state.propsValidation[this.filedsValidator.getErrorPropName(propName)] !== false} />
        </Form.Field>
    }

    getForm() {
        return <Container>
            <Segment>
                <Form>
                    {this.getContributorsField()}
                    {this.props.editMode && <Button size='mini' onClick={() => this.save()} primary>Save</Button>}
                </Form>
            </Segment>
        </Container>
    }

    render() {
        return (this.state.users === null || this.state.selectedUserIds === null) ? <Loader /> : this.getForm()
    }
}

export default Contributors