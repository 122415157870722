import HttpReq from "../../service/httpReq"

class SchemaRepo {

    static mgmtPath = 'api/mgmt/schema'

    static get(id, schemaConsumer, errorConsumer) {
        HttpReq.get(SchemaRepo.mgmtPath + "/" + id, schemaConsumer, errorConsumer)
    }

    static listTemplates(schemaConsumer, errorConsumer) {
        HttpReq.get(SchemaRepo.mgmtPath + "/templates", schemaConsumer, errorConsumer)
    }

    static save(createSchemaSpec, schemaConsumer, errorConsumer) {
        HttpReq.post(SchemaRepo.mgmtPath, createSchemaSpec, schemaConsumer, errorConsumer)
    }

    static saveTemplate(createSchemaSpec, schemaConsumer, errorConsumer) {
        HttpReq.post(SchemaRepo.mgmtPath + "/templates", createSchemaSpec, schemaConsumer, errorConsumer)
    }

    static update(schemaUpdateSpec, schemaConsumer, errorConsumer) {
        HttpReq.put(SchemaRepo.mgmtPath, schemaUpdateSpec, schemaConsumer, errorConsumer)
    }

    static delete(id, schemaConsumer, errorConsumer) {
        HttpReq.delete(SchemaRepo.mgmtPath + "/" + id, schemaConsumer, errorConsumer)
    }

    static kpis(successConsumer, errorConsumer) {
        HttpReq.get(SchemaRepo.mgmtPath + "/kpis", successConsumer, errorConsumer)
    }

}

export default SchemaRepo