import React from 'react';
import { Link } from 'react-router-dom';
import { Header } from 'semantic-ui-react'


function TermsAndConditions() {


    return <>
        <Header as="h2" textAlign='center'>Terms and Conditions of Use</Header>
        <div>
            Welcome to 3D StockPicker.<br />
            <br />
            This site is operated by 3D Stockpicker OOD. (registered in Bulgaria, Veselin Stoyanov 22, 1415 Sofia with the company ID Number 206979511).<br />
            <br />
            By accessing and using this Website, you agree to comply with and be bound by the following terms and conditions of use. If you do not agree to these terms, please refrain from using our Website.<br />
            <br />
            <Header as="h4">Acceptance of Terms</Header>
            By using the Website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.

            <Header as="h4">Use of Financial Data</Header>
            The financial data provided on this Website is for informational purposes only. While we strive for accuracy, we cannot guarantee the completeness or accuracy of the data. Users are encouraged to independently verify critical information.
            <Header as="h4">Limitation of Liability</Header>
            3D StockPicker is not liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to financial losses, arising out of or in connection with the use of the website.
            <Header as="h4"> Financial and Trading Disclaimer</Header>
            3D StockPicker is not responsible for any financial losses incurred by users based on the information provided on the website. Users are advised to conduct their own research and seek professional advice before making any financial or trading decisions.
            <Header as="h4">Intellectual Property</Header>
            All content on this Website, including financial data, logos, and other intellectual property, is owned by 3D StockPicker LTD. Users are granted a limited, non-exclusive license to access and use the content for personal and non-commercial purposes.
            <Header as="h4">User Obligations</Header>
            Users agree not to engage in any activities that may harm the Website, compromise security, or violate any applicable laws. Unauthorized use of the Website or its content may result in termination of access. <br />When using data from our database in public reports, screenshots, or presentations, users must cite https://www.3d-stockpicker.com/ as the source.
            <Header as="h4">Privacy Policy</Header>
            By using the Website, you agree to the terms outlined in our <Link to="/info/privacyPolicy">Privacy Policy</Link>. We collect and handle user information in accordance with our privacy practices.
            <Header as="h4">Disclaimers</Header>

            The Website is provided "as is" without any warranties, express or implied. We disclaim any liability for the accuracy, completeness, or timeliness of the financial data provided. Users use the Website at their own risk.

            <Header as="h4">Termination of Access</Header>

            We reserve the right to terminate or restrict access to the Website for any reason, without notice.

            <Header as="h4">Changes to Terms</Header>

            We may modify these terms and conditions at any time. Changes will be effective upon posting. Users will be notified of significant changes.

            <Header as="h4">Use of Third-Party Services</Header>
            We utilize third-party services, including but not limited to Google Analytics,
            to analyze the use of our website. By using the website, you acknowledge and agree to the
            collection and processing of data by these third-party services for the purposes outlined
            in our <Link to="/info/privacyPolicy">Privacy Policy</Link>.

            <Header as="h4">Contact Information</Header>

            For questions or concerns regarding these terms and conditions, please contact us at <b className='noTextWrap'>support@3d-stockpicker.com</b>.
        </div>
    </>
}


export default TermsAndConditions


