import React from 'react';
import { Link } from 'react-router-dom';
import { Header } from 'semantic-ui-react'


function Services() {
    return <>
        <Header as="h2" textAlign='center'>Services</Header>
        <div>
            Apart from the services that we provide as part of our differtent <Link to="/info/accountTypes">Account Types</Link> we also provide the following services:
            <br />
            <Header as="h4">Your own instance of the 3D StockPicker tool.</Header>
            This service will allow you to have your own data while taking advantage of our know-how about data collection.
            You will have your own data management tool. Some of the main advantages of having this tool are:
            <ul>
                <li>You have multiple types of reviews that can be used to ensure the quality of your data.</li>
                <li>The tool keeps track of any change so you know who and when changed any data.</li>
                <li>You can collect data in a very rich format that cannot be achieved with pure Excel sheets.</li>
                <li>You can read your data in a very efficient way. The tool has many useful filtering and calculation options.</li>
                <li>You have all of your data in one place.</li>
            </ul>
            <Header as="h4">We can enter data for you in your own 3D stockpicker tool or any other platform that you have.</Header>
            <p>
                Data entry can often be an expensive and challenging process to monitor.
                However, we recognize the importance of accurate data management and have both the expertise and dedicated team to execute it precisely.
                That's why we offer comprehensive data entry services, allowing our clients to maintain full control of their data without the complications
                of internal resource management, high salary concerns and data quality issues.
            </p>
            <Header as="h4">We can integrate the 3D StockPicker tool with your existing tools.</Header>
            <p>
                In today's dynamic business landscape, the integration of tools is paramount for streamlined operations. Recognizing the importance of synergy between different systems,
                we offer a robust integration service that seamlessly connects our tool with your existing suite of applications.
            </p>
        </div>
    </>
}


export default Services


