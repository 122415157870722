import React from 'react';
import { EditReportSchemaFormWrapper, NewReportSchemaFormWrapper } from './schema/schemaFormWrapper';
import { Container } from 'semantic-ui-react'
import { Tab } from 'semantic-ui-react'
import NotSavedModal from '../../../../../components/notSavedModal';
import SchemaUtil from '../../../../../utils/schemaUtil';
import PaneNameFactory from '../../../../../utils/tabPaneNameFactory';
import AuthService from '../../../../../service/auth';
import { ROLES } from '../../../../../constnats/user';
import { DoubleMap } from '../../../../../structures/doubleMap';

class Schemas extends React.Component {
    constructor(props) {
        super(props);

        this.setDirty = this.setDirty.bind(this);

        const { panes, schemaToPane } = this.getPanes();
        this.schemaToPane = schemaToPane
        this.panes = panes

        const paneId = schemaToPane.getValueByKey(
            this.props.tabsContext.getSchemaId())
        const activeIndex = paneId === null ? 0 : paneId

        this.state = {
            activeIndex,
            confirmationModal: <></>,
        }

        this.activeTabDirty = false
    }


    getPanes() {
        const schemas = this.props.company.schemas.sort(SchemaUtil.comapreSchemas)
        //TODO this must be real auth context
        const isUserBShepard = AuthService.hasRole(AuthService.getAuthContext(), ROLES.READ_ALL_MODELS_AND_REPORTS)
        const userId = AuthService.getUserId()

        const pane = (name, content) => {
            return {
                menuItem: name,
                render: () => <Tab.Pane>{content}</Tab.Pane>
            }
        }

        const schemaToPane = new DoubleMap()
        const panes = schemas.map((schema, i) => {
            schemaToPane.add(schema.id, i)
            let shemaTab
            if (schema.visibility !== "SYSTEM" &&
                (schema.branchType === "MAIN" || userId === schema.owner.id || isUserBShepard)) {
                shemaTab = <EditReportSchemaFormWrapper key={schema.id}
                    schema={schema}
                    onSchemaAdded={this.props.reloadSchemas}
                    onSchemaUpdated={this.props.reloadSchemas}
                    onSchemaDeleted={this.props.reloadSchemas}
                    setDirty={this.setDirty}
                />
            } else {
                shemaTab = <>Not enough privileges!</>
            }

            return pane(PaneNameFactory.forSchema(schema), shemaTab)
        })

        panes.push(pane("+", <NewReportSchemaFormWrapper key="create"
            onSaved={this.props.reloadSchemas}
            setDirty={this.setDirty}
            companyId={this.props.company.id}
            weight={schemas.length + 1}
        />))


        return { schemaToPane, panes }
    }

    setDirty(dirty) {
        this.props.setIsDirty(dirty)
        this.activeTabDirty = dirty
    }

    handleTabChange = (e, { activeIndex }) => {
        if (this.activeTabDirty === false) {
            this.setState({ activeIndex })
            this.props.tabsContext.setSchemaId(this.schemaToPane.getKeyByValue(activeIndex))
        } else {
            this.setState({
                confirmationModal: <NotSavedModal
                    onConfirm={() => this.setState({ confirmationModal: <></> })} />
            })
        }
    }

    render() {
        return <Container>
            {this.state.confirmationModal}
            <Tab panes={this.panes}
                className="centeredPanesCollection"
                activeIndex={this.state.activeIndex}
                onTabChange={this.handleTabChange}
                menu={{
                    pointing: true,
                    secondary: true,
                    attached: true,
                    tabular: true,
                    style: {
                        display: "flex",
                        paddingBottom: "6px"
                    }
                }} />
        </Container>
    }
}

export default Schemas