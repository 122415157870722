import { Form } from "semantic-ui-react";

function NumInput({ error, label, value, onChange, disabled = false }) {
    return (
        <Form.Input
            error={error}
            label={label}
            type='number'
            min='0'
            value={value}
            disabled={disabled}
            onChange={v => onChange(v.target.value)} />
    )
}

export default NumInput