import { default as DescriptorUtil } from "../descriptor/descriptorUtils"
import Utils from "../utils"
import Config from "../../constnats/config"

/**
 * @param {String or Number} correction
 *      The value of the field correction as sprcified its metadata. Required 
 * @param {Object} fieldDescriptor  Required
 * @returns true if the correction is valid, false otherwise
 */
function validCorrection(correction, fieldDescriptor) {


    if (Utils.isNumber(correction)) {
        if (typeof correction === 'string') {
            if (correction.includes(".")) {
                return false
            }
            if (correction.includes(",") && correction.length - correction.indexOf(",") > Config.FLOATING_POINT_PRECISION + 1) {
                return false
            }
        }

        if (Utils.toNumber(correction) !== 0 && DescriptorUtil.supportsCorrection(fieldDescriptor) === false) {
            return false
        }

    } else {
        return false
    }

    return true
}

export { validCorrection }