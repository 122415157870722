import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react'
import { getPeriod, getRelocationPath, getYearsComponent, toResourceProps } from '../../../../../utils/reportResourceUtil';
import { ResourceConfigTable, ResourceConfigRow } from './resourceConfigV2';

function EditResourceModalV2({ resource, onSave, onClose }) {

    const [resourceProps, setResourceProps] =
        useState(toResourceProps(resource.path))
    const [period, setPeriod] = useState(getPeriod(resource.path))
    const [year, setYear] = useState(getYearsComponent(resource.path).split("_")[1])
    const [originalOnly, setOriginalOnly] = useState(resource.path.includes("/original_only/"))

    const save = () => {
        const oldPath = resource.path
        const newPath = getRelocationPath(oldPath, year,
            period, originalOnly, resourceProps)
        if (oldPath === newPath) {
            return
        }

        onSave(newPath)
    }

    return <Modal size='fullscreen' open={true}  >
        <Modal.Content>
            <ResourceConfigTable rows={[
                <ResourceConfigRow
                    key={1}
                    originalFileName={resource.props.originalfilename}
                    year={year}
                    onYearChange={setYear}
                    period={period}
                    onPeriodChange={setPeriod}
                    resourceProps={resourceProps}
                    onResourcePropsChange={setResourceProps}
                    originalOnly={originalOnly}
                    onOriginalOnlyChange={setOriginalOnly} />
            ]} />
        </Modal.Content>
        <Modal.Actions>
            <Button size='mini' primary onClick={save}>
                Save
            </Button>
            <Button size='mini' onClick={onClose}>
                Close
            </Button>
        </Modal.Actions>
    </Modal>
}

export default EditResourceModalV2