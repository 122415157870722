
class SchemaUtil {

    /**
     * 
     * @param {*} s1 Schema Required 
     * @param {*} s2 Schema Required
     *       Required
     * @returns int
     */
    static comapreSchemas(s1, s2) {
        if (s1.weight === s2.weight) {
            return new Date(s1.createdOn) - new Date(s2.createdOn)
        }

        return s1.weight - s2.weight
    }
}

export default SchemaUtil