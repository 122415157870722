import SemanticDatepicker from "react-semantic-ui-datepickers"

function reflectZoneOffset(date) {

    const offsetInHours = (date.getTimezoneOffset() / 60) * -1
    const timeZoneAjustedDate = date.getHours() == offsetInHours ? date : new Date(new Date(date.getTime()).setHours(offsetInHours))
    timeZoneAjustedDate.setMinutes(0);
    timeZoneAjustedDate.setSeconds(0);

    return timeZoneAjustedDate
}

function DatePicker({ date, label, onChange }) {
    const _date = date || new Date()
    //Hack for BG

    return <SemanticDatepicker
        className="smallField"
        label={label} value={reflectZoneOffset(_date)}
        format='DD/MM/YYYY'
        onChange={(e, d) => {
            if (d.value !== null) {
                onChange(reflectZoneOffset(d.value))
            }
        }} />
}

export default DatePicker