import React from 'react';
import { Tab } from 'semantic-ui-react'
import SchemaUtil from '../../../../../../utils/schemaUtil';
import PaneNameFactory from '../../../../../../utils/tabPaneNameFactory';
import { DoubleMap } from '../../../../../../structures/doubleMap';
import SchemaLabelsReport from './schemaLabelAnalysis';

class LabelAnalysis extends React.Component {

    constructor(props) {
        super(props)

        const { panes, schemaToPane } = this.getPanes();
        this.schemaToPane = schemaToPane
        this.panes = panes

        const paneId = schemaToPane.getValueByKey(
            this.props.tabsContext.getSchemaId())
        const activeIndex = paneId === null ? 0 : paneId

        this.state = { activeIndex }
    }

    getPanes() {
        const schemaToPane = new DoubleMap()
        const panes = this.props.company.schemas
            .sort(SchemaUtil.comapreSchemas).map((schema, i) => {
                schemaToPane.add(schema.id, i)
                return {
                    menuItem: PaneNameFactory.forSchema(schema),
                    render: () => <Tab.Pane>
                        <SchemaLabelsReport key={schema.id} schema={schema} />
                    </Tab.Pane>
                }
            })

        return { panes, schemaToPane }
    }

    render() {
        return <Tab panes={this.panes}
            className="centeredPanesCollection"
            onTabChange={(e, { activeIndex }) => {
                this.setState({ activeIndex })
                this.props.tabsContext.setSchemaId(this.schemaToPane.getKeyByValue(activeIndex))
            }}
            activeIndex={this.state.activeIndex}
            menu={{
                pointing: true,
                secondary: true,
                attached: true,
                tabular: true,
                style: {
                    display: "flex",
                    paddingBottom: "6px"
                }
            }} />
    }
}


export default LabelAnalysis