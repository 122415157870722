class DoubleMap {
    constructor() {
        this.map = {}
    }
    add(key, val) {
        this.map[key] = val
    }

    getValueByKey(key) {
        const val = this.map[key]
        return val === undefined ? null : val
    }

    getKeyByValue(val) {
        const key = Object.keys(this.map).find(k => this.map[k] === val)
        return key === undefined ? null : key
    }
}

export { DoubleMap }