import React from "react";
import { Dropdown } from "semantic-ui-react";

export default function CategoryOptions({ id, openModal, deleteCategory }) {
    const ops = [
        getOp("Edit", () => openModal(id)),
        getOp("Delete", () => deleteCategory(id)),
    ];

    function getOp(name, onClick) {
        return {
            key: name,
            size: "mini",
            text: name,
            value: name,
            onClick: onClick,
        };
    }

    return (
        <Dropdown
            className="icon fieldOps"
            size="mini"
            icon="ellipsis vertical"
            options={ops}
            trigger={<></>}
        />
    );
}
