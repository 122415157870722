import { Checkbox, Input, Table, Dropdown } from "semantic-ui-react"
import { ReportType } from "../../../../../constnats/reportConstants"
import { PERIODS } from "../../../../../constnats/resourceConstants"
import { RESOURCE_CATEGORIES } from "../../../../../constnats/resourceConstants"
import { SUPPORTED_YEARS } from "../../../../../constnats/generalConstants"

function ResourceConfigTable({ rows }) {
    return <Table compact='very' >
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Original Name</Table.HeaderCell>
                <Table.HeaderCell>Year</Table.HeaderCell>
                <Table.HeaderCell>Period</Table.HeaderCell>
                <Table.HeaderCell>Financial Document Type</Table.HeaderCell>
                <Table.HeaderCell>Format</Table.HeaderCell>
                <Table.HeaderCell>Note</Table.HeaderCell>
                <Table.HeaderCell>Suffix</Table.HeaderCell>
                <Table.HeaderCell>Original Only</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {rows}
        </Table.Body>
    </Table >
}

function ResourceConfigRow({ originalFileName, resourceProps, year, onYearChange,
    period, onPeriodChange, onResourcePropsChange, originalOnly,
    onOriginalOnlyChange }) {

    const resourcePropsCopy = Object.assign({}, resourceProps)

    function onResourcePropsChangeLocal(prop, val) {
        resourcePropsCopy[prop] = val
        onResourcePropsChange(resourcePropsCopy)
    }

    return <Table.Row>
        <Table.Cell collapsing>
            <p>{originalFileName}</p>
        </Table.Cell>
        <Table.Cell collapsing>
            <Dropdown
                onChange={(a, b) => onYearChange(b.value)}
                value={year}
                placeholder='Year'
                options={SUPPORTED_YEARS.map(c => {
                    return { key: c, text: c, value: c }
                })}
                fluid selection search />
        </Table.Cell>
        <Table.Cell collapsing>
            <Dropdown
                onChange={(a, b) => onPeriodChange(b.value)}
                value={period}
                placeholder='Period'
                options={PERIODS.map(c => {
                    return { key: c, text: c, value: c }
                })}
                fluid selection search />
        </Table.Cell>
        <Table.Cell collapsing>
            <Dropdown
                onChange={(c, v) =>
                    onResourcePropsChangeLocal("finDocType", v.value)}
                value={resourcePropsCopy.finDocType}
                placeholder='Type'
                options={RESOURCE_CATEGORIES.map(c => {
                    return { key: c, text: c, value: c }
                })}
                fluid selection search />
        </Table.Cell>
        <Table.Cell collapsing>
            <Dropdown
                onChange={(c, v) =>
                    onResourcePropsChangeLocal("reportType", v.value)}
                value={resourcePropsCopy.reportType}
                placeholder='Type'
                options={ReportType.ALL.map(c => {
                    return { key: c, text: c, value: c }
                })}
                fluid selection search />
        </Table.Cell>
        <Table.Cell collapsing>
            <Checkbox checked={resourcePropsCopy.note == true}
                onChange={(evt, data) =>
                    onResourcePropsChangeLocal("note", data.checked)} />
        </Table.Cell>
        <Table.Cell collapsing>
            <Input placeholder='Suffix'
                value={resourcePropsCopy.suffix} onChange={v =>
                    onResourcePropsChangeLocal("suffix", v.target.value.replace(/[^A-Za-z0-9\s()]/g, ""))
                } />
        </Table.Cell>
        <Table.Cell collapsing>
            <Checkbox checked={originalOnly}
                onChange={(evt, data) => onOriginalOnlyChange(data.checked)} />
        </Table.Cell>
    </Table.Row>

}


export { ResourceConfigTable, ResourceConfigRow }