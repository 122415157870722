class CollectionUtils {

    /**
     * @param {[]} arr Required
     * @param {func} keyProvider  Required
     * @param {func} mergeFunc  Optional
     * @returns not null object of key values
     */
    static arrToMap(arr, keyProvider, mergeDuplicaes = (a, b) => b) {
        return arr.reduce((map, o) => {
            const key = keyProvider(o)
            if (map[key] != undefined) {
                o = mergeDuplicaes(map[key], o)
            }

            map[key] = o;
            return map;
        }, {})
    }

    /**
     * @param {*} collection Required
     * @returns true if empty, otherwice false
     */
    static isEmpty(collection) {
        return collection.length > 0
    }


    /**
     * NOTE: Mutates the original array 
     * @param {[]} arr Required
     * @param {func} valueProvider  Required
     * @returns not null soerded array
     */
    static sortArrByString(arr, valueProvider) {
        arr.sort((a, b) => {
            if (valueProvider(a) < valueProvider(b)) {
                return -1;
            }
            if (valueProvider(a) > valueProvider(b)) {
                return 1;
            }
            return 0;
        })

        return arr
    }

}

export default CollectionUtils