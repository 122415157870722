class Cache {

    static cache = new Map()

    static get(key, valueProvider, valueConsumer) {
        if (Cache.cache.has(key)) {
            valueConsumer(JSON.parse(JSON.stringify(Cache.cache.get(key))))
        } else {
            valueProvider(value => {
                Cache.cache.set(key, value)

                if (Cache.cache.size > 4) {
                    try {
                        console.log(Cache.cache.keys().next().value)
                        Cache.cache.delete(Cache.cache.keys().next().value)
                    } catch (error) {
                        Cache.clean()
                        console.error(error)
                    }
                }
                valueConsumer(JSON.parse(JSON.stringify(Cache.cache.get(key))))
            })
        }
    }

    static clear() {
        Cache.cache.clear()
    }

}

export default Cache