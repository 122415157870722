import { v4 as uuidv4 } from 'uuid';
import AuthService from "./auth";
import EventRepo from "../utils/repository/eventRepo";
import HttpReq from './httpReq';
import ObjCheck from '../utils/objCheck';
import Assert from '../utils/asserts';
import { NO_INTERNET_CONNECTION } from '../messages';
import { ROLES } from '../constnats/user';
import Alert from '../utils/alert';

const INCORRECT_LOGIN_DATA_MSG = "Incorrect username or password"
const FAILED_TO_ENTER_UPDATE_QA_PHASE = "Cannot enter QA review phase because it is not allowed in current state."


const LS_SESSION_KEY = "sessionId"


if (!localStorage.getItem(LS_SESSION_KEY)) {
    localStorage.setItem(LS_SESSION_KEY, uuidv4())
}

const sessionId = localStorage.getItem(LS_SESSION_KEY)

class Event {

    /**
     * @param {String} message Required
     * @param {String} stack Required
     * @param {String} issuer Required 
     */
    constructor(message, stack, issuer) {
        if (ObjCheck.isNullUndefinedOrEmpty(message)) {
            message = "NO MSG"
        }
        this.id = uuidv4()
        this.message = message + ""
        this.stack = stack + ""
        this.issuer = issuer
        this.user = AuthService.getFullName() || "Unknown"
        this.browser = navigator.userAgent + ""
        this.path = window.location.href + ""
        this.uiDate = Date.now()
        this.sessionId = sessionId
    }
}

class JsEventLogger {

    static maxErrors = 20
    static loggedErrors = 0
    static lsEventsKey = 'errorEvents'

    /**
     * @param {String} errorMsg Required
     * @param {String} stack Required
     * @param {String} issuer Required 
     */
    static log(errorMsg, stacktrace, issuer) {
        if (ObjCheck.isNullOrUndefined(errorMsg)) {
            Assert.fail("Unexpected error MSG:" + errorMsg)
            errorMsg = "NO MSG"
        }
        if (typeof errorMsg === 'string') {
            if (errorMsg.includes(HttpReq.FAILURE_MSG_EXPIRED_SESSION) ||
                errorMsg.includes(INCORRECT_LOGIN_DATA_MSG) ||
                errorMsg === NO_INTERNET_CONNECTION ||
                errorMsg === FAILED_TO_ENTER_UPDATE_QA_PHASE) {
                console.log("Ignoring log:" + errorMsg)
                return
            }

            errorMsg = errorMsg.substring(0, 3000)
            stacktrace = stacktrace.substring(0, 6000)
        }

        const event = new Event(errorMsg, stacktrace, issuer)
        if (event.browser.includes("Googlebot")) {
            console.log("Ignoring log:" + event)
            return
        }
        JsEventLogger._addLocalCopy(event)
        JsEventLogger._log(event, () => JsEventLogger._removeLocalCopy(event))
    }


    /**
     * Tries to flush all messages form local storages.
     */
    static flush() {
        const events = Object.values(JsEventLogger._getLocalCopies())

        for (const event of events) {
            if (typeof event.uiDate === 'string') {
                event.uiDate = Date.now() - 1000 * 60 * 60 * 24 * 60
            }
            JsEventLogger._log(event, () => JsEventLogger._removeLocalCopy(event))
        }
    }

    static _log(errorEvent, onSuccess) {
        if (JsEventLogger.loggedErrors < JsEventLogger.maxErrors) {
            JsEventLogger.loggedErrors++
            EventRepo.create(errorEvent, onSuccess,
                e => {
                console.warn("Faled to puch log event:" + JSON.stringify(errorEvent))
            })
        } else {
            console.warn("Too many errors, stop sending error events to the server")
        }
    }

    static _addLocalCopy(event) {
        const events = JsEventLogger._getLocalCopies()
        events[event.id] = event
        JsEventLogger._setLocalCopies(events)
    }

    static _removeLocalCopy(event) {
        const events = JsEventLogger._getLocalCopies()
        delete events[event.id]
        JsEventLogger._setLocalCopies(events)
    }

    static _getLocalCopies() {
        try {
            return JSON.parse(localStorage.getItem(JsEventLogger.lsEventsKey)) || {}
        } catch (e) {
            console.error("Unexpected value for js events")
            localStorage.removeItem(JsEventLogger.lsEventsKey)
            return {}
        }

    }

    static _setLocalCopies(events) {
        localStorage.setItem(JsEventLogger.lsEventsKey, JSON.stringify(events))
    }
}

setInterval(() => {
    JsEventLogger.loggedErrors = 0
    JsEventLogger.flush()
}, 1000 * 60 * 2)

window.addEventListener('error', e => {
    const { message, error } = e;

    JsEventLogger.log(message, error.stack, "Event Listener")
})

if (process.env.REACT_APP_REDIRECT_CONSOLE_ERROR === "true") {
    const originalConsoleErr = console.error
    console.error = (...args) => {
        if (AuthService.hasGlobalRole(ROLES.EMPLOYEE)) {
            Alert.error("Unexpected error has occurred. Please contact the administrator.")
        }

        JsEventLogger.log(args[0], "N/A", "ERROR LOG")
        for (const arg of args) {
            originalConsoleErr(arg)
        }
    }
}

export default JsEventLogger