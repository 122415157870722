class FiledsValidator {

    /**
     * @param {[]} propsToValidate
     * Array of properties that require validation, Required 
     * @param {Map} customValidations prop to custom validation for it
     */
    constructor(propsToValidate, customValidations = {}) {
        this.propsToValidate = propsToValidate
        this.customValidations = customValidations
    }

    /**
     * @returns Map of valid error props for all configured properties
     */
    getValidErrorProps() {
        const props = {}
        for (const prop of this.propsToValidate) {
            props[this.getErrorPropName(prop)] = false
        }

        return props
    }

    /**
     * @param {String} propName Required 
     * @param {Map} currentErrorProps Required
     * @returns not-null new Map of error properties where the propName is marked as valid. 
     * NOTE: If the propName is not part of the current FiledsValidator instance
     * congiguration the method will result in no-op
     */
    dicardErrorIfExists(propName, currentErrorProps) {
        const currentErrorPropsCopy = Object.assign({}, currentErrorProps)
        currentErrorPropsCopy[this.getErrorPropName(propName)] = false

        return currentErrorPropsCopy
    }

    /**
     * @param {String} propName
     * A prop name to get a corresponding error prop fors 
     * @returns not-null
     */
    getErrorPropName(propName) {
        return propName + "_validation"
    }

    /**
     * @param {Map} objectToValidate An object that contains the properties that needs to be validated 
     * @returns not-null
     */
    validate(objectToValidate) {
        const objectProps = Object.keys(objectToValidate).filter(p => this.propsToValidate.includes(p))
        const newErrorProps = {}
        let allValid = true;
        for (const propName of objectProps) {
            const validationRes = this._validateProp(propName, objectToValidate)
            const propValid = validationRes.valid
            newErrorProps[this.getErrorPropName(propName)] = propValid ? false : { content: validationRes.msg }
            allValid = allValid && propValid
        }

        return {
            valid: allValid,
            newErrorProps: newErrorProps
        }
    }

    _validateProp(propName, objectToValidate) {
        const value = objectToValidate[propName]
        const customValidation = this.customValidations[propName]
        let valid;
        let msg;
        if (customValidation) {
            valid = customValidation.validate(value)
            msg = customValidation.msg
        } else {
            const val = objectToValidate[propName]
            if (Array.isArray(val)) {
                valid = val.length > 0
            } else if (Number.isInteger(parseInt(val, 10))) {
                valid = true
            } else {
                valid = val && val.trim().length > 0
            }
            msg = "The field is required"
        }

        return { valid: valid, msg: msg }
    }

}

export default FiledsValidator