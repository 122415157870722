import { useEffect, useState } from "react";
import ResourceRepo from "../../../../../utils/repository/resourceRepo";
import { Button, Checkbox, Dropdown, Header, Icon, Table } from "semantic-ui-react";
import Loader from "../../../../../components/loader";
import { ResourceProps, toResourceProps, sortResourceInfos } from "../../../../../utils/reportResourceUtil";
import { UploadFormV2Modal } from "./uploadFormV2";
import EditResourceModalV2 from "./editResourceModalV2";
import Alert from "../../../../../utils/alert";
import ConfirmationModal from '../../../../../components/confirmationModal'

function format(resources, documentsOnly) {
    const map = {}
    for (const resource of resources) {
        const path = resource.path;
        const resourceProps = toResourceProps(path)
        if (documentsOnly && resourceProps.fileExtension === 'png') {
            continue
        }
        const props = resource.props
        const year = props.year
        const quoter = props.quoter
        const awsUrl = resource.serverUrl + "/" + path
        if (map[year] === undefined) {
            map[year] = {}
        }

        if (map[year][quoter] === undefined) {
            map[year][quoter] = []
        }
        const originalOnly = path.includes("/original_only/")
        map[year][quoter].push({
            path, name: ResourceProps.userFriendlyName(resourceProps, true),
            year, quoter, awsUrl, lastModified: resource.lastModified,
            originalOnly, fileExtension: resourceProps.fileExtension
        })
    }

    const years = Object.keys(map)
    for (let year of years) {
        const quters = Object.keys(map[year])
        for (let quoter of quters) {
            sortResourceInfos(map[year][quoter])
        }
    }

    return map
}

// function DriveUrl({ filePath }) {
//     const [driveUrl, setDriveUrl] = useState(null);

//     return driveUrl === null ? <Button size="mini" onClick={() => {
//         ResourceRepo.get(filePath, resource => {
//             setDriveUrl(resource.props.googledrivelink)
//         })
//     }}>Load Drive Url</Button> : <a href={driveUrl}
//         target="_blank" rel="noopener noreferrer">Preview</a>
// }

function CompanyResources({ company }) {
    const [loading, setLoading] = useState(true);
    const [resources, setResources] = useState(null);
    const [modal, setModal] = useState(null);
    const [documentsOnly, setDocumentsOnly] = useState(true);

    const refreshResources = () => {
        setLoading(true)
        ResourceRepo.listCompanyResources(company.id, resources => {
            setResources(resources)
            setLoading(false)
        })
    }

    useEffect(() => {
        refreshResources()
    }, [company.id]);

    const resourceRows = () => {
        const resourcesMap = format(resources, documentsOnly)

        let currenYearIdx

        return Object.keys(resourcesMap).map((year, yearIdx) => {
            const yearResources = resourcesMap[year]
            return Object.keys(yearResources).map((q, qIdx) => {

                return yearResources[q].map((resource, resourceIdx) => {
                    const quoterReports = yearResources[q]
                    const row = <Table.Row key={resource.path}>
                        {currenYearIdx !== yearIdx && <>
                            <Table.Cell rowSpan={
                                Object.keys(yearResources)
                                    .map(q => yearResources[q].length)
                                    .reduce((a, b) => a + b, 0)} >
                                {year}
                            </Table.Cell>
                        </>}
                        {resourceIdx === 0 && <Table.Cell rowSpan={quoterReports.length}>
                            {resource.quoter}
                        </Table.Cell>}
                        <Table.Cell>
                            <a href={resource.awsUrl}
                                target="_blank" rel="noopener noreferrer" >
                                {resource.name}</a>
                        </Table.Cell>
                        <Table.Cell>
                            {resource.fileExtension === 'pdf' && <a href={"https://drive.google.com/viewerng/viewer?url=" + resource.awsUrl} target="_blank" rel="noopener noreferrer">Preview</a>}
                        </Table.Cell>
                        <Table.Cell>
                            {resource.originalOnly ? "Yes" : "No"}
                        </Table.Cell>
                        <Table.Cell>
                            {new Date(resource.lastModified).toLocaleString()}
                        </Table.Cell>
                        <Table.Cell>
                            <Icon className='cursorPointer' name="edit"
                                onClick={() => openEditModal(resource.path)} />
                        </Table.Cell>
                        <Table.Cell>
                            <Icon className='cursorPointer' name="delete"
                                onClick={() => {
                                    setLoading(true)
                                    ResourceRepo.delete(resource.path, () => {
                                        const resCopy = resources.slice()
                                        setResources(resCopy.filter(r => r.path !== resource.path))
                                        setLoading(false)
                                    })
                                }} />
                        </Table.Cell>
                    </Table.Row>

                    currenYearIdx = yearIdx

                    return row
                })
            })
        })
    }

    const openUploadModal = () => {
        setModal(<UploadFormV2Modal companyId={company.id} onClose={hasChanges => {
            setModal(null)
            if (hasChanges === true) {
                refreshResources()
            }
        }} />)
    }

    const openEditModal = path => {
        ResourceRepo.get(path, resource => {
            setModal(<EditResourceModalV2 resource={resource}
                onClose={() => setModal(null)}
                onSave={newPath => {
                    ResourceRepo.relocateReportResources({
                        oldPath: resource.path, newPath
                    }, () => {
                        setModal(null)
                        refreshResources()
                    })
                }} />)
        })

    }

    const content = () => {
        return <div>
            All<Checkbox slider checked={documentsOnly} className="modeSlider"
                onChange={(e, data) => setDocumentsOnly(data.checked)} />Documents Only
            <Button floated="right" size="mini" onClick={openUploadModal}>Upload Resources</Button>
            {modal}
            {resources.length === 0 ? <Header as="h5" textAlign='center'>No Uploaded Resources</Header> :
                <Table selectable compact='very' >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Year (Original)</Table.HeaderCell>
                            <Table.HeaderCell>Quarter</Table.HeaderCell>
                            <Table.HeaderCell>File Server Link</Table.HeaderCell>
                            <Table.HeaderCell>Google Drive</Table.HeaderCell>
                            <Table.HeaderCell>Has Prev. Year Data</Table.HeaderCell>
                            <Table.HeaderCell>Last Modified</Table.HeaderCell>
                            <Table.HeaderCell>Edit</Table.HeaderCell>
                            <Table.HeaderCell>Delete</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {resourceRows()}
                    </Table.Body>
                </Table>
            }
            <DelateOptions companyId={company.id} onDeleted={refreshResources} />
        </div>
    }

    return loading ? <Loader /> : content()
}



function DelateOptions({ companyId, onDeleted }) {
    const [modal, setModal] = useState(null);
    const deleteFunc = minutes => {
        setModal(<ConfirmationModal onConfirm={() => {
            ResourceRepo.deleteLatestUploaded(companyId, minutes, () => {
                Alert.success("The operation was executes successfully.")
                onDeleted()
            })
        }}
            onCancel={() => setModal(null)}
            msg={"Are you sure that you want the delete the resources that have been uploaded during the last " + minutes + " minutes"} />)

    }

    const options = [
        {
            key: '60', text: 'Delete All From Last Hour',
            onClick: () => deleteFunc(60)
        },
        {
            key: '1440', text: 'Delete All Last 24 Hours',
            onClick: () => deleteFunc(1440)
        },
    ]

    return <Button.Group color='red' size="mini" floated="right" >
        <Button onClick={() => deleteFunc(15)}>Delete All From Last 15 mins</Button>
        {modal}
        <Dropdown
            className='button icon'
            floating
            options={options}
            trigger={<></>}
        />
    </Button.Group>
}


export default CompanyResources