import ObjCheck from "./objCheck"
import Alert from "./alert"

class Utils {

    /**
     * @param {any} data If data is of type string its all commas in is are replaced with dots.
     * //NOTE: ISSUES with tailing zeros
     * @returns boolean
     */
    static isNumber(data) {
        const normalizedData = Utils._commaToDot(data)

        return !isNaN(normalizedData)
            && Number.isInteger(parseInt(normalizedData, 10))
            && Number(normalizedData) + "" === normalizedData + ""
    }

    //NOTE: ISSUES with tailing zeros
    static isNumberStandard(data) {
        return !isNaN(data)
            && Number.isInteger(parseInt(data, 10))
            && Number(data) + "" === data + ""
    }

    /**
     * @param {any} data Data to be converted to number. The data MUST be convertabel. Requred
     * @param {boolean} assert True if the asserts are enabled. Optional
     * @returns {Number} the number
     */
    static toNumber(data, assert = true) {
        if (!Utils.isNumber(data)) {
            if (assert) {
                Alert.error("Failed to convert " + data + " to number. Please do not save the data and contact the administrator.")
            }
            throw new Error("Failed to convert " + data + " to number")
        }

        return Number(Utils._commaToDot(data))
    }

    static isNonStirctConvertableToNumber(data) {
        return Utils.isNumber(data) || ObjCheck.isNullUndefinedEmptyOrDash(data)
    }

    /**
     * @param {any} data Data to be converted to non-strictly to a number. The data MUST be convertabel. Requred
     * @returns {Number}  the number
     */
    static toNumberNonStrict(data) {
        const number = ObjCheck.isNullUndefinedEmptyOrDash(data) ? 0 : Number(Utils._commaToDot(data))

        if (isNaN(number)) {
            Alert.error("Failed to convert (non-strict) " + data + " to number. Please do not save the data and contact the administrator.")
            throw new Error("Failed to convert " + data + " to number")
        }

        return number
    }

    static findEntry(entries, descriptorId) {
        return entries.find(e => e.descriptorId() === descriptorId)
    }

    /**
     * @param {Number} number Number to apply correction on. Required
     * @param {any} correction Number to correct with. NOTE: if invalid the corection is not applied Required
     * @param {int} precision Required
     * @returns {Number} corrected number
     */
    static applyCorrection(number, correction, precision) {
        return Number((Utils.isNumber(correction) ? number + Utils.toNumber(correction) : number).toFixed(precision))
    }

    /**
     * @param {number} number Required 
     * @param {number} precision Required 
     * @returns {string} not-null
     */
    static toReadMode(number, precision) {
        return String(Number(number.toFixed(precision))).replace(".", ",")
    }

    static _commaToDot(data) {
        return typeof data === 'string' ? data.replace(",", ".") : data
    }

    /**
     * @param {{}} urlPositionToParam A map of url param position (number) to 
     * its new value (any). Required 
     * 
     * NOTE: If the new param value is equal to null the param that is on the
     * corresponding position is deleted.
     */
    static updateUrlPathParam(urlPositionToParam) {
        let urlParams = window.location.pathname.split('/')
        for (const [position, urlParam] of Object.entries(urlPositionToParam)) {
            if (urlParams[position] !== String(urlParam)) {
                urlParams[position] = urlParam
            }
        }

        urlParams = urlParams.filter(p => p !== null)

        window.history.replaceState(null, "", urlParams.join('/'))
    }
}

export default Utils