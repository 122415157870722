import HttpReq from "../../service/httpReq"
import Cache from "./cache"


function cacheKey(schemaId) {
    return "ReprtsRepo" + schemaId
}

//TODO Rename to ReprtsRepo
class GlobalReportsRepo {
    static publicPath = 'api/reports'

    static listBySchema(schemaId, reportConsumer, errorConsumer) {
        Cache.get(cacheKey(schemaId), func => {
            HttpReq.get(GlobalReportsRepo.publicPath + "?schemaId=" + schemaId, func, errorConsumer)
        }, reportConsumer)
    }

}

export default GlobalReportsRepo