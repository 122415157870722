import React from 'react';
import { Header } from 'semantic-ui-react'


function PrivacyPolicy() {


    return <>
        <Header as="h2" textAlign='center'>Privacy Policy</Header>
        <div>

            Welcome to 3D StockPicker. This Privacy Policy outlines how we collect, use, and protect your personal information. By using the Website, you agree to the terms described in this policy.
            <br />
            <Header as="h4">Information We Collect</Header>
            We may collect the following types of personal information:
            <br />
            Contact Information: Name, email address, and other contact details.
            <br />
            Account Information: Usernames, passwords, and other account-related information.
            <br />
            Usage Data: Information about your interactions with the Website, including pages visited and actions taken.
            <Header as="h4">How We Use Your Information</Header>
            We use the collected information for the following purposes:
            <br />
            To provide and maintain the Website.
            <br />
            To personalize user experience and improve our services.
            <br />
            To send periodic emails regarding updates, news, or related information.
            <Header as="h4">Cookies and Tracking Technologies</Header>
            We use cookies and similar technologies to enhance user experience and gather information about usage patterns. Users can control cookie preferences through their browser settings.
            <Header as="h4">Third-Party Services</Header>
            We may use third-party services, such as Google Analytics, to collect, monitor, and analyze user behavior on our website and to improve its user experience.
            These services may collect information sent by your browser as part of a web page request, such as cookies or your IP address.
            <Header as="h4">Data Sharing</Header>
            We may share non-personal information with third-party service providers for the purpose of improving our website and user experience. These third-party service providers are obligated not to disclose or use the information for any other purpose.
            <Header as="h4">Data Security</Header>
            We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is entirely secure.
            <Header as="h4">Your Choices</Header>
            You can choose not to provide certain information, but this may limit your ability to access certain features of the Website. You can also unsubscribe from promotional emails at any time.
            <Header as="h4">Children's Privacy</Header>
            The Website is not intended for users under the age of 18. We do not knowingly collect personal information from children.
            <Header as="h4">Changes to the Privacy Policy</Header>
            We reserve the right to update this Privacy Policy at any time. Changes will be effective upon posting, and users will be notified of significant updates.
            <Header as="h4">Contact Information</Header>
            For questions or concerns regarding these terms and conditions, please contact us at <b className='noTextWrap'>support@3d-stockpicker.com</b>.
        </div>
    </>
}


export default PrivacyPolicy


