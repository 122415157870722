import HttpReq from "./httpReq";

class MailService {

    static publicApiKey = 'api/public/emails'
    static authContext = 'user'

    static sendUserRequest(requestData, onSuccess, onError) {
        HttpReq.post(MailService.publicApiKey + "/client-requests", requestData, onSuccess, onError)
    }
}

export default MailService