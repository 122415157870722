import Period from "./horiziontalPeriodCalculator"
import { ReportField, ReportFillingType, ReportType } from "../constnats/reportConstants"
import ReportUtils from "./reportUtils"
import CollectionUtils from './collections'

const DUPLICATED_REPORTS_MSG = "The table contains at least two reports with same year, period, version and format."

//TODO Remove/Rename
class ReportsUtils {

    /**
     * @param {[]} dynamicFlatDescriptors Required 
     */
    constructor(dynamicFlatDescriptors) {
        this._dynamicFlatDescriptors = dynamicFlatDescriptors
    }

    static sort(reports, desc = false) {
        reports.sort((a, b) => {
            let periodCompDiff
            //TODO Refactore by removing the nested IFs
            if (a[ReportField.YEAR] === b[ReportField.YEAR]) {
                periodCompDiff = Period.compare(a[ReportField.PERIOD], b[ReportField.PERIOD])
                if (periodCompDiff === 0) {
                    periodCompDiff = ReportsUtils._compareByFilingType(a[ReportField.TYPE], b[ReportField.TYPE])
                    if (periodCompDiff === 0) {
                        periodCompDiff = ReportsUtils._compareByReportType(a[ReportField.REPORT_TYPE], b[ReportField.REPORT_TYPE])
                    }
                }
            } else {
                periodCompDiff = a[ReportField.YEAR] - b[ReportField.YEAR]
            }

            return desc === true ? periodCompDiff * -1 : periodCompDiff
        })

        return reports
    }

    /**
    * @param {[]} knownReports Known reports as stored in the database. Required 
    * @param {func} reportDuplicatesHandler Required
    * @param {boolean} enableAsserts Optional 
    * @returns {[]} not-null array that does not contain more than one report for same period and year. 
    */
    getCalculatedReports(knownReports, reportDuplicatesHandler, enableAsserts) {
        return Period.getCalculatedReports(knownReports,
            this._dynamicFlatDescriptors, reportDuplicatesHandler, enableAsserts)
    }

    static _compareByFilingType(filingTypeA, filingTypeB) {
        return ReportsUtils._compare(filingTypeA, filingTypeB, ReportFillingType.PERSISTENT_TYPES)
    }

    static _compareByReportType(reportTypeA, reportTypeB) {
        return ReportsUtils._compare(reportTypeA, reportTypeB, ReportType.ALL)
    }

    static _compare(a, b, collection) {
        for (const type of collection) {
            if (a === type && b === type) {
                return 0
            } else if (a === type) {
                return -1
            } else if (b === type) {
                return 1
            }
        }
    }

}

export { ReportsUtils, DUPLICATED_REPORTS_MSG }
