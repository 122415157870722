import HttpReq from "../../service/httpReq"
import Cache from "./cache"
import { PathFactory } from "./utils"

class TableUpdatesRepo {
    static prefix = 'api/mgmt/table-updates'

    static get(id, updateConsumer, errorConsumer) {
        HttpReq.get(PathFactory.create([TableUpdatesRepo.prefix, id]), updateConsumer, errorConsumer)
    }

    static getChanges(id, successConsumer, errorConsumer) {
        HttpReq.get(PathFactory.create([TableUpdatesRepo.prefix, id, "changes"]), successConsumer, errorConsumer)
    }

    static find(spec, updateConsumer, errorConsumer) {
        let params
        if (spec.phases) {
            params = new URLSearchParams(spec)
            params.delete("phases")
            for (const phase of spec.phases) {
                params.append('phases', phase)
            }
        } else {
            params = new URLSearchParams(spec)
        }

        const cacheKey = `${this.prefix}-${JSON.stringify(spec)}`
        Cache.get(cacheKey, func => HttpReq.get(PathFactory.create([TableUpdatesRepo.prefix], params), func, errorConsumer),updateConsumer)
    }

    static create(spec, updateConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.post(PathFactory.create([TableUpdatesRepo.prefix]), spec, updateConsumer, errorConsumer)
    }

    static update(spec, updateConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.put(PathFactory.create([TableUpdatesRepo.prefix]), spec, updateConsumer, errorConsumer)
    }

    static delete(updateId, successConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.delete(PathFactory.create([TableUpdatesRepo.prefix, updateId]), successConsumer, errorConsumer)
    }

    static markAsReadyForPeerReview(updateId, updateConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.put(PathFactory.create([TableUpdatesRepo.prefix, updateId],
            new URLSearchParams({ action: "requestPeerReview" })), {}, updateConsumer, errorConsumer)
    }

    static markAsReadyForQaReview(updateId, updateConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.put(PathFactory.create([TableUpdatesRepo.prefix, updateId],
            new URLSearchParams({ action: "requestQaReview" })), {}, updateConsumer, errorConsumer)
    }

    static requestReviewsRevision(updateId, updateConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.put(PathFactory.create([TableUpdatesRepo.prefix, updateId],
            new URLSearchParams({ action: "requestReviewsRevision" })), {}, updateConsumer, errorConsumer)
    }

    static apply(updateId, updateConsumer, errorConsumer) {
        HttpReq.put(PathFactory.create([TableUpdatesRepo.prefix, updateId],
            new URLSearchParams({ action: "apply" })), {}, update => {
                Cache.clear()
                updateConsumer(update)
            }, errorConsumer)
    }

    static getReview(reviewId, reviewConsumer, errorConsumer) {
        HttpReq.get(PathFactory.create([TableUpdatesRepo.prefix, "reviews", reviewId]), reviewConsumer, errorConsumer)
    }

    static getReviewChanges(reviewId, respConsumer, errorConsumer) {
        HttpReq.get(PathFactory.create([TableUpdatesRepo.prefix, "reviews", reviewId, "changes"]), respConsumer, errorConsumer)
    }

    static startReview(spec, reviewConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.post(PathFactory.create([TableUpdatesRepo.prefix, "reviews"]), spec, reviewConsumer, errorConsumer)
    }

    static updateReview(spec, reviewConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.put(PathFactory.create([TableUpdatesRepo.prefix, "reviews"]), spec, reviewConsumer, errorConsumer)
    }

    static deleteReview(reviewId, successConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.delete(PathFactory.create([TableUpdatesRepo.prefix, "reviews", reviewId]), successConsumer, errorConsumer)
    }

    static kpis(successConsumer, errorConsumer) {
        HttpReq.get(PathFactory.create([TableUpdatesRepo.prefix, "kpis"]), successConsumer, errorConsumer)
    }

    static performanceKpis(params, successConsumer, errorConsumer) {
        HttpReq.get(
            PathFactory.create([TableUpdatesRepo.prefix, "kpis", "performance"],
                new URLSearchParams(params))
            , successConsumer, errorConsumer)
    }

    static reviewKpis(successConsumer, errorConsumer) {
        HttpReq.get(PathFactory.create([TableUpdatesRepo.prefix, "reviews", "kpis"]), successConsumer, errorConsumer)
    }

    static performanceKpisV2(params, successConsumer, errorConsumer) {
        HttpReq.get(
            PathFactory.create([TableUpdatesRepo.prefix, "kpis", "performance_v2"
            ], new URLSearchParams(params)), successConsumer, errorConsumer)
    }


    static reviewPerformanceKpis(params, successConsumer, errorConsumer) {
        HttpReq.get(
            PathFactory.create([TableUpdatesRepo.prefix, "reviews", "kpis", "performance"
            ], new URLSearchParams(params)), successConsumer, errorConsumer)
    }

}

export default TableUpdatesRepo