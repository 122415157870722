import React from 'react'
import { Grid, Icon, Menu } from 'semantic-ui-react'
import ScaleUtil from '../../../../../../utils/scaleUtil';
import { CalculationExpUtil } from '../../../../../../utils/descriptor/calculationExp';
import Utils from '../../../../../../utils/descriptor/descriptorUtils';
import { DescriptorProps } from '../../../../../../constnats/descriptor';

const props = {
    id: { name: "ID", valMap: id => id.replace("custom__", "").slice(0, 8) },
    [DescriptorProps.VIEW_SCALE]: { name: "V. Scale", valMap: s => ScaleUtil.numToTextRepresentation(s, true) },
    [DescriptorProps.SCALABLE]: {
        name: "Scalable", valMap: s => s ? <Icon name="checkmark" /> : ""
    },
    [DescriptorProps.DATA_CALC_TYPE]: { name: "Calc. Type", valMap: v => v },
    [DescriptorProps.CALCULATION]: {
        name: "Calc",
        valMap: v => v === CalculationExpUtil.NO_OP_CALC ? "" : <Icon name="checkmark" />,
        getRelatedRelatedDescriptors: descriptor =>
            Utils.hasCalculation(descriptor) ?
                CalculationExpUtil.getIncludedIds(descriptor[DescriptorProps.CALCULATION]) : []

    },
    [DescriptorProps.SECONDARY_CALCULATION]: {
        name: "S. Calc",
        valMap: v => v === CalculationExpUtil.NO_OP_CALC ? "" : <Icon name="checkmark" />,
        getRelatedRelatedDescriptors: descriptor =>
            Utils.hasSecondaryCalculation(descriptor) ?
                CalculationExpUtil.getIncludedIds(descriptor[DescriptorProps.SECONDARY_CALCULATION]) : []

    },
    [DescriptorProps.IMPORTANCE]: { name: "Imp.", valMap: v => v }
}

function DescriptorPropsColumns({ highlightedRows, setHighlightedRows, flatDescriptors }) {

    return Object.keys(props).map(p => <Grid.Column key={p} textalign='left' className='descProps'>
        <Menu fluid vertical >
            <table className='column-table'>
                <tbody>
                    {flatDescriptors.map((d, idx) => {
                        return (
                            <tr key={idx} className='column-table-row horizontals'>
                                {d.dynamicField ? <td style={highlightedRows.includes(idx + 1) ? Object.assign({}, d.style, { backgroundColor: "#ffff99" }) : d.style}
                                    onMouseEnter={() => {
                                        const getRelatedRelatedDescriptorsFunc = props[p].getRelatedRelatedDescriptors
                                        const descriptorIds = getRelatedRelatedDescriptorsFunc === undefined ?
                                            [] : getRelatedRelatedDescriptorsFunc(d)

                                        if (descriptorIds.length > 0) {
                                            setHighlightedRows(descriptorIds.map(id => flatDescriptors.findIndex(d => d.id === id) + 1))
                                        }
                                    }}
                                    onMouseLeave={() => setHighlightedRows([])}>{props[p].valMap(d[p])}</td> : <td style={d.style}>{idx === 0 ? props[p].name : <></>}</td>}
                            </tr>
                        )
                    })}
                </tbody>

            </table>
        </Menu>
    </Grid.Column>)

}


export default DescriptorPropsColumns