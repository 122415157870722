import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Checkbox, Segment, Header, Label, FormField } from 'semantic-ui-react'
import AuthService from '../../../service/auth';
import FiledsValidator from '../../../utils/validation/fieldsValidator';
import Loader from '../../../components/loader';
import Alert from '../../../utils/alert';

class RegistrationForm extends React.Component {
    constructor(props) {
        super(props);
        var emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
        this.filedsValidator = new FiledsValidator(["fullName", "password", "passwordConfirmation", "email"],
            {
                "email": { validate: val => val.match(emailRegex) && val.length < 51 && val.length > 3, msg: "The email is invalid." },
                "fullName": { validate: val => val.length < 51 && val.length > 3, msg: "It must be shorter than 50 symbols and longer than 3" },
                "password": { validate: val => val.length > 5, msg: "It must be longer than 5 symbols" },
                "passwordConfirmation": {
                    validate: val => val === this.state.registrationData.password,
                    msg: "The password does not match"
                }
            })

        this.state = {
            registrationData: {
                fullName: "",
                password: "",
                passwordConfirmation: "",
                email: "",
                termsAccepted: false,
                pendingEamilConfirmation: false
            },
            termsNotAccepted: false,
            loading: false,
            propsValidation: this.filedsValidator.getValidErrorProps(),
        }
    }

    onPropChange(newVal, prop) {
        const registrationDataCopy = Object.assign({}, this.state.registrationData)
        registrationDataCopy[prop] = newVal
        this.setState({ registrationData: registrationDataCopy })
        this.setState({ propsValidation: this.filedsValidator.dicardErrorIfExists(prop) })
    }

    getField(forProp, label, type = "text") {
        return <Form.Field>
            <Form.Input
                input={type}
                label={label}
                placeholder={label}
                onChange={v => this.onPropChange(v.target.value, forProp)}
                value={this.state.registrationData[forProp]}
                error={this.state.propsValidation[this.filedsValidator.getErrorPropName(forProp)]} />
        </Form.Field>
    }

    register() {
        const registrationData = this.state.registrationData
        this.setState({ termsNotAccepted: !registrationData.termsAccepted })
        if (this.validate() && registrationData.termsAccepted) {
            this.setState({ loading: true })
            AuthService.register(registrationData, res => {
                if (res.status === "SUCCESS") {
                    this.setState({ loading: false, pendingEamilConfirmation: true })
                } else {
                    this.setState({ loading: false })
                    Alert.error(res.msg)
                }
            }

            )
        }
    }

    validate() {
        const validationRes = this.filedsValidator.validate(this.state.registrationData)
        this.setState({ propsValidation: validationRes.newErrorProps })
        return validationRes.valid
    }

    regsrationForm() {
        const state = this.state
        return <Form>
            {this.getField("fullName", "Full Name")}
            {this.getField("email", "Email")}
            {this.getField("password", "Password", "password")}
            {this.getField("passwordConfirmation", "Confirm Password", "password")}
            <FormField>
                <Checkbox
                    checked={state.registrationData.termsAccepted}
                    label="I accept the "
                    onChange={(evt, data) => {
                        this.onPropChange(data.checked, "termsAccepted")
                        this.setState({ termsNotAccepted: false })
                    }}
                />
                <Link to="/info/privacyPolicy"> Privacy Policy</Link> and the
                <Link to="/info/termsAndConditions"> Terms of Use</Link>.
                {state.termsNotAccepted &&
                    <Label pointing prompt>
                        You must accept the Privacy Policy and the Terms of Use in order to proceed with the registration.
                    </Label>}
            </FormField>
            <Button size='mini' onClick={() => this.register()} primary>Register</Button>
            <Link className='registrationLoginLink' to="/user/login">Log In</Link>
        </Form>
    }

    render() {
        const state = this.state
        return state.pendingEamilConfirmation ? <>
            <Header as="h4" textAlign='center'>Your registration was successful.</Header>
            We have sent an email with a confirmation link to {state.registrationData.email}.
            Please allow 5-10 minutes for this message to arrive.
        </> :
            <Segment className='registrationForm'>
                <Header as="h4" textAlign='center'>Registration</Header>
                {state.loading ? <Loader msg="Registration in progress." /> : this.regsrationForm()}
            </Segment>
    }
}

export default RegistrationForm


