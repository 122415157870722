import KeyValue from "../../../structures/keyValePair"
import { ReportFillingType, ReportType } from "../../../constnats/reportConstants"
import ScaleUtil from "../../../utils/scaleUtil"

const LABEL_FORMATS = {
    DEFAULT: 'As reported',
    STANDARDIZED: 'Standardized'
}

class ConfigProps {

    static HEADER_COLUMN_FONT_SIZE = "headerColumnFontSize"
    static FLOATING_POINT_PRECISION = "floatingPointPrecision"
    static ADD_TRAILING_ZEROS = "addTrailingZeros"
    static SHOW_NOTES = "showNotes"
    static SHOW_3D_RATIOS = "show3dRatios"
    static DIFF_DETECTION_THRESHOLD = "diffDetectionThreshold"
    static DIFF_DETECTION_IGNORE_ZERO_DASH = "diffDetectionIgnoreZeroDash"
    static HEADER_COLUMN_WIDTH = "headerColumnWidth"
    static EQUAL_ROWS_HIGH = "equalRowsHeight"
    static SCALE_COLUMN = "scaleColumn"
    static HORIZONTALS = "horizontals"
    static VERTICALS = "verticals"
    static HIDE_EMPTY_ROWS = "hideEmptyRows"
    static SHOW_IMPORTANT_ROWS_ONLY = "showImportantRowsOnly"

    static PREFFERED_SCALE = "prefferedScale"
    static NUMBER_LOCALE = "numberLocale"

    static REPORTS_FORMAT = "reportsFormat"

    static CATEGORY_BASED_MODE = 'categoryBasedMode'

    static ONLY_MATCHING = "onlyMatching"
    static HIDE_STANDALONE_CALCED_CELLS = "hideStandaloneCalculatedCells"

    static YEARS_FILTER = "yearsFilter"
    static PERIODS_FILTER = "periodsFilter"
    static REPORT_TYPES_FILTER = "reportTypesFilter"
    static FILLING_TYPE_FILTER = "fillingTypeFilter"
    static ORIGINAL_VERSION_CONDITIONAL_FILTER = "conditionalFilter"

    static REPORTS_ORDER = "reportsOrder"

    static HIDE_ORIGINAL_IF_SAME_AS_LATEST = "hideOriginalIfSameAsLates"
    static CONDITIONAL_ORIGINAL_BASED_ON_FUZZY_DIFF = "hideOriginalBasedOnFuzzyDiff"
    static HIDE_ORIGINAL_IF_LATEST_PRESENTED = "hideOriginalIfLaterstPresented"
    static CONDITIONAL_ORIGINAL_DISABLED = "originalConditionalDisabled"



    static QS_CLASS = "Q"
    static HS_CLASS = "H"
    static NINE_M_CLASS = "9"
    static FY_CLASS = "F"
    static PERIOD_CLASSES = "periodClasses"

    static HIDE_SUBSET_REPORTS = "hideSubsetReports"

    static SHOW_CALCULATED_STATEMENTS = "showCalcedStatements"
    static GRANULAR_PERIODS_FILTERING = "granularPeriodsFiltering"

    static SHOW_ADVANCED_SETTINGS = "showAdvancedSettings"
    static SHOW_CHARTS = "showCharts"

    static SHOW_INFO_ICONS = "showInfoIcons"
    static SHOW_YEARLY_GROWTH_PERCENTAGES = "showYearlyGrowth"
    static SHOW_PERIOD_GROWTH_PERCENTAGES = "showPeriodGrowth"
    static SHOW_ORIGINAL_CELL_DATA_IF_LATEST_MISSING = "showOriginalCellDataIfLatestMissing"
    static LABELS_FORMAT = 'labelsFormat'
}

class PropValues {

    static ASC = "ASC"
    static DESC = "DESC"
    static ORDER_OPS = [PropValues.ASC, PropValues.DESC]
}

class KeyValueOptions {

    static SORTING = [
        new KeyValue(PropValues.DESC, "Newest First"),
        new KeyValue(PropValues.ASC, "Oldest First"),
    ]

    static FILLING_TYPE_FILTER = [
        KeyValue.createSame(ReportFillingType.LATEST_FILLING),
        KeyValue.createSame(ReportFillingType.ORIGINAL_FILLING),
    ]

    static ORIGINAL_VERSION_CONDITIONAL_FILTER = [
        new KeyValue(ConfigProps.HIDE_ORIGINAL_IF_LATEST_PRESENTED, "Latest is presented"),
        new KeyValue(ConfigProps.HIDE_ORIGINAL_IF_SAME_AS_LATEST, "Same like Latest"),
        new KeyValue(ConfigProps.CONDITIONAL_ORIGINAL_BASED_ON_FUZZY_DIFF, "No Fuzzy Diff with Latest"),
        new KeyValue(ConfigProps.CONDITIONAL_ORIGINAL_DISABLED, "Never"),

    ]


    static REPORT_TYPE_FILTER = [
        KeyValue.createSame(ReportType.STANDARD),
        KeyValue.createSame(ReportType.CONDENSED),
        KeyValue.createSame(ReportType.PROFORMA),
    ]

    static GRANULAR_PERIODS_FILTER_OPS = [
        KeyValue.createSame("Q1"), KeyValue.createSame("Q2"),
        KeyValue.createSame("Q3"), KeyValue.createSame("Q4"),
        new KeyValue(["Q1", "Q2", "Q3", "Q4"], "Qs"),
        KeyValue.createSame("H1"),
        KeyValue.createSame("H2"),
        new KeyValue(["H1", "H2"], "Hs"),
        KeyValue.createSame("9M"),
        KeyValue.createSame("FY")
    ]

    static PERIODS_FILTER_OPS = [
        new KeyValue(["Q1", "Q2", "Q3", "Q4"], "Qs"),
        new KeyValue(["H1", "H2"], "Hs"),
        KeyValue.createSame("9M"),
        KeyValue.createSame("FY")
    ]

    static THOUSANDS_SEPARATOR_OPS = [
        new KeyValue("de-DE", "Dot"), new KeyValue("en-US", "Comma")
    ]

    static SCALE_OPS = ScaleUtil.scalePairs

    static PERIOD_CLASS_OPS = [
        new KeyValue("whiteP", "White"),
        new KeyValue("greenP", "Green"), new KeyValue("blueP", "Blue"),
        new KeyValue("yellowP", "Yellow")
    ]

    static DIFF_DETECTION_THRESHOLD_OPS = [
        new KeyValue("0", "0%"),
        new KeyValue("1", "1%"),
        new KeyValue("2", "2%"),
        new KeyValue("3", "3%"),
        new KeyValue("4", "4%"),
        new KeyValue("5", "5%"),
        new KeyValue("7", "7%"),
        new KeyValue("10", "10%"),
    ]

    static HEADER_COLUMN_WIDTH_OPS = [
        new KeyValue("S", "Small"),
        new KeyValue("M", "Medium"), new KeyValue("L", "Large"),
        new KeyValue("XL", "Extra Large")
    ]

    static HEADER_COLUMN_FONT_SIZE_OPS = ["Default", "10", "11", "12", "13"]
        .map(KeyValue.createSame)

    static FLOATING_POINT_PRECISION_OPS = ["0", "1", "2", "3", "4", "5", "6"]
        .map(KeyValue.createSame)

    static LABELS_FORMAT_OPS = Object.values(LABEL_FORMATS).map(KeyValue.createSame)

}

const DEFAULT_FILTER_AND_CFG = {
    [ConfigProps.YEARS_FILTER]: {
        from: 1000,
        to: 2030,
    },
    [ConfigProps.PERIODS_FILTER]: ["Q1", "Q2", "Q3", "Q4", "H1", "H2", "9M", "FY"],
    [ConfigProps.FILLING_TYPE_FILTER]: [
        ReportFillingType.LATEST_FILLING,
        ReportFillingType.ORIGINAL_FILLING
    ],
    [ConfigProps.REPORT_TYPES_FILTER]: [
        ReportType.STANDARD,
        ReportType.CONDENSED,
        ReportType.PROFORMA
    ],
    [ConfigProps.ORIGINAL_VERSION_CONDITIONAL_FILTER]: ConfigProps.HIDE_ORIGINAL_IF_LATEST_PRESENTED,
    [ConfigProps.PREFFERED_SCALE]: 1000,
    [ConfigProps.REPORTS_ORDER]: PropValues.ASC,
    [ConfigProps.SHOW_PERIOD_GROWTH_PERCENTAGES]: false,
    [ConfigProps.SHOW_YEARLY_GROWTH_PERCENTAGES]: false,
}

const DEFAULT_TABLE_CFG = {
    [ConfigProps.EQUAL_ROWS_HIGH]: true, [ConfigProps.SCALE_COLUMN]: true,
    [ConfigProps.NUMBER_LOCALE]: "de-DE",
    [ConfigProps.HIDE_STANDALONE_CALCED_CELLS]: false,
    [ConfigProps.HIDE_EMPTY_ROWS]: true,
    [ConfigProps.SHOW_IMPORTANT_ROWS_ONLY]: false,
    [ConfigProps.SHOW_3D_RATIOS]: true,
    [ConfigProps.FLOATING_POINT_PRECISION]: "2",
    [ConfigProps.ADD_TRAILING_ZEROS]: true,
    [ConfigProps.HEADER_COLUMN_WIDTH]: 0,
    [ConfigProps.PERIOD_CLASSES]: {
        "9": 'yellowP', F: 'greenP', H: 'blueP', Q: 'whiteP'
    },
    [ConfigProps.HEADER_COLUMN_WIDTH]: "M",
    [ConfigProps.HEADER_COLUMN_FONT_SIZE]: "Default",
    [ConfigProps.DIFF_DETECTION_IGNORE_ZERO_DASH]: true,
    [ConfigProps.DIFF_DETECTION_THRESHOLD]: "3",
    [ConfigProps.GRANULAR_PERIODS_FILTERING]: true,
    [ConfigProps.SHOW_CALCULATED_STATEMENTS]: true,
    [ConfigProps.HORIZONTALS]: true,
    [ConfigProps.SHOW_INFO_ICONS]: true,
    [ConfigProps.CATEGORY_BASED_MODE]: false,
    [ConfigProps.SHOW_ORIGINAL_CELL_DATA_IF_LATEST_MISSING]: false,
    [ConfigProps.LABELS_FORMAT]: LABEL_FORMATS.DEFAULT,
}

class UpdatePurpose {
    static REGULAR_UPDATE = "REGULAR_UPDATE"
    static DEFERRED_REVIEWS_EXECUTION = "DEFERRED_REVIEWS_EXECUTION"
    static MISTAKES_DETECTION = "MISTAKES_DETECTION"
    static MISTAKES_HOLDER = "MISTAKES_HOLDER"
}

class UpdatePhase {
    static APPLIED = "APPLIED"
    static DRAFT = "DRAFT"
    static READY_FOR_PEER_REVIEW = "READY_FOR_PEER_REVIEW"
    static READY_FOR_QA_REVIEW = "READY_FOR_QA_REVIEW"
}

class ReviewType {
    static CORE_DATA_CHECK = "CORE_DATA_CHECK"
    static VISUAL_CHECK = "VISUAL_CHECK"
    static FULL_CHECK = "FULL_CHECK"
}
export {
    ConfigProps, PropValues, KeyValueOptions, UpdatePurpose,
    UpdatePhase, ReviewType, DEFAULT_FILTER_AND_CFG, DEFAULT_TABLE_CFG, LABEL_FORMATS
}