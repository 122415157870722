/**
 * Holds the string name representation of periods
 */
export const PERIODS_MAP = {
    Q1: "Q1",
    Q2: "Q2",
    Q3: 'Q3',
    Q4: 'Q4',
    H1: 'H1',
    H2: 'H2'
}

/**
 * Holds the corresponding periods for a given period, which are used 
 * when comparing and calculation the growth of the specified period
 * 
 * Example: 
 * When calculating how much Q2 has grown in comparison 
 * to previous period, Q2 is compared to Q1
 */
export const CURRENT_TO_PAST_PERIODS_MAP = {
    Q1: PERIODS_MAP.Q4,
    Q2: PERIODS_MAP.Q1,
    Q3: PERIODS_MAP.Q2,
    Q4: PERIODS_MAP.Q3,
    H1: PERIODS_MAP.H2,
    H2: PERIODS_MAP.H1,
}