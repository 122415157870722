/**
 * @param {string} label Required
 * @returns {string}
 */
function normalizeLabel(label) {
    return label.replaceAll("–", "-").toLowerCase().replace(/\s+/g, '')
}

/**
 * @description Check if the descriptorLabel and/or reportedAsLabel are the same along side their normalized version
 * @param {string} descriptorLabel Required
 * @param {string} descriptorLabelNormalized see {@link normalizeLabel} Required
 * @param {string} reportedAsLabel Required
 * @returns {boolean}
 */
function labelsSimilarityCheck(descriptorLabel, descriptorLabelNormalized, reportedAsLabel) {
    return descriptorLabel === reportedAsLabel ||
        descriptorLabelNormalized === normalizeLabel(reportedAsLabel)
}

export { normalizeLabel, labelsSimilarityCheck }
