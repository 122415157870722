import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Header, Loader } from 'semantic-ui-react'
import UserRepo from '../../../utils/repository/userRepo';

function UserActivation() {
    const [activated, setActivated] = useState(undefined)
    const code = useParams().code

    useEffect(() => {
        UserRepo.activate(code, res => {
            setActivated(res.status === "SUCCESS")
        })
    }, [code])


    return activated === undefined ? <Loader /> : <ActivationStatus success={activated} />
}


function ActivationStatus({ success }) {
    return success ? <>
        <Header as='h1' textAlign='center'>Your email was successfully verified.</Header>
        <p className='textCenter'>You can proceed to the  <Link to="/user/login">Log In</Link> page.</p>
    </> : <>
        <Header as='h1' textAlign='center'>The email verifification failed.</Header>
        <p className='textCenter'>Please try again or <Link to="/contactUs">contact us</Link>.</p>
    </>
}


export default UserActivation