import HttpReq from "../../service/httpReq"

class ResourceRepo {
    static apiMgmtPrefix = 'api/mgmt/resources'
    static apiPrefix = 'api/resources'

    static saveReportResources(createSpec, resourceConsumer, errorConsumer) {
        HttpReq.post(ResourceRepo.apiMgmtPrefix + "/report", createSpec, resourceConsumer, errorConsumer)
    }

    static get(filePath, successCallback, errorConsumer) {
        HttpReq.get(ResourceRepo.apiMgmtPrefix + "/report/one?" +
            new URLSearchParams({ filePath: filePath }), successCallback, errorConsumer)
    }

    static relocateReportResources(relocationSpec, resourceConsumer, errorConsumer) {
        HttpReq.put(ResourceRepo.apiMgmtPrefix + "/report?action=relocate",
            relocationSpec, resourceConsumer, errorConsumer)
    }

    static listReportResources(listSpec, resourceConsumer, errorConsumer) {
        HttpReq.get(ResourceRepo.apiPrefix + "/report?" +
            new URLSearchParams(listSpec), resourceConsumer, errorConsumer)
    }

    static listCompanyResources(companyId, resourceConsumer, errorConsumer) {
        HttpReq.get(ResourceRepo.apiMgmtPrefix + "/company/" + companyId, resourceConsumer, errorConsumer)
    }

    static delete(filePath, successCallback, errorConsumer) {
        HttpReq.delete(ResourceRepo.apiMgmtPrefix + "/report?" +
            new URLSearchParams({ filePath }), successCallback, errorConsumer)
    }

    static deleteLatestUploaded(filePath, uploadedDuringLast, successCallback, errorConsumer) {
        HttpReq.delete(ResourceRepo.apiMgmtPrefix + "/report?" +
            new URLSearchParams({ filePath, uploadedDuringLast }), successCallback, errorConsumer)
    }

}

export default ResourceRepo