import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

type NotSavedModalProps = {
    onConfirm: () => void;
    onReject?: () => void;
};

function NotSavedModal({ onConfirm, onReject }: NotSavedModalProps) {
    return (
        <Modal open={true} size="small">
            <Modal.Header>Confirmation</Modal.Header>
            <Modal.Content>
                <p>Unsaved data will be lost, if you leave the page!</p>
            </Modal.Content>
            <Modal.Actions>
                <Button size="mini" primary onClick={onConfirm}>
                    Do not leave
                </Button>
                {onReject ? (
                    <Button size="mini" color="red" onClick={onReject}>
                        Continue Without Saving
                    </Button>
                ) : (
                    <></>
                )}
            </Modal.Actions>
        </Modal>
    );
}

export default NotSavedModal;
