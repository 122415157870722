import { Header, Icon, Message, Table } from "semantic-ui-react"
import { getResourceFilter } from "../../../utils/reportResourceUtil"
import { ReportField } from "../../../constnats/reportConstants"

function ReportsDiff({ schemaErrors, reportDiffs, editName, reviewName, detailed, companyId }) {
    const reportsDiffsMap = {}

    for (const reportDiff of reportDiffs) {
        const name = reportDiff.name
        if (reportsDiffsMap[name] === undefined) {
            reportsDiffsMap[name] = []
        }
        reportsDiffsMap[name].push(reportDiff)
    }

    const getBody = (reportsDiffsMap, schemaErrors) => {
        const reportsDiff = Object.values(reportsDiffsMap)

        let body
        if (schemaErrors === false && reportsDiff.length === 0) {
            body = <Message success attached='bottom'>
                No differences found. </Message>
        } else {
            body = <>
                {schemaErrors &&
                    <Message warning attached='bottom'>
                        <Icon name='warning' />
                        The report differences may not be calculated with 100% beacause of Model differences!
                    </Message>
                }
                <Table celled structured selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>No</Table.HeaderCell>
                        <Table.HeaderCell>Report</Table.HeaderCell>
                        {detailed && <>
                            <Table.HeaderCell>Field Label</Table.HeaderCell>
                            <Table.HeaderCell>Property</Table.HeaderCell>
                            <Table.HeaderCell>{editName} branch</Table.HeaderCell>
                            <Table.HeaderCell>{reviewName} branch</Table.HeaderCell>
                        </>}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {reportsDiff.map((reportDiffs, reportIdx) =>
                        reportDiffs.map((diff, idx) => {
                            return <Table.Row key={idx} error={diff.type === "ERROR"} warning={diff.type === "WARNING"}>
                                {idx === 0 && <>
                                    <Table.Cell rowSpan={reportDiffs.length} >{reportIdx + 1}</Table.Cell>
                                    <Table.Cell rowSpan={reportDiffs.length} >
                                        <ReportCellContent companyId={companyId} reportName={diff.name} />
                                    </Table.Cell>
                                </>}
                                {detailed && <>
                                    <Table.Cell>{diff.fieldLabel}</Table.Cell>
                                    <Table.Cell>{diff.property}</Table.Cell>
                                    <Table.Cell>{String(diff.edit)}</Table.Cell>
                                    <Table.Cell>{String(diff.review)}</Table.Cell>
                                </>}
                            </Table.Row>
                        })
                    )}
                </Table.Body>
            </Table>
            </>
        }

        return body
    }

    return <>
        <Header as="h5">Report Differences</Header>
        {getBody(reportsDiffsMap, schemaErrors)}
    </>
}


function ReportCellContent({ reportName, companyId }) {
    const [year, period, version] = reportName.split("/")
    const filter = getResourceFilter({ [ReportField.YEAR]: year, [ReportField.PERIOD]: period, [ReportField.TYPE]: version }, companyId)
    return <a target="_blank" rel="noopener noreferrer"
        href={"/resources/preview/" + btoa(JSON.stringify(filter))}>
        {reportName}
    </a>
}


export default ReportsDiff