import React, { useEffect, useState } from 'react';
import BasicData from './components/primaryInfo';
import { Header } from 'semantic-ui-react'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tab } from 'semantic-ui-react'
import CompanyRepo from '../../../utils/repository/companyRepo';
import NotSavedModal from '../../../components/notSavedModal';
import { usePrompt } from '../../../reactRouter/hooks';
import Schemas from './components/financialTables/schemas';
import Contributors from './components/contributors';
import { AuthContext, CompanyAuthContext } from '../../../App';
import LabelAnalysis from './components/financialTables/labelAnalysis/labelAnalysis';
import CompanyResources from './components/resources/companyResources';
import Reports from './components/reportsTab';
import Loader from '../../../components/loader';
import Updates from './components/updates';
import Utils from '../../../utils/utils';
import {default as DescriptorUtils} from '../../../utils/descriptor/descriptorUtils';
import { SchemaNames } from '../../../constnats/schemaConstants';

class AdministrationTabs {
    static PRIMANY_INFO = "Primary Info"
    static MODLES = "Models"
    static REPORTS = "Reports"
    static UPDATES = "Updates"
    static RESOURCES = "Resources"
    static LABELS_ANALYSIS = "Labels Analysis"
    static CONTRIBUTORS = "Contributors"
    static PERMISSIONS = "Permissions"

    static _positionMap = {
        [AdministrationTabs.PRIMANY_INFO]: 0,
        [AdministrationTabs.MODLES]: 1,
        [AdministrationTabs.REPORTS]: 2,
        [AdministrationTabs.UPDATES]: 3,
        [AdministrationTabs.RESOURCES]: 4,
        [AdministrationTabs.LABELS_ANALYSIS]: 5,
        [AdministrationTabs.CONTRIBUTORS]: 6,
        [AdministrationTabs.PERMISSIONS]: 7,
    }

    static urlParamPosition = {
        levelOneTab: 4,
        levelTwoTab: 5
    }

    static tabPositionsWithoutSchemas = [
        AdministrationTabs._positionMap[AdministrationTabs.PRIMANY_INFO],
        AdministrationTabs._positionMap[AdministrationTabs.RESOURCES],
        AdministrationTabs._positionMap[AdministrationTabs.CONTRIBUTORS]
    ]
}

function getTargetTabIndex(targetTabName) {
    let index
    if (targetTabName === null) {
        index = AdministrationTabs._positionMap[AdministrationTabs.REPORTS]
    } else {
        targetTabName = tabNameNormalize(targetTabName)
        const tabName = Object.keys(AdministrationTabs._positionMap)
            .find(tabName => tabNameNormalize(tabName) === targetTabName)
        index = AdministrationTabs._positionMap[tabName]
    }

    return index
}

function tabNameNormalize(tabName) {
    return tabName?.toLowerCase().replaceAll(" ", "")
}

class TabsContext {
    constructor(schemaId = null, onSchemaChange) {
        this.schemaId = schemaId
        this.schemas = []
        this.onSchemaChange = onSchemaChange
    }

    setSchemas(schemas) {
        this.schemas = schemas
        if (!this.schemas.some(schema => schema.name === SchemaNames.IS)) {
            this.setSchemaId(this.schemas[0].id)
        }
    }

    getSchemaName() {
        return this.schemas.find(s => s.id == this.schemaId)?.name
    }

    getSchemaId() {
        return this.schemaId
    }


    setSchemaId(schemaId) {
        this.schemaId = schemaId
        this.onSchemaChange(schemaId)
    }
}

class Company extends React.Component {
    constructor(props) {
        super(props);

        this.setDirty = this.setDirty.bind(this);

        this.state = {
            company: null,
            reloadVersion: 0,
            activeIndex: this.props.companyId === "new" ? 0 : getTargetTabIndex(this.props.tabName),
            confirmationModal: <></>,
            loading: true
        }

        this.activeTabDirty = false
        this.tabsContext = new TabsContext(this.props.schemaId, (schemaId) => {
            if (!AdministrationTabs.tabPositionsWithoutSchemas.includes(this.state.activeIndex)) {
                Utils.updateUrlPathParam({ [AdministrationTabs.urlParamPosition.levelTwoTab]: schemaId })
            }
        })

        this.props.onPageLeave(() => {
            return new Promise(resolve => {
                if (this.activeTabDirty === true) {
                    this.setState({
                        confirmationModal:
                            <NotSavedModal
                                onConfirm={() => {
                                    this.setState({ confirmationModal: <></> })
                                    resolve(true)
                                }}
                                onReject={() => resolve(false)}
                            />
                    })
                } else {
                    resolve(false)
                }
            })
        })
        this.updateBrowserUrl(getTargetTabIndex(this.props.tabName))
    }

    setDirty(dirty) {
        this.props.setIsDirty(dirty)
        this.activeTabDirty = dirty
    }

    componentDidMount() {
        if (this.props.companyId === "new") {
            this.setState({ loading: false })
        } else {
            this.reloadData()
        }
    }

    /**
     * @param {number} activeIndex Required
     **/
    updateBrowserUrl(activeIndex) {
        const schemaId = this.tabsContext.getSchemaId()



        const tabName = tabNameNormalize(Object.keys(AdministrationTabs._positionMap).find(key =>
            AdministrationTabs._positionMap[key] === activeIndex))

        if (tabName) { 
            const urlParams = { [AdministrationTabs.urlParamPosition.levelOneTab]: tabName }
            if (AdministrationTabs.tabPositionsWithoutSchemas.includes(activeIndex)) {
                urlParams[AdministrationTabs.urlParamPosition.levelTwoTab] = null
            } else if (schemaId) {
                urlParams[AdministrationTabs.urlParamPosition.levelTwoTab] = schemaId
            }

            Utils.updateUrlPathParam(urlParams)
        }
    }

    reloadData() {
        CompanyRepo.getInternal(this.props.companyId, company => {
            this.tabsContext.setSchemas(company.schemas.map(s => { return { id: s.id, name: s.name } }))
            DescriptorUtils.addFullCategoriesToSchemas(company.schemas, () => this.setState({
                company, loading: false,
                reloadVersion: this.state.reloadVersion + 1
            }))
        })
    }

    panes() {
        const company = this.state.company
        const panes = []

        function pane(name, content) {
            return {
                menuItem: name,
                render: () => <Tab.Pane className="companyRootTab" >{content}</Tab.Pane>
            }
        }

        panes.push(pane(AdministrationTabs.PRIMANY_INFO, <BasicData
            company={company}
            onSaved={company => this.setState({ company })}
            setDirty={this.setDirty}
        />))

        if (company !== null) {
            panes.push(pane(AdministrationTabs.MODLES, <Schemas
                key={this.state.reloadVersion}
                reloadSchemas={() => this.reloadData()}
                company={company}
                setIsDirty={this.setDirty}
                tabsContext={this.tabsContext} />))

            panes.push(pane(AdministrationTabs.REPORTS, <Reports
                key={this.state.reloadVersion}
                company={company}
                setIsDirty={this.setDirty}
                tabsContext={this.tabsContext} />))

            panes.push(pane(AdministrationTabs.UPDATES, <Updates
                schemas={company.schemas}
                companyName={company.name}
                companyId={company.id}
                tabsContext={this.tabsContext}
                reloadSchemas={() => this.reloadData()} />))

            panes.push(pane(AdministrationTabs.RESOURCES, <CompanyResources
                company={company} />))

            panes.push(pane(AdministrationTabs.LABELS_ANALYSIS, <LabelAnalysis
                key={this.state.reloadVersion}
                company={company}
                tabsContext={this.tabsContext} />))

            panes.push(pane(AdministrationTabs.CONTRIBUTORS, <Contributors
                companyId={company.id}
                setIsDirty={this.setDirty}
                editMode={true} />))
        }

        return panes
    }

    handleTabChange = (e, { activeIndex }) => {
        if (this.activeTabDirty === false) {
            this.setState({ activeIndex })
            this.updateBrowserUrl(activeIndex)
        } else {
            this.setState({
                confirmationModal: <NotSavedModal onConfirm={() => this.setState({ confirmationModal: <></> })} />
            })
        }
    }

    render() {
        const state = this.state
        return state.loading ? <Loader /> : <>{state.confirmationModal}
            <Header as="h2" textAlign='center'>{state.company !== null && state.company.name}</Header>
            <Tab panes={this.panes()}
                activeIndex={state.activeIndex}
                onTabChange={this.handleTabChange}
                menu={{
                    pointing: true,
                    secondary: true,
                    style: {
                        display: "flex",
                        justifyContent: "center"
                    }
                }} />
        </>
    }
}

Company.contextType = CompanyAuthContext;

const CompanyMemo = React.memo(({ companyId, tabName, schemaId, setIsDirty, onPageLeave }) => {
    const authContext = Object.assign({}, React.useContext(AuthContext))
    authContext.entityId = companyId

    // key is for force rerendering
    return <CompanyAuthContext.Provider value={authContext}>
        <Company key={companyId}
            {...{ schemaId, companyId, tabName, setIsDirty, onPageLeave }}
        /></CompanyAuthContext.Provider>

}, (prevProps, nextProps) => prevProps.companyId === nextProps.companyId)

function CompanyAdministration() {
    let [isDirty, setIsDirty] = useState(false);
    const tabName = useParams().levelOneTab || null
    const schemaId = useParams().levelTwoTab

    const pageLeaveFuncRef = { func: () => new Promise(resolve => { resolve(false) }) }

    function onPageLeave(func) {
        pageLeaveFuncRef.func = func
    }

    usePrompt(pageLeaveFuncRef, isDirty)

    return <CompanyMemo
        tabName={tabName}
        schemaId={schemaId}
        companyId={useParams().companyID}
        setIsDirty={setIsDirty}
        onPageLeave={onPageLeave} />;
}

export { CompanyAdministration, AdministrationTabs }