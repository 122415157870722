import React from 'react';
import Reports from './reports';
import ReportsTable from '../../../../company/components/fiancialTable/tableWrapper';
import { Checkbox } from 'semantic-ui-react';

function ReportsWrapper({ schema, companyId, currency, readOnly, setDirty }) {
    const [clientViewMode, setClientViewMode] = React.useState(!readOnly)

    return <>
        <div className='clientFillerViewSwitch'> Client View <Checkbox slider checked={clientViewMode} className="modeSlider"
            onChange={(e, data) => setClientViewMode(data.checked)} />Filler View</div>
        {clientViewMode ? <Reports
            {...{ currency, companyId, readOnly, schema, setDirty }} /> :
            <ReportsTable
                currency={currency}
                schema={JSON.parse(JSON.stringify(schema))}
                companyId={companyId} />}
    </>
}


export default ReportsWrapper