import HttpReq from "../../service/httpReq"

class ChangeLogRepo {
    static prefix = 'api/mgmt/changeLog'

    static getByEntity(entityId, changeLogsConsumer, errorConsumer) {
        HttpReq.get(ChangeLogRepo.prefix + "/entity/" + entityId, changeLogsConsumer, errorConsumer)
    }
}

export default ChangeLogRepo