class ArrMap {
    constructor() {
        this._map = {}
    }

    add(k, v) {
        if (!this._map[k]) {
            this._map[k] = []
        }
        this._map[k].push(v)

    }

    addAll(k, values) {
        if (!this._map[k]) {
            this._map[k] = []
        }
        for (const v of values) {
            this._map[k].push(v)
        }
    }


    get(k) {
        return this._map[k]
    }

    execIfPresent(k, func) {
        const v = this._map[k]
        if (v !== undefined) {
            func(v)
        }
    }
}
export default ArrMap