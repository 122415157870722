import { Table } from "semantic-ui-react"
import TableUpdatesRepo from "../../../../utils/repository/tableUpdatesRepo";
import { useEffect, useState } from "react";
import UserRepo from "../../../../utils/repository/userRepo";
import CollectionUtils from "../../../../utils/collections";
import { ROLES } from "../../../../constnats/user";
import Alert from "../../../../utils/alert";

const QA_IDS = ["2485", "2495", "130559"]

function TaskManagement() {
    const [updateKPIs, setUpdateKPIs] = useState({})
    const [notStartedReviews, setNotStartedReviews] = useState({})
    const [inProgressReviews, setInProgressReviews] = useState({})
    const [pendingRevisionReviews, setPendingRevisionReviews] = useState({})
    const [notStartedQaReviews, setNotStartedQaReviews] = useState(null)
    const [usersMap, setUsersMap] = useState({})

    useEffect(() => {
        TableUpdatesRepo.kpis(setUpdateKPIs)
        TableUpdatesRepo.reviewKpis(reviews => {
            setNotStartedReviews(CollectionUtils.arrToMap(reviews.notStartedReviews, u => u.userId, (a, b) => {
                Alert.warn("PLEASE CONTACT ADMINISTRATOR")
                const count = a.count + b.count
                const acumulator = { count, userId: a.userId }
                return acumulator
            }))
            setInProgressReviews(CollectionUtils.arrToMap(reviews.inProgressReviews, u => u.userId + "_" + u.type))
            setPendingRevisionReviews(CollectionUtils.arrToMap(reviews.pendingRevisionReviews, u => u.userId, (a, b) => {

                const count = a.count + b.count
                const acumulator = { count, userId: a.userId }
                return acumulator
            }))
            setNotStartedQaReviews(reviews.notStartedQaReviews)
        })

        UserRepo.list({ userRoles: [ROLES.EMPLOYEE], includeDisabled: true },
            users => setUsersMap(CollectionUtils.arrToMap(users, u => u.id)))
    }, [false]);

    const userToNonRejectedUpdates = {}

    if (updateKPIs.notAppliedKpis) {
        for (const record of updateKPIs.notAppliedKpis) {
            if (userToNonRejectedUpdates[record.userId] === undefined) {
                userToNonRejectedUpdates[record.userId] = {}
            }

            userToNonRejectedUpdates[record.userId][record.phase] = record.count
        }
    }

    const userToRejectedUpdates = {}

    if (updateKPIs.rejectedKpis) {
        for (const record of updateKPIs.rejectedKpis) {
            if (userToRejectedUpdates[record.userId] === undefined) {
                userToRejectedUpdates[record.userId] = 0
            }

            userToRejectedUpdates[record.userId] += record.count
        }
    }

    return <Table striped>
        <Table.Header >
            <Table.Row >
                <Table.HeaderCell rowSpan='2' >User</Table.HeaderCell>
                <Table.HeaderCell colSpan='4'>Active Updates</Table.HeaderCell>
                <Table.HeaderCell rowSpan='2'>Pending Reviews </Table.HeaderCell>
                <Table.HeaderCell rowSpan='2'>Reviews Pending Revision</Table.HeaderCell>
                <Table.HeaderCell colSpan='3'>In Progress Reviews</Table.HeaderCell>
                <Table.HeaderCell rowSpan='3'>Blocked Updates(MIN)</Table.HeaderCell>
                <Table.HeaderCell rowSpan='2'>Active On Tasks</Table.HeaderCell>
                <Table.HeaderCell rowSpan='2'>Tasks In Queue</Table.HeaderCell>
            </Table.Row>
            <Table.Row >
                <Table.HeaderCell>In Draft Phase</Table.HeaderCell>
                <Table.HeaderCell>In Peer Review Phase</Table.HeaderCell>
                <Table.HeaderCell>In QA Review Phase</Table.HeaderCell>
                <Table.HeaderCell>Of which rejected</Table.HeaderCell>
                <Table.HeaderCell>FULL_CHECK</Table.HeaderCell>
                <Table.HeaderCell>VISUAL_CHECK</Table.HeaderCell>
                <Table.HeaderCell>CORE_DATA_CHECK</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {Object.keys(userToNonRejectedUpdates).map(id => {
                const nonRejectedUpdates = userToNonRejectedUpdates[id]
                const drafts = nonRejectedUpdates["DRAFT"] || 0
                const forPeerReview = nonRejectedUpdates["READY_FOR_PEER_REVIEW"] || 0
                const forQaReview = nonRejectedUpdates["READY_FOR_QA_REVIEW"] || 0
                const rejectedUpdates = userToRejectedUpdates[id] || 0
                const _notStartedReviews = notStartedReviews?.[id]?.count || 0
                const inProgresVC = inProgressReviews?.[id + "_VISUAL_CHECK"]?.count || 0
                const inProgresFC = inProgressReviews?.[id + "_FULL_CHECK"]?.count || 0
                const inProgresCDC = inProgressReviews?.[id + "_CORE_DATA_CHECK"]?.count || 0
                const pendingRevisionReviewsCount = pendingRevisionReviews?.[id]?.count || 0
                const activeTasks = drafts + rejectedUpdates + inProgresFC + inProgresVC + inProgresCDC

                return {
                    id,
                    nonRejectedUpdates, drafts, forPeerReview,
                    forQaReview, rejectedUpdates, _notStartedReviews,
                    inProgresVC, inProgresFC, inProgresCDC,
                    activeTasks, pendingRevisionReviewsCount
                }
            }).sort((a, b) => (a.activeTasks + a._notStartedReviews + a.pendingRevisionReviewsCount) - (b.activeTasks + b._notStartedReviews + b.pendingRevisionReviewsCount)).map(d =>
                <Table.Row key={d.id}>
                    <Table.Cell>
                        {usersMap[d.id]?.fullName}
                    </Table.Cell>
                    <Table.Cell negative={d.drafts > 2}>
                        {d.drafts}
                    </Table.Cell>
                    <Table.Cell>
                        {d.forPeerReview}
                    </Table.Cell>
                    <Table.Cell>
                        {d.forQaReview}
                    </Table.Cell>
                    <Table.Cell negative={d.rejectedUpdates > 1}>
                        {d.rejectedUpdates}
                    </Table.Cell>
                    <Table.Cell negative={d._notStartedReviews > 4}>
                        {d._notStartedReviews}
                    </Table.Cell>
                    <Table.Cell negative={d.pendingRevisionReviewsCount > 4}>
                        {d.pendingRevisionReviewsCount}
                    </Table.Cell>
                    <Table.Cell negative={d.inProgresFC > 5}>
                        {d.inProgresFC}
                    </Table.Cell>
                    <Table.Cell>
                        {d.inProgresVC}
                    </Table.Cell>
                    <Table.Cell>
                        {d.inProgresCDC}
                    </Table.Cell>
                    <Table.Cell negative={d._notStartedReviews + d.pendingRevisionReviewsCount + d.inProgresFC + d.inProgresVC + d.inProgresCDC > 5}>
                        {d._notStartedReviews + d.pendingRevisionReviewsCount + d.inProgresFC + d.inProgresVC + d.inProgresCDC}
                    </Table.Cell>
                    <Table.Cell>
                        {d.activeTasks}
                    </Table.Cell>
                    <Table.Cell>
                        {d._notStartedReviews + d.pendingRevisionReviewsCount} {QA_IDS.includes(d.id) && ("(" + notStartedQaReviews + ")")}
                    </Table.Cell>
                </Table.Row>
            )}
        </Table.Body>
    </Table>
}

export default TaskManagement