import HttpReq from "../../service/httpReq"
import Cache from "./cache"

class ClientReportsRepo {
    static publicPath = 'api/client-reports'

    static get(id, reportConsumer, errorConsumer) {
        HttpReq.get(ClientReportsRepo.publicPath + "/" + id, reportConsumer, errorConsumer)
    }

    static save(createReportSpec, reportConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.post(ClientReportsRepo.publicPath, createReportSpec, reportConsumer, errorConsumer)
    }

    static update(updateReportSpec, reportConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.put(ClientReportsRepo.publicPath, updateReportSpec, reportConsumer, errorConsumer)
    }

    static delete(id, resConsumer, errorConsumer) {
        Cache.clear()
        HttpReq.delete(ClientReportsRepo.publicPath + "/" + id, resConsumer, errorConsumer)
    }
}

export default ClientReportsRepo