import React, { useState } from 'react';
import { Button, Form, FormField, Header, Input, TextArea } from 'semantic-ui-react'
import NewslettersService from '../../../service/newsletters';
import Loader from '../../../components/loader';

const summaryValidation = val => (val.length < 100 && val.length > 3) ? false : { content: "It must be shorter than 100 symbols and longer than 3 symbols." }
const contentValidation = val => (val.length > 10) ? false : { content: "It must be longer than 10 symbols." }

function getFiledChangeHandler(newValHandler, validator) {

    return changeEvent => {
        const newVal = changeEvent.target.value
        newValHandler({ value: newVal, error: validator(newVal) })
    }
}

function NewsletterFrom() {

    const [contnet, setContent] = useState({
        value: "Hi,\nBest Regards,\n3D StockPicker Team", error: false
    })
    const [summary, setSummary] = useState({ value: "3D StockPicker Newsletter", error: false })
    const [validationEnabled, setValidationEnabled] = useState(false)
    const [sendOpRes, setSendOpRes] = useState(null)
    const [sending, setSending] = useState(false)

    function onSubmit() {
        setValidationEnabled(true)

        if (contnet.error === false && summary.error === false) {
            setSending(true)
            NewslettersService.send({ summary: summary.value, content: contnet.value },
                (res) => {
                    setSending(true)
                    setSendOpRes(<SendOpRes serverRes={res} />)
                }, () => setSendOpRes(<SendOpRes />))
        }
    }

    return sendOpRes ? sendOpRes :
        sending ?
            <Loader msg="Sending, It may take a while " />
            :
            <>
                <Header as="h2" textAlign='center'>Newsletter</Header>
                <Form>
                    <FormField
                        control={Input}
                        label='Summary'
                        placeholder='Summary'
                        onChange={getFiledChangeHandler(setSummary, summaryValidation)}
                        value={summary.value}
                        error={validationEnabled ? summary.error : false}
                    />
                    <FormField
                        control={TextArea}
                        label='Content'
                        placeholder='Content'
                        onChange={getFiledChangeHandler(setContent, contentValidation)}
                        value={contnet.value}
                        error={validationEnabled ? contnet.error : false}
                    />
                    <Button size='mini' onClick={onSubmit}>Send</Button >
                </Form>
            </>
}

function SendOpRes({ serverRes }) {
    const title = serverRes !== undefined ? "The newsletter was sent successfully." :
        "We are sorry but we was not able to sent the newsletter."

    return <>
        <Header as="h4" textAlign='center'>{title}</Header>

        {serverRes &&
            <>
                <Header as="h5">Failed to send to:</Header>
                <ul>
                    {serverRes.data.failedToSendTo.map(e => <li>{e}</li>)}
                </ul>
                <Header as="h5">Sent to:</Header>
                <ul>
                    {serverRes.data.sentTo.map(e => <li>{e}</li>)}
                </ul>
            </>
        }
    </>
}

export default NewsletterFrom


