import React from 'react';
import { Card, Tab } from 'semantic-ui-react'
import NotSavedModal from '../../../../components/notSavedModal';
import { AuthContext, CompanyAuthContext } from '../../../../App';
import { ROLES } from '../../../../constnats/user';
import AuthService from '../../../../service/auth';
import SchemaUtil from '../../../../utils/schemaUtil';
import PaneNameFactory from '../../../../utils/tabPaneNameFactory';
import ReportsWrapper from './financialTables/reportsWrapper';
import { DoubleMap } from '../../../../structures/doubleMap';
import { Description } from '../../../../components/company/description';
import { ExternalResourcesCard } from '../../../../components/company/externalLinkResources';
import { SchemasContext } from '../../../../contexts/schemaContext';

class CompanyReportsInternal extends React.Component {

    constructor(props, context) {
        super(props)

        this.setDirty = this.setDirty.bind(this)

        const { panes, schemaToPane } = this.getPanes(context);
        this.schemaToPane = schemaToPane
        this.panes = panes

        const paneId = schemaToPane.getValueByKey(
            this.props.tabsContext.getSchemaId())
        const activeIndex = paneId === null ? 0 : paneId

        this.state = {
            activeIndex,
            confirmationModal: <></>,
        }

        this.dirtyTab = false
    }

    setDirty(dirty) {
        this.dirtyTab = dirty
        this.props.setDirty(dirty)
    }

    handleTabChange = (e, { activeIndex }) => {
        if (this.dirtyTab === false) {
            this.setState({ activeIndex })
            this.props.tabsContext.setSchemaId(this.schemaToPane.getKeyByValue(activeIndex))
        } else {
            this.setState({
                confirmationModal: <NotSavedModal onConfirm={() => this.setState({ confirmationModal: <></> })} />
            })
        }
    }

    getPanes(context) {
        const isUserBShepard = AuthService.hasRole(context, ROLES.READ_ALL_MODELS_AND_REPORTS)
        const userId = AuthService.getUserId()
        const calcPemissions = schema => {
            let permissions = "no"
            if (schema.visibility !== "SYSTEM") {
                if (schema.branchType === "MAIN") {
                    permissions = "readOnly"
                } else {
                    if (userId === schema.owner.id) {
                        permissions = "edit"
                    } else if (isUserBShepard === true) {
                        permissions = "readOnly"
                    }
                }
            }

            return permissions
        }

        const company = this.props.company
        const companyId = company.id
        const schemaToPane = new DoubleMap()
        const panes = company.schemas
            .sort(SchemaUtil.comapreSchemas).map((schema, i) => {
                schemaToPane.add(schema.id, i)
                const permissions = calcPemissions(schema)
                return {
                    menuItem: PaneNameFactory.forSchema(schema),
                    render: () => <Tab.Pane>
                        {permissions === "no" ?
                            <>Not enough privileges!</> :
                            <ReportsWrapper key={schema.id}
                                schema={schema}
                                readOnly={permissions === "readOnly"}
                                companyId={companyId}
                                currency={company.currency}
                                setDirty={this.setDirty} />}
                    </Tab.Pane>
                }
            })

        return { panes, schemaToPane }
    }

    render() {
        const schemas = this.props.company.schemas.map(s => ({ id: s.id, branchType: s.branchType, name: s.name }))
        return (
            <SchemasContext.Provider value={schemas}>
                <div className='reportTabsContainer'>
                    {this.state.confirmationModal}
                    <Tab className="reportTabs centeredPanesCollection"
                        panes={this.panes}
                        onTabChange={this.handleTabChange}
                        activeIndex={this.state.activeIndex}
                        menu={{
                            pointing: true,
                            secondary: true,
                            attached: true,
                            tabular: true,
                            style: {
                                display: "flex",
                                paddingBottom: "6px"
                            }
                        }}
                    />
                    <Card.Group className="companyCardGroup">
                        <Description description={this.props.company.description} />
                        <ExternalResourcesCard company={this.props.company} />
                    </Card.Group>
                </div>
            </SchemasContext.Provider>
        )
    }
}

CompanyReportsInternal.contextType = CompanyAuthContext;

export default function Reports({ company, setIsDirty, tabsContext }) {
    const authContext = Object.assign({}, React.useContext(AuthContext))
    authContext.entityId = company.id

    return <CompanyAuthContext.Provider value={authContext}>
        <CompanyReportsInternal company={company} setDirty={setIsDirty}
            tabsContext={tabsContext} />
    </CompanyAuthContext.Provider>
}