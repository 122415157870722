import { DescriptorProps } from "../../constnats/descriptor";
import { MetadataField, ReportField } from "../../constnats/reportConstants";
import Alert from "../alert";
import DescriptorType from "../descriptor/descriptorType";
import ReportUtils from "../reportUtils";
import ObjCheck from "../objCheck";
import Utils from "../utils";
import { DataCalcType } from "../../models/descriptorModel";
import CollectionUtils from "../collections";

const INPUT_SCALE = 1

/**
 * Goes over every provided data calculation type and checks if the provided descriptors
 * are all the same data calculation type and if that type is one of the provided data calculation types
 * @param {string[]} descriptorIdsToCheck 
 * @param {Object} descriptorsMap 
 * @param {string[]} dataCalcTypes
 * @returns {boolean} non-null
 */
const checkIfEveryDescriptorHasSameDataCalcType = (descriptorIdsToCheck, descriptorsMap, dataCalcTypes) => {
    let haveSameDataCalcType = false;

    for (const calcType of dataCalcTypes) {
        haveSameDataCalcType = descriptorIdsToCheck.every(descriptorId => {
            return descriptorsMap[descriptorId][DescriptorProps.DATA_CALC_TYPE] === calcType
        })

        if (haveSameDataCalcType) {
            break;
        }
    }

    return haveSameDataCalcType
}

/**
 * 
 * @param {string[]} descriptorIdsToCheck 
 * @param {Object} nonCategorizedReport 
 * @returns {boolean} non-null
 */
const checkIfAnyDescriptorIsManuallyCalculated = (descriptorIdsToCheck, nonCategorizedReport) => {
    return descriptorIdsToCheck.some(descriptorId =>
        nonCategorizedReport[ReportField.METADATA]?.fieldSpecific?.[descriptorId]?.[MetadataField.MANUALLY_CALCED]
    )
}
/**
 * Goes over the specified descriptors and returns the first descriptor that has 
 * field specific meta data with p2p calc property or undefined if none are found.
 * @param {Object} nonCategorizedReport Required
 * @param {string[]} nonCategorizedDescriptorIds Required
 * @returns {string | undefined}
 */
const getFirstDescriptorWithFieldSpecificMetadataP2pCalc = (nonCategorizedReport, nonCategorizedDescriptorIds) => {
    let descriptorWithFieldSpecificMetadataP2pCalc;
    for (const descriptorId of nonCategorizedDescriptorIds) {
        if (nonCategorizedReport[ReportField.METADATA]?.fieldSpecific[descriptorId]?.[MetadataField.P2P_CALC]) {
            descriptorWithFieldSpecificMetadataP2pCalc = descriptorId
            break;
        }
    }

    return descriptorWithFieldSpecificMetadataP2pCalc
}

/**
 * @param {Object} nonCategorizedReport Required
 * @param {Object} categorizedDescriptor Required
 * @param {Object} nonCategorizedDescriptorsMap Required
 * @returns {Object} not null
 */
const getCategorizedDescriptorMetaData = (nonCategorizedReport, categorizedDescriptor, nonCategorizedDescriptorsMap) => {
    let metadata
    if (!categorizedDescriptor[DescriptorProps.DYNAMIC]) {
        metadata = nonCategorizedReport[ReportField.METADATA].fieldSpecific[categorizedDescriptor[DescriptorProps.ID]]
    } else {
        const descriptorsIds = Object.keys(categorizedDescriptor[DescriptorProps.CATEGORY_RELATION_SIGNS])
        if (descriptorsIds.length === 1) {
            metadata = nonCategorizedReport[ReportField.METADATA].fieldSpecific[descriptorsIds[0]]
        } else {
            metadata = ReportUtils.getDefaultMetadata(INPUT_SCALE);

            /**
             * Here we try to be more accurate with the meta data
             * in cases where every descriptor has a data calculation type of CUMULATIVE
             * we can safely assume that the p2p calculation for the categorized descriptor
             * will be the same as any of the descriptors it is comprized of.
             * Analogically the same goes for NON CUMULATIVE.
             * Fail case: If all of the descriptors do not have the same data calculation type
             * we cannot assume what the calculation will be so we leave it as undefined
             * Example: if one of the descriptors has a data calculation type of CUMULATIVE and the 
             * other descriptor has a data calculation type of VERTICAL. We do not know which calculation
             * is the correct one, so we leave it as undefined
             * Or when the non categorized report is non calculated or has missing field specific metadata
             * here we will also return undefined.
             * This undefined value should later be handled in {@link _getDiffDetectionMethod}
             */
            if (checkIfEveryDescriptorHasSameDataCalcType(descriptorsIds, nonCategorizedDescriptorsMap, [DataCalcType.CUMULATIVE, DataCalcType.NON_CUMULATIVE])) {
                const descriptorIdWithP2pCalc = getFirstDescriptorWithFieldSpecificMetadataP2pCalc(nonCategorizedReport, descriptorsIds)
                if (descriptorIdWithP2pCalc) {
                    metadata[MetadataField.P2P_CALC] = nonCategorizedReport[ReportField.METADATA].fieldSpecific[descriptorIdWithP2pCalc][MetadataField.P2P_CALC]
                }
            }
            /**
             * After discussion we reached the conclusion that if one field is marked as manually
             * calculated then the categorized product from the fields will also we considered as 
             * manually calculated
             */
            if (checkIfAnyDescriptorIsManuallyCalculated(descriptorsIds, nonCategorizedReport)) {
                metadata[MetadataField.MANUALLY_CALCED] = true
            }
        }
    }
    return metadata;
}

/**
 * @param {Object} categorizedDescriptor Required
 * @param {Object} nonCategorizedReport Required
 * @returns {Object} not null
 */
const getCategorizedFieldValue = (categorizedDescriptor, nonCategorizedReport) => {
    let categorizedFieldValue;

    for (const descriptorId of Object.keys(categorizedDescriptor[DescriptorProps.CATEGORY_RELATION_SIGNS])) {
        let fieldValue = nonCategorizedReport[ReportField.DYNAMIC_FIELDS][descriptorId];
        if (ObjCheck.isNullUndefinedEmptyOrDash(fieldValue)) {
            continue;
        }
        if (DescriptorType.isNumber(categorizedDescriptor)) {
            if (Utils.isNumber(fieldValue)) {
                if (categorizedFieldValue === undefined) {
                    categorizedFieldValue = 0
                }

                fieldValue = Utils.toNumber(fieldValue);
                if (categorizedDescriptor[DescriptorProps.CATEGORY_RELATION_SIGNS][descriptorId]) {
                    fieldValue *= -1;
                }
                categorizedFieldValue += fieldValue
            } else {
                categorizedFieldValue = "ERROR"
                Alert.warn("Encounter a non number value for a number descriptor when merging categories!")
                break;
            }
        } else {
            categorizedFieldValue = fieldValue
        }
    }

    if (categorizedFieldValue === undefined) {
        categorizedFieldValue = '-'
    }

    return categorizedFieldValue
}

/**
 * @param {Object[]} categorizedFlatDescriptors Required
 * @param {Object} nonCategorizedReport Required
 * @param {Object} nonCategorizedDescriptorsMap Required
 * @returns {Object} not null
 */
const getCategorizedReport = (categorizedFlatDescriptors, nonCategorizedReport, nonCategorizedDescriptorsMap) => {
    const categorizedReport = {
        [ReportField.ID]: nonCategorizedReport[ReportField.ID],
        [ReportField.MANAGED]: nonCategorizedReport[ReportField.MANAGED],
        [ReportField.METADATA]: { fieldSpecific: {} },
        [ReportField.DYNAMIC_FIELDS]: {}
    }

    for (const categorizedDescriptor of categorizedFlatDescriptors) {
        const categorizedDescriptorId = categorizedDescriptor[DescriptorProps.ID]

        categorizedReport[ReportField.METADATA].fieldSpecific[categorizedDescriptorId] = getCategorizedDescriptorMetaData(nonCategorizedReport, categorizedDescriptor, nonCategorizedDescriptorsMap)

        if (!categorizedDescriptor[DescriptorProps.DYNAMIC]) {
            categorizedReport[categorizedDescriptorId] = nonCategorizedReport[categorizedDescriptorId];
        } else {
            categorizedReport[ReportField.DYNAMIC_FIELDS][categorizedDescriptorId] = getCategorizedFieldValue(categorizedDescriptor, nonCategorizedReport)
        }
    }

    return categorizedReport
}

/**
 * @description Returns new reports based on the provided descriptors and non categorized reports. 
 * The categorization is executed based on the descriptorsIdsToMerge and categoryRelationSigns fields 
 * of the descriptors.
 * @param {Object[]} categorizedFlatDescriptors Required
 * @param {Object[]} nonCategorizedReports Required
 * @param {Object} nonCategorizedDescriptorsMap Required
 * @returns {Object[]} not null
 */
const getCategorizedReports = (categorizedFlatDescriptors, nonCategorizedReports, nonCategorizedDescriptorsMap) => {
    const categorizedReports = [];

    for (const report of nonCategorizedReports) {
        categorizedReports.push(getCategorizedReport(categorizedFlatDescriptors, report, nonCategorizedDescriptorsMap));
    }

    return categorizedReports
}

export { getCategorizedReports }
