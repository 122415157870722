import React from 'react';
import { EditRow, ViewRow } from './columnComponents';
import { ConfigProps } from '../../../../../../components/financialTables/configuration/constants';
import { ReportField, SrcFieldValue } from '../../../../../../constnats/reportConstants';

const FONT_WEIGHT_BOLD_STYLE = { fontWeight: "bold" }

function getTableRowStyle(descriptor, row, highlightedRows) {
    const style = JSON.parse(JSON.stringify(descriptor.style))
    if (style.backgroundColor !== undefined) {
        Object.assign(style, FONT_WEIGHT_BOLD_STYLE)
    }

    if (descriptor.note === true) {
        style.color = "#9f9d9d"
    }

    if (highlightedRows.includes(row)) {
        style.backgroundColor = "#ffff99"
    }

    return style
}

function getColumnTableRowsClasses (advancedTableCfg) {
    if(advancedTableCfg[ConfigProps.HORIZONTALS]) {
        return "horizontals";
    }

    return "";
}

function ColumnTable({ entries, editMode, highlightedRows, report, advancedTableCfg, reportSource, filterCfg }) {
    const rowsClasses = getColumnTableRowsClasses(advancedTableCfg);
    const getTableRow = (entity, verticalLevel) => {
        const style = getTableRowStyle(entity.getDescriptor(), verticalLevel + 1, highlightedRows);
        return editMode === true ? (
            <EditRow {...{ entity, style }} key={verticalLevel} showSource={reportSource === SrcFieldValue.MIXED} />
        ) : (
            <ViewRow
                {...{ entity, style }}
                key={verticalLevel}
                yoYGrowthEnabled={filterCfg[ConfigProps.SHOW_YEARLY_GROWTH_PERCENTAGES]}
                poPGrowthEnabled={filterCfg[ConfigProps.SHOW_PERIOD_GROWTH_PERCENTAGES]}
                reportPeriod={report[ReportField.PERIOD]}
                className={rowsClasses}
            />
        );
    };

    return (
        <table className="column-table">
            <tbody>{entries.map(getTableRow)}</tbody>
        </table>
    );
}

export default ColumnTable;
