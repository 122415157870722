import React from 'react';
import { Checkbox, Header, Popup, Table } from 'semantic-ui-react'
import ReportsRepo from '../../../../../../utils/repository/repoertsRepo';
import Utils from '../../../../../../utils/descriptor/descriptorUtils';
import ObjCheck from '../../../../../../utils/objCheck';
import Loader from '../../../../../../components/loader';
import { MetadataField } from '../../../../../../constnats/reportConstants';
import ReactSlider from 'react-slider';
import ReportUtils from '../../../../../../utils/reportUtils';

class Remediator {

    /**
     * 
     * @param {string} id Required 
     * @param {string} label Required
     * @param {funtion} func Remediation func.Required
     * @param {boolean} enabled Required
     */
    constructor(id, label, func, enabled) {
        this.id = id
        this.label = label
        this.func = func
        this.enabled = enabled
    }

    remediate(str) {
        return this.enabled ? this.func(str) : str
    }
}

class SchemaLabelsReport extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            reports: null,
            loading: true,
            filterEmptyColumns: true,
            yearsFilter: { min: 0, max: 0, from: 0, to: 0 }
        }

        this.remediators = [
            new Remediator("spacesDiffs", "Ignore all space diffs", a => {
                return a.replace(/\s+/g, '')
            }, true),
            new Remediator("caseDiffs", "Ignore upper/lower case diffs", a => {
                return a.toLowerCase()
            }, true),
            new Remediator("nonLetterDiffs", "Ignore all non leter diffs", a => {
                return a.replace(/\W/g, '')
            }, false)
        ]
    }

    checkbox(remediator) {
        return <div key={remediator.label}>
            <Checkbox className='checkbox' checked={remediator.enabled}
                label={remediator.label}
                onChange={(evt, data) => {
                    remediator.enabled = data.checked
                    this.forceUpdate();
                }} />
        </div>
    }

    componentDidMount() {
        ReportsRepo.listBySchema(this.props.schema.id, reports => {

            let min = reports.length > 0 ? reports[0].year : 0
            let max = 0
            reports.forEach(r => {
                const year = r.year
                if (year > max) {
                    max = year
                } else if (year < min) {
                    min = year
                }
            })

            this.setState({ reports, yearsFilter: { min, max, from: min, to: max } })
        })

    }

    getTableData() {
        const { from, to } = this.state.yearsFilter
        const flatDescriptors = Utils.flatDescriptors(this.props.schema.descriptors)
        const reports = this.state.reports.filter(r => r.year >= from && r.year <= to)

        // Descriptor label => Reported as Labels
        const labelsMap = {}
        let maxDiffLabesSize = 0
        for (const descriptor of flatDescriptors) {
            const descriptorLabel = descriptor.label
            //Map of remediated reported as labels to original label.
            const remediatedRalToLabelsMap = {}
            //duplicate => [reportedIn]
            const localLabelDuplicatesMap = {}

            let remediatedDescriptorLabel = descriptorLabel
            for (const remediator of this.remediators) {
                remediatedDescriptorLabel = remediator.remediate(remediatedDescriptorLabel)
            }
            for (const report of reports) {
                const fieldMetadata = ReportUtils.getFieldMetadata(report, descriptor.id)
                if (!ObjCheck.isNullOrUndefined(fieldMetadata)) {
                    const reportedAsLabel = fieldMetadata[MetadataField.REPORTED_AS_LABEL]
                    if (!ObjCheck.isNullUndefinedEmptyOrDash(reportedAsLabel)) {
                        let remediatedReportedAsLabel = reportedAsLabel
                        for (const remediator of this.remediators) {
                            remediatedReportedAsLabel = remediator.remediate(remediatedReportedAsLabel)
                        }

                        if (remediatedReportedAsLabel !== remediatedDescriptorLabel) {
                            if (remediatedRalToLabelsMap[remediatedReportedAsLabel] === undefined) {
                                remediatedRalToLabelsMap[remediatedReportedAsLabel] = reportedAsLabel
                                localLabelDuplicatesMap[reportedAsLabel] = []
                            }

                            localLabelDuplicatesMap[remediatedRalToLabelsMap[remediatedReportedAsLabel]]
                                .push(report.year + "/" + report.period)
                        }
                    }
                }
            }

            const localLabelDuplicates = Object.keys(localLabelDuplicatesMap).map(duplicatedLabel => {
                return { duplicatedLabel, reportedIn: localLabelDuplicatesMap[duplicatedLabel] }
            })
            const labelsLength = localLabelDuplicates.length

            if (labelsLength > maxDiffLabesSize) {
                maxDiffLabesSize = labelsLength
            }

            labelsMap[descriptorLabel] = localLabelDuplicates
        }

        if (this.state.filterEmptyColumns) {
            Object.keys(labelsMap).forEach(key => {
                if (Object.keys(labelsMap[key]).length === 0) {
                    delete labelsMap[key]
                }
            })
        }

        //labelDuplicates [[{duplicatedLabel:label:reportedIn:[]} ],]
        const labelsArrays = Object.values(labelsMap)

        const rows = []
        for (let rowIdx = 0; rowIdx < maxDiffLabesSize; rowIdx++) {
            const row = []
            for (let labelsArraysIdx = 0; labelsArraysIdx < labelsArrays.length; labelsArraysIdx++) {
                if (labelsArrays[labelsArraysIdx] === undefined) {
                    break
                }
                row.push(labelsArrays[labelsArraysIdx][rowIdx])
            }
            rows.push(row)
        }

        const headers = Object.keys(labelsMap)
        return { rows, headers }
    }

    getTable() {
        const { rows, headers } = this.getTableData()

        if (rows.length > 0 && headers.length > 0) {
            return <Table celled structured compact singleLine>
                <Table.Header >
                    <Table.Row >
                        {headers.map((label, i) =>
                            <Table.HeaderCell key={i}>{label}</Table.HeaderCell>
                        )}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((row, i) =>
                        <Table.Row key={i}>{row.map((l, c) =>
                            ObjCheck.isNullOrUndefined(l) ?
                                <Table.Cell key={c} /> : <Table.Cell key={c}>
                                    <Popup className='labelDuplatesDetails'
                                        content={<ul>{l.reportedIn.sort().map((p, idx) => <li key={idx}>{p}</li>)}</ul>}
                                        trigger={<div>{l.duplicatedLabel}</div>} />
                                </Table.Cell>
                        )}</Table.Row>
                    )}
                </Table.Body>
            </Table>
        } else {
            return <Header as="h4" textAlign='center'>No Labels Diff</Header>
        }
    }

    content() {
        const config = this.state.yearsFilter
        return <div className='schemaLabelsReport'>
            <div className="tableConfigSegment">
                <Header as="h5" textAlign='center'>Years Filter</Header>
                <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="slider-thumb"
                    trackClassName="slider-track"
                    defaultValue={[config.min, config.max]}
                    renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                    pearling
                    value={[config.from, config.to]}
                    minDistance={0}
                    min={config.min}
                    max={config.max}
                    onAfterChange={s => this.setState({ yearsFilter: Object.assign(config, { from: s[0], to: s[1] }) })}
                />
            </div>
            {this.remediators.map(r => this.checkbox(r))}
            <Checkbox className='checkbox' checked={this.state.filterEmptyColumns}
                label="Filter empty columns"
                onChange={(evt, data) =>
                    this.setState({ filterEmptyColumns: data.checked })
                } />
            {this.getTable()}
        </div>
    }

    render() {
        return this.state.reports === null ? <Loader /> : this.content()
    }
}

export default SchemaLabelsReport