import Utils from "../../../../../../utils/utils"
import Assert from "../../../../../../utils/asserts"
import { CalculationExpUtil } from "../../../../../../utils/descriptor/calculationExp"
import ArrMap from '../../../../../../structures/arrayMap'
import Alert from "../../../../../../utils/alert"
import ObjCheck from "../../../../../../utils/objCheck"
import { validCorrection } from "../../../../../../utils/validation/fieldMetadataValidator"
import { DescriptorValidator } from "../../../../../../utils/validation/reportFieldValidator"
import Config from "../../../../../../constnats/config"
import Equals from "../../../../../../utils/equals"
import { default as DescriptorUtil } from "../../../../../../utils/descriptor/descriptorUtils"
import { WARN, INVALID } from "../../../../../../constnats/generalConstants"
import { MetadataField, ReportField } from "../../../../../../constnats/reportConstants"
import ReportUtils from "../../../../../../utils/reportUtils"
import { default as DescriptorUtils } from '../../../../../../utils/descriptor/descriptorUtils'
import CollectionUtils from "../../../../../../utils/collections"
import {DescriptorProps} from "../../../../../../constnats/descriptor"
import DescriptorType from "../../../../../../utils/descriptor/descriptorType"

class CellEntity {

    /**
     * @param {String} data Representation of the field value. Optional
     * @param { notes:String, reportedAsLabel:String, correction:string, manuallyCalculated:boolean,
     * inputScale:number, src:{string|null}, calcsDisabled:src:{boolean|null} } metadata
     *   Representation of the metadata associated with that field. Required
     * @param {Object} descriptor Descriptor of the field. Required
     * @param {Boolean} disabled True if the filed can be edited. Required
     * @param {Function} onChange Function to be executed if the field is changed. Required
     * @param {Collention of Function} dataListeners Listeners to be called if the field data is changed
     * @param {Boolean} dynamicField
     * @param {Collention of Function} correctionListeners Listeners to be called if the field correction
     *   (metadata#correction) is changed
     */
    constructor(data, metadata, descriptor, disabled, onChange, dataListeners,
        dynamicField, correctionListeners) {

        //TODO Ensure it is a string outside of this class
        /** 
         * "ACTUAL" value not influenced by scale (K,M,B)
        */
        this.data = data === undefined ? "" : String(data) 
        this.originalData = this.data
        this.metadata = Object.assign({}, metadata)
        this.descriptor = descriptor
        this.disabled = disabled
        this.onChange = descriptorChange => onChange(descriptorChange, this)
        this.dataListeners = dataListeners
        this.dynamicField = dynamicField
        this.correctionListeners = correctionListeners
        this.expectation = ""
        this.dataWarn = false

        const allowdValues = DescriptorUtils.allowdValues(this.descriptor)
        this.allowdValuesMap = allowdValues.length === 0
            ? null : CollectionUtils.arrToMap(allowdValues, kv => kv.key)
    }

    getScalable() {
        return this.descriptor.scalable
    }

    setDisabled(disabled) {
        if (this.disabled !== disabled) {
            this.disabled = disabled
            this.onChange(false)
        }
    }

    /**
     * @returns {boolean} True if the entity data is disabled for edit.
     */
    getDisabled() {
        return this.disabled
    }

    subscribeDataListener(listener) {
        this.dataListeners.push(listener)
    }

    subscribeCorrectionListener(listener) {
        this.correctionListeners.push(listener)
    }


    /**
     * @description The function returns the data of the cell in view format(if the data is a number it will be scaled 
     * accordingly to the specified scale (K,M,B)). If there is no data the function will check 
     * if there is a P2P calculation. In cases of such
     * calculation the P2P calculated result will be returned. If the P2P calculated result is a number, it will 
     * be scaled accordingly to the specified scale (K,M,B)
     * @returns {string} not null or undefined
     */
    getDataInViewFormat() {
        let data
        if (this.allowdValuesMap !== null) {
            const kv = this.allowdValuesMap[String(this.data)]
            if (kv === undefined) {
                Alert.error("Unexpected predefined data value:" + this.data + " Please contact the administrator.")
                data = "ERROR"
            } else {
                data = kv.value
            }
        } else if (Utils.isNumber(this.data) && DescriptorType.isNumber(this.descriptor)) {
            data = Utils.toNumber(this.data) / this.getViewScale()
        } else if (ObjCheck.isNullUndefinedEmptyOrDash(this.data) && !ObjCheck.isNullOrUndefined(this.metadata[MetadataField.P2P_CALC_RES])) {
            if(DescriptorType.isNumber(this.descriptor)) {
                data =  Utils.toNumber(this.metadata[MetadataField.P2P_CALC_RES]) / this.getViewScale()
            }else {
                data = this.metadata[MetadataField.P2P_CALC_RES]
            }
        } else {
            data = this.data
        }

        return String(data)
    }

    /**
     * @param {string} data  not null or undefined
     * @param {boolean|undefined} dataWarn True of there is a warning during the
     * calculation of the data.
     */
    setData(data, dataWarn) {
        Assert.typeString(data, "setData")
        if (this.data !== data) {
            this.data = data
            this.dataListeners.forEach(l => l())
            this.onChange(true)
        }
        this.dataWarn = dataWarn
    }

    /**
     * @returns {string} not null or undefined
     */
    getData() {
        Assert.typeString(this.data, "getData")
        return this.data
    }

    /**
     * @returns {string} not null or undefined original data
     */
    getOriginalData() {
        return this.originalData
    }

    /**
     * @returns {string} not-null id of the descriptor.
     */
    descriptorId() {
        return this.descriptor.id
    }

    /**
     * @returns {string} not-null descriptor.
     */
    getDescriptor() {
        return this.descriptor
    }

    /**
     * @returns {{notes:String, reportedAsLabel:String, correction:string, 
     * manuallyCalculated:boolean, inputScale:number, src:{string|null}}}
     *  Not null
     */
    getMetadata() {
        return this.metadata
    }

    /**
     * @param { {notes:String, reportedAsLabel:String, correction:string,
     * manuallyCalculated:boolean, inputScale:number, src:{string|null},
     * calcsDisabled:src:{boolean|null}}} metadata
     *  Required
     */
    setMetadata(metadata) {
        if (metadata[MetadataField.CALCS_DISABLED] !== true) {
            metadata[MetadataField.CALCS_DISABLED] = null
        } else {
            this.setDisabled(false)
        }

        if (metadata[MetadataField.CALCS_DISABLED] === null &&
            this.metadata[MetadataField.CALCS_DISABLED] === true) {
            this.setData("")
        }

        const correctionChange = metadata.correction !== this.metadata.correction
        this.metadata = metadata
        if (correctionChange) {
            this.correctionListeners.forEach(l => l())
        }

        if (!Equals.deepEquals(metadata)) {
            this.onChange(true)
        }
    }

    /**
     * @returns true if the field metadata is valid, false otherwise
     */
    validMetadata() {
        return !ObjCheck.isNullOrUndefined(this.metadata) &&
            validCorrection(this.metadata.correction, this.descriptor)
    }

    /**
     * @returns true if the field metadata correction fiels must be disabled, false otherwise
     */
    correctionDisabled() {
        return !DescriptorUtil.supportsCorrection(this.descriptor) &&
            (this.metadata.correction === 0 || this.metadata.correction === "0") //TODO
    }

    /**
     * @returns {string} "INVALID" if the data is invalid, "WARN" if there is possibility for issues, and "VALID"
     */
    validData() {
        let res
        if (!this.validMetadata() || this.dataWarn === true) {
            res = WARN
        } else {
            res = DescriptorValidator
                .validateData(this.descriptor, this.data, this.expectation, this.metadata.correction).status
        }

        return res
    }

    /**
    * @returns {string} "INVALID" if the data and/or metadata are invalid, "WARN" if there is possibility for issues, and "VALID"
    */
    valid() {
        let res
        if (!this.validMetadata()) {
            res = INVALID
        } else {
            res = this.validData()
        }

        return res
    }

    /**
     * @param {number} scale Required
     * NOTE: 0 for reset
     * @param {boolean} updateData Optional
     */
    setFieldInputScale(scale, updateData) {
        Assert.typeNumber(scale, "setFieldInputScale")

        const change = this._getMetadataInputScale() !== scale
        if (change) {
            const prevCalcScale = this.getEffectiveInputScale()

            this._setMetadataInputScale(scale)

            if (updateData) {
                this._scaleData(prevCalcScale)
            }

            this.onChange(true)
        }
    }

    setExtendedSearch(done) {
        this.metadata[MetadataField.EXTENDED_SEARCH] = done === true ? true : undefined
        this.onChange(true)
    }

    getExtendedSearch() {
        return this.metadata[MetadataField.EXTENDED_SEARCH] === true
    }

    /**
     * @returns {number} Scale to present the data in Edit mode. not-null
     */
    getEffectiveInputScale() {
        const metadataInputScale = this._getMetadataInputScale()
        Assert.positiveNumber(metadataInputScale, "getEffectiveInputScale")

        return metadataInputScale
    }

    /**
     * Sets field src. 
     * May be null if it matches the report src or the field is not reported
     */
    setSource(src) {
        Assert.typeStringOrNull(src, "setSource")

        this.metadata[MetadataField.SRC] = src
    }

    /**
     * @returns {string}  May be null if it matches the report src
     * or the field is not reported
     */
    getSource() {
        return this.metadata[MetadataField.SRC]
    }

    /**
     * @returns true if the calculations that affect this field are disabled, false otherwise
     */
    getCalcsDisabled() {
        return this.metadata[MetadataField.CALCS_DISABLED] === true
    }

    /** 
     * @returns {number} Scale to present the data in View mode. not-null  
     */
    getViewScale() {
        Assert.positiveNumber(this.descriptor.viewScale, "getViewScale")
        return this.descriptor.viewScale
    }

    /**
     * @param {string} expectation 
     */
    setExpectation(expectation) {
        this.expectation = expectation
    }

    /**
     * Refresh based on the newest schema. NOTE: works only if executed on all entities.
     */
    refresh() {
        this.dataListeners.forEach(l => l())
    }

    _scaleData(prevCalcScale) {
        const newCalcScale = this.getEffectiveInputScale()
        /**
         * Accepts a number and returns a new one based on a scale change.
         * Here the floating point precision has been set to a lower value 
         * due to the way JS handles floating point precision.
         * With greater precision values the following bug sometimes occurs:
         * By changing the scale and/or adding new numbers to the field we get
         * unexpected number after the trailing zeros
         * Example:
         * Prerequisites: there should be a custom field that should be a number
         * enter an initial number(8,47) and select the "M" option for the scale
         * save the changes and then convert it to a scale of "B";
         * when the popup for the changes open the new value will be 8470000000,000001
         * instead of just 8470000000,000000
         * Previous way of fixing the issue was the following: 
         * selecting the scale of "M" clicking save and then re-selecting the scale of "B" 
         */
        const scale = num => Utils.toReadMode((newCalcScale / prevCalcScale) * num, Config.FLOATING_POINT_PRECISION_FOR_SCALED_NUMBERS)

        // NOTE: this scales also the result of calculated fields which it not correct because they are naturaly scaled.
        // The problem is temporarely workarounded by forsing the system to recaclulate them. (this.correctionListeners.forEach(l => l()))
        if (Utils.isNumber(this.data)) {
            const newData = scale(Utils.toNumber(this.data))
            this.data = newData
            this.dataListeners.forEach(l => l())
        }

        let correction = this.metadata.correction
        if (Utils.isNumber(correction)) {
            this.metadata.correction = scale(Utils.toNumber(correction))
        }

        this.correctionListeners.forEach(l => l())
    }

    /**
     * @returns {number} 
     */
    _getMetadataInputScale() {
        const is = this.metadata.inputScale
        Assert.positiveNumber(is, "_getMetadataInputScale")
        return is
    }

    _setMetadataInputScale(scale) {
        Assert.typeNumber(this.metadata.inputScale, "_setMetadataInputScale")
        if (scale === 0) {
            this.metadata.inputScale = this.descriptor[DescriptorProps.INPUT_SCALE]
        } else {
            this.metadata.inputScale = scale
        }
    }
}

class EntityFactory {

    constructor(onEntryChange) {
        this._entities = []
        this._onEntryChange = onEntryChange
        this._listentesRegistry = new ArrMap()
    }

    /**
     * @param {{}} descriptors As stored in the database. Required 
     * @param {{}} report. Report as persisted in the database. Required 
     * @returns not-null
     */
    create(descriptors, report) {
        this._create(descriptors, report)

        this._entities.forEach(e =>
            this._listentesRegistry.execIfPresent(e.descriptorId(),
                listeners => listeners.forEach(l => e.subscribeDataListener(l))));

        return this._entities
    }


    _create(descriptors, report) {
        const fsMetadata = ReportUtils.getFieldsMetadata(report)
        for (const descriptor of descriptors) {
            const descriptorId = descriptor.id
            const dynamicField = descriptor.dynamicField
            const fieldData = dynamicField ? report[ReportField.DYNAMIC_FIELDS][descriptorId] : report[descriptorId]
            const fieldMetadata = ReportUtils.getNormalizedFieldMetadata(fsMetadata[descriptorId], descriptor[DescriptorProps.INPUT_SCALE])
            this._createEntity(fieldData, fieldMetadata, descriptor, dynamicField, report)
        }
    }

    _createEntity(fieldData, fieldMetadata, descriptor, dynamicField, report) {
        const entry = new CellEntity(fieldData, fieldMetadata, descriptor,
            DescriptorType.isHeadline(descriptor),
            this._onEntryChange, [], dynamicField, []);
        this._entities.push(entry)
        this._configDependancyChangeListening(descriptor, entry)
        this._create(descriptor.subFields, report)
    }

    _configDependancyChangeListening(descriptor, entry) {
        if (DescriptorUtil.hasCalculation(descriptor)) {

            this._configCalculationChangeListening(descriptor.calculation, entry, () => {
                entry.setData("")
                entry.setDisabled(false)
            }, (res, noNumbersCalc, hasNonConfiguredFields) => {
                const correction = entry.getMetadata().correction

                if (res === 0 && correction === "0" && noNumbersCalc === true) {
                    entry.setData("-", false)
                } else {
                    const percent = DescriptorType.isPercent(entry.getDescriptor())
                    if (percent) {
                        res = res * 100
                    }
                    const data = String(Utils.applyCorrection(res, correction,
                        percent ? 2 : Config.FLOATING_POINT_PRECISION)).replace(".", ",")
                        + (percent ? "%" : "")
                    entry.setData(data, hasNonConfiguredFields)
                }

                entry.setDisabled(true)
            })

            if (DescriptorUtil.hasSecondaryCalculation(descriptor)) {
                this._configCalculationChangeListening(descriptor.secondaryCalculation, entry, () => {
                    entry.setExpectation("")
                }, (res, noNumbersCalc) => {
                    let expectation
                    if (res === 0 && entry.getMetadata().correction === "0" && noNumbersCalc === true) {
                        expectation = "-"
                    } else {
                        expectation = Utils.toReadMode(res, Config.FLOATING_POINT_PRECISION)
                    }
                    entry.setExpectation(expectation)
                })
            }
        }
    }

    _configCalculationChangeListening(calculation, entry, onNotCalculatable, onCalculated) {
        const calcFrags = CalculationExpUtil.strToFragments(calculation)
        /**
         * IDs of the entities that are part of the calculation
         */
        const calcEntryIds = []
        calcFrags
            .filter(frag => CalculationExpUtil.isArgsGroup(frag))
            .forEach(ids => calcEntryIds.push(...ids))

        const strict = calculation.includes("/") || calculation.includes("*")
        const li = () => {
            if (entry.getCalcsDisabled() !== true) {
                this._calculate(calcEntryIds, calcFrags, strict, onNotCalculatable, onCalculated)
            }
        }

        entry.subscribeCorrectionListener(li)
        calcEntryIds.forEach(f => this._listentesRegistry.add(f, li))
    }


    _calculate(calcEntryIds, calcFrags, strict, onNotCalculatable, onCalculated) {
        if (CalcHelper.notCalculatabilityCheck(calcEntryIds, this._entities, strict)) {
            onNotCalculatable()
        } else {
            const [exp, hasNonConfiguredFields] = CalcHelper.buildExpression(calcFrags, this._entities)
            const res = Number(eval(exp))
            let noNumbersCalc = false
            if (res === 0) {
                noNumbersCalc = CalcHelper.noNumbersCheck(calcEntryIds, this._entities)
            } else if (!Utils.isNumber(res)) {
                if (isFinite(res) && isNaN(res)) {
                    Assert.fail("Unexpected calculation result:" + res)
                }
                onNotCalculatable()
                return
            }

            onCalculated(res, noNumbersCalc, hasNonConfiguredFields)
        }
    }

}

class CalcHelper {

    static buildExpression(calcFrags, entries) {
        let exp = ""
        let hasNonConfiguredFields = false

        calcFrags.forEach(frag => {
            if (CalculationExpUtil.isArgsGroup(frag)) {
                exp += "(" + frag.map(fieldId => {
                    const data = Utils.findEntry(entries, fieldId).getData()
                    hasNonConfiguredFields = ObjCheck.isNullUndefinedOrEmpty(data) || hasNonConfiguredFields
                    return Utils.toNumberNonStrict(data)
                }).join("+") + ")"
            } else {
                exp += frag
            }
        })

        return [exp, hasNonConfiguredFields]
    }

    static notCalculatabilityCheck(calcEntryIds, entities, strict) {
        const check = strict ? Utils.isNumber : Utils.isNonStirctConvertableToNumber
        return calcEntryIds.some(id => {
            const calcEntry = Utils.findEntry(entities, id)
            if (ObjCheck.isNullOrUndefined(calcEntry)) {
                Alert.error("Incorrect Report Model Calculation. Do not save the report changes. Go to Report model configuration page for more information.")
                return true
            }
            return !check(calcEntry.getData())
        })
    }


    static noNumbersCheck(calcEntryIds, entities) {
        return calcEntryIds.every(id => {
            const calcEntry = Utils.findEntry(entities, id)
            if (ObjCheck.isNullOrUndefined(calcEntry)) {
                Alert.error("Incorrect Report Model Calculation. Do not save the report changes. Go to Report model configuration page for more information.")
                return true
            }

            return ObjCheck.isNullUndefinedEmptyOrDash(calcEntry.getData())
        })
    }

}

export default EntityFactory