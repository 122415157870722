import { CalculationExpUtil } from "./descriptor/calculationExp";
import ReportUtils from "./reportUtils";
import { DataCalcType } from "../models/descriptorModel";
import Utils from "./descriptor/descriptorUtils";
import Assert from "./asserts";
import MathUtil from "./mathUtil";
import ObjCheck from "./objCheck";
import { MetadataField, ReportField, ReportFillingType, ReportType, SrcFieldValue } from "../constnats/reportConstants";
import { DescriptorProps } from "../constnats/descriptor";
import DescriptorType from "./descriptor/descriptorType";

class Calculation {
    constructor(period1, sign, period2) {
        this.p1 = period1;
        this.sign = sign;
        this.p2 = period2;
    }
}


function getPossibleReports() {
    const possibleReports = []
    for (const period of Period.periods) {
        for (const reportType of ReportType.ALL) {
            for (const type of ReportFillingType.PERSISTENT_TYPES) {
                possibleReports.push({ period, reportType, type })
            }
        }
    }

    return possibleReports
}

/**
 * NOTE: Here we assume that there are no more than one non calculated report
 * for a given year and same period, reportType, and type. 
 */
function getCalculationKey({ period, reportType, type }) {
    return period + reportType + type
}

class Period {
    static periods = ["Q1", "Q2", "H1", "Q3", "9M", "Q4", "H2", "FY"]
    //TODO
    static counter = 0

    static calculations = {
        "Q1": new Calculation("H1", "-", "Q2"),
        "Q2": new Calculation("H1", "-", "Q1"),
        "Q3": new Calculation("9M", "-", "H1"),
        "Q4": new Calculation("FY", "-", "9M"),
        "H1": new Calculation("Q1", "+", "Q2"),
        "9M": new Calculation("H1", "+", "Q3"),
        "H2": new Calculation("FY", "-", "H1")
    }

    //TODO When H1 and Q2 are presented we can calculate standalone value of H1 using Q2
    static timeMatchingPeriods = [["H1", "Q2"], ["FY", "H2", "Q4"], ["9M", "Q3"]]

    /**
     * @param {[]} knownReports Known reports to be used for the calculation.
     * Required 
     * @param {[]} dynamicFlatDescriptors Required
     * @param {func} reportDuplicatesHandler Required
     * @param {boolean} [enableAsserts] Optional
     * @returns {[]} not-null array that does not contain more than one report for same period and year. 
     */
    static getCalculatedReports(knownReports, dynamicFlatDescriptors, reportDuplicatesHandler, enableAsserts) {
        let calculatedReports = []
        const verticalCalcExecutors = Period._getVerticalCalcExecutors(dynamicFlatDescriptors, enableAsserts)
        for (const [year, reports] of Object.entries(Period._mapToYear(knownReports))) {
            calculatedReports = calculatedReports
                .concat(Period._getCalculations(dynamicFlatDescriptors, reports,
                    Number(year), verticalCalcExecutors, reportDuplicatesHandler))
        }

        return calculatedReports
    }

    static _mapToYear(reports) {
        const map = {}
        reports.forEach(r => {
            if (map[r.year] === undefined) {
                map[r.year] = []
            }
            map[r.year].push(r)
        });

        return map
    }

    /**
     * 
     * @param {[Object]} flatDynamicDescriptors Required
     * @param {boolean} [enableAsserts] Optional 
     * @returns {Object} not-null
     */
    static _getVerticalCalcExecutors(flatDynamicDescriptors, enableAsserts) {
        const preparedCalcs = {}
        for (const descriptor of flatDynamicDescriptors) {
            if (descriptor[DescriptorProps.DATA_CALC_TYPE] === DataCalcType.VERTICAL) {
                if(enableAsserts) {
                    Assert.trueVal(Utils.hasCalculation(descriptor),
                        "Vertical calc mode without calculation. " + JSON.stringify(descriptor))
                }
                const preparedCalc = CalculationExpUtil.toPreparedCalc(descriptor)
                if(preparedCalc) {

                    preparedCalcs[descriptor.id] = preparedCalc
                }
            }
        }

        return preparedCalcs
    }

    static _getCalculations(flatDynamicDescriptors, yearReports, year,
        verticalCalcExecutors, reportDuplicatesHandler) {
        const calcKeyToReportsMap = Period._calcKeyToReportsMap(yearReports, reportDuplicatesHandler)
        const calculatedReports = []

        const possibleReports = getPossibleReports()

        for (const possibleReport of possibleReports) {
            const period = possibleReport.period
            let [cumulativeDataCalc, displayCDCalc]
                = Period._getCumulativeDataCalculation(possibleReport, calcKeyToReportsMap)
            let [nonCumulativeDataCalc, srcReport, displayNCDCalc]
                = Period._getNonCumulativeDataCalculation(possibleReport, calcKeyToReportsMap)

            if (cumulativeDataCalc !== null || nonCumulativeDataCalc !== null) {
                cumulativeDataCalc = cumulativeDataCalc || (p => "-")
                nonCumulativeDataCalc = nonCumulativeDataCalc || (p => "-")

                let cumulativeDataAdded = false
                let nonCumulativeDataAdded = false
                const fieldSpecificMetadata = {}
                const dynamicFields = {}
                for (const descriptor of flatDynamicDescriptors) {

                    const descriptorId = descriptor.id;
                    let calcResult
                    const calcType = descriptor[DescriptorProps.DATA_CALC_TYPE]
                    if (DescriptorType.isHeadline(descriptor)) {
                        calcResult = ""
                    } else if (calcType === DataCalcType.CUMULATIVE) {
                        calcResult = cumulativeDataCalc(descriptorId)
                        if (calcResult !== "-") {
                            cumulativeDataAdded = true
                            fieldSpecificMetadata[descriptorId] = { [MetadataField.P2P_CALC]: displayCDCalc }
                        }
                    } else if (calcType === DataCalcType.NON_CUMULATIVE) {
                        calcResult = nonCumulativeDataCalc(descriptorId)
                        if (calcResult !== "-") {
                            nonCumulativeDataAdded = true
                            fieldSpecificMetadata[descriptorId] = { [MetadataField.P2P_CALC]: displayNCDCalc }
                        }
                    } else {
                        calcResult = "-"
                    }

                    dynamicFields[descriptorId] = calcResult
                }

                if (cumulativeDataAdded || nonCumulativeDataAdded) {
                    const calculatedReport = Object.assign(
                        ReportUtils.createEmptyReport("TMP:" + (++Period.counter)),
                        {
                            [ReportField.PERIOD]: period,
                            [ReportField.YEAR]: year,
                            [ReportField.TYPE]: possibleReport[ReportField.TYPE],
                            [ReportField.REPORT_TYPE]: possibleReport[ReportField.REPORT_TYPE],
                            [ReportField.SRC]: SrcFieldValue.CALCULATED,
                            [ReportField.DYNAMIC_FIELDS]: dynamicFields,
                            [ReportField.METADATA]: { fieldSpecific: fieldSpecificMetadata }
                        })
                    if (cumulativeDataAdded === false && nonCumulativeDataAdded === true) {
                        calculatedReport.subsetOfReport = srcReport
                    } else {
                        //Reset
                        delete calculatedReport.subsetOfReport
                    }

                    for (const [descriptorId, calc] of
                        Object.entries(verticalCalcExecutors)) {

                        const calcRes = calc.calculate(calculatedReport[ReportField.DYNAMIC_FIELDS])
                        if (calcRes !== "-") {
                            calculatedReport[ReportField.DYNAMIC_FIELDS][descriptorId] =
                                calc.calculate(calculatedReport[ReportField.DYNAMIC_FIELDS])
                            fieldSpecificMetadata[descriptorId] = {
                                [MetadataField.P2P_CALC]: "Vertical Calculation In Calculated Period:"
                                    + year + " " + period + " " + possibleReport[ReportField.TYPE]
                            } 
                        }

                    }

                    calculatedReports.push(calculatedReport)
                }
            }
        }

        return calculatedReports
    }

    static compare(a, b) {
        for (const period of Period.periods) {
            if (a === period && b === period) {
                return 0
            } else if (a === period) {
                return -1
            } else if (b === period) {
                return 1
            }
        }
    }

    /**
     * Maps a period calculation key to a the list of reports for it.
     */
    static _calcKeyToReportsMap(reports, reportDuplicatesHandler) {
        const map = {}
        reports.forEach(reportToAdd => {
            const reportKey = getCalculationKey(reportToAdd)
            if (map[reportKey] === undefined) {
                map[reportKey] = reportToAdd
            } else {
                map[reportKey] = reportDuplicatesHandler(map[reportKey], reportToAdd)
            }
        })

        return map
    }

    static _getCumulativeDataCalculation(possibleReport, periodToReportMap) {
        const calc = Period.calculations[possibleReport.period]
        let mathOp = null
        // let mixCalc = false
        if (calc !== undefined) {
            let p1Rep = periodToReportMap[getCalculationKey({ ...possibleReport, period: calc.p1 })]
            let p2Rep = periodToReportMap[getCalculationKey({ ...possibleReport, period: calc.p2 })]

            // if (possibleReport.type === ReportFillingType.LATEST_FILLING && (p1Rep ? !p2Rep : p2Rep)) {
            //     if (!p1Rep) {
            //         type1 = ReportFillingType.ORIGINAL_FILLING
            //         p1Rep = perodToReportMap[getCalculationKey({ period: calc.p1, reportType: possibleReport.reportType, type: type1 })]
            //     } else {
            //         type2 = ReportFillingType.ORIGINAL_FILLING
            //         p2Rep = perodToReportMap[getCalculationKey({ period: calc.p2, reportType: possibleReport.reportType, type: type2 })]
            //     }
            //     mixCalc = true
            // } 

            if (p1Rep && p2Rep) {
                mathOp = prop =>
                    MathUtil.safeExecMathOp(p1Rep[ReportField.DYNAMIC_FIELDS][prop], p2Rep[ReportField.DYNAMIC_FIELDS][prop],
                        calc.sign === "-" ? MathUtil.subtract : MathUtil.sum)
            } 
        }

        return [mathOp, calc ? (calc.p1 + " " + calc.sign + " " + calc.p2) : ""]
    }

    static _getNonCumulativeDataCalculation(possibleReport, periodToReportMap) {
        const period = possibleReport.period
        let copyOp = null
        let reportToCopyFrom = null
        let periodsToCopyFrom = Period.timeMatchingPeriods.find(set => set.includes(period))
        let displayCalc = null

        /**
         * the following change fixes an issue where values from Q2 were not being
         * copied to H1
         * the issue occurred because the first report that was being found H1 itself
         * which led to the case where the report copied values from itself and not 
         * from the corresponding report in this case Q2
         */
        if(periodsToCopyFrom) {
            periodsToCopyFrom = periodsToCopyFrom.filter(p => p !== period)
        }

        if (periodsToCopyFrom !== undefined) {
            for (const periodToCopyFrom of periodsToCopyFrom) {
                reportToCopyFrom = periodToReportMap[getCalculationKey({ ...possibleReport, period: periodToCopyFrom })] || null
                if (reportToCopyFrom !== null) {
                    displayCalc = periodToCopyFrom
                    copyOp = prop => {
                        let result = reportToCopyFrom[ReportField.DYNAMIC_FIELDS][prop]
                        result = ObjCheck.isNullUndefinedOrEmpty(result) ? "-" : result
                        return result
                    }
                    break
                }
            }
        }

        return [copyOp, reportToCopyFrom, displayCalc]
    }
}

export default Period