import React, { useEffect, useState } from 'react';
import { Tab } from 'semantic-ui-react'
import Loader from '../../../../components/loader';
import { UpdatesTab } from '../../../../components/admin/updates/updatesTab';
import SchemaUtil from '../../../../utils/schemaUtil';


function Updates({ companyId, companyName, schemas, reloadSchemas, tabsContext }) {
    const [panes, setPanes] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);

    function updateTabsContext(activeIndex) {
        const tabName = panes[activeIndex].menuItem
        const schemaId = schemas.filter(schema => (schema.name === tabName
            && schema.branchType === "MAIN"))?.[0].id
        if (schemaId) {
            tabsContext.setSchemaId(schemaId)
        }
    }

    useEffect(() => {
        const name = tabsContext.getSchemaName()
        let _activeIndex = 0
        const panes = schemas
            .filter(s => s.branchType === "MAIN")
            .sort(SchemaUtil.comapreSchemas)
            .map((schema, i) => {
                if (schema.name === name) {
                    _activeIndex = i
                }
                return {
                    menuItem: schema.name, render: () => {
                        const schemaId = schema.id
                        return <Tab.Pane>
                            <UpdatesTab key={schemaId} schemaName={schema.name}
                                {...{ schemaId, reloadSchemas, companyId, companyName }} />
                        </Tab.Pane>
                    }
                }
            })
        setPanes(panes)
        setActiveIndex(_activeIndex)
    }, [companyId]);

    const content = () => {
        return <Tab
            panes={panes}
            onTabChange={(e, { activeIndex }) => {
                setActiveIndex(activeIndex)
                updateTabsContext(activeIndex)
            }}
            activeIndex={activeIndex}
            menu={{
                pointing: true,
                secondary: true,
                attached: true,
                tabular: true,
                style: {
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: "6px"
                }
            }}
        />
    }


    return panes === null ? <Loader /> : content()
}

export default Updates