import HttpReq from "../../../service/httpReq"

class SchemaViewData {
    static prefix = 'api/mgmt/ui/schema'

    static getFormData(schemaId, viewConsumer, errorConsumer) {
        HttpReq.get(SchemaViewData.prefix + "/form/" + schemaId, viewConsumer, errorConsumer)
    }
}

export default SchemaViewData