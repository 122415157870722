import { Form } from "semantic-ui-react"

function SelectFieldWrapper({ label, prop, config, opsKV, onChange }) {
    return <SelectField label={label}
        opsKV={opsKV} val={config[prop]}
        onChange={data => onChange(prop, data)} />
}

function SelectField({ label, opsKV, val, onChange }) {
    const toSelectOps = keyValueArr => {
        return keyValueArr.map(kv => {
            return {
                key: kv.key, text: kv.value, value: kv.key
            }
        })
    }

    return <>
        <span className="slectLabel">{label} </span>
        <Form.Select className="selectField"
            options={toSelectOps(opsKV)}
            value={val}
            onChange={(c, v) => onChange(v.value)} />
    </>
}

function CheckboxField({ checked, label, onChange }) {
    return <Form.Checkbox label={label}
        checked={checked}
        onChange={(e, data) => onChange(data.checked)} />
}

function CheckboxBtn({ checked, label, onChange }) {

    function onClick() {
        onChange(!checked)
    }

    return <div className={checked ? "checkboxBtn checked" : "checkboxBtn"}
        onClick={onClick}>{label}
    </div>
}


function MultiselectField({ selected, optionsKV, onChange }) {
    selected = selected || []

    const checkbox = (prop, label) => <CheckboxBtn key={label} label={label}
        checked={Array.isArray(prop) ? prop.every(p => selected.includes(p)) : selected.includes(prop)}

        onChange={isSelected => {
            const values = Array.isArray(prop) ? prop : [prop]
            if (isSelected) {
                selected.push(...values)
                onChange(selected)
            } else {
                onChange(selected.filter(s => !values.includes(s)))
            }
        }} />

    return optionsKV.map(kv => checkbox(kv.key, kv.value))
}

export { SelectFieldWrapper, SelectField, CheckboxField, MultiselectField, CheckboxBtn }