import React from 'react';
import { Button, Checkbox, Dropdown, Form, Header, Loader, Table } from 'semantic-ui-react'
import EventRepo from '../../../utils/repository/eventRepo';
import { formatDateTimeUTC } from '../../../utils/dateTimeUtils';
import Alert from '../../../utils/alert';

class EventsLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: null,
            usersFilter: [],
            userOps: [],
            condensedFormat: false
        }
    }

    componentDidMount() {
        EventRepo.list(events => {
            events = events
                .sort(function (a, b) {
                    return new Date(a.uiDate) - new Date(b.uiDate);
                })

            const users = Array.from(new Set(events.map(e => e.user)))
            const userOps = users.map(u => {
                return {
                    key: u,
                    text: u,
                    value: u
                }
            })

            this.setState({
                events, usersFilter: users, userOps
            })
        })
    }


    deleteAll() {
        EventRepo.deleteAll(() => {
            Alert.success("Deleted")
            this.setState({ events: [] })
        })
    }

    isLoading() {
        return this.state.events === null
    }

    table() {
        const events = this.state.events
            .filter(e => this.state.usersFilter.includes(e.user))
        return this.state.condensedFormat ? <CondensedEventsTable events={events} /> : <DetailedEventsTable events={events} />
    }

    content() {
        return <>
            <Header as="h2" textAlign='center'>JS Events</Header>
            <Form.Field>
                <label>Users</label>
                <Dropdown
                    onChange={(a, b) => this.setState({ usersFilter: b.value })}
                    value={this.state.usersFilter}
                    placeholder='Users'
                    options={this.state.userOps} fluid multiple selection search />
            </Form.Field>
            <br />
            <Checkbox checked={this.state.condensedFormat}
                label="Condensed format"
                onChange={(evt, data) =>
                    this.setState({ condensedFormat: data.checked })
                } />
            {this.state.events.length > 0 ? <>{this.table()}
                <Button onClick={() => this.deleteAll()}
                    size='mini'
                    color='red'>Delete All
                </Button> </> : <Header as="h4" textAlign='center'>No Events Found</Header>}
            <br /><br />
        </>
    }

    render() {
        return this.isLoading() ? <Loader /> : this.content();
    }
}

function DetailedEventsTable({ events }) {
    return <Table celled className='jsEventsTable'>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Message</Table.HeaderCell>
                <Table.HeaderCell>Stack</Table.HeaderCell>
                <Table.HeaderCell>Other</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {events
                .map(event => {
                    return <Table.Row key={event.id}>
                        <Table.Cell>{event.message}</Table.Cell>
                        <Table.Cell>{event.stack}</Table.Cell>
                        <Table.Cell>
                            <b>Issuer:</b>{event.issuer}<br />
                            <b>User:</b><span className='redFont'>{event.user}</span><br />
                            <b>Browser:</b>{event.browser}<br />
                            <b>Path:</b>{event.path}<br />
                            <b>Server Date UTC:</b>{event.date && formatDateTimeUTC(event.date)}<br />
                            <b>IU Date UTC:</b><span className='redFont'>{event.uiDate && formatDateTimeUTC(event.uiDate)}</span><br />
                            <b>Session ID:</b>{event.sessionId}<br />
                        </Table.Cell>
                    </Table.Row>
                })}
        </Table.Body>
    </Table>

}

function CondensedEventsTable({ events }) {
    const eventsMap = {}

    events.forEach(e => {
        const message = e.message
        if (eventsMap[message] === undefined) {
            eventsMap[message] = 0
        }
        eventsMap[message]++

    });
    const messages = [...new Set(events.map(e => e.message))]

    return <Table celled>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Message</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {messages
                .map((message, idx) => {
                    return <Table.Row key={idx}>
                        <Table.Cell>{message}</Table.Cell>
                    </Table.Row>
                })}
        </Table.Body>
    </Table >
}

export default EventsLog