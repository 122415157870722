import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Divider, Segment, Header } from 'semantic-ui-react'
import AuthService from '../../../service/auth';
import ObjCheck from '../../../utils/objCheck';
import FiledsValidator from '../../../utils/validation/fieldsValidator';
import Alert from '../../../utils/alert';


class GaMonitor {
    static gaLoaded = false
    static listener = undefined
    static registerListener(cb) {
        GaMonitor.listener = cb
        if (GaMonitor.gaLoaded) {
            GaMonitor.listener()
        }
    }

    static onLoaded() {
        GaMonitor.gaLoaded = true
        if (GaMonitor.listener) {
            GaMonitor.listener()
        }
    }
}

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.filedsValidator = new FiledsValidator(["username", "password"])

        this.state = {
            loginData: {
                username: "",
                password: ""
            },
            propsValidation: this.filedsValidator.getValidErrorProps(),
            divRef: React.createRef(),
            googleInitialized: false
        }

        this.googleBtnRendered = false
    }

    componentDidMount() {
        GaMonitor.registerListener(() =>
            this.setState({ googleInitialized: true }))
    }

    onPropChange(newVal, prop) {
        const loginDataCopy = Object.assign({}, this.state.loginData)
        loginDataCopy[prop] = newVal
        this.setState({
            loginData: loginDataCopy,
            propsValidation: this.filedsValidator.dicardErrorIfExists(prop)
        })
    }

    getField(forProp, label, type = "text") {
        return <Form.Field>
            <Form.Input
                type={type}
                label={label}
                placeholder={label}
                onChange={v => this.onPropChange(v.target.value, forProp)}
                value={this.state.loginData[forProp]}
                error={this.state.propsValidation[this.filedsValidator.getErrorPropName(forProp)]} />
        </Form.Field>
    }

    login() {
        if (this.validate()) {
            AuthService.login(this.state.loginData, error => {
                if (!ObjCheck.isNullOrUndefined(error) &&
                    !ObjCheck.isNullOrUndefined(error.json) &&
                    error.json.statusCode === 401) {
                    Alert.error("Incorrect email or password!", "N/A", false)
                } else {
                    Alert.error(ObjCheck.isNullOrUndefined(error) ? "Failed to login." : error.msg)
                }
            })
        }
    }

    validate() {
        const validationRes = this.filedsValidator.validate(this.state.loginData)
        this.setState({ propsValidation: validationRes.newErrorProps })
        return validationRes.valid
    }



    render() {
        if (this.state.googleInitialized && !this.googleBtnRendered) {
            window.google.accounts.id.renderButton(this.state.divRef.current, {
                size: 'medium',
                type: 'standard',
                text: 'sign_in',
                width: "350"
            });
            this.googleBtnRendered = true
        }

        return <Segment className='loginForm'>
            <Header as="h4" textAlign='center'>Log In</Header>
            <Form>
                {this.getField("username", "Email")}
                {this.getField("password", "Password", "password")}
                <Button size='mini' onClick={() => this.login()} primary>Log In</Button>
                <Link className='registrationLoginLink' to="/user/registration">Registration</Link>
                <Divider horizontal>Or</Divider>
                <div className='googleLoginBtn' ref={this.state.divRef}></div>
            </Form>
        </Segment>
    }
}

window.handleCredentialResponse = async (response) => {
    AuthService.googleLogin(response)
}

window.onGoogleLibraryLoad = () => {
    window.google.accounts.id.initialize({
        client_id: '461425471539-d76vo4gcit5s89ki5s7tfgplioaaafh1.apps.googleusercontent.com',
        callback: window.handleCredentialResponse,
        ux_mode: "popup",
    });
    GaMonitor.onLoaded()
}

export default LoginForm