import React from 'react';
import ChangeLogRepo from '../../utils/repository/changeLogRepo';
import { Button, Modal, Table } from 'semantic-ui-react'
import { serverToLocalDateTime } from '../../utils/dateTimeUtils';
import Loader from '../loader';
import ObjCheck from '../../utils/objCheck';

class ChangeLog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            changeLogs: null
        }
    }

    componentDidMount() {
        ChangeLogRepo.getByEntity(this.props.entityId,
            changeLogs => this.setState({ changeLogs }))
    }

    getList() {
        return <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell collapsing>Num.</Table.HeaderCell>
                    <Table.HeaderCell>User</Table.HeaderCell>
                    <Table.HeaderCell>Summary</Table.HeaderCell>
                    <Table.HeaderCell>Description</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {this.state.changeLogs.map((change, idx) => {
                    return <Table.Row key={change.id}>
                        <Table.Cell>{idx + 1}</Table.Cell>
                        <Table.Cell>{change.username}</Table.Cell>
                        <Table.Cell>{change.summary}</Table.Cell>
                        <Table.Cell> {React.createElement(this.props.descriptionCompType,
                            {
                                description: change.description,
                                flatDescriptors: this.props.flatDescriptors
                            })}
                        </Table.Cell>
                        <Table.Cell>{change.date && serverToLocalDateTime(change.date)}</Table.Cell>
                    </Table.Row>
                })}
            </Table.Body>
        </Table>
    }

    render() {
        return (this.state.changeLogs === null) ? <Loader /> : this.getList()
    }
}

function ChangeLogModal({ entityId, onCancel, descriptionConvertor, descriptionCompType, flatDescriptors }) {
    return <Modal
        open={true}
        size='fullscreen'>
        <Modal.Header>Change Log</Modal.Header>
        <Modal.Content>

            <ChangeLog {...{ descriptionConvertor, entityId, descriptionCompType, flatDescriptors }} />
        </Modal.Content>
        <Modal.Actions>
            <Button size='mini' onClick={onCancel}>
                Close
            </Button>
        </Modal.Actions>
    </Modal>
}


function SchemaChangeDescription({ description, flatDescriptors }) {
    if (!ObjCheck.isNullUndefinedOrEmpty(description)) {
        const changes = description.split("&&")
        return <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell collapsing>Field ID  </Table.HeaderCell>
                    <Table.HeaderCell>Label</Table.HeaderCell>
                    <Table.HeaderCell>Property</Table.HeaderCell>
                    <Table.HeaderCell>Old Value</Table.HeaderCell>
                    <Table.HeaderCell>New Value</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {changes.map((change, idx) => {
                    const details = change.split("::")
                    const descriptorId = details[0]?.trim()
                    const label = flatDescriptors.find(d => d.id == descriptorId)?.label

                    let oldValue = details[2]
                    if (oldValue) {
                        for (const descriptor of flatDescriptors) {
                            oldValue = oldValue.replaceAll(descriptor.id, " " + descriptor.label + " ")
                        }
                    }

                    let newValue = details[3]
                    if (newValue) {
                        for (const descriptor of flatDescriptors) {
                            newValue = newValue.replaceAll(descriptor.id, " " + descriptor.label + " ")
                        }
                    }

                    return <Table.Row key={idx}>
                        <Table.Cell collapsing>{descriptorId}</Table.Cell>
                        <Table.Cell>{label}</Table.Cell>
                        <Table.Cell>{details[1]}</Table.Cell>
                        <Table.Cell>{oldValue}</Table.Cell>
                        <Table.Cell>{newValue}</Table.Cell>
                    </Table.Row>
                })}
            </Table.Body>
        </Table>
    }

    return <></>
}

function ReportChangeDescription({ description, flatDescriptors }) {
    if (!ObjCheck.isNullUndefinedOrEmpty(description)) {
        const changes = description.split("&&")
        return <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Field</Table.HeaderCell>
                    <Table.HeaderCell>Old Value</Table.HeaderCell>
                    <Table.HeaderCell>New Value</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {changes.map((change, idx) => {
                    const details = change.split("::")
                    const descriptorId = details[0]?.trim()
                    const label = flatDescriptors.find(d => d.id == descriptorId)?.label || descriptorId
                    return <Table.Row key={idx}>
                        <Table.Cell>{label}</Table.Cell>
                        <Table.Cell>{details[1]}</Table.Cell>
                        <Table.Cell>{details[2]}</Table.Cell>
                    </Table.Row>
                })}
            </Table.Body>
        </Table>
    }

    return <></>
}

export { ChangeLogModal, SchemaChangeDescription, ReportChangeDescription }
