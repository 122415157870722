class KeyValue {

  /**
   * @param {any} key Optional 
   * @param {any} value  Optional 
   */
  constructor(key, value) {
    this.key = key
    this.value = value
  }

  /**
   * @param {any} keyVal  Optional
   * @returns not-null
   */
  static createSame(keyVal) {
    return new KeyValue(keyVal, keyVal)
  }

}

export default KeyValue