import React from 'react';
import TableActionButton from '../tableActionButton';
import './advancedSettingsToggle.styles.css';

type AdvancedSettingsToggleProps = {
    showAdvancedSettings: boolean;
    toggleAdvancedSettings: () => void;
};

const AdvancedSettingsToggle = ({ showAdvancedSettings, toggleAdvancedSettings }: AdvancedSettingsToggleProps) => {
    return (
        <TableActionButton className="advanced-settings-btn" onClick={toggleAdvancedSettings}>
            {showAdvancedSettings ? 'Hide Advanced Settings' : 'Show Advanced Settings'}
        </TableActionButton>
    );
};

export default AdvancedSettingsToggle;
