import { DescriptorProps } from "../../constnats/descriptor"
import DescriptorType from "../descriptor/descriptorType"

export function isCategorizationRequired(flatDynamicDescriptors) {
    return flatDynamicDescriptors.some(d => d[DescriptorProps.CATEGORY])
}

export function hasFieldsWithoutConfiguredCategory(flatDynamicDescriptors) {
    /**
     * TODO: here the first condition !d[DescriptorProps.CATEGORY] in the callback function should be removed 
     * after a migration 
     * Currently this is needed to account for descriptors with categories and with a category configured property 
     * of false.
     * After the migration all descriptors with categories will have their category configured prop set to true and
     * the additional check will be obsolete
     */
    return flatDynamicDescriptors.some(d => !d[DescriptorProps.CATEGORY] && !d[DescriptorProps.CATEGORY_CONFIGURED] && !DescriptorType.isHeadline(d))
}