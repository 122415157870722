import cloneDeep from "lodash/cloneDeep";

class CloneUtils {

    /**
     * @param {any} obj 
     * @returns not-null
     */
    static deepClone(obj) {
        return cloneDeep(obj)
    }

}

export default CloneUtils