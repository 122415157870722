import HttpReq from "../../service/httpReq"


class CompanyRepo {
    static publicPath = 'api/companies'
    static mgmtPath = 'api/mgmt/companies'

    static list(companyConsumer, errorConsumer) {
        HttpReq.get(CompanyRepo.publicPath, companyConsumer, errorConsumer)
    }

    static listInternal(params, companyConsumer, errorConsumer) {
        HttpReq.get(CompanyRepo.mgmtPath + "?" + new URLSearchParams(params),
            companyConsumer, errorConsumer)
    }

    static search(name, companiesConsumer, errorConsumer) {
        HttpReq.get(CompanyRepo.publicPath + "/search?" + new URLSearchParams({ name: name }), companiesConsumer, errorConsumer)
    }

    static getInternal(id, companyConsumer, errorConsumer) {
        HttpReq.get(CompanyRepo.mgmtPath + "/" + id, companyConsumer, errorConsumer)
    }

    static get(id, companyConsumer, errorConsumer) {
        HttpReq.get(CompanyRepo.publicPath + "/" + id, companyConsumer, errorConsumer)
    }

    static save(saveCompanySpec, companyConsumer, errorConsumer) {
        HttpReq.post(CompanyRepo.mgmtPath, saveCompanySpec, companyConsumer, errorConsumer)
    }

    static update(updateCompanySpec, companyConsumer, errorConsumer) {
        HttpReq.put(CompanyRepo.mgmtPath, updateCompanySpec, companyConsumer, errorConsumer)
    }

    static getContributors(id, contributorsConsumer, errorConsumer) {
        HttpReq.get(CompanyRepo.mgmtPath + "/" + id + "/contributors", contributorsConsumer, errorConsumer)
    }

    static updateContributors(updateContributorsSpec, contributorsConsumer, errorConsumer) {
        HttpReq.put(CompanyRepo.mgmtPath + "/contributors", updateContributorsSpec, contributorsConsumer, errorConsumer)
    }

    static kpis(successConsumer, errorConsumer) {
        HttpReq.get(CompanyRepo.mgmtPath + "/kpis", successConsumer, errorConsumer)
    }

}

export default CompanyRepo