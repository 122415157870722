import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

type ConfirmModalProps = {
    msg: string;
    onConfirm: () => void;
    onCancel: () => void;
    className?: string;
};

function ConfirmationModal({ msg, onCancel, onConfirm, className = '' }: ConfirmModalProps) {
    return (
        <Modal open={true} size="small">
            <Modal.Header>Confirmation</Modal.Header>
            <Modal.Content className={className}>
                <p>{msg}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button size="mini" color="red" onClick={onConfirm}>
                    Confirm
                </Button>
                <Button size="mini" onClick={onCancel}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default ConfirmationModal;
