import { useEffect, useRef, useState } from "react"
import { Button, Form, Input, Modal } from "semantic-ui-react"
import Utils from "../../../utils/descriptor/descriptorUtils"
import { ReportField, ReportFillingType, ReportType, SrcFieldValue } from "../../../constnats/reportConstants"
import { Descriptor } from "../../../models/descriptorModel"
import ClientReportsRepo from "../../../utils/repository/clientReportsRepo"
import Loader from "../../../components/loader"
import ReportUtils from "../../../utils/reportUtils"
import ObjCheck from "../../../utils/objCheck"
import ScaleUtil from "../../../utils/scaleUtil"
import { default as NumUtils } from "../../../utils/utils"
import Assert from "../../../utils/asserts"
import Alert from "../../../utils/alert"
import { ReportsUtils } from "../../../utils/reportsUtils"
import ConfirmationModal from "../../../components/confirmationModal"
import { DescriptorProps } from "../../../constnats/descriptor"
import DescriptorType from "../../../utils/descriptor/descriptorType"

function CreateReportModal({ schema, knownReports, onCreated, onCancel }) {

    const report = getNewReport(knownReports)

    return (
        <ReportModal {...{ schema, report, onCancel }}
            title="Create Financial Statement"
            onSave={report => {
                const a = { schemaId: report.schemaId, report }
                console.log(a)

                ClientReportsRepo.save(
                    Object.assign(
                        { schemaId: schema.id }, report
                    ), onCreated)
            }}
        />
    )
}

function UpdateReportModal({ schema, reportId, onUpdated, onCancel }) {
    const [report, setReport] = useState(null)

    useEffect(() => ClientReportsRepo.get(reportId, setReport), [reportId])

    return report === null ? <Loader />
        : <ReportModal {...{ schema, report, onCancel }}
            title="Update Financial Statement"
            onSave={updatedReport =>
                ClientReportsRepo.update(updatedReport, onUpdated)}
            onDelete={() => ClientReportsRepo.delete(reportId, onUpdated)}
        />
}

function getNewReport(knownReports) {
    const report = ReportUtils.createEmptyReport(null)

    const reports = ReportsUtils.sort(knownReports.filter(r =>
        r[ReportField.TYPE] === ReportFillingType.ORIGINAL_FILLING
        && r[ReportField.SRC] !== SrcFieldValue.USER_INFO))

    const lastReport = reports[reports.length - 1]

    Assert.notNullOrUndefined(lastReport, "getNewReport->latest report")

    let year
    if (lastReport[ReportField.PERIOD] === "FY") {
        year = lastReport[ReportField.YEAR] + 1
    } else {
        year = lastReport[ReportField.YEAR]
    }

    Assert.notNullOrUndefined(year, "getNewReport->year")

    const prevYear = lastReport[ReportField.YEAR] - 1
    const prevYearReports = reports
        .filter(r => r[ReportField.YEAR] === prevYear)

    let prevYearPeriods = prevYearReports.map(r => r[ReportField.PERIOD])
    prevYearPeriods = prevYearPeriods.filter((r, index) => prevYearPeriods.indexOf(r) === index)

    let period
    for (let pIdx = 0; pIdx < prevYearPeriods.length; pIdx++) {
        if (prevYearPeriods[pIdx] === lastReport[ReportField.PERIOD]) {
            if (pIdx < prevYearPeriods.length - 1) {
                period = prevYearPeriods[pIdx + 1]
            } else {
                period = prevYearPeriods[0]
            }
            break
        }
    }

    if (period === undefined) {
        Assert.fail("No matching period")
        period = "FY"
    } else {
        if ((period === "Q4" || period === "H2") && prevYearPeriods.includes("FY")) {
            period = "FY"
        } else if (period === "Q3" && prevYearPeriods.includes("9M")) {
            period = "9M"
        } else if (period === "Q2" && prevYearPeriods.includes("H1")) {
            period = "H1"
        }
    }

    let format = prevYearReports.find(r => r[ReportField.PERIOD] === period)?.[ReportField.REPORT_TYPE]
    if (format === undefined) {
        Assert.fail("No matching format")
        format = ReportType.STANDARD
    }

    ReportOps.prepolulateReportFields(report, year, period, ReportFillingType.ORIGINAL_FILLING, format)

    return report

}

function ReportModal({ schema, report, title, onSave, onDelete, onCancel }) {
    const [flatDescriptors] = useState(Utils.flatDescriptors(schema.descriptors))
    const [modal, setModal] = useState(null)

    const [uiReport, setUiReport] = useState(() =>
        ServerToUIReportConvertor.getUIReport(report, flatDescriptors))

    function save() {
        if (uiReport.valid()) {
            onSave(UIToServerReportConvertor.getServerReport(uiReport, flatDescriptors))
        } else {
            Alert.error("Some of the fields contain invalid values.")
        }
    }

    return (
        < Modal size="small" open={true} >

            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                {modal}
                <ReportForm {...{ uiReport }} />
            </Modal.Content>
            <Modal.Actions>
                <Button color="blue" primary size='mini' onClick={save}>
                    Save
                </Button>

                {onDelete && <Button color="red" size='mini' onClick={() =>
                    setModal(
                        <ConfirmationModal
                            msg="Are you sure that you want to delete this financial statement?"
                            onConfirm={() => onDelete(report.id)}
                            onCancel={() => setModal(null)} />
                    )
                }>
                    Delete
                </Button>}
                <Button size='mini' onClick={onCancel}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal >
    )
}

function ReportForm({ uiReport }) {
    const [renderIdx, setRenderIdx] = useState(0)
    const [activeInputIdx, setActiveInputIdx] = useState(5)

    useEffect(() => {
        uiReport.setChangeHandler(() => setRenderIdx(renderIdx + 1))

        document.addEventListener("keydown", keyPressHandler, false);
        return () => {
            document.removeEventListener("keydown", keyPressHandler, false);
        }
    })

    function keyPressHandler(e) {
        if (e.key == "ArrowDown") {
            setActiveInputIdx(activeInputIdx < uiReport.getEditableFields().length - 1 ? activeInputIdx + 1 : activeInputIdx)
        } else if (e.key == "ArrowUp") {
            setActiveInputIdx(activeInputIdx > 0 ? activeInputIdx - 1 : 0)
        }
    }

    return (
        <table key={renderIdx} className="clientReportForm">
            <tbody>
                {uiReport.getEditableFields().map((field, idx) =>
                    <tr style={field.getStyle()} key={idx} >
                        <td>{field.getLabel()}</td>
                        <td>
                            <Field reportField={field}
                                activate={() => {
                                    setActiveInputIdx(idx)
                                }}
                                deactivate={() => {
                                    if (activeInputIdx === idx) {
                                        setActiveInputIdx(-1)
                                    }
                                }}
                                active={activeInputIdx == idx} />
                        </td>
                        <td>
                            {field.getScaleSymbol()}
                        </td>
                    </tr>
                )}
            </tbody>
        </table >
    )
}

function Field({ reportField, active, activate, deactivate }) {
    const filedClasses = reportField.valid() ? (active ? "activeInput" : "") : "invalidVal"
    return (
        <div style={{ cursor: "pointer" }} className={filedClasses} onMouseDown={activate}>
            {reportField.getPredefinedValues().length > 0 ?
                <SelectField  {...{ reportField, deactivate }} /> :
                <InputField {...{ reportField, active, deactivate }} />}
        </div>
    )
}

function SelectField({ reportField, deactivate }) {
    const options = reportField.getPredefinedValues()
        .map(o => { return { key: o.key, text: o.value, value: String(o.key) } })

    return (
        <Form.Select
            basic={true}
            options={options}
            value={String(reportField.getValue())}
            onChange={(c, v) => reportField.setValue(v.value)}
            onBlur={deactivate}
            className="clientSelectField"
        />
    )
}


function InputField({ reportField, active, deactivate }) {
    const inputRef = useRef(null);
    const [inputVal, setInputVal] = useState(reportField.getValue())
    const [editMode, setEditMode] = useState(active)

    useEffect(() => {
        if (active && editMode) {
            inputRef.current.focus()
        } else if (active) {
            setEditMode(true)
        }

    }, [active, editMode])

    function handleLeave() {
        reportField.setValue(inputVal)
        setEditMode(false)
        deactivate()
    }

    return (
        editMode ?
            <Input size="mini"
                value={inputVal}
                ref={inputRef}
                onBlur={handleLeave}
                onChange={v => setInputVal(v.target.value)}
            /> :
            <div className="readMode">
                {reportField.getUserFrendyValue()}
            </div>
    )
}


/// Business logic
const PRIMARY_IS_DESCRIPTOR = new Descriptor(DescriptorProps.INPUT_SCALE, "Primary Input Scale",
    null, false, {}, false,
    DescriptorType.ANY, false, 1)
PRIMARY_IS_DESCRIPTOR.scalable = false


class ServerToUIReportConvertor {

    static fixedReportProps = [ReportField.SRC]

    static getUIReport(report, flatDescriptors) {
        return new UIReport(report, flatDescriptors,
            d => !DescriptorType.isHeadline(d) &&
                !ServerToUIReportConvertor.fixedReportProps.includes(d.id),
            ServerToUIReportConvertor._convertVal)
    }

    static _convertVal(val, descriptor) {
        let converted
        if (ObjCheck.isNullUndefinedOrEmpty(val)) {
            converted = ""
        } else if (DescriptorType.isNumber(descriptor)) {
            converted = ServerToUIReportConvertor._convertNumReportValue(val)
        } else if (DescriptorType.isPercent(descriptor)) {
            converted = ServerToUIReportConvertor._convertPercentReportValue(val)
        } else {
            converted = String(val)
        }

        return String(converted)
    }

    static _convertNumReportValue(num) {
        if (NumUtils.isNumber(num)) {
            return NumUtils.toNumber(num)
        } else {
            Assert.trueVal(ObjCheck.isEmptyString(num), "Unexpected field value")
            return num
        }
    }

    static _convertPercentReportValue(val) {
        if (val.endsWith("%")) {
            const num = val.replace("%", "")
            if (NumUtils.isNumber(num)) {
                return NumUtils.toNumber(num)
            } else {
                Assert.trueVal(ObjCheck.isEmptyString(num), "Unexpected field value")
                return num
            }
        } else {
            return "ERROR"
        }
    }
}

class UIReport {

    constructor(serverReport, flatDescriptors, editableDescriptorsPredicate, valConvertor) {
        this._serverReport = serverReport
        this._editableDescriptorsPredicate = editableDescriptorsPredicate
        this._fields = {}

        this._onReportChange = () => { }

        const onFieldChange = descriptorId => {
            if (descriptorId === DescriptorProps.INPUT_SCALE) {
                this._scaleReport(this.getField(descriptorId).getValue())
            }

            this._onReportChange()
        }

        this._fields[PRIMARY_IS_DESCRIPTOR.id] = new IUReportField(PRIMARY_IS_DESCRIPTOR, "0", onFieldChange)

        for (const descriptor of flatDescriptors) {
            const dynamic = descriptor[DescriptorProps.DYNAMIC] === true
            const descriptorId = descriptor.id
            const rawVal = dynamic ?
                serverReport[ReportField.DYNAMIC_FIELDS][descriptorId] : serverReport[descriptorId]

            const uiVal = valConvertor(rawVal, descriptor)

            this._fields[descriptorId] = new IUReportField(descriptor, uiVal, onFieldChange)
        }
    }

    setChangeHandler(onChange) {
        this._onReportChange = onChange
    }

    getField(id) {
        return this._fields[id]
    }

    getFields() {
        return Object.values(this._fields)
    }

    getEditableFields() {
        return this.getFields().filter(f => this._editableDescriptorsPredicate(f.descriptor()))
    }

    getOriginalServerReport() {
        return this._serverReport
    }

    valid() {

        return !Object.values(this._fields).some(f => !f.valid())
    }

    _scaleReport(scale) {
        Assert.trueVal(NumUtils.isNumberStandard(scale),`UIReport > _scaleReport > scale is not a number standard scale:${scale}`)
        scale = Number(scale)

        //TODO
        Object.values(this._fields).forEach(f => scale === 0 ? f.setScale(f.descriptor().inputScale) : f.setScale(scale))
        // TODO
        this._onReportChange(this)
    }
}

class IUReportField {

    /**
     * @param {*} descriptor Required
     * @param {string} actualValue Actual value Required
     * @param {func} onChange Required
     */
    constructor(descriptor, actualValue, onChange) {
        Assert.typeString(actualValue, "IUReportField ctor")
        this._descriptor = descriptor
        this._actualValue = actualValue
        this._scale = descriptor.inputScale
        Assert.typeNumber(descriptor.inputScale, "IUReportField ctor")
        this._onChange = onChange

        this._notAppliedValue = null
    }


    /**
     * @returns not-null
     */
    descriptor() {
        return this._descriptor
    }

    /**
     * @returns {string}  not-null
     */
    getScaleSymbol() {
        return this._descriptor.dynamicField && ScaleUtil.numToTextRepresentation(this._scale, true)
    }

    getStyle() {
        return this._descriptor.style
    }

    /**
     * @returns {string}  not-null
     */
    getLabel() {
        return this._descriptor.label
    }

    /**
     * @returns {string}  not-null
     */
    getActualValue() {
        return this._actualValue
    }

    /**
     * @returns {string} the value that without the scale factor.
     */
    getValue() {
        if (this._notAppliedValue !== null) {
            return this._notAppliedValue
        }
        if (this._scalingSubject(this._actualValue)) {
            return String(Number(this._actualValue) / this._scale)
        } else {
            return this._actualValue
        }
    }

    /** 
     * @param {string} value the value that without the scale factor.
     */
    setValue(value) {
        Assert.typeString(value, "IUReportField setValue")
        this._setActualValue(value, this._scale)
        this._onChange(this._descriptor.id)
    }

    /**
     * @param {number} scale Required
     */
    setScale(scale) {
        Assert.typeNumber(scale, "IUReportField setScale")
        if (this._scalable()) {
            const currentVal = this.getValue()
            if (this._scalingSubject(currentVal)) {
                this._setActualValue(currentVal, scale)
            }

            this._scale = scale
        }
    }

    _setActualValue(value, scale) {
        if (this._overflow(value, scale)) {
            this._actualValue = "ERROR: too big"
            this._notAppliedValue = value
        } else if (this._scalingSubject(value)) {
            this._actualValue = String(Number(value) * scale)
            this._notAppliedValue = null
        } else {
            this._actualValue = value
            this._notAppliedValue = null
        }
    }

    /**
     * @returns not-null
     */
    getPredefinedValues() {
        return Utils.predefinedValues(this._descriptor)
    }

    getUserFrendyValue() {
        if (DescriptorType.isNumber(this._descriptor) && NumUtils.isNumberStandard(this._actualValue)) {
            return Number(this.getValue()).toLocaleString("en-US", { maximumFractionDigits: 4 })
        }
        return this._actualValue
    }

    valid() {
        return ((
            !DescriptorType.isNumber(this._descriptor) &&
            !DescriptorType.isPercent(this._descriptor)
        )
            || NumUtils.isNumberStandard(this._actualValue)
            || ObjCheck.isEmptyString(this._actualValue))
            && this._notAppliedValue === null
    }

    validateValue(value) {
        return this._overflow(value, this._scale)
    }

    _scalingSubject(value) {
        return DescriptorType.isNumber(this._descriptor) && NumUtils.isNumberStandard(value) && this._scalable()
    }

    _scalable() {
        return this._descriptor.scalable === true
    }

    _overflow(value, scale) {
        if (DescriptorType.isNumber(this._descriptor)) {
            if (this._scalingSubject(value)) {
                return this._multiplicationOverflow(Number(value), scale)
            }

            return this._numOverflow(Number(value))
        } else {
            return false
        }
    }

    _numOverflow(a) {
        return a > Number.MAX_SAFE_INTEGER || a < Number.MIN_SAFE_INTEGER
    }

    _multiplicationOverflow(a, b) {
        // Check for overflow during multiplication
        if (a > 0) {
            return b > Number.MAX_SAFE_INTEGER / a || b < Number.MIN_SAFE_INTEGER / a;
        } else if (a < 0) {
            return b > Number.MIN_SAFE_INTEGER / a || b < Number.MAX_SAFE_INTEGER / a;
        } else {
            return false;
        }
    }
}



class UIToServerReportConvertor {

    static getServerReport(uiReport, flatDescriptors) {
        const reportCopy = JSON.parse(JSON.stringify(uiReport.getOriginalServerReport()))

        for (const descriptor of flatDescriptors) {
            const dynamic = descriptor[DescriptorProps.DYNAMIC] === true
            const descriptorId = descriptor.id
            const uiField = uiReport.getField(descriptorId)

            const newVal = UIToServerReportConvertor._convertVal(uiField, descriptor)
            if (dynamic) {
                reportCopy[ReportField.DYNAMIC_FIELDS][descriptorId] = newVal
            } else {
                reportCopy[descriptorId] = newVal
            }
        }
        return reportCopy
    }

    static _convertVal(uiField, descriptor) {
        const uiVal = uiField.getActualValue()
        let serverVal
        if (DescriptorType.isNumber(descriptor)) {
            serverVal = UIToServerReportConvertor._convertNumReportValue(uiVal)
        } else if (DescriptorType.isPercent(descriptor)) {
            serverVal = UIToServerReportConvertor._convertPercentReportValue(uiVal)
        } else {
            serverVal = uiVal
        }


        return serverVal
    }

    static _convertNumReportValue(num) {
        if (NumUtils.isNumberStandard(num)) {
            return num.replace(".", ",")
        } else {
            Assert.trueVal(ObjCheck.isEmptyString(num), "Unexpected field value")
            return ""
        }
    }

    static _convertPercentReportValue(num) {
        if (NumUtils.isNumberStandard(num)) {
            return num + "%"
        } else {
            Assert.trueVal(ObjCheck.isEmptyString(num), "Unexpected field value")
            return ""
        }
    }
}

class ReportOps {

    static prepolulateReportFields(report, year, period, version, format) {
        report[ReportField.SRC] = SrcFieldValue.USER_INFO
        report[ReportField.YEAR] = year
        report[ReportField.PERIOD] = period
        report[ReportField.TYPE] = version
        report[ReportField.REPORT_TYPE] = format
    }
}

export { CreateReportModal, UpdateReportModal }