import { Grid, Header, Segment } from "semantic-ui-react"
import { ConfigProps, KeyValueOptions } from './constants'

import { SelectFieldWrapper, SelectField, CheckboxBtn } from './fields'
import AuthService from "../../../service/auth"
import { ROLES } from "../../../constnats/user"

//TODO Rename
function TableAndP2pConfig({ tableConfig, onTableConfigChange, chartsRef }) {

    const onTableConfigPropChange = (prop, val) => {
        onTableConfigChange(Object.assign({}, tableConfig, { [prop]: val }))
    }

    return <Segment className="tableConfigSegment advanced" size="mini">
        <Grid divided>
            <Grid.Row>
                <Grid.Column width={3}>
                    <DataFormatConfig config={tableConfig} onPropChange={onTableConfigPropChange} />
                </Grid.Column>
                <Grid.Column width={5}>
                    <TableStyleConfing config={tableConfig} onPropChange={onTableConfigPropChange} />
                </Grid.Column>
                <Grid.Column width={3}>
                    <DiffDetection config={tableConfig} onPropChange={onTableConfigPropChange} />
                </Grid.Column>
                <Grid.Column width={3}>
                    <OtherConfing config={tableConfig} onPropChange={onTableConfigPropChange} chartsRef={chartsRef} />
                </Grid.Column>

            </Grid.Row>
        </Grid>
    </Segment>
}

function DataFormatConfig({ config, onPropChange }) {

    // const checkbox = (prop, label) => <CheckboxField label={label}
    //     checked={config[prop]}
    //     onChange={data => onPropChange(prop, data)} />

    return <div className="configModule">
        <ModuleHeader text='Data Format' />
        <SelectFieldWrapper label="Thousands separator" prop={ConfigProps.NUMBER_LOCALE}
            config={config} opsKV={KeyValueOptions.THOUSANDS_SEPARATOR_OPS}
            onChange={onPropChange} />
        <SelectFieldWrapper label="Decimals precision" prop={ConfigProps.FLOATING_POINT_PRECISION}
            config={config} opsKV={KeyValueOptions.FLOATING_POINT_PRECISION_OPS}
            onChange={onPropChange} />
        <SelectFieldWrapper label="Labels format" prop={ConfigProps.LABELS_FORMAT}
            config={config} opsKV={KeyValueOptions.LABELS_FORMAT_OPS}
            onChange={onPropChange} />
        {/* {checkbox(ConfigProps.ADD_TRAILING_ZEROS, 'Add Trailing Zeros')} */}
        {/* <SelectFieldWrapper label="Reports Format" prop={ConfigProps.REPORTS_FORMAT}
            config={config} opsKV={["Normalized", "As Reported"].map(KeyValue.createSame)}
            onChange={onPropChange} /> */}
    </div>
}


function TableStyleConfing({ config, onPropChange }) {

    const checkbox = (prop, label) => <CheckboxBtn label={label}
        checked={config[prop]}
        onChange={data => onPropChange(prop, data)} />

    return <div className="configModule">
        <ModuleHeader text='Table Style' />
        <Grid divided>
            <Grid.Row>
                <Grid.Column width={8}> <PeriodClassesConfing config={config[ConfigProps.PERIOD_CLASSES]}
                    onChange={val => onPropChange(ConfigProps.PERIOD_CLASSES, val)} />    </Grid.Column>
                <Grid.Column width={8}>
                    <SelectFieldWrapper label="Header column font size" prop={ConfigProps.HEADER_COLUMN_FONT_SIZE}
                        config={config} opsKV={KeyValueOptions.HEADER_COLUMN_FONT_SIZE_OPS}
                        onChange={onPropChange} />
                    <SelectFieldWrapper label="Header column width" prop={ConfigProps.HEADER_COLUMN_WIDTH}
                        config={config} opsKV={KeyValueOptions.HEADER_COLUMN_WIDTH_OPS}
                        onChange={onPropChange} />
                    {checkbox(ConfigProps.EQUAL_ROWS_HIGH, 'Equal rows height')}
                    {checkbox(ConfigProps.HIDE_EMPTY_ROWS, 'Hide empty rows')}
                    {AuthService.hasGlobalRole(ROLES.EMPLOYEE) && checkbox(ConfigProps.SHOW_IMPORTANT_ROWS_ONLY, 'Overview Mode')}
                    {checkbox(ConfigProps.SCALE_COLUMN, 'Scale column')}
                    {checkbox(ConfigProps.VERTICALS, 'Verticals')}
                    {checkbox(ConfigProps.HORIZONTALS, 'Horizontals')}
                    {checkbox(ConfigProps.SHOW_INFO_ICONS, "Info Icons")}

                    {/* {checkbox(ConfigProps.HIDE_STANDALONE_CALCED_CELLS, 'Hide Standalone Calculated Cells')} */}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>
}

function DiffDetection({ config, onPropChange }) {

    const checkbox = (prop, label) => <CheckboxBtn label={label}
        checked={config[prop]}
        onChange={data => onPropChange(prop, data)} />



    return <div className="configModule">
        <ModuleHeader text='Fuzzy Diff Detection' />
        <SelectFieldWrapper label="Threshold for numeric value diff detection"
            prop={ConfigProps.DIFF_DETECTION_THRESHOLD}
            config={config} opsKV={KeyValueOptions.DIFF_DETECTION_THRESHOLD_OPS}
            onChange={onPropChange} />
        {checkbox(ConfigProps.DIFF_DETECTION_IGNORE_ZERO_DASH, 'Treat "-" and "0" values as no diff')}
    </div>
}

function ChartsCheckbox({ prop, label, onPropChange, config, chartsRef }) {
    const handleChange = (data) => {
        onPropChange(prop, data)
        if (data && chartsRef.current) {
            chartsRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }
    return (
        <CheckboxBtn
            label={label}
            checked={config[prop]}
            onChange={data => handleChange(data)}
        />
    )
}

function OtherConfing({ config, onPropChange, chartsRef }) {

    const checkbox = (prop, label) => <CheckboxBtn
        label={label}
        checked={config[prop]}
        onChange={data => onPropChange(prop, data)} />

    return <div className="configModule">
        <ModuleHeader text='Other' />
        {checkbox(ConfigProps.SHOW_NOTES, 'Show Notes')}
        {checkbox(ConfigProps.SHOW_3D_RATIOS, 'Show 3D Ratios')}
        {checkbox(ConfigProps.SHOW_CALCULATED_STATEMENTS, 'Calculate missing Financial Statements')}
        {checkbox(ConfigProps.GRANULAR_PERIODS_FILTERING, 'Detailed Periods filtering')}
        <ChartsCheckbox
            chartsRef={chartsRef}
            label={"Charts"}
            config={config}
            onPropChange={onPropChange}
            prop={ConfigProps.SHOW_CHARTS}
        />
        {AuthService.hasGlobalRole(ROLES.EMPLOYEE) && checkbox(ConfigProps.CATEGORY_BASED_MODE, 'Category based mode')}
        {checkbox(ConfigProps.SHOW_ORIGINAL_CELL_DATA_IF_LATEST_MISSING,"Show Original cell data if Latest is missing.")}
    </div>
}

function PeriodClassesConfing({ config, onChange }) {
    const onPropChange = (prop, val) => {
        onChange(Object.assign({}, config, { [prop]: val }))
    }

    const perodClassesKV = KeyValueOptions.PERIOD_CLASS_OPS

    return <>
        <SelectFieldWrapper label="FY Colour" prop={ConfigProps.FY_CLASS}
            config={config} opsKV={perodClassesKV} onChange={onPropChange} />
        <SelectFieldWrapper label="Hs Colour" prop={ConfigProps.HS_CLASS}
            config={config} opsKV={perodClassesKV} onChange={onPropChange} />
        <SelectFieldWrapper label="9M Colour" prop={ConfigProps.NINE_M_CLASS}
            config={config} opsKV={perodClassesKV} onChange={onPropChange} />
        <SelectFieldWrapper label="Qs Colour" prop={ConfigProps.QS_CLASS}
            config={config} opsKV={perodClassesKV} onChange={onPropChange} />
    </>
}


function ModuleHeader({ text }) {
    return <Header as="h5" textAlign='center'>{text}</Header>
}

export default TableAndP2pConfig