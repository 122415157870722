import { MetadataField, ReportField, SrcFieldValue } from '../../../../../../constnats/reportConstants';
import { Button, Modal, Form, Table, Header } from 'semantic-ui-react';
import ReportsRepo from '../../../../../../utils/repository/repoertsRepo';
import { useState } from 'react';
import Loader from '../../../../../../components/loader';
import ReportUtils from '../../../../../../utils/reportUtils';
import Utils from '../../../../../../utils/descriptor/descriptorUtils';
import { DescriptorProps } from '../../../../../../constnats/descriptor';
import ConfirmationModal from '../../../../../../components/confirmationModal';

const DIFF_METADATA_PROPS = [
    MetadataField.REPORTED_AS_LABEL, MetadataField.CALCS_DISABLED,
    MetadataField.MANUALLY_CALCED, MetadataField.INPUT_SCALE,
    MetadataField.NOTES, MetadataField.SRC
]

const DEFAULT_COPY_CFG = {
    [MetadataField.REPORTED_AS_LABEL]: true,
    [MetadataField.CALCS_DISABLED]: false,
    [MetadataField.MANUALLY_CALCED]: false,
    [MetadataField.INPUT_SCALE]: false,
    [MetadataField.NOTES]: false,
    [MetadataField.SRC]: false,
}


function updateReports(reports, onComlete) {
    function updateReport(reports, idx) {
        if (reports[idx] !== undefined) {
            ReportsRepo.update(reports[idx], res => updateReport(reports, ++idx))
        } else if (reports.length > 0) {
            onComlete()
        }
    }

    updateReport(reports, 0)
}

/**
 * @description
 * Checks to see if there are conflicts between 2 mutually exclusive fields.
 * If there is a conflict it returns true if there is not it returns false
 * Manually calculated fields are mutually exclusive with Calculation disabled fields.
 * In this case it will check if the prop is either manually calculated or calculation disabled
 * If it is it will check their values from the source and target meatadata.
 * Manually calculated and Calculation disabled are bool values, if both are true
 * there is a conflict and the function will return true 
 * @param {string} currentProp 
 * @param {Object} targetMeta 
 * @param {Object} srcMeta 
 * @returns {boolean} not-null
 */
function hasManuallyCalcedAndCalcDisabledConflicts(currentProp, targetMeta, srcMeta) {
    let hasConflict = false

    if (currentProp === MetadataField.CALCS_DISABLED && srcMeta[currentProp] && targetMeta[MetadataField.MANUALLY_CALCED]) {
        hasConflict = true
    } else if (currentProp === MetadataField.MANUALLY_CALCED && srcMeta[currentProp] && targetMeta[MetadataField.CALCS_DISABLED]) {
        hasConflict = true
    }

    return hasConflict
}

/**
 * @param {Object} mutuallyExclusiveConflicts Object containing reports ids (for which a conflict has been found) as key and an object as a value that contains the report identifiers (year period report type and type) as well as the descriptorsIs (as an array) that have a conflict  
 * @param {*} targetReportNormalized 
 * @param {*} descriptor 
 */
function fillInManuallyCalcedAndCalcDisabledConflicts(mutuallyExclusiveConflicts, targetReportNormalized, descriptor) {
    if (!mutuallyExclusiveConflicts[targetReportNormalized[ReportField.ID]]) {
        mutuallyExclusiveConflicts[targetReportNormalized[ReportField.ID]] = {
            [ReportField.YEAR]: targetReportNormalized[ReportField.YEAR],
            [ReportField.PERIOD]: targetReportNormalized[ReportField.PERIOD],
            [ReportField.REPORT_TYPE]: targetReportNormalized[ReportField.REPORT_TYPE],
            [ReportField.TYPE]: targetReportNormalized[ReportField.TYPE],
            descriptors: []
        }
    }
    mutuallyExclusiveConflicts[targetReportNormalized[ReportField.ID]].descriptors.push(descriptor[DescriptorProps.ID])
}

/**
 * 
 * @param {Object.<string, {year:string,period:string,reportType:string,type:string,descriptors:string[]}>} mutuallyExclusiveConflicts
 * @param {Object} flatDescriptorsMap
 * @returns {string}
 */
function getMessageForFieldsWithManuallyCalcedAndCalcDisabled(mutuallyExclusiveConflicts, flatDescriptorsMap) {
    let message = '';
    for (const conflictInformation of Object.values(mutuallyExclusiveConflicts)) {
        const descriptorsLabels = conflictInformation.descriptors.map(descriptorId => {
            return flatDescriptorsMap[descriptorId][DescriptorProps.LABEL]
        }).join(", ");
        message += `${conflictInformation[ReportField.YEAR]} ${conflictInformation[ReportField.PERIOD]} ${conflictInformation[ReportField.REPORT_TYPE]} ${conflictInformation[ReportField.TYPE]} has fields that will be marked as both Manually calculated and Calculation disabled. Due to this the Manually calculated and Calculations disabled property will NOT BE copied over. Fields: ${descriptorsLabels}\n`
    }

    return message
}

/**
 * @param {Object} configMap 
 * @param {Object[]} nonMixedReport 
 * @param {Object[]} descriptors 
 * @param {Object} mutuallyExclusiveConflicts 
 * @returns {string} not-null
 */
function getMessageFormConfirmationModal(configMap, nonMixedReport, descriptors, mutuallyExclusiveConflicts) {
    let message = "";

    if (configMap[MetadataField.SRC] && nonMixedReport) {
        message += `You are about to copy source fields from a Mixed to non Mixed report\n`
    }

    const flatDescriptorsMap = Utils.flatDescriptorsMap(descriptors)
    const manuallyCalcedAndCalcDisabledMessage = getMessageForFieldsWithManuallyCalcedAndCalcDisabled(mutuallyExclusiveConflicts, flatDescriptorsMap)

    message += `${manuallyCalcedAndCalcDisabledMessage}`

    return message
}



function prepareDescriptorsForUpdate(descriptors, configMap, srcFieldSpecificMetadata, targetReportNormalized, mutuallyExclusiveConflicts) {

    for (const descriptor of descriptors) {
        const descriptorId = descriptor.id
        const srcMeta = srcFieldSpecificMetadata[descriptorId]
        const targetMeta = ReportUtils.getFieldMetadata(targetReportNormalized, descriptorId)
        for (const prop of Object.keys(configMap)) {
            if (configMap[prop]) {
                if (hasManuallyCalcedAndCalcDisabledConflicts(prop, targetMeta, srcMeta)) {
                    fillInManuallyCalcedAndCalcDisabledConflicts(mutuallyExclusiveConflicts, targetReportNormalized, descriptor)
                } else {
                    targetMeta[prop] = srcMeta[prop]
                }
            }
        }
        const subFields = descriptor[DescriptorProps.SUBFIELDS]
        if (subFields?.length > 0) {
            prepareDescriptorsForUpdate(subFields, configMap, srcFieldSpecificMetadata, targetReportNormalized, mutuallyExclusiveConflicts)
        }
    }
}

function prepareReportsForUpdate(srcReport, targetReports, descriptors,
    schemaId, configMap) {
    const mutuallyExclusiveConflicts = {}
    const srcReportCopy = JSON.parse(JSON.stringify(srcReport))
    const srcReportNormalized = ReportUtils.normalizeReport(srcReportCopy, descriptors)
    const srcFieldSpecificMetadata = ReportUtils.getFieldsMetadata(srcReportNormalized)

    const preparedReports = []
    for (const report of targetReports) {
        const targetReportCopy = JSON.parse(JSON.stringify(report))
        const oldTargetReportNormalized = ReportUtils.normalizeReport(targetReportCopy, descriptors)
        const newTargetReportNormalized = JSON.parse(JSON.stringify(oldTargetReportNormalized))

        prepareDescriptorsForUpdate(descriptors, configMap, srcFieldSpecificMetadata, newTargetReportNormalized, mutuallyExclusiveConflicts)
        targetReportCopy.schemaId = schemaId
        preparedReports.push({ oldReport: oldTargetReportNormalized, newReport: newTargetReportNormalized })

    }

    return [preparedReports, mutuallyExclusiveConflicts]
}

function getConflicts(preUpdateReport, postUpdateReport) {
    const conflicts = {}

    const defaultMetadata = ReportUtils.getDefaultMetadata()
    for (const descriptorId of Object.keys(ReportUtils.getFieldsMetadata(preUpdateReport))) {
        const oldMetadata = ReportUtils.getFieldMetadata(preUpdateReport, descriptorId)
        const newMetadata = ReportUtils.getFieldMetadata(postUpdateReport, descriptorId)

        for (const prop of DIFF_METADATA_PROPS) {

            if (oldMetadata[prop] !== newMetadata[prop] &&
                defaultMetadata[prop] !== oldMetadata[prop]) {
                if (conflicts[descriptorId] === undefined) {
                    conflicts[descriptorId] = []
                }

                conflicts[descriptorId].push(new PropDiff(prop, oldMetadata[prop], newMetadata[prop]))
            }
        }
    }

    return conflicts
}

function ConfigField({ configMap, property, label, onMapChange }) {
    return <Form.Checkbox checked={configMap[property]} label={label}
        onChange={(evt, data) => {
            const mapCopy = Object.assign({}, configMap)
            mapCopy[property] = data.checked
            onMapChange(mapCopy)
        }} />
}

function CopyDataModal({ schemaId, srcReport, copyTargets, descriptors, closeModal }) {
    const [loading, setLoading] = useState(false)
    const [availableTargets] = useState(copyTargets)
    const [selectedTargetIds, setSelectedTargetIds] = useState(availableTargets.map(r => r.id))
    const [configMap, setConfigMap] = useState(Object.assign({}, DEFAULT_COPY_CFG))
    const [confirmModal, setConfirmModal] = useState(<></>)

    const [preparedReportsForUpdate, mutuallyExclusiveConflicts] = prepareReportsForUpdate(srcReport,
        availableTargets, descriptors, schemaId, configMap)

    function onSelectedTargetsChange(id, added) {
        const copy = selectedTargetIds.slice()
        if (added) {
            copy.push(id)
        } else {
            const index = copy.indexOf(id);
            if (index !== -1) {
                copy.splice(index, 1);
            }
        }

        setSelectedTargetIds(copy)
    }

    function handleCopy(reportsToUpdate) {
        setLoading(true)
        updateReports(reportsToUpdate,
            () => {
                setConfirmModal(<></>)
                setLoading(false)
                closeModal(true)
            })
    }

    return loading ? <Loader /> :
        <Modal size='large' open={true}>
            {confirmModal}
            <Modal.Header>Copy Report Data</Modal.Header>
            <Modal.Content >
                {availableTargets.length === 0 ?
                    <Header as="h4" textAlign='center'>No Corresponding Reports</Header> :
                    <>
                        <Header as="h5">Copy Configuration</Header>
                        <Form>
                            <ConfigField configMap={configMap}
                                property={MetadataField.REPORTED_AS_LABEL}
                                label="Copy Labels"
                                onMapChange={setConfigMap} />
                            <ConfigField configMap={configMap}
                                property={MetadataField.NOTES}
                                label="Copy Notes"
                                onMapChange={setConfigMap} />
                            <ConfigField configMap={configMap}
                                property={MetadataField.INPUT_SCALE}
                                label="Copy Scale"
                                onMapChange={setConfigMap} />
                            <ConfigField configMap={configMap}
                                property={MetadataField.MANUALLY_CALCED}
                                label="Copy Manually Calculated"
                                onMapChange={setConfigMap} />
                            <ConfigField configMap={configMap}
                                property={MetadataField.CALCS_DISABLED}
                                label="Copy Disable Automatic Calculatios"
                                onMapChange={setConfigMap} />
                            <ConfigField configMap={configMap}
                                property={MetadataField.SRC}
                                label="Copy Fields Source"
                                onMapChange={setConfigMap} />
                        </Form>
                    </>}
                <Preview {...{
                    selectedTargetIds, onSelectedTargetsChange,
                    preparedReportsForUpdate, descriptors
                }} />
            </Modal.Content>
            <Modal.Actions>
                <Button size='mini' primary onClick={() => {
                    const reportsToUpdate = preparedReportsForUpdate.map(p => p.newReport)
                        .filter(r => selectedTargetIds.includes(r.id))
                    const nonMixedReport = reportsToUpdate.find(report => report[ReportField.SRC] !== SrcFieldValue.MIXED)

                    if (configMap[MetadataField.SRC] && nonMixedReport || !!Object.keys(mutuallyExclusiveConflicts).length) {
                        const message = getMessageFormConfirmationModal(configMap, nonMixedReport, descriptors, mutuallyExclusiveConflicts)
                        setConfirmModal(<ConfirmationModal className='pre-wrap' msg={message} onConfirm={() => handleCopy(reportsToUpdate)} onCancel={() => setConfirmModal(<></>)} />)
                    } else {
                        handleCopy(reportsToUpdate)
                    }

                }}>
                    Copy
                </Button>
                <Button size='mini' onClick={closeModal}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal >
}

function Preview({ selectedTargetIds, preparedReportsForUpdate,
    onSelectedTargetsChange, descriptors }) {

    const flatDescriptors = Utils.flatDescriptorsMap(descriptors)

    return preparedReportsForUpdate.length > 0 &&
        <>
            <Header as="h5">The following {selectedTargetIds.length} reports are going to be updated.</Header>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Year</Table.HeaderCell>
                        <Table.HeaderCell>Period</Table.HeaderCell>
                        <Table.HeaderCell>Filling Type</Table.HeaderCell>
                        <Table.HeaderCell>Conflicts</Table.HeaderCell>
                        <Table.HeaderCell>Copy To</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {preparedReportsForUpdate.map((p, idx) => {
                        const newReport = p.newReport

                        return <Table.Row key={idx} >
                            <Table.Cell>{newReport.year}</Table.Cell>
                            <Table.Cell>{newReport.period}</Table.Cell>
                            <Table.Cell>{newReport.type}</Table.Cell>
                            <Table.Cell>
                                <Diff flatDescriptors={flatDescriptors}
                                    preUpdateReport={p.oldReport}
                                    postUpdateReport={newReport} />
                            </Table.Cell>
                            <Table.Cell>
                                <Form.Checkbox checked={selectedTargetIds.includes(newReport.id)}
                                    onChange={(evt, data) =>
                                        onSelectedTargetsChange(newReport.id, data.checked)
                                    } />
                            </Table.Cell>
                        </Table.Row>
                    })}
                </Table.Body>
            </Table >
        </>
}

class PropDiff {
    constructor(prop, oldVal, newVal) {
        this.prop = prop
        this.oldVal = oldVal
        this.newVal = newVal
    }
}

function Diff({ preUpdateReport, postUpdateReport, flatDescriptors }) {
    const diff = getConflicts(preUpdateReport, postUpdateReport)

    return Object.keys(diff).length > 0 &&
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Field</Table.HeaderCell>
                    <Table.HeaderCell>Property</Table.HeaderCell>
                    <Table.HeaderCell>Old Value</Table.HeaderCell>
                    <Table.HeaderCell>New Value</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {Object.entries(diff).map(([k, v], idx) =>
                    v.map((d, idx2) =>
                        <Table.Row warning key={idx + "_" + idx2}>
                            <Table.Cell>{flatDescriptors[k].label}</Table.Cell>
                            <Table.Cell>{d.prop}</Table.Cell>
                            <Table.Cell>{String(d.oldVal === null ? false : d.oldVal)}</Table.Cell>
                            <Table.Cell>{String(d.newVal === null ? false : d.newVal)}</Table.Cell>
                        </Table.Row>)
                )}
            </Table.Body>
        </Table>
}


export default CopyDataModal