import React from 'react';
import { Form, Button, Dropdown, Header } from 'semantic-ui-react'
import UserRepo from '../../../utils/repository/userRepo';
import RolesRepo from '../../../utils/repository/rolesRepo';
import Loader from '../../../components/loader';
import { useParams } from 'react-router-dom';
import Alert from '../../../utils/alert';
import { Link } from "react-router-dom";
import FiledsValidator from '../../../utils/validation/fieldsValidator';

class EditUserAdminFormInternal extends React.Component {

    constructor(props) {
        super(props);

        var emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        this.filedsValidator = new FiledsValidator(["fullName", "email"],
            {
                "email": { validate: val => val.match(emailRegex), msg: "The email is invalid" },
                "fullName": { validate: val => val.length < 51 && val.length > 3, msg: "It must be shorter than 50 symbols and longer than 3" },
            })

        this.state = {
            user: null,
            roles: null,
            originalFullName: null,
            propsValidation: this.filedsValidator.getValidErrorProps(),
        }
    }

    componentDidMount() {
        UserRepo.get(this.props.userId, user => this.setState({ user: user, originalFullName: user.fullName }))
        RolesRepo.list(roles => this.setState({ roles: roles }))
    }

    save() {
        if (this.validate()) {
            const spec = Object.assign({}, this.state.user)
            spec.userId = this.state.user.id
            UserRepo.updateInternal(spec, res =>
                Alert.success("The user roles are successfully updated."))
        }
    }

    validate() {
        const validationRes = this.filedsValidator.validate(this.state.user)
        this.setState({ propsValidation: validationRes.newErrorProps })
        return validationRes.valid
    }

    onRolesChange(newRoles) {
        this.setState({ selectedRoles: newRoles })
    }

    onPropChange(newVal, prop) {
        const user = Object.assign({}, this.state.user)
        user[prop] = newVal
        this.setState({
            user,
            propsValidation: this.filedsValidator.dicardErrorIfExists(prop)
        })
    }

    getField(forProp, label, type = "text") {
        return <Form.Field>
            <Form.Input
                input={type}
                label={label}
                placeholder={label}
                onChange={v => this.onPropChange(v.target.value, forProp)}
                value={this.state.user[forProp]}
                error={this.state.propsValidation[this.filedsValidator.getErrorPropName(forProp)]} />
        </Form.Field>
    }

    getForm() {
        const options = this.state.roles.map(r => {
            return {
                key: r.id,
                text: r.name,
                value: r.name
            }
        })
        const user = this.state.user

        return <>
            <Header as="h2" textAlign='center'>{this.state.originalFullName}</Header>
            <Form>
                {this.getField("fullName", "Full Name")}
                {this.getField("email", "Email")}
                <Form.Field>
                    <label>Roles</label>
                    <Dropdown
                        onChange={(a, b) => this.onPropChange(b.value, "roles")}
                        value={user.roles}
                        placeholder='User Roles'
                        options={options} fluid multiple selection search />
                </Form.Field>
                <Form.Checkbox label="Disable"
                    checked={user.disabled}
                    onChange={(e, data) => this.onPropChange(data.checked, "disabled")} />
                <Button size='mini' onClick={() => this.save()} primary>Save</Button>
                <Link to='/admin/users'>Manage Other Users</Link>
            </Form></>

    }

    render() {
        return (this.state.user === null || this.state.roles === null) ?
            <Loader /> : this.getForm()
    }
}


export default function EditUserAdminForm() {
    const params = useParams();

    return <EditUserAdminFormInternal userId={params.userID} />;
}

