import HttpReq from "./httpReq";

class NewslettersService {

    static epPath = 'api/mgmt/newsletters'

    static send(newsletter, onSuccess, onError) {
        HttpReq.post(NewslettersService.epPath, newsletter, onSuccess, onError)
    }
}

export default NewslettersService