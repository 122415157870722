import React, { useEffect, useState } from 'react';
import AuthService from '../../../service/auth';
import UserRepo from '../../../utils/repository/userRepo';
import { Checkbox } from 'semantic-ui-react';

function UserProfile() {
    const [user, setUser] = useState(null)


    const userId = AuthService.getUserId()
    useEffect(() => {
        UserRepo.get(AuthService.getUserId(), setUser)
    }, [userId])

    return user &&
        <>
            <h2>Profile</h2>
            <b>Name: </b>{user.fullName}
            <br />
            <b>Email: </b>{user.email}
            <br />
            <Checkbox
                checked={user.newsletterSubscription}
                label="I want to receive newsletters on my email."
                onChange={(evt, data) =>
                    UserRepo.update({
                        userId: user.id,
                        newsletterSubscription: data.checked
                    }, () => window.location.reload())
                } />
        </>
}


export default UserProfile


