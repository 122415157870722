import HttpReq from "./httpReq";
import AuthMonitor from "./authMonitor";
import ObjCheck from "../utils/objCheck";
import { ROLES } from "../constnats/user";

class AuthService {
    static apiKey = 'api/auth'
    static authContext = 'user'

    static register(registerData, registrationResConsumer, errorConsumer) {
        HttpReq.post(AuthService.apiKey + "/register", registerData, registrationResConsumer, errorConsumer)
    }

    static login(loginData, errorConsumer) {
        HttpReq.post(AuthService.apiKey + "/token", loginData, AuthService._loginHandler, errorConsumer)
    }

    static googleLogin(googleJwt, errorConsumer) {
        HttpReq.post(AuthService.apiKey + "/token/google", { jwtToken: googleJwt.credential }, AuthService._loginHandler, errorConsumer)
    }

    static logout() {
        AuthService._cleanAuthContext()
        AuthMonitor.onChange()
    }

    /**
     * @param {func} onSuccess Executed on successfull token renew
     * @param {func} onError Executed if token renew fail
     */
    static refreshAuthToken(onSuccess, onError) {
        const refreshToken = AuthService._getRefreshToken()
        if (refreshToken === null) {
            onError()
        } else {
            const renewSpec = { refreshToken: refreshToken }
            HttpReq.post(AuthService.apiKey + "/token/renew", renewSpec,
                authData => {
                    AuthService._persistAuthContext(authData);
                    AuthMonitor.onChange()
                    onSuccess()
                }, error => {
                    if (!ObjCheck.isNullOrUndefined(error) &&
                        !ObjCheck.isNullOrUndefined(error.json) &&
                        error.json.statusCode === 400) {
                        onError()
                    } else {
                        AuthMonitor.onIssue({
                            msg: "Cannot renew your session because of network prblems. Please check you network and click 'Retry'.",
                            resolve: () => AuthService.refreshAuthToken(() => onSuccess(), () => onError())
                        })
                    }
                })
        }
    }

    /**
     * @returns Auth context or null if auth context not presented.
     */
    static getAuthToken() {
        return AuthService._getAuthContextProp("authToken")
    }

    /**
     * @returns Auth context or null if not presented.
     */
    static getAuthContext() {
        const auth = localStorage.getItem(AuthService.authContext)
        return auth === null ? null : JSON.parse(auth)
    }

    /**
      * @param {auth context} authContext Optional
      * @param {string} roleName Required
      * @returns boolean
      */
    static hasRole(authContext, roleName) {
        return AuthService.hasAnyRole(authContext, [roleName])
    }

    /**
      * @param {string} roleName Required
      * @returns boolean
      */
    static hasGlobalRole(roleName) {
        return AuthService.hasRole(AuthService.getAuthContext(), roleName)
    }

    /**
      * @param {string} roleNames Required
      * @returns boolean
      */
    static hasAnyGlobalRole(roleNames) {
        return AuthService.hasAnyRole(AuthService.getAuthContext(), roleNames)
    }

    /**
     * @param {auth context} authContext Optional
     * @param {Collection of string} roleNames Required
     * @returns boolean
     */
    static hasAnyRole(authContext, roleNames) {
        let hasRole = false
        if (authContext) {
            hasRole = roleNames.findIndex(roleName => authContext.roles.findIndex(r => r.name === roleName) >= 0) >= 0

            if (!hasRole) {
                const entityId = authContext.entityId
                if (entityId > 0) {
                    hasRole = AuthService._hasAnyEntityLevelRole(authContext, entityId, roleNames)
                }
            }
        }

        return hasRole
    }

    /**
     * @param {auth context} authContext Optional
     * @returns not-null
     */
    static getGlobalRoles(authContext) {

        const roles = new Set()

        if (!ObjCheck.isNullOrUndefined(authContext) && !ObjCheck.isNullOrUndefined(authContext.roles)) {
            authContext.roles.forEach(role => roles.add(role.name))
        }

        return {
            has: role => roles.has(role),
            hasAny: rolesList => rolesList.findIndex(role => roles.has(role)) >= 0,
            size: () => roles.size
        }
    }

    /**
     * @returns Full name or null if auth context not presented.
     */
    static getFullName() {
        return AuthService._getAuthContextProp("fullName")
    }

    /**
     * @returns userId or null if auth context not presented.
     */
    static getUserId() {
        return AuthService._getAuthContextProp("userId")
    }

    static isAuthenticated() {
        return AuthService._getAuthContextProp("userId") !== null
    }

    static _hasAnyEntityLevelRole(authContext, entityId, roleNames) {
        const userPermission = authContext.permissions[entityId]
        if (userPermission) {
            const globalRoles = AuthService._globalRoles()
            const roleIds = roleNames
                .map(n => globalRoles.find(r => r.name === n))
                .filter(r => ObjCheck.isNullOrUndefined())
                .map(r => r.id)
            for (const roleId of roleIds) {
                if (userPermission.roleIds.includes(roleId)) {
                    return true
                }
            }
        }

        return false
    }

    static _getRefreshToken() {
        return AuthService._getAuthContextProp("refreshToken")
    }

    static _getAuthContextProp(prop) {
        const auth = AuthService.getAuthContext()
        return auth === null ? null : auth[prop];
    }

    static _globalRoles() {
        const auth = AuthService.getAuthContext()
        return auth === null ? [] : auth.roles
    }

    static _persistAuthContext(authContext) {
        localStorage.setItem(AuthService.authContext, JSON.stringify(authContext))
    }

    static _cleanAuthContext() {
        localStorage.removeItem(AuthService.authContext);
    }

    static _loginHandler(authData) {
        AuthService._persistAuthContext(authData)
        if (AuthService.hasGlobalRole(ROLES.EMPLOYEE)) {
            // Needed in order to ensure that the activity loggger is activated
            window.location.reload()
        } else {
            AuthMonitor.onChange()
        }
    }


}

export default AuthService