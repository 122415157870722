import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import { Tab, Loader, Header } from 'semantic-ui-react'
import ResourceRepo from "../../../utils/repository/resourceRepo";
import { ResourceProps, sortResourceInfos, toResourceProps } from "../../../utils/reportResourceUtil";
import { ResourceCategory } from "../../../constnats/resourceConstants";
import Alert from "../../../utils/alert";


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const GENERAL_TAB_NAME = "General"
const IS_TAB_NAME = "Income Statement"
const BS_TAB_NAME = "Balance Sheet"
const CF_TAB_NAME = "Cash Flow"
const KPIS_TAB_NAME = "KPIs"
const OUTLOOK_TAB_NAME = "Outlook"
const SEGMENTS_TAB_NAME = "Segments"
const OTHER_TAB_NAME = "Other"

const LEVEL_ONE_TABS = [
    GENERAL_TAB_NAME,
    IS_TAB_NAME,
    BS_TAB_NAME,
    CF_TAB_NAME,
    KPIS_TAB_NAME,
    OUTLOOK_TAB_NAME,
    SEGMENTS_TAB_NAME,
    OTHER_TAB_NAME
]

const RESOURCE_CATEGORIES_TO_LEVEL_ONE_TAB = {
    [ResourceCategory.PRESENTATION]: GENERAL_TAB_NAME,
    [ResourceCategory.PRESS_RELEASE]: GENERAL_TAB_NAME,
    [ResourceCategory.REPORT]: GENERAL_TAB_NAME,
    [ResourceCategory.INCOME_STATEMENT]: IS_TAB_NAME,
    [ResourceCategory.BALANCE_SHEET]: BS_TAB_NAME,
    [ResourceCategory.CASH_FLOW]: CF_TAB_NAME,
    //We have some incorrectly named Cash Flows
    "Cash flow": CF_TAB_NAME,
    [ResourceCategory.KPI]: KPIS_TAB_NAME,
    [ResourceCategory.OUTLOOK]: OUTLOOK_TAB_NAME,
    [ResourceCategory.PRESENTATION_OUTLOOK]: OUTLOOK_TAB_NAME,
    [ResourceCategory.PRESS_RELEASE_OUTLOOK]: OUTLOOK_TAB_NAME,
    [ResourceCategory.REPORT_OUTLOOK]: OUTLOOK_TAB_NAME,
    [ResourceCategory.SEG_BY_PROD]: SEGMENTS_TAB_NAME,
    [ResourceCategory.SEG_BY_REG]: SEGMENTS_TAB_NAME,
    "Segments": SEGMENTS_TAB_NAME,
    [ResourceCategory.REPORT_SUPLEMENT]: OTHER_TAB_NAME,
}


function toResourcesModel(resources) {
    const model = {}

    LEVEL_ONE_TABS.forEach(t => {
        model[t] = null
    })

    resources.forEach(serverResource => {
        const path = serverResource.path
        const serverUrl = serverResource.serverUrl
        const resourceProps = toResourceProps(path)
        const resource = {
            serverUrl, path, name: ResourceProps.userFriendlyName(resourceProps),
            extension: resourceProps.fileExtension,
        }

        const finDocType = resourceProps.finDocType
        const fileExtension = resourceProps.fileExtension
        let tabName = RESOURCE_CATEGORIES_TO_LEVEL_ONE_TAB[finDocType]
        if (tabName === undefined) {
            console.error("Unexpected fin doc type " + finDocType)
            tabName = OTHER_TAB_NAME
        }
        if (model[tabName] === null) {
            model[tabName] = { pdfs: [], images: [], other: [] }
        }

        if (fileExtension === "png") {
            model[tabName].images.push(resource)
        } else if (fileExtension === "pdf") {
            model[tabName].pdfs.push(resource)
        } else {
            model[tabName].other.push(resource)
        }
    })

    Object.keys(model).forEach(k => {
        if (model[k] === null) {
            delete model[k]
        } else {
            sortResourceInfos(model[k].pdfs)
            sortResourceInfos(model[k].images)
            sortResourceInfos(model[k].other)
        }
    })

    return model
}

function resourceModelToTabsCfg(resourcesModel, activeTabIdx) {
    return Object.keys(resourcesModel || {}).map((resourceCathegory, idx) => {
        const resources = resourcesModel[resourceCathegory]

        return getTabCfg(
            resourceCathegory,
            <SubTabs hidden={activeTabIdx !== idx} key={resourceCathegory} {...resources} />
        )
    })
}

function getTabCfg(name, content) {
    return {
        key: name,
        menuItem: name,
        pane: content
    }
}

function ResourceViewer() {
    let params = useParams();
    const query = JSON.parse(atob(params.query))
    const [activeTabIdx, setActiveTabIdx] = React.useState(0)
    const [resourcesModel, setResourcesModel] = React.useState(null)

    const handleTabChange = (e, { activeIndex }) => setActiveTabIdx(activeIndex)

    useEffect(() => ResourceRepo.listReportResources(query, resources => {
        setResourcesModel(toResourcesModel(resources))
    }, err => {
        console.error("Failed to load resources" + err)
        Alert.error("Unexpected error. Plase try again.", "N/A", false)
        setResourcesModel({})
    }), [params.query])



    const generalTabs = resourceModelToTabsCfg(resourcesModel, activeTabIdx)

    if (!resourcesModel) {
        return <Loader />
    }

    return <Tab
        renderActiveOnly={false}
        activeIndex={activeTabIdx}
        onTabChange={handleTabChange}
        className="resourceViewer"
        panes={generalTabs} />
}

function SubTabs({ pdfs, images, other, hidden }) {
    const { height } = useWindowDimensions();

    const subTabs = []

    subTabs.push(
        ...pdfs.map(pdf =>
            getTabCfg(
                pdf.name,
                <Tab.Pane className="pdf" key={pdf.path}>
                    <object data={pdf.serverUrl + "/" + pdf.path} width="100%" height={(height - 70) + "px"} ></object>
                </Tab.Pane>)
        )
    )

    if (images.length > 0) {
        subTabs.push(
            getTabCfg(
                "Images",
                <Tab.Pane className="img" key="images" >
                    {images.map((image, idx) =>
                        <div key={idx}>
                            <Header as="h3" textAlign='center'> {image.name}</Header>
                            <img style={{ display: "block", maxWidth: "100%" }} src={image.serverUrl + "/" + image.path} />
                        </div>
                    )}
                </Tab.Pane>
            )
        )
    }

    if (other.length > 0) {
        subTabs.push(
            getTabCfg(
                "Other",
                <Tab.Pane key="other">
                    {other.map((o, idx) =>
                        <div key={idx}>
                            <a href={o.serverUrl + "/" + o.path} >{o.name}</a>
                        </div>
                    )}
                </Tab.Pane>
            )
        )
    }

    const classes = `paneWrapper ${hidden ? "hidden" : ""}`
    return subTabs.length > 1 ?
        <Tab className={classes} renderActiveOnly={false} panes={subTabs} /> :
        <div className={classes}>{subTabs[0].pane}</div>
}


export default ResourceViewer