import ObjCheck from "./objCheck"

class Assert {

    static typeNumber(num, msg) {
        if (typeof num !== 'number') {
            console.error("Assertion Failure:" + num + " is not a number. " + msg)
        }
    }

    static typeString(str, msg) {
        if (typeof str !== 'string') {
            console.error("Assertion Failure:" + str + " is not a string. " + msg)
        }
    }

    static positiveNumber(num, msg) {
        Assert.typeNumber(num, msg)
        if (parseInt(num) <= 0) {
            console.error("Assertion Failure:" + num + " is not positive. " + msg)
        }
    }

    static positiveNonNegative(num, msg) {
        Assert.typeNumber(num, msg)
        if (parseInt(num) < 0) {
            console.error("Assertion Failure:" + num + " is negative. " + msg)
        }
    }

    static positiveNumberOrUndefined(num, msg) {
        if (num !== undefined) {
            Assert.typeNumber(num, msg)
            if (parseInt(num) <= 0) {
                console.error("Assertion Failure:" + num + " is not positive or undefined. " + msg)
            }
        }
    }

    static typeStringOrNull(str, msg) {
        if (typeof str !== 'string' && str !== null) {
            console.error("Assertion Failure:" + str + " is not a string or null. " + msg)
        }
    }

    static assertTrue(predicat, msg) {
        if (predicat() !== true) {
            console.error("Assertion Failure: [predicat]" + msg)
        }
    }

    static trueVal(val, msg) {
        if (val !== true) {
            console.error("Assertion Failure: [trueVal]" + msg)
        }
    }

    static notNullOrUndefined(data, msg) {
        if (ObjCheck.isNullOrUndefined(data)) {
            console.error("Null or undefined data: " + data + " msg:" + msg)
        }
    }

    static condition(predicate, msg) {
        if (!predicate()) {
            console.error("Assertion failed:" + msg)
        }
    }

    static fail(msg) {
        console.error("Assertion fail:" + msg)
    }
}

export default Assert