import Config from "../constnats/config"
import Utils from "./utils"

class MathUtil {

    /**
     * @param {number} a Reqired 
     * @param {number} b Reqired
     * @returns {number} not-unll
     */
    static sum(a, b) {
        return a + b
    }

    /**
     * @param {number} a Reqired 
     * @param {number} b Reqired
     * @returns {number} not-unll
     */
    static subtract(a, b) {
        return a - b
    }

    /**
     * @param {any} param1 Reqired 
     * @param {any} param2 Reqired
     * @returns {string} not-unll
     */
    static safeExecMathOp(param1, param2, op) {
        let res
        if (Utils.isNumber(param1) && Utils.isNumber(param2)) {
            res = Number(op(Utils.toNumber(param1), Utils.toNumber(param2)).toFixed(Config.FLOATING_POINT_PRECISION))
        } else {
            res = "-"
        }
        return res
    }
}


export default MathUtil