import Alert from "./alert"
import ObjCheck from "./objCheck"
import { ReportField, ReportFillingType, ReportType } from "../constnats/reportConstants"

/**
 * 
 * @param {{[ReportField.YEAR], [ReportField.TYPE], [ReportField.PERIOD]}} report Required 
 * @param {int|string} companyId  Required
 * @returns not-null
 */
function getResourceFilter(report, companyId) {

    const year = report[ReportField.YEAR]
    const latestFiling = report[ReportField.TYPE] === ReportFillingType.LATEST_FILLING

    return {
        companyId: companyId,
        year: latestFiling ? Number(year) + 1 : year,
        quoter: getGetPeriod(report[ReportField.PERIOD]),
        excludeOriginalOnly: latestFiling
    }
}


/**
 * 
 * @param {string} period Rquired 
 * @returns not-null
 */
function getGetPeriod(period) {

    let resourcePeriod
    if (period === "Q1") {
        resourcePeriod = "1"
    } else if (period === "H1" || period === "Q2") {
        resourcePeriod = "2"
    } else if (period === "Q3" || period === "9M") {
        resourcePeriod = "3"
    } else if (period === "Q4" || period === "H2" || period === "FY") {
        resourcePeriod = "4"
    } else {
        console.error("Unexpected period:" + period)
    }

    return resourcePeriod
}


/**
 * @param {string} path File path requred 
 * @returns {ResourceProps} not-null 
 */
function toResourceProps(path) {
    const fileNameStartIdx = path.lastIndexOf("/") + 1
    const fileName = path.slice(fileNameStartIdx - path.length)


    return ResourceProps.deserialize(fileName)
}

const removeFileExtension = path => {
    const extensionStartIdx = path.lastIndexOf(".")
    return path.slice(0, extensionStartIdx)
}


function sortResourceInfos(resourceInfos) {
    return resourceInfos.sort((a, b) => {
        a = a.name
        b = b.name

        if (a === b) {
            return 0;
        } else if (a > b) {
            return 1;
        } else {
            return -1;
        }
    })
}

function getExtension(path) {
    const pathArr = path.split(".")
    return pathArr.length > 1 ? (pathArr[pathArr.length - 1].toLowerCase()) : ""
}

class ResourceProps {

    constructor(finDocType = "", reportType = "", note = false, suffix = "",
        fileExtension = "", uuid = null) {

        this.finDocType = finDocType
        this.reportType = reportType
        this.note = note
        this.suffix = suffix
        this.fileExtension = fileExtension
        this.uuid = uuid
    }

    static valid(props) {
        return (!ObjCheck.isNullUndefinedOrEmpty(props.finDocType))
            && (!ObjCheck.isNullUndefinedOrEmpty(props.reportType))
    }

    static serialize(props) {
        const uuidPrefix = props.uuid === null ? "" : props.uuid + "--"
        return `${uuidPrefix}${props.finDocType}_${props.reportType}_${props.note ? "t" : "f"}_${props.suffix}.${props.fileExtension}`
    }

    /**
    * @param {string} fileName File name with extension Required 
    * @returns not-null
    */
    static deserialize(fileName) {
        const uuidEndIdx = fileName.indexOf("--")
        let fileNameWithoutUUID

        if (uuidEndIdx > 0) {
            fileNameWithoutUUID = fileName.slice(uuidEndIdx + 2 - fileName.length)
        } else {
            fileNameWithoutUUID = fileName
        }

        const [finDocType, encodedReportType, isNote, suffix] = removeFileExtension(fileNameWithoutUUID).split("_")

        const reportType = encodedReportType === undefined ? ReportType.STANDARD : encodedReportType
        const note = isNote === "t" ? true : false
        return new ResourceProps(finDocType, reportType, note, suffix,
            getExtension(fileNameWithoutUUID),
            fileName.slice(0, uuidEndIdx))
    }

    static userFriendlyName(props, withExtension) {
        const note = props.note ? " Note" : ""
        const suffix = ObjCheck.isNullUndefinedOrEmpty(props.suffix) ? "" : ` ${props.suffix}`
        const extension = withExtension ? ("." + props.fileExtension.toLowerCase()) : ""
        return `${props.finDocType} ${props.reportType}${note}${suffix}${extension}`.replace(/\s\s+/g, ' ')
    }
}


function getRelocationPath(oldPath, year, period, originalOnly, resourceProps) {
    const fileName = ResourceProps.serialize(resourceProps)

    if (!ResourceProps.valid(resourceProps)
        || ObjCheck.isNullUndefinedOrEmpty(year)
        || ObjCheck.isNullUndefinedOrEmpty(period)) {
        Alert.warn("The Financial Document Type, Report Type, Year and Period must be configured for all files.")
        return
    }

    const oldPathSections = oldPath.split("/")

    if (oldPathSections.length === 4) {
        oldPathSections[1] = toYearComponent(year)
        oldPathSections[2] = period
        oldPathSections[3] = fileName
        if (originalOnly === true) {
            oldPathSections.splice(3, 0, "original_only")
        }
    } else if (oldPathSections.length === 5) {
        oldPathSections[1] = toYearComponent(year)
        oldPathSections[2] = period
        oldPathSections[4] = fileName
        if (originalOnly === false) {
            oldPathSections.splice(3, 1)
        }
    } else {
        Alert.warn("Unexpected resource path:" + oldPath)
        throw new Error("Unexpected resource path:" + oldPath)
    }

    return oldPathSections.join("/")
}

function toYearComponent(origianlYear) {
    origianlYear = Number(origianlYear)
    return "original_" + origianlYear + "_latest_" + (origianlYear - 1)
}
/** 
 * format original_2018_latest_2017
 */
function getYearsComponent(path) {
    return path.split("/")[1]
}

function getPeriod(path) {
    return path.split("/")[2]
}

export {
    getResourceFilter, getGetPeriod, toResourceProps,
    sortResourceInfos, ResourceProps, getExtension, removeFileExtension,
    getRelocationPath, getYearsComponent, getPeriod
}