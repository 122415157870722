import HttpReq from "../../service/httpReq"

class UserRepo {
    static clientPrefix = 'api/users'
    static mngmtPrefix = 'api/mgmt/users'
    static publicPrefix = 'api/public/users'

    static usersCache = {}

    static get(id, userConsumer, errorConsumer) {
        HttpReq.get(UserRepo.clientPrefix + "/" + id, userConsumer, errorConsumer)
    }

    static update(updateUserSpec, userConsumer, errorConsumer) {
        HttpReq.put(UserRepo.clientPrefix, updateUserSpec, userConsumer, errorConsumer)
    }

    static activate(code, onSuccess, onError) {
        HttpReq.put(UserRepo.publicPrefix + "?" + new URLSearchParams({ activate: true, code }), {},
            onSuccess, onError)
    }

    static changePass(changePassSpec, successConsumer, errorConsumer) {
        HttpReq.put(UserRepo.clientPrefix + "/password", changePassSpec, successConsumer, errorConsumer)
    }

    static list(listSpec, usersConsumer, errorConsumer) {
        const cacheKey = JSON.stringify(listSpec)
        if (UserRepo.usersCache[cacheKey] === undefined) {
            HttpReq.get(UserRepo.mngmtPrefix + "?" + new URLSearchParams(listSpec), users => {
                UserRepo.usersCache[cacheKey] = users
                usersConsumer(users)
            }, errorConsumer)
        } else {
            usersConsumer(UserRepo.usersCache[cacheKey])
        }
    }

    static updateInternal(updateSpec, userConsumer, errorConsumer) {
        HttpReq.put(UserRepo.mngmtPrefix, updateSpec, userConsumer, errorConsumer)
    }
}

export default UserRepo